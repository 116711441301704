import React, { Component } from 'react';
import { clone, colorApplication, convertDate, formatNumberSpaces, isset, issetDot, redirect, t, time } from '../../../base/Utils';
import Chart from '../analytics/chart/Chart';
import PropTypes from 'prop-types';
import ProfilePicture from '../profilePicture/ProfilePicture';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import i18n from '../../../i18n';
import Text from '../text/Text';
import styled from 'styled-components';
import Select from '../select/Select';
import { LabelsPopup } from '../label/Label';
import ProgressBar, { ProgressBarSkeleton } from '../progressBar/ProgressBar';
import { TableMedia } from '../media/Media';
import Status from '../../containers/analytics/components/status/Status';

export const types = ['Normal', 'Analytics', 'BigScreen', 'Insights'];

var NoDataArea = styled.div`
    width: 100%;
    font-size: 18px;
    position: relative;
    padding: 16px;
    box-sizing: border-box;
    text-align: center;
`;

var TableArea = styled.table`
    border-collapse: separate;
    border-spacing: 0;
    border: ${props => props.type === 'BigScreen' || props.type.includes('Insights') ? 0 : '1px solid #dedede'};
    border-radius: 6px;
    width: 100%;

    thead td {
        font-weight: bold;
        border-bottom: ${props => props.type === 'BigScreen' ? 0 : '1px solid #dedede'};
    }

    thead td:first-child {
        padding-left: ${props => !props.type.includes('Insights') ? '24px' : '0px'};
    }

    tr {
        text-align: left;
        height: ${props => !props.type.includes('Insights') ? props.type === 'BigScreen' ? '55px' : '60px' : '40px'};
        font-size: 14px;

        td:first-child {
            padding-left: ${props => !props.type.includes('Insights') ? '24px' : '0px'};
        }
        td:last-child {
            padding-right: ${props => !props.type.includes('Insights') ? '24px' : '0px'}
        }
    }

    tbody tr:last-child td{
        border-bottom: 0;
    }

    tbody tr:hover{
            ${props => props.handleChildRow && { backgroundColor: 'rgba(10,92,156,.04) !important', cursor: 'pointer' }}
    }

    tbody tr:last-child:hover{
            ${props => props.pagination && Object.keys(props.pagination).length > 0 && { backgroundColor: 'white !important', cursor: 'default' }}
    }

    td {
        padding-right: 36px;
        margin: 0;
        border-bottom: ${props => props.type === 'BigScreen' ? 0 : '1px solid #dedede'};
    }

    .TdGroup {
        td {
            border-bottom: 0;
        }
    }
`;

var TablePaginationArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

var PaginationButtons = styled.div`
    > *{
        margin: 0px 20px;
    }
    :last-child{
        margin-right: 0px
    }
`;

var rowOptions = [
    {
        value: 5,
        label: 5
    },
    {
        value: 10,
        label: 10
    },
    {
        value: 30,
        label: 30
    },
    {
        value: 50,
        label: 50
    }
];

class Table extends Component {
    constructor(props) {
        super(props);
        this.valueToUppercase = false;
        this.canBeFixed = true;
        this.state = { showPopupLabels: false, labels: [], rowKey: 0 };
    }

    handleLabelsPopup(labels) {
        var rowKey = this.state.rowKey;
        var body = this.props.data.body[`${rowKey}`];
        for (let bodyValue of body) {
            if (bodyValue.type === 'labels') {
                bodyValue.labels = labels;
            }
        }
        this.setState({ showPopupLabels: false });
    }

    addDefaultImage(e) {
        e.target.src = require('../../../resources/images/no-media.svg');
    }

    handleSortType(sortType, value, defaultSort, key) {
        var data = this.props.data;
        var body = data.body;
        var head = data.head;
        switch (sortType) {
        case 'request':
            if (isset(this.props.onPaginationCallback)) {
                this.props.onPaginationCallback({ orderBy: value, sortedBy: (defaultSort === 'asc' ? 'desc' : 'asc') }, true);
            }
            break;
        case 'local':
            if (defaultSort === 'desc') {
                head[`${key}`].value.defaultSort = 'asc';
                body.sort(function (a, b) {
                    return a[`${key}`].value.value.toLowerCase() > b[`${key}`].value.value.toLowerCase() ? -1 : a[`${key}`].value.value.toLowerCase() < b[`${key}`].value.value.toLowerCase() ? 1 : 0;
                });
            }
            else {
                head[`${key}`].value.defaultSort = 'desc';
                body.sort(function (a, b) {
                    return b[`${key}`].value.value.toLowerCase() > a[`${key}`].value.value.toLowerCase() ? -1 : b[`${key}`].value.value.toLowerCase() < a[`${key}`].value.value.toLowerCase() ? 1 : 0;
                });
            }

            if (isset(this.props.onSorted)) {
                this.props.onSorted((defaultSort === 'asc' ? 'desc' : 'asc'));
            }

            break;
        default:
            break;
        }
        this.setState({ data });
    }

    getCell = (data, key, rowKey, tableType, subItem) => {
        data = clone(data, false);

        var cellValue = '';
        var spacing = isset(data.spacing) ? data.spacing : 'normal';
        var align = isset(data.align) ? data.align : 'left';
        var loadingError = issetDot(data, 'loadingError', false);
        var style = {};

        if (isset(data.icon) && isset(data.icon.value)) {
            cellValue = <Icon set={data.icon.value} />;
        }

        if (spacing === 'tight') {
            style.width = this.props.fixTable ? 'auto' : '1px';
            style.padding = '0px 8px';
            style.whiteSpace = 'nowrap';
            this.canBeFixed = false;
        }

        if (spacing === 'compact') {
            style.width = this.props.fixTable ? 'auto' : '1px';
            style.padding = '0px 24px';
            style.whiteSpace = 'nowrap';
            this.canBeFixed = false;
        }

        if (spacing === 'fixed') {
            style.width = '200px';
            style.maxWidth = '200px';
        }

        switch (data.type) {
        case 'value':
            var type = issetDot(data, 'value.type', '');
            var value = issetDot(data, 'value.value', '');
            var tooltip = issetDot(data, 'value.tooltip', '');
            var sortable = issetDot(data, 'value.sortable', false);
            var defaultSort = issetDot(data, 'value.defaultSort', '');
            var orderValue = '';
            if (type === 'number') {
                value = formatNumberSpaces(value);
            }
            else if (type === 'string') {
                orderValue = clone(value);
                style.whiteSpace = 'nowrap';
                style.textOverflow = 'ellipsis';
                style.overflow = 'hidden';
                value = i18n.t(value);

                if (this.valueToUppercase || (this.props.type === 'BigScreen'))
                    style.textTransform = 'uppercase';
            } else if (type === 'percentage') {
                value = value + '%';
            }
            else if (type === 'currency') {
                value = formatNumberSpaces(value) + isset(data.currency, '');
            }
            else if (type === 'date') {
                var dateType = isset(data, 'value.dateType', 'full');

                if (dateType === 'relative') {
                    value = time(value);
                }
                else {
                    value = convertDate(value, true, true);
                }
            }
            if (sortable && !this.props.isExporting && !this.props.noFilters) {
                data = this.props.data;
                var sortType = isset(this.props.sortType, 'local');
                switch (orderValue) {
                case 'Social Network(s)':
                    orderValue = 'network';
                    break;
                case 'Profile(s)':
                    orderValue = 'profile';
                    break;
                case 'Ad Name':
                    orderValue = 'adName';
                    break;
                case 'Creation Date':
                    orderValue = 'created_at';
                    break;
                case 'Date':
                    orderValue = 'publishedTime';
                    break;
                case 'Engagement':
                    orderValue = 'totalEngagement';
                    break;
                default:
                    orderValue = orderValue.toLowerCase();
                    break;
                }

                value = <div style={{ justifyContent: align, cursor: 'pointer', whiteSpace: 'nowrap', display: 'flex', alignItems: 'center', width: 'fit-content' }} onClick={() => this.handleSortType(sortType, orderValue, isset(this.props.orderBy, '') !== orderValue ? 'asc' : sortType === 'local' ? defaultSort : isset(this.props.sortedBy, ''), key)} >{value} {tooltip && <Icon name='Help Circle' size='Small' style={{ marginLeft: '5px' }} tooltip={i18n.t(tooltip)} />} {isset(this.props.orderBy, '') === orderValue && <Icon color='black' name={'Arrow ' + ((sortType === 'local' ? defaultSort : isset(this.props.sortedBy, '').toLowerCase()) !== 'asc' ? 'Down' : 'Up')} />}
                    {loadingError && tableType === 'head' && <Status style={{ marginLeft: '8px', display: 'inline-flex', height: '100%' }} >
                        <Text>{t(<>Unable to collect data. Please <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => redirect()} color='Blue'>refresh</Text> to try again.</>)}</Text>
                    </Status>}
                </div>;
            }
            if (type === 'component') {
                cellValue = value;
                var elements = document.getElementsByClassName('tr_' + rowKey);
                if (isset(value.props.isChecked)) {
                    for (var i = 0; i < elements.length; i++) {
                        elements[`${i}`].style.backgroundColor = value.props.isChecked ? 'rgba(10,92,156,.04)' : 'transparent';
                    }
                }
            }
            else
                cellValue = <div style={{ display: 'flex', alignItems: 'center', justifyContent: align, width: '100%' }}><Text align={align} weight={((tableType === 'head' && this.props.type !== 'BigScreen') || (this.props.type === 'BigScreen' && data.size === 'large')) ? 'Bold' : 'Regular'} singleLine size={this.props.type === 'BigScreen' ? data.size === 'small' ? 'Big' : data.size === 'large' ? 'Giant' : 'Huge' : 'Medium'} color={isset(data.color, this.props.type === 'BigScreen' ? 'white' : 'black')}>{value}</Text> {tooltip && !sortable && <Icon name='Help Circle' size='Small' style={{ marginLeft: '5px' }} tooltip={i18n.t(tooltip)} />}
                </div>;
            break;
        case 'chart':
            cellValue = <div style={{ minWidth: this.props.type === 'BigScreen' ? '260px' : 'auto' }}>
                <Chart type="horizontalBar" isExporting={this.props.isExporting} parentType={this.props.type} data={data} />
            </div>;
            break;
        case 'component':
            cellValue = data.value.value;
            break;
        case 'photo':
            cellValue = <ProfilePicture type='Big' background={data.photo.value} />;
            break;
        case 'media':
            cellValue = <TableMedia isExporting={this.props.isExporting} data={data.media} thumbnail={data.thumbnail} parentType={this.props.type} />;
            break;
        case 'action':
            cellValue = <Button type="Link" to={data.action.value} >{i18n.t('See More')}</Button>;
            break;
        case 'icon':
            cellValue = <Icon tooltip={i18n.t(data.icon.tooltip)} name={data.icon.value} size="Big" />;
            break;
        case 'profile':
            if (isset(data.profile))
                cellValue = <ProfilePicture tooltip={data.profile.name} type='Big' background={data.profile.photo} />;
            break;
        case 'labels':
            for (var id in data.labels) {
                tooltip = '';
                if (id <= 6) {
                    tooltip = data.labels[`${id}`].text + '\n';
                }
                else {
                    tooltip = 'and other ' + (data.labels.length - 6);
                    break;
                }
            }
            cellValue = <Button type="Link" tooltip={tooltip} onClick={() => this.setState({ labels: data.labels, showPopupLabels: true, rowKey: rowKey })} >{data.labels.length > 0 ? i18n.t(data.labels.length + ' Tags') : i18n.t('Add Tag')}</Button>;
            break;
        case 'group':
            cellValue = isset(data.items, []).map((item, key) => {
                return this.getCell(item, key, rowKey, tableType, true);
            });
            cellValue = <div className='TdGroup' style={{ display: 'flex', alignItems: 'center', justifyContent: align === 'right' ? 'flex-end' : 'flex-start' }}>{cellValue}</div>;
            break;
        case 'loading':
            cellValue = !this.props.isExporting ? <ProgressBarSkeleton style={{ justifyContent: align === 'right' ? 'flex-end' : 'flex-start' }} /> : '-';
            break;
        default:
            break;
        }

        if (data.loading && tableType === 'body') {
            cellValue = !this.props.isExporting ? <ProgressBarSkeleton style={{ justifyContent: align === 'right' ? 'flex-end' : 'flex-start' }} /> : '-';
        }

        if (loadingError && tableType === 'body') {
            cellValue = <Text color='Grey'>{i18n.t('N/A')}</Text>;
        }

        if (isset(data.color)) {
            var auxColor = colorApplication(data.color, {});
            style.color = data.color = auxColor.color;
        }

        if (isset(data.size)) {
            if (data.size === 'large') {
                style.fontSize = '36px';
                style.fontWeight = 'bold';
                style.textTransform = 'uppercase';
            }

            if (data.size === 'small') {
                style.fontSize = '16px';
            }
        }

        style.textAlign = align;

        if (subItem) {
            style.padding = '0px 2px';
            return <div key={key} style={style}>{cellValue}</div>;
        }

        return <td key={key} colSpan={data.columns || 1} rowSpan={data.rows || 1} style={style}>{cellValue}</td>;
    };

    render() {
        var { type, noHead, rowsPerPageOptions = [], pagination, paginationText, noRows, toUppercase, onPaginationCallback, handleChildRow, loading, area, defaultRowsPerPage, ...props } = this.props;
        var columns = 0;
        var thead = null;
        this.valueToUppercase = toUppercase;
        var data = this.props.data;

        if (isset(data.head, []).length > 0 && !noHead) {
            thead = <thead><tr>{
                data.head.map((cell, key) => {
                    columns += cell.columns || 1;

                    return this.getCell(cell, key, 0, 'head');
                })
            }</tr></thead>;
        } else {
            columns = 1;
        }

        if (rowsPerPageOptions.length < 1)
            rowsPerPageOptions = rowOptions;

        var tbody = null;
        var maxRow = 1;
        if (isset(data.body)) {
            if (data.body.length > 0) {
                tbody = <tbody data-disable={loading}>{
                    data.body.map((row, rowKey) => {
                        maxRow = row.length;
                        return <tr key={rowKey} className={'tr_' + rowKey} onClick={() => isset(handleChildRow) ? handleChildRow(rowKey) : function () { }}>{
                            row.map((cell, key) => this.getCell(cell, key, rowKey, 'body'))
                        }</tr>;
                    })
                }
                {(pagination && Object.keys(pagination).length > 0) && <tr>
                    <td colSpan={maxRow}>
                        <TablePaginationArea {...props}>
                            <Text>{paginationText}</Text>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {noRows ? null : <><Text style={{ marginRight: '16px' }}>{i18n.t('Rows per page')}</Text>
                                    <div style={{ width: '100px', marginRight: '76px' }}>
                                        <Select menuPlacement={type === 'Analytics' ? 'top' : 'auto'} value={defaultRowsPerPage ? rowOptions.find(option => option.value === parseInt(defaultRowsPerPage, rowOptions[0])) : rowOptions[0]} options={rowsPerPageOptions} onChange={(e) => onPaginationCallback({ rowsPerPage: e.value })} />
                                    </div></>}
                                <Text>{pagination.current} of {pagination.total}</Text>
                                <PaginationButtons>
                                    <Icon style={{ cursor: 'pointer' }} color='black' name="Angle Left Skip" data-disable={pagination.current === 1} onClick={() => onPaginationCallback({ page: pagination.first })} />
                                    <Icon style={{ cursor: 'pointer' }} color='black' name="Chevron Left" data-disable={pagination.current === 1} onClick={() => onPaginationCallback({ page: pagination.prev })} />
                                    <Icon style={{ cursor: 'pointer' }} color='black' name="Chevron Right" data-disable={pagination.current === pagination.total} onClick={() => onPaginationCallback({ page: pagination.next })} />
                                    <Icon style={{ cursor: 'pointer' }} color='black' name="Angle Right Skip" data-disable={pagination.current === pagination.total} onClick={() => onPaginationCallback({ page: pagination.last })} />
                                </PaginationButtons>
                            </div>
                        </TablePaginationArea>
                    </td>
                </tr>}
                </tbody>;
            }
        }

        if (!tbody) {
            tbody = <tbody data-disable={loading}><td colSpan={columns}><NoDataArea><Text align='center' singleLine size={this.props.type === 'BigScreen' ? 'Huge' : 'Medium'} color={this.props.type === 'BigScreen' ? 'white' : 'black'}>{i18n.t('No data to show.')}</Text></NoDataArea></td></tbody>;
        }

        return <>
            <TableArea pagination={pagination} handleChildRow={isset(handleChildRow)} type={type} style={{ tableLayout: (this.canBeFixed || this.props.fixTable ? 'fixed' : 'auto') }}>
                {thead}
                {loading && <td style={{ padding: '0px', height: 'auto', borderBottom: '0px' }} colSpan={columns}>{<ProgressBar style={{ height: '4px' }} type='Animated' background='transparentBlue' color='azureBlue' />}</td>}
                {tbody}
            </TableArea>
            {this.state.showPopupLabels && <LabelsPopup area={area} icsOpen={this.state.showPopupLabels} callbackLabelPopup={(newLabels) => this.handleLabelsPopup(newLabels)} activeLabels={this.state.labels} />}
        </>;
    }
}

Table.propTypes = {
    /**
     * Custom class for table styling
     */
    type: PropTypes.oneOf(types),
    /**
     * Array for data
     */
    data: PropTypes.object,
    /**
     * set All value to uppercase
     */
    toUppercase: PropTypes.bool,
    /**
     *  Remove the head from table
     */
    noHead: PropTypes.bool,
    /**
     *  show Pagination Area
     */
    enablePagination: PropTypes.bool,
    /**
     *  The current Page from table
     */
    currentPage: PropTypes.number,
    /**
     *  The maximum Page from table
     */
    maxPage: PropTypes.number,
    /**
     *  callback from pagination area
     */
    onPaginationCallback: PropTypes.func
};

Table.defaultProps = {
    type: 'Normal',
    data: {}
};

export default Table;
