import React from 'react';
import { clone, colorApplication, convertDate, formatNumberSpaces, isset, issetDot, number, redirect, t, time } from '../../../../base/Utils';
import Icon from '../../icon/Icon';
import Text from '../../text/Text';
import i18n from '../../../../i18n';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FilterLabel from '../../../containers/listening/components/filterLabel/FilterLabel';
import Status from '../../../containers/analytics/components/status/Status';

const DescriptiveItem = styled.div`
    width: auto;
    white-space: nowrap;
    display: ${props => props.type === 'BigScreen' ? 'block' : 'flex'};
    flex-direction: column;
    margin: ${props => isset(props.type, '').includes('Insights') ? '0px 36px 16px 0px' : '0px 8px 12px 8px'};
    max-width: ${props => props.type && (!props.isExporting || props.type !== 'BigScreen') && '300px'};
    padding: ${props => props.type && props.type.includes('Analytics') && props.area !== 'competitors' && '0px 8px'};
    ${props => props.fairDivision && { minWidth: '120px' }};
    ${props => props.spacing === 'bottom' ? { marginBottom: '45px' } : props.spacing === 'top' && { marginTop: '45px' }};
`;

const TrendingArea = styled.div`
    margin-top: ${props => props.type !== 'BigScreen' && '8px'} ;
    display: ${props => props.type === 'BigScreen' ? 'inline-flex' : 'flex'};
    align-items: center;
    width: fit-content;
    max-width: '100%';
    ${props => props.type === 'BigScreen' && { verticalAlign: 'text-bottom', marginLeft: '25px' }}
`;

var colorCounts = {};
var iconColorCounts = {};

const handleValueType = (value, type, currency, formatNumber) => {
    if (isset(value)) {
        switch (type) {
        case 'percentage':
            value = formatNumberSpaces(value) + '%';
            break;
        case 'currency':
            value = formatNumberSpaces(value) + isset(currency, '');
            break;
        default:
            if (formatNumber && typeof value === 'number', value >= 1E9) {
                value = number(value, 0, 1E9, 1E6);
                break;
            }
            value = /^\d+\.\d+$/.test(value) || typeof value === 'number' ? formatNumberSpaces(value) : t(value);
            break;
        }
    }
    else {
        value = i18n.t('No Data');
    }
    return value;
};

const DescriptiveItems = ({ data, type, area, descriptiveTitleVisible, formatNumber, isExporting }) => {

    data = clone(data) || {};
    data.items = data.items || [];
    var isPositive = false;
    var isNeutral = false;
    colorCounts = {};
    iconColorCounts = {};
    var sizes = [];
    var splitted = [];

    data.items.map((item) => {
        var size = isset(item.size, 'large');

        if (sizes.indexOf(size) === -1) {
            sizes.push(size);
            splitted[sizes.indexOf(size)] = [];
        }

        splitted[sizes.indexOf(size)].push(item);
        return null;
    });

    var fairDivision = area === 'competitors' ? true : isset(data.fairDivision, false);

    var style = { display: 'flex', flexWrap: 'wrap', zIndex: '1' };

    if (fairDivision) {
        style.width = '100%';
    }

    return splitted.map((items, sKey) => {
        return <div key={sKey} style={style}>
            {items.map((item, key) => {
                if (isset(item.extra)) {
                    if (item.extra.type === 'growth') {
                        if (parseFloat(isset(item.extra.value.value, 0)) === 0.00) {
                            isNeutral = true;
                            isPositive = false;
                        }
                        else if (parseFloat(isset(item.extra.value.value, 0)) > 0.00) {
                            isPositive = true;
                            isNeutral = false;
                        }
                        else {
                            isNeutral = false;
                            isPositive = false;
                            item.extra.value.value *= -1;
                        }

                        var extraColor = (isNeutral ? 'Grey' : (isPositive ? 'Green' : 'Red'));

                    }
                    else if (item.extra.type === 'negative' || item.extra.type === 'neutral' || item.extra.type === 'positive') {
                        extraColor = item.extra.type;
                    }
                    else {
                        if (isset(item.extra.color) && item.extra.color) {
                            var auxColor = colorApplication(item.extra.color, colorCounts, 'Extra');
                            colorCounts = auxColor.counts;
                            extraColor = auxColor.color;
                        }
                    }
                }

                if (issetDot(item, 'icon.color')) {
                    if (item.icon.color.toLowerCase() === 'primarypallet') {
                        auxColor = colorApplication(item.icon.color, iconColorCounts, 'Icons');
                        iconColorCounts = auxColor.counts;
                        var iconColor = auxColor.color;
                    }
                    else {
                        iconColor = item.icon.color;
                    }
                }

                if (issetDot(item, 'color')) {
                    if (item.color.toLowerCase() === 'primarypallet') {
                        auxColor = colorApplication(item.color, { Value: { primaryPallet: item.stack } }, 'Value');
                        var valueColor = auxColor.color;
                    }
                    else {
                        valueColor = item.color;
                    }
                }

                var descriptionStyle = { paddingLeft: '8px' };
                if (type === 'Dashboard') {
                    descriptionStyle = { position: 'absolute', top: '0', right: '0', display: 'flex', alignItems: 'center' };
                }

                return <DescriptiveItem isExporting={isExporting} spacing={item.spacing} area={area} type={type} key={key} fairDivision={fairDivision}>
                    <div style={{ marginBottom: '8px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {isset(item.icon)
                            ? <Icon
                                color={iconColor}
                                name={item.icon.value}
                                style={{ marginRight: '8px' }} />
                            : null}
                        <div style={{ display: 'inline-flex' }}>
                            {(isset(item.title) && item.title.toString().trim() !== '')
                                ? <Text
                                    data-skeleton={item.loading && !isExporting}
                                    style={descriptiveTitleVisible ? { overflow: 'visible' } : {}}
                                    singleLine
                                    size={type === 'BigScreen' ? 'Huge' : isset(item.size, 'large') !== 'large' ? 'Medium' : 'Big'}
                                    color={type === 'BigScreen' ? 'White' : 'black'}>
                                    {!isExporting && item.loading ? 'Big Number 1' : item.title === 'emptyTitle' ? '‏‏‎ ‎' : i18n.t(item.title)}
                                </Text>
                                : null
                            }
                            {item.failedData && <Status style={{ marginLeft: '8px', display: 'inline-flex', height: '100%' }} data={data.status} >
                                <Text>{t(<>Unable to collect data. Please <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => redirect()} color='Blue'>refresh</Text> to try again.</>)}</Text>
                            </Status>}
                        </div>
                        {(isset(item.subTitle) && item.subTitle.toString().trim() !== '') &&
                            <Text size='Medium' color='grey' >
                                {i18n.t(item.subTitle)}
                            </Text>}
                        <div style={descriptionStyle}>
                            {item.filtered && <FilterLabel />}
                            {!isExporting ? isset(item.description) && ((isset(item.helpUrl) && item.helpUrl) ? <Icon onClick={() => redirect(item.helpUrl, '_blank')} name="Help Outline" color="Grey" style={{ cursor: 'pointer', marginLeft: '16px' }} size='Small' tooltip={i18n.t(item.description)} /> : <Icon name="Help Circle" color="Grey" size='Small' style={{ marginLeft: '16px' }} tooltip={i18n.t(item.description)} />) : null}
                        </div>
                    </div>
                    {isset(item.lastUpdatedTime)
                        ? <div style={{ position: 'absolute', top: '0px', right: '0px' }}><Text singleLine style={{ textTransform: 'uppercase' }} size="Tiny" color="Grey">
                            {time(item.lastUpdatedTime)}
                        </Text></div>
                        : null}
                    {isset(item.value) || item.failedData
                        ? <Text
                            data-skeleton={!isExporting && item.loading}
                            singleLine
                            style={type === 'BigScreen' ? { height: '77px' } : {}}
                            size={type === 'BigScreen' ? 'Titanic' : isset(item.size, 'large') !== 'large' ? type.includes('Insights') ? 'Biggest' : isset(item.size, 'large') === 'medium' ? isExporting ? 'Big' : 'Biggest' : 'Big' : 'Giant'}
                            weight={type === 'BigScreen' ? 'Black' : 'SemiBold'}
                            tooltip={(isset(item.startDate) && isset(item.endDate)) ? convertDate(item.startDate) + ' - ' + convertDate(item.endDate) : typeof item.value === 'string' ? t(item.value) : formatNumberSpaces(item.value)}
                            color={item.failedData ? 'alto' : isset(item.color) ? valueColor : type === 'BigScreen' ? 'White' : 'black'}>
                            {!isExporting && item.loading ? '999 999 999' : handleValueType(item.failedData ? undefined : item.value, item.type, item.currency, formatNumber)}
                        </Text>
                        : null}
                    {isset(item.vs)
                        && <div style={{ marginTop: '5px', display: 'flex', alignItems: 'center', width: 'fit-content', maxWidth: '100%' }} data-skeleton={!isExporting && item.loading}>
                            <Text singleLine size="Biggest" color="Blue" >VS</Text>
                            <Text tooltip={(isset(item.vs.startDate) && isset(item.vs.endDate)) ? convertDate(item.vs.startDate) + ' - ' + convertDate(item.vs.endDate) : typeof item.vs.value === 'string' ? t(item.vs.value) : formatNumberSpaces(item.vs.value)} style={{ marginLeft: '8px' }} singleLine size={isExporting ? 'Big' : 'Biggest'} color={isset(item.vs.value) ? 'Black' : 'Alto'} >{!isExporting && item.loading ? '999 999 999' : handleValueType(item.failedData ? undefined : item.vs.value, item.vs.type, item.vs.currency, formatNumber)}</Text>
                        </div>
                    }
                    {isset(item.extra)
                        && <TrendingArea data-skeleton={!isExporting && item.loading} type={type}>
                            {item.extra.type === 'growth' ?
                                <Icon style={type === 'BigScreen' ? { marginRight: '4px' } : {}} size={type === 'BigScreen' ? 'Huge' : 'Big'} name={'Trending ' + (isNeutral ? 'Flat' : (isPositive ? 'Up' : 'Down'))} color={isNeutral ? 'Grey' : (isPositive ? 'Green' : 'Red')} />
                                :
                                <div style={{ width: '0px', height: '10px', float: 'left' }}></div>
                            }
                            <Text singleLine size={type === 'BigScreen' ? 'Colossal' : isset(item.icon) ? 'Small' : 'Medium'} color={extraColor} >{!isExporting && item.loading ? '999 999 999' : handleValueType(item.extra.value.value, item.extra.value.type, item.extra.value.currency, formatNumber)}</Text>
                        </TrendingArea>}
                </DescriptiveItem>;
            })}
        </div>;
    });
};

DescriptiveItems.propTypes = {
    /**
     * DescriptiveItem data
     */
    data: PropTypes.object
};

export default DescriptiveItems;
