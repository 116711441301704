import React, { Component } from 'react';
import { isset, issetDot, t, time } from '../../../base/Utils';
import Text from '../text/Text';
import ProfilePicture from '../profilePicture/ProfilePicture';
import './Notes.scss';
import i18n from '../../../i18n';
import Icon from '../icon/Icon';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import Button from '../button/Button';
import { TextArea } from '../input/Input';
import PropTypes from 'prop-types';
import NoteRequests from './NoteRequest';
import ProgressiveLoading from '../progressiveLoading/ProgressiveLoading';


const NoteArea = styled.div`
    ${props => props.disabled && 'opacity: 0.5; pointer-events: none; user-select: none;'}
`;

const NoteTopArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
`;

const NoteTopInfoArea = styled.div`
    display: flex;
    align-items: center;
`;

const NoteButtonArea = styled.div`
    display: flex;
    align-items: center;
    .Check.Circle:hover{
        color: ${Config.colors.green};
    }
    .Pencil:hover{
        color: ${Config.colors.blue};
    }
`;
export default class Note extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editNote: false,
            data: {}
        };
    }

    closeEditNote(data, history) {
        this.setState({ editNote: false, dataState: isset(data, {}) });

        if (isset(this.props.onCallback)) {
            this.props.onCallback(history);
        }
    }

    cancelNote(response) {
        if (response) {
            this.setState({ editNote: false });
        }
    }

    render() {
        var dataState = this.state.dataState || {};

        var { onCheckClick = function () { }, data = {}, disabled = false, noteId = '', area = '', ...props } = this.props;

        var createdBy = issetDot(dataState, 'created.by.name', false) || issetDot(data, 'created.by.name', '');
        var date = time(issetDot(dataState, 'created.time', 0) || issetDot(data, 'created.time', 0));
        var noteText = isset(dataState.text, false) || isset(data.text, '');
        var photo = issetDot(dataState, 'created.by.photo', false) || issetDot(data, 'created.by.photo', '');
        var referenceId = issetDot(dataState, 'id', false) || issetDot(data, 'id', '');

        return (
            <NoteArea disabled={disabled} {...props}>
                <NoteTopArea>
                    <NoteTopInfoArea>
                        <ProfilePicture type="Normal" background={photo} />
                        <Text singleLine color="Black" style={{ margin: '0px 8px' }}>{createdBy}</Text>
                        <Text color="Grey" style={{ whiteSpace: 'nowrap' }} size="Small">{t(date)}</Text>
                    </NoteTopInfoArea>
                    <NoteButtonArea>
                        <Icon tooltip={i18n.t('Finish')} name="Check Circle" color="Grey" style={{ margin: '0px 8px', cursor: 'pointer' }} size="Medium" onClick={onCheckClick} />
                        <Icon tooltip={i18n.t('Edit')} name="Pencil" color="Grey" size="Medium" style={{ cursor: 'pointer' }} onClick={() => this.setState({ editNote: !this.state.editNote })} />
                    </NoteButtonArea>
                </NoteTopArea>
                {this.state.editNote ? <AddNote area={area} buttonText={i18n.t('Save')} onCancel={(response) => this.cancelNote(response)} edit value={noteText} onAdd={(data, history) => this.closeEditNote(data, history)} noteId={noteId} referenceId={referenceId} />
                    : <Text size="Medium" color="Black" >{noteText}</Text>
                }
            </NoteArea>
        );
    }
}

Note.propTypes = {
    /**
     * Note Function for check button
     */
    onCheckClick: PropTypes.func,
    /**
     * Note data
     */
    data: PropTypes.object,
    /**
     * disable the notification
     */
    disabled: PropTypes.bool,
    /**
     * Note note id
     */
    noteId: PropTypes.string,
    /**
     * Note area
     */
    area: PropTypes.string
};


const AddNoteBottomArea = styled.div`
    display: flex;
    align-items: top;
    justify-content: space-between;
    padding: 0px 16px;
`;
const AddNoteButtonArea = styled.div`
    display: flex;
    flex-direction: column;
`;

var countCharacters = 0;
export class AddNote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            noteAreaText: '',
        };
    }

    componentDidMount() {
        if (isset(this.props.value) && this.props.value !== '') {
            this.setState({ noteAreaText: this.props.value });
        }
    }

    handleChangeNoteBox(e) {
        var text = e.target.value;

        if (text !== '' || text.length === 0) {
            this.setState({ noteAreaText: text });
            countCharacters = text.length;
        }
    }

    addNote() {
        var area = this.props.area;
        var referenceId = this.props.referenceId;
        var edit = this.props.edit || false;
        var dateTime = Date.now();
        var checkOverwriteAfter = Math.floor(dateTime / 1000);
        var id = this.props.noteId;
        this.setState({ isDisabled: true }, () => {
            var params = { text: this.state.noteAreaText, area };

            if (edit) {
                NoteRequests.putNote(params, area, id, referenceId, checkOverwriteAfter, (responseData) => {
                    this.handleOnConfirmNote(responseData);
                });
            } else {
                NoteRequests.postNote(params, area, referenceId, (responseData) => {
                    this.handleOnConfirmNote(responseData);
                });
            }
        });
    }

    handleOnConfirmNote(responseData) {
        switch (responseData.status) {
        case 'ok':
            this.setState({ isDisabled: false });
            this.props.onAdd(responseData.data, responseData.history);
            countCharacters = 0;
            break;
        case 'error':
            this.setState({ isDisabled: false });
            break;
        default:
        }
    }

    cancelNote() {
        if (isset(this.props.onCancel)) {
            this.props.onCancel(true);
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            this.addNote();
        }
        else if (e.key === 'Escape') {
            this.cancelNote();
        }
    }

    render() {
        var maxCharacters = 2200;
        var { buttonText = i18n.t('Submit'), ...props } = this.props;

        countCharacters = this.state.noteAreaText.length;
        return (
            <NoteArea {...props} data-disable={this.state.isDisabled} >
                <TextArea
                    style={{ fontSize: '14px' }}
                    type="textarea"
                    maxCharacters={maxCharacters}
                    // value={this.state.value}
                    onInput={(e) => this.handleChangeNoteBox(e)}
                    onSelect={(e) => this.handleChangeNoteBox(e)}
                    placeholder={i18n.t('Note')}
                    value={this.state.noteAreaText}
                    onKeyDown={this.handleKeyDown}
                    readOnly={this.state.isDisabled}
                />
                <AddNoteBottomArea>
                    <AddNoteButtonArea>
                        <Text style={{ display: 'flex' }} color="Grey" ><div style={{ display: 'flex' }}>{i18n.t('enter to')} <Button style={{ fontSize: '14px', padding: '0px', height: '16px', overflow: 'visible' }} type="Link" disabled={countCharacters < 1} onClick={() => this.addNote()} >{buttonText}</Button></div></Text>
                        <Text style={{ display: 'flex' }} color="Grey"><div style={{ display: 'flex' }}>{i18n.t('esc to')} <Button style={{ fontSize: '14px', padding: '0px', height: '16px', overflow: 'visible' }} onClick={() => this.cancelNote()} type="Link" >{i18n.t('Cancel')}</Button></div></Text>
                    </AddNoteButtonArea>
                    <div>
                        <Text size="Small" color="Grey" marginRight={16} >{countCharacters + '/' + maxCharacters}</Text>
                    </div>
                </AddNoteBottomArea>
            </NoteArea>
        );
    }
}

const NoteInfoArea = styled.div`
    padding: 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export class NotesArea extends Component {
    constructor(props) {
        super(props);
        this.state = { notes: props.data, loading: false, processingNotes: false, createNewNote: false };
    }

    handleCreateNote(data, history) {
        var notes = this.state.notes;
        notes.data = [data, ...notes.data];
        this.setState({ notes: notes, createNewNote: false });
        if (this.props.onCallbackHistory) {
            this.props.onCallbackHistory(history);
        }
    }

    deleteClickedNote(id) {
        var notes = this.state.notes;
        var { referenceId, area } = this.props;

        this.setState({ loading: true }, () => {
            NoteRequests.deleteNote(area, referenceId, id, () => {
                for (var key in notes.data) {
                    if (notes.data[`${key}`].id === id) {
                        notes.data.splice(key, 1); break;
                    }
                }
                this.setState({ notes, loading: false });
            });
        });
    }

    callbackNotes(response) {
        var notes = this.state.notes;

        notes.data.map((note, key) => {
            if (note.id === response.data.id) {
                notes.data.splice(key, 1);
                notes.data.unshift(response.data);
            }
            return null;
        });

        this.setState({ notes: notes });
    }

    loadMoreNotes() {
        this.setState({ processingNotes: true }, () => {
            var notes = this.state.notes;
            var pagination = isset(notes.pagination, {});
            var { referenceId, area } = this.props;

            NoteRequests.getNotes(area, referenceId, pagination.next, (responseData) => {
                notes.data = [...notes.data, ...responseData.data];
                notes.pagination = responseData.pagination;

                this.setState({ notes, processingNotes: false });
            });
        });
    }

    render() {
        var { referenceId, area } = this.props;
        var { notes, loading, processingNotes } = this.state;

        return <>
            <NoteInfoArea>
                <Text size="Medium" color="Black" >{i18n.t('Notes')}</Text>
                <Text style={{ cursor: 'pointer' }} onClick={() => { this.setState({ createNewNote: true }); }} size="Medium" color="Blue" >{i18n.t('Add Note')}</Text>
            </NoteInfoArea>
            {this.state.createNewNote ?
                <AddNote style={{ padding: '0px 16px' }} area={area} buttonText={i18n.t('Create')} onCancel={() => this.setState({ createNewNote: false })} onAdd={(data, history) => this.handleCreateNote(data, history)} referenceId={referenceId} />
                : null}
            {isset(notes.data, []).length !== 0 ?
                <ProgressiveLoading onTriggerCallback={() => this.loadMoreNotes()} pagination={notes.pagination} isLoading={processingNotes}  >
                    {isset(notes.data, []).map((note, index) => (
                        <Note
                            key={index}
                            style={{ padding: '12px 16px' }}
                            onCallback={(response) => this.callbackNotes(response)}
                            disabled={loading}
                            data={note}
                            noteId={referenceId}
                            area={area}
                            onDeleteClick={() => this.deleteClickedNote(note.id)}
                        />
                    ))}
                </ProgressiveLoading>
                : <div style={{ padding: '12px 16px' }}>
                    <Text size="Medium" color="Grey" >{i18n.t('No notes added to this user. Click Add Note to create the first note for this user.')}</Text>
                </div>
            }
        </>;
    }
}
