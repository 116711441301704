import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '../text/Text';
import styled from 'styled-components';
import { isset, t } from '../../../base/Utils';


export const sizes = {
    'Big': {
        width: '60px',
        height: '30px',
    },
    'Medium': {
        width: '30px',
        height: '16px',

    },
    'Small': {
        width: '20px',
        height: '10px',
    }
};


const Wrapper = styled.label`
    display: flex;
    align-items: center;
    position: relative;
    ${props => props.disabled && 'pointer-events: none; opacity: 0.3; user-select: none;'}
`;


const MainToggle = styled.div`
    cursor: pointer;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
    position: relative;
    display: inline-block;
    height: ${props => isset(sizes[props.size].height, 'Small')};
    width: ${props => isset(sizes[props.size].width, 'Small')};
    &:before{
        position: absolute;
        content: "";
        height: 80%;
        width: 40%;
        left: 5%;
        bottom: 10%;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 50%;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    }
`;

const HiddenInput = styled.input`
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    &:checked + ${MainToggle} {
        background-color: #0a5c9c;
        &::before{
            left: 52%;
        }
    }
    &:focus + ${MainToggle}{
        box-shadow: 0 0 1px #0a5c9c;
    }
`;



class Toggle extends Component {
    leftOption = {}
    rightOption = {}

    constructor(props) {
        super(props);
        this.state = { isChecked: false, disabled: false, prevDisabled: false };
        this.leftOption = this.props.leftOption || '';
        this.rightOption = this.props.rightOption || '';
    }

    componentDidMount() {
        this.handleDefaultVal();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.defaultVal !== this.props.defaultVal) {
            this.handleDefaultVal();
        }
    }

    handleDefaultVal() {
        var isChecked = false;
        var defaultVal = this.props.defaultVal || null;

        if (defaultVal !== null) {
            if (isset(this.leftOption.value) && isset(this.rightOption.value)) {
                if (this.leftOption.value === defaultVal) {
                    isChecked = false;
                } else {
                    isChecked = true;
                }
            }
            else {
                isChecked = defaultVal;
            }
        }
        this.setState({ isChecked });
    }

    handleChange() {
        var onChange = this.props.onChange || function () { };
        var isChecked = !this.state.isChecked;
        if (Object.keys(this.rightOption).length > 0 && Object.keys(this.leftOption).length > 0) {
            if (isChecked) onChange(this.rightOption);
            else onChange(this.leftOption);
        }
        else {
            onChange(isChecked);
        }

        this.setState({ isChecked: isChecked });
    }



    render() {
        var {
            isChecked = this.state.isChecked,
            size = '',
            ...props
        } = this.props;

        return (
            <Wrapper {...props} onChange={() => this.handleChange()}>
                {this.leftOption !== '' && <Text size="Medium" color={isChecked ? 'grey' : 'blue'} style={{ 'marginRight': '8px' }} onClick={() => this.handleChange()}>{t(this.leftOption.label)}</Text>}

                <HiddenInput size={size} type="checkbox" checked={isChecked} />
                <MainToggle size={size} onChange={() => this.handleChange()} />

                {this.rightOption !== '' && <Text size="Medium" color={isChecked ? 'blue' : 'grey'} style={{ 'marginLeft': '8px' }} onClick={() => this.handleChange()}>{t(this.rightOption.label)}</Text>}
            </Wrapper>
        );
    }
}

Toggle.propTypes = {
    /**
     * Size for the toggle
     */
    size: PropTypes.oneOf(['Small', 'Medium', 'Big']),
    /**
     * Left Text for the toggle
     */
    leftOption: PropTypes.object,
    /**
     * Right Text for the toggle
     */
    rightOption: PropTypes.object,
    /**
     * Disable toggle
     */
    disabled: PropTypes.bool,
};

Toggle.defaultProps = {
    size: 'Small',
    leftOption: '',
    rightOption: '',
};



export default Toggle;
