import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import Text from '../components/text/Text';
import Button from '../components/button/Button';
import styled from 'styled-components';

import TourController from './TourController';
import React, { Component } from 'react';
import { sendMessage, deleteFakeElement } from '../containers/OldPages';
import { isset, timeout } from '../../base/Utils';
import Config from '../../base/config/Config';

import i18n from '../../i18n';

const TooltipWrapper = styled.div`
    background: ${Config.colors.white};
    padding: 14px;
    border-radius: 5px;
    max-width: 380px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 12px;
`;

const TooltipWidthWrapper = styled.div`
    min-width: 100%;
    clear: both;
    text-align: left;
    display: flex;
    gap: 10px;
`;

const TooltipProgressStep = styled.div`
    height: 3px;
    min-width: 5px;
    width: 100%;
    background-color: ${props => props.made ? Config.colors.pink : Config.colors.lightgrey};
    border-radius: 3px;
`;

const TourTooltip = ({
    continuous,
    index,
    step,
    size,
    backProps,
    skipProps,
    primaryProps,
    tooltipProps,
}) => {
    var stepsProgress = [];

    for (var i = 0; i < size; i++) {
        stepsProgress[`${i}`] = (i <= index ? true : false);
    }

    return <TooltipWrapper style={step.style || {}} {...tooltipProps}>
        {step.title && <><TooltipWidthWrapper><Text size="Big" weight="SemiBold" style={{ letterSpacing: '0.15px', lineHeight: '24px' }}>{step.title}</Text></TooltipWidthWrapper></>}
        {step.content && <><TooltipWidthWrapper><Text size="Medium" style={{ letterSpacing: '0.25px', lineHeight: '20px' }}>{step.content}</Text></TooltipWidthWrapper></>}
        <TooltipWidthWrapper style={{ flexDirection: 'column', marginTop: 'auto' }}>
            <TooltipWidthWrapper>
                {stepsProgress.map((value, key) => (<TooltipProgressStep made={value} key={key} />))}
            </TooltipWidthWrapper>
            <TooltipWidthWrapper style={{ justifyContent: 'end' }}>
                {isset(step.showSkipButton, false) && (
                    <span style={{ flex: 1 }}><Button type="TourLink" {...skipProps}>{step.skipButtonText ? step.skipButtonText : i18n.t('Skip')}</Button></span>
                )}
                {(index > 0 && !isset(step.hideBackButton, false)) && (
                    <Button type="TourSecondary" {...backProps}>{step.backButtonText ? step.backButtonText : i18n.t('Back')}</Button>
                )}
                {(continuous && !isset(step.hideNextButton, false)) && (
                    <Button type="Tour" {...primaryProps}>{step.nextButtonText ? step.nextButtonText : (index < (size - 1) ? i18n.t('Next') : i18n.t('Finish'))}</Button>
                )}
            </TooltipWidthWrapper>
        </TooltipWidthWrapper>
    </TooltipWrapper>;
};

export default class Tour extends Component {
    constructor() {
        super();

        window.addEventListener('resize', () => {
            if (this.state.run) {
                this.setState({ run: false }, () => {
                    this.checkStepBeforeLoad();
                });
            }
        }, false);

        this.state = {
            steps: [],
            run: false,
            stepIndex: 0,
            mainKey: 0
        };

        TourController.setStepsCallback((steps) => {
            this.setState({ steps });
        });

        TourController.setRunCallback((run) => {
            this.setState({ run }, () => {
                if (run) {
                    this.checkStepBeforeLoad();
                }
            });
        });

        TourController.setIndexCallback((stepIndex) => {
            this.setState({ stepIndex });
        });

        TourController.setGetParamsCallback(() => {
            return this.state;
        });
    }

    functions = {}
    captureClicksEvent = null;
    clickCallback = (e) => {
        if (this.state.run && (e.target === document || (e.target && !e.target.closest('#react-joyride-portal') && !e.target.closest('.__floater')))) {
            this.functions.next();
        }
    };

    callback(data) {
        const { action, index, status, type, step, size } = data;
        const correctSize = size - 1;

        console.log(data);

        if (isset(step.spotlightClicks, false) && isset(step.nextOnSpotlightClicks, true) && type !== 'beacon') {
            document.addEventListener('click', this.clickCallback);
        } else {
            document.removeEventListener('click', this.clickCallback);
        }

        // Execute callback before loading step
        if (((type === 'step:before' && index > 0) || (type === 'tour:start' && index == 0)) && isset(step.beforeStep)) {
            step.beforeStep();
        }

        TourController.callCallback({ index, size: correctSize, status: 'running' });

        if (([STATUS.FINISHED, STATUS.SKIPPED]).includes(status)) {
            // Need to set our running state to false, so we can restart if we click start again.
            this.setState({
                steps: [],
                run: false,
                stepIndex: 0,
                mainKey: this.state.mainKey + 1
            }, () => {
                if (isset(step.afterStep) && status === STATUS.FINISHED) {
                    step.afterStep();
                }
                TourController.callCallback({ index, size: correctSize, status: 'ended' });
            });
        } else if (([EVENTS.STEP_AFTER]).includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
            this.setState({
                run: false,
                stepIndex: nextStepIndex,
            }, () => {
                // Execute callback when next step
                if (isset(step.afterStep) && type === 'step:after' && index < correctSize && action !== 'prev') {
                    step.afterStep();
                }

                setTimeout(() => {
                    this.checkStepBeforeLoad();
                }, (isset(this.state.steps[`${nextStepIndex}`]) ? (this.state.steps[`${nextStepIndex}`].sleepBeforeStart || 0) : 0));
            });
        }

        if (Config.environment === 'development')
            console.log(type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type, data);
    }

    waitForTargetInterval = null;

    checkStepBeforeLoad() {
        var { steps, stepIndex } = this.state;

        deleteFakeElement();

        if (isset(steps[`${stepIndex}`])) {
            if (isset(steps[`${stepIndex}`].waitForTarget, false)) {
                var target = isset(steps[`${stepIndex}`].realTarget) ? steps[`${stepIndex}`].realTarget : steps[`${stepIndex}`].target;
                console.log('-' + target + '-');
                if (!(typeof target === 'string' && document.querySelector('#legacyElementFaker') !== null)) {
                    const timeoutCallback = (data, timeout) => {
                        if (typeof target === 'string' && document.querySelector('#legacyElementFaker') !== null) {
                            timeout.clear();
                            if (steps[`${stepIndex}`].target !== '#legacyElementFaker') {
                                steps[`${stepIndex}`].realTarget = steps[`${stepIndex}`].target;
                            }
                            steps[`${stepIndex}`].target = '#legacyElementFaker';
                            setTimeout(() => this.setState({ run: true, steps }), 100);
                        } else if (isset(steps[`${stepIndex}`].getFromLegacy, false)) {
                            sendMessage({ type: 'getDOM', selector: target });
                        }
                    };

                    this.setState({ run: false }, () => {
                        var timeoutValue = timeout('tour', timeoutCallback, 100, null, true, ((steps[`${stepIndex}`].timeoutTargetLegacy || 10000) / 100));
                        timeoutCallback({}, timeoutValue);
                    });
                }
            } else {
                this.setState({ run: true });
            }
        }
    }

    render() {
        var { run, stepIndex, steps, mainKey } = this.state;

        return <Joyride
            key={mainKey}
            callback={(data) => this.callback(data)}
            getHelpers={(functions) => this.functions = functions}
            continuous
            run={run}
            floaterProps={{ disableAnimation: true }}
            showProgress
            hideCloseButton
            hideBackButton
            disableOverlayClose
            stepIndex={stepIndex}
            steps={steps}
            spotlightPadding={5}
            tooltipComponent={TourTooltip}
            styles={{ options: { primaryColor: Config.colors.pink } }}
        />;
    }
}
