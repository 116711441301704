import React, { Component } from 'react';
import styled from 'styled-components';
import i18n from '../../../i18n';
import Config from '../../../base/config/Config';
import Button from '../button/Button';
import Text from '../text/Text';
import Icon from '../icon/Icon';
import Box from '../box/Box';

var DataDetailsArea = styled.div`
    display: flex;
    flex-direction: column;
    border-left: 1px solid #EEE;
    padding: 24px;
    width: 340px;
    box-sizing: border-box;
`;

export default class DataDetails extends Component {
    constructor(props) {
        super(props);
        this.state = { showTooltip: false };
        this.statusRef = React.createRef();
    }

    render() {
        var { data = {}, onClose, onBack, backButton, ...props } = this.props;
        return <DataDetailsArea {...props}>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                {!backButton ? <Text weight='Bold' size='Bigger'>{i18n.t(data.title)}</Text>
                    :
                    <Text onClick={onBack} style={{ cursor: 'pointer' }} weight='Bold' size='Bigger'><Icon name='Arrow Left' /> {i18n.t('Back')}</Text>}
                <Icon style={{ cursor: 'pointer' }} onClick={onClose} name='Remove' color='Black' />
            </div>
            {<div style={{ marginTop: '36px' }} >
                <Text size='Bigger' >{i18n.t(data.subTitle)}</Text>
                <div>
                    <Text color='Grey'>{i18n.t(data.date)}</Text>
                </div>
                {data.message && <div style={{ marginTop: '24px' }}>
                    <Text size='Big'>{i18n.t(data.message)}</Text>
                </div>}
                <div style={{ marginTop: '24px' }}>
                    <Box type='Insights' data={data.box} />
                </div>
                {data.bottomText && <div style={{ marginTop: '36px' }}>
                    <Text color='Grey' size='Big'>{i18n.t(data.bottomText)}</Text>
                </div>}
                <div style={{ marginTop: '24px' }}>
                    <Text color='Grey'>{i18n.t('Was this insight helpful?')}</Text>
                    <div style={{ display: 'flex', marginTop: '8px' }}>
                        <Button type='Tertiary' style={{ color: Config.colors.grey, marginRight: '8px' }} text={i18n.t('Yes')} />
                        <Button type='Tertiary' style={{ color: Config.colors.grey }} text={i18n.t('No')} />
                    </div>
                </div>
            </div>}
        </DataDetailsArea>;
    }
}
