import React, { Component } from 'react';
import Text from '../../../../components/text/Text';
import Icon from '../../../../components/icon/Icon';
import ToolTip from '../../../../components/toolTip/Tooltip';
import i18n from '../../../../../i18n';
import { convertDate, isset, openSourceLogin, t } from '../../../../../base/Utils';


export default class Status extends Component {
    constructor(props) {
        super(props);
        this.state = { showTooltip: false };
        this.statusRef = React.createRef();
    }

    top = 0;
    left = 0;
    createTooltip() {
        if (document.getElementById('StatusTooltip'))
            return;
        var status = this.statusRef;
        this.top = status.current.getBoundingClientRect().bottom + 4;
        this.left = (status.current.getBoundingClientRect().left - (170 / 2));
        this.setState({ showTooltip: true });
        window.addEventListener('mousemove', (e) => this.handleTooltip(e));
    }

    handleTooltip(e) {
        if (document.getElementById('StatusTooltip') && (e.clientY > this.top + document.getElementById('StatusTooltip').clientHeight || e.clientX < this.left || e.clientX > this.left + document.getElementById('StatusTooltip').clientWidth || e.clientY < (this.top - 30))) {
            this.setState({ showTooltip: false });
            window.removeEventListener('mousemove', this.handleTooltip);
        }
    }

    handleTooltipLeave(e) {
        var left = this.statusRef.current.getBoundingClientRect().left;
        var top = this.statusRef.current.getBoundingClientRect().top;
        if (this.state.showTooltip && (e.clientX < left || e.clientX > left + this.statusRef.current.clientWidth || e.clientY < top)) {
            this.setState({ showTooltip: false });
            window.removeEventListener('mousemove', this.handleTooltip);
        }
    }

    render() {
        var { data = {}, source, children, ...props } = this.props;
        return <span onMouseEnter={() => this.createTooltip()} onMouseLeave={(e) => this.handleTooltipLeave(e)} style={{ display: 'inline-flex', position: 'relative' }} ref={this.statusRef} {...props}  >
            <Icon name={(data.value === 'ok' ? 'Check' : data.value === 'error' || data.value === 'noProfile' ? 'Remove' : data.value === 'collectingData' ? 'Load' : 'Warning') + ' Shield'} color={data.value === 'ok' ? 'Green' : data.value === 'error' ? 'Red' : data.value === 'noProfile' ? 'Grey' : data.value === 'collectingData' ? 'Purple' : 'Orange'} />
            {((data.value === 'ok' || data.value === 'error') && isset(data.data, []).length < 1) ? null : this.state.showTooltip && <ToolTip onClick={(e) => e.stopPropagation()} pointerEvent='all' id='StatusTooltip' top={this.top} left={this.left} >
                <div style={{ width: '175px', display: 'flex', flexWrap: 'wrap', cursor: 'default' }}>
                    {children ? children : <>
                        <Text color={data.value === 'ok' ? 'Green' : data.value === 'error' ? 'Red' : data.value === 'noProfile' || data.value === 'collectingData' ? 'Grey' : 'Orange'}>{data.value === 'ok' ? i18n.t('Last update was successful (last 30 days)') : data.value === 'error' ? i18n.t('Last update failed (last 30 days)') : data.value === 'noProfile' ? i18n.t('The profile associated with this report does not exist. Edit or delete this report.') : data.value === 'collectingData' ? i18n.t('Collecting data') : i18n.t('Updated with problems at (last 30 days)')}{data.value !== 'collectingData' && data.value !== 'noProfile' ? ':' : ''}</Text>
                        {isset(data.data, []).map((data, key) => {
                            if (parseInt(key) > 4)
                                return;
                            if (Array.isArray(data)) {
                                return data.map((subData, key) => {
                                    return <Text key={key} color='heavygrey'>{convertDate(subData, false, true) + (parseInt(key) !== data.length - 1 ? ' ' + i18n.t('to') + ' ' : '; ')}</Text>;
                                });
                            }
                            return <Text key={key} color='heavygrey'>{convertDate(data, false, true) + '; '}</Text>;
                        })}
                        {isset(data.data, []).length > 5 && <Text color='heavygrey'>{t('and ' + (data.data.length - 5) + ' other dates')}</Text>}
                        {isset(data.message) && <Text color='heavygrey'>{i18n.t(data.message)}</Text>}
                        {data.value === 'error' && !window.location.pathname.includes('demo') && <Text onClick={(e) => { e.stopPropagation(); openSourceLogin(source); }} style={{ cursor: 'pointer', marginTop: '15px', textDecoration: 'underline' }} color='Blue'>{i18n.t('Reconect account')}</Text>}
                    </>}
                </div>
            </ToolTip>
            }
        </span>;
    }
}
