import Config from '../../base/config/Config';
import { isset } from '../../base/Utils';

class Broker {

    static broker = null;

    static callbacks = {};

    static definedCallbacks = [];

    static async start(id, token) {
        this.broker = window.io.connect(Config.brokerHost);

        this.broker.on('connect', () => {
            this.broker.emit('new_user', { id_user: id, token: isset(token, null) });
        });

        this.defineCallbacks();
    }

    static addListener(channelName, callback) {
        if (isset(this.callbacks[channelName], null) === null) {
            this.callbacks[channelName] = [];
        }
        this.callbacks[channelName].push(callback);
        this.defineCallbacks();
    }

    static defineCallbacks() {
        if (isset(this.broker, null) !== null) {
            for (var channelCallback in this.callbacks) {
                if (!this.definedCallbacks.includes(channelCallback)) {
                    this.definedCallbacks.push(channelCallback);
                    this.broker.on(channelCallback, (data) => {
                        this.callbacks[channelCallback].map((callback) => { return callback(data); });
                    });
                }
            }
        }
    }
}

export default Broker;
