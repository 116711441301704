import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import Request from '../../classes/Request';

class NotificationsRequest {
    static getNotification(id, callback) {
        Request.send({
            method: 'GET',
            url: Config.backendHost + 'notifications/' + id
        }, (response) => {
            response = isset(response, {});

            if (isset(response.status, '') === 'ok') {
                var {
                    id = btoa(new Date().getTime() + (Math.random() * 1000)),
                    photo = '',
                    date = (new Date().getTime() / 1000),
                    content = '',
                    type = 'primary',
                    area = 'General',
                    readed = false,
                    actions = []
                } = isset(response.data, {});

                callback(id, area, content, date, photo, readed, type, actions);
            }
        });
    }

    static getNotifications(url, callback) {
        Request.send({
            url: url,
            method: 'get',
        }, (response) => {
            callback(isset(response, {}));
        });
    }

    static readAllNotifications(callback) {
        Request.send({
            url: Config.backendHost + 'notifications/read',
            method: 'put',
        }, (response) => {
            callback(isset(response, {}));
        });
    }

    static readNotification(notificationId, callback) {
        Request.send({
            url: Config.backendHost + 'notifications/' + notificationId + '/read',
            method: 'put',
        }, (response) => {
            callback(isset(response, {}));
        });
    }

}

export default NotificationsRequest;
