import i18n from '../../../i18n';

export default () => ([
    {
        target: '',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('All your reports in one place'),
        content: i18n.t('All the reports you create will appear in this list. Feel free to open the report you just created.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideNextButton: true
    },
    {
        target: '#new_analytics',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Welcome to your report'),
        content: i18n.t('Here, you can analyze all the data available in your report.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Metrics Menu'),
        content: i18n.t('This menu contains all of the metrics for your report. You can select and see them here.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Time interval'),
        content: i18n.t('You can change the time interval for your report analytics.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Compare between your profiles'),
        content: i18n.t('Compare metrics with another one of your profiles'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Export your reports'),
        content: i18n.t('You can export your reports to PowerPoint, PDF, or CSV. It only takes a few moments.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Share your reports with others'),
        content: i18n.t('You can share your reports with others externally through a link or email. The shared report is completely white-labeled, with no references to Swonkie.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '#dashboad_result_spawn',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Start analysing your data'),
        content: i18n.t('Congratulations on creating your first report! Gathering all the data for a report can take some time, but now it\'s time to start analyzing it.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
        nextButtonText: i18n.t('Finish'),
    }
]);
