import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import Request from '../../classes/Request';

class ObjectivesRequest {
    static postObjective(params, area, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'objectives/' + area,
            params: params
        }, (response) => {
            callback(isset(response, {}));
        });
    }

    static deleteObjective(params, area, callback) {
        Request.send({
            method: 'delete',
            url: Config.backendHost + 'objectives/' + area,
            params: params
        }, (response) => {
            callback(isset(response, {}));
        });
    }

}

export default ObjectivesRequest;
