import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from '../icon/Icon';
import Text from '../text/Text';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import i18n from '../../../i18n';

const MainSentiment = styled.div`
    ${props => props.disable && 'opacity: 0.3; pointer-events: none; user-select: none;'}
    display: flex;

    .Icon.Emoji.Happy{
        &:hover{
            color: ${Config.colors.green}
        }
    }
    .Icon.Emoji.Neutral{
        &:hover{
            color: ${Config.colors.grey}
        }
    }
    .Icon.Emoji.Sad{
        &:hover{
            color: ${Config.colors.red}
        }
    }
`;

class Sentiment extends Component {
    constructor(props) {
        super(props);
        this.state = { sentiment: isset(props.sentiment, '') };
    }

    componentDidUpdate(previousProps) {
        if (previousProps.sentiment !== this.props.sentiment) {
            this.setState({ sentiment: this.props.sentiment });
        }
    }

    onCallback(sentiment) {
        if (isset(this.props.onChange)) {
            this.props.onChange(sentiment);
        }
        this.setState({ sentiment: sentiment });
    }

    render() {
        var { disable = false, readOnly, ...props } = this.props;
        var sentiment = this.state.sentiment;

        var cursor = 'pointer';
        if (readOnly) {
            cursor = 'default';
        }
        return (
            <MainSentiment disable={disable} readOnly={readOnly} {...props}>
                <>
                    {readOnly && sentiment !== 'positive' ? null : <div style={{ display: 'flex', alignItems: 'center' }}> <Icon tooltip={i18n.t('Positive')} style={{ cursor: cursor }} name="Emoji Happy" size="Big" color={sentiment !== 'positive' ? 'Greenopacity' : 'Green'} onClick={(e) => { e.stopPropagation(); !readOnly && this.onCallback('positive'); }} /> {readOnly && <Text style={{ marginLeft: '6px' }} color='Green'>{i18n.t('Positive')}</Text>}</div>}
                    {readOnly && sentiment !== 'neutral' ? null : <div style={{ display: 'flex', alignItems: 'center' }}><Icon tooltip={i18n.t('Neutral')} style={{ cursor: cursor }} name="Emoji Neutral" size="Big" color={sentiment !== 'neutral' ? 'Greyopacity' : 'Grey'} onClick={(e) => { e.stopPropagation(); !readOnly && this.onCallback('neutral'); }} /> {readOnly && <Text style={{ marginLeft: '6px' }} color='Grey'>{i18n.t('Neutral')}</Text>}</div>}
                    {readOnly && sentiment !== 'negative' ? null : <div style={{ display: 'flex', alignItems: 'center' }}><Icon tooltip={i18n.t('Negative')} style={{ cursor: cursor }} name="Emoji Sad" size="Big" color={sentiment !== 'negative' ? 'Redopacity' : 'Red'} onClick={(e) => { e.stopPropagation(); !readOnly && this.onCallback('negative'); }} /> {readOnly && <Text style={{ marginLeft: '6px' }} color='Red'>{i18n.t('Negative')}</Text>}</div>}
                </>
            </MainSentiment>
        );
    }
}

Sentiment.propTypes = {
    /**
     * Type of the active sentiment button
     */
    sentiment: PropTypes.oneOf(['positive', 'neutral', 'negative']),
    /**
     * Disable the sentiments buttons
     */
    disable: PropTypes.bool,
    /**
     * Show only the active sentiment
     */
    readOnly: PropTypes.bool
};

export default Sentiment;
