import Config from '../../base/config/Config';
import { hash, isset, localStorageItem } from '../../base/Utils';
import Session from '../../base/Session';

class Request {
    static history = [];

    static async send(data, callback, withCredentials) {
        const environment = process.env.REACT_APP_ENVIRONMENT;

        var { id } = Session.getActiveParams();
        var userID = id;
        callback = callback || function () { };
        withCredentials = (isset(withCredentials) ? withCredentials : true);

        var {
            method = 'GET',
            url = '',
            params = {},
            headers = {},
            onDuplicate = 'none', // Options: cancel, block, none
            requestID = null, // Unique request ID (will set automatically if not sent)
            cache = null // Cache time in seconds ( 900 = 15 min,... )
        } = data;

        method = method.toLowerCase();
        if (!requestID) requestID = userID + '-' + cache + '-' + hash(JSON.stringify(data) + callback.toString());

        if (['post', 'get', 'put', 'delete'].indexOf(method) < 0) {
            return false;
        }

        if (typeof this.history[`${requestID}`] !== 'undefined') {
            if (onDuplicate === 'block') {
                return false;
            } else if (onDuplicate === 'cancel') {
                this.cancel(requestID);
            }
        }

        if (environment === 'development' && Config.fakerIP != null) {
            headers = Object.assign({
                'ip': Config.fakerIP
            }, headers);
        }

        if (method !== 'get') {
            headers = Object.assign({
                'Content-Type': 'application/json'
            }, headers);
        } else {
            if (Object.keys(params).length > 0) {
                url += ((url.indexOf('?') !== -1) ? '&' : '?') + this.encodeQueryData(params);
            }
        }

        //TODO: Reimplement later
        /*if (cache && Utils.localStorage("getItem", requestID) && environment !== 'development') {
            var xhr = JSON.parse(Utils.localStorage("getItem", requestID));

            setTimeout(() => callback(JSON.parse(xhr.responseText), xhr), 1);
            return xhr;
        }*/

        var xhr = new XMLHttpRequest();

        if (withCredentials) {
            xhr.withCredentials = withCredentials;
        }

        xhr.open(method, url, true); // eslint-disable-line

        for (var key in headers) {
            xhr.setRequestHeader(key, headers[`${key}`]);
        }

        xhr.onreadystatechange = () => {
            // Finished
            if (xhr.readyState === 4) {
                delete this.history[`${requestID}`];
                var {
                    responseText = '',
                    responseType = '',
                    status = 0,
                    statusText = ''
                } = xhr;

                try {
                    var response = JSON.parse(responseText);

                    if (isset(response.status, '') === 'ok' && cache) {
                        setTimeout(() => {
                            localStorageItem('setItem', requestID, JSON.stringify({
                                responseText,
                                responseType,
                                status,
                                statusText
                            }), cache);
                        }, 1);
                    }

                    callback(response, xhr);
                } catch {
                    callback(responseText, xhr);
                }
            }
        };

        xhr.send(JSON.stringify(params));

        this.history[`${requestID}`] = xhr;

        return xhr;
    }

    static encodeQueryData(data) {
        const ret = [];
        for (let [key, value] of Object.entries(data)) {
            if (typeof value === 'object' || Array.isArray(value)) {
                for (let arrD in value) {
                    ret.push(`${encodeURIComponent(key)}[${arrD}]=${encodeURIComponent(value[`${arrD}`])}`);
                }
            } else if (value === 'null' || typeof value === 'undefined') {
                ret.push(encodeURIComponent(key));
            } else {
                ret.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }
        return ret.join('&');
    }

    static cancel(id) {
        this.history[`${id}`].abort();
        delete this.history[`${id}`];
    }
}

export default Request;
