import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import Request from '../../classes/Request';

class PopupRequest {
    static getTutorialVideos(callback) {
        Request.send({
            url: Config.backendHost + 'tutorialVideos',
            method: 'get',
        },
        (data) => {
            callback(isset(data, {}));
        });
    }
    static postTutorialVideos(params, callback) {
        Request.send({
            url: Config.backendHost + 'tutorialVideos',
            method: 'post',
            params: params
        },
        (data) => {
            callback(isset(data, {}));
        });
    }
}

export default PopupRequest;
