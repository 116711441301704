import i18n from '../../../i18n';
import React from 'react';
import linkedinCarouselUpdate from '../../../resources/images/linkedinCarouselUpdate.png';
import Text from '../../components/text/Text.js';
import { redirect } from '../../../base/Utils';

// eslint-disable-next-line
export default () => ([
    {
        title: i18n.t('LinkedIn Carousel Publications'),
        content: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ marginBottom: '8px' }} size='Medium' color='heavygrey'>{i18n.t('Now in Swonkie you can')}:</Text>
            <Text size='Big' >{i18n.t('You can publish your images on LinkedIn in a carousel format. We use the LinkedIn document feature to create a slideshow of your images without the need for you to edit or change them. Try it and tell us what you think!')}</Text>
            <img src={linkedinCarouselUpdate} style={{ width: 'calc(100% - 20px)', margin: '20px 10px' }} />
        </div>,
        placement: 'center',
        target: 'body',
        style: { minWidth: '600px', minHeight: '320px' },
        nextButtonText: i18n.t('Try it'),
        skipButtonText: i18n.t('Later'),
        showSkipButton: true,
        afterStep: () => {
            redirect('/produce-content');
        }
    }
]);
