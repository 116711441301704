import React, { Component, createRef } from 'react';
import Config from '../../base/config/Config';
import Session from '../../base/Session';
import { clone, isset } from '../../base/Utils';
import TourController from '../tours/TourController';
import { posthog } from 'posthog-js';

var iframe = null;
export default class OldPages extends Component {
    target = Config.oldHost;
    ref = null;

    reactPaths = [
        'listening',
        'export',
        'offers',
        'discover',
        'badges'
    ];

    excludePaths = [
        'competitors/monitoring'
    ]

    pathMatch = [
        {
            oldPath: 'new_dashboard',
            newPath: 'dashboard'
        },
        {
            oldPath: 'dashboard',
            newPath: 'activity'
        },
        {
            oldPath: 'new_analytics',
            newPath: 'analytics'
        },
        {
            oldPath: 'produce_content',
            newPath: 'produce-content'
        },
        {
            oldPath: 'personal_settings',
            newPath: 'settings'
        },
        {
            oldPath: 'manage_social_networks',
            newPath: 'social-networks'
        },
        {
            oldPath: 'manage_channels',
            newPath: 'channels'
        },
        {
            oldPath: 'team_management',
            newPath: 'team'
        }
    ];

    constructor() {
        super();
        this.ref = createRef();
        this.state = {
            showIframe: false
        };

        Session.setCallback(() => {
            sendMessage({ type: 'reload' });
            setTimeout(() => this.setPostHogIdentify(), 1000);
        });
    }

    componentDidMount() {
        iframe = this.ref.current.contentWindow;
        window.addEventListener('message', (event) => {
            if (this.target.indexOf(event.origin) !== -1 &&
                isset(event.data) && isset(event.data.path)) {
                var path = event.data.path.replace('#', '');

                path = this.getPagePath(path, 'new');
                this.props.history.push(path);

                this.legacySyncActions(isset(event.data.action, {}));
            }
        }, false);

        setTimeout(() => { this.lastPath = this.getPagePath(); }, 500);
    }

    legacySyncActions(data) {
        switch (isset(data.type, '')) {
        case 'switchAccount':
            var id = isset(data.id, null);
            if (id) {
                Session.handleTeam(id);
            } else {
                Session.handleLogoutTeam();
            }
            break;
        case 'event':
            document.dispatchEvent(new Event(isset(data.id, 'click')));
            break;
        case 'fullscreen':
            if (document.querySelector('.Top.Bar')) {
                document.querySelector('.Top.Bar').style.display = (isset(data.value, false) ? 'none' : '');
            }
            break;
        case 'supportChat':
            window.toggleChat(isset(data.value, true));
            break;
        case 'refreshUserData':
            Session.checkLogin();
            break;
        case 'setDOM':
            if (data.selector && data.html && data.rect) {
                if (document.getElementById('legacyElementFaker') !== null) {
                    document.getElementById('legacyElementFaker').remove();
                }

                var div = document.createElement('div');
                div.id = 'legacyElementFaker';
                div.innerHTML = data.html;
                div.dataset.id = data.id;
                div.style.position = 'absolute';
                div.style.opacity = 0;
                div.style.pointerEvents = 'none';
                div.style.width = data.rect.width + 'px';
                div.style.height = data.rect.height + 'px';

                var topValue = (data.rect.top + document.getElementById('oldPageIframe').getBoundingClientRect().top);
                div.style.top = topValue + 'px';
                div.style.left = data.rect.left + 'px';
                div.style.overflow = 'hidden';

                if ((topValue + data.rect.height) > window.innerHeight) {
                    var outsideMargin = (topValue + data.rect.height) - window.innerHeight;
                    if (data.rect.height > outsideMargin) {
                        div.style.height = (data.rect.height - outsideMargin) + 'px';
                    } else {
                        div.style.height = '10px';
                        div.style.top = (window.innerHeight - 10) + 'px';
                    }
                }

                document.body.appendChild(div);
            }
            break;
        case 'tourController':
            if (data.tour) {
                TourController.load(data.tour, (data) => sendMessage({ type: 'tourController', data }));
            }
            break;
        case 'tourController.status':
            sendMessage({ type: 'tourController.status', data: TourController.status() });
            break;
        case 'tourController.setStatus':
            if (data.tour && data.status) {
                TourController.setStatus(data.status, data.tour);
            }
            break;
        case 'popup':
            var el = document.querySelector('.Top.Bar #popupSimulator');

            if (el) el.remove();

            if (isset(data.value, false)) {
                var popupSimulator = document.createElement('div');
                popupSimulator.id = 'popupSimulator';
                document.querySelector('.Top.Bar').appendChild(popupSimulator);
            }
            break;
        case 'postHogSetPropertyFlags':
            if (data.set) {
                posthog.setPersonPropertiesForFlags(data.set);
                posthog.reloadFeatureFlags();
            }
            break;
        case 'logout':
            Session.logout();
            break;
        default:
            break;
        }
    }

    lastPath = null;

    syncPaths() {
        var path = this.getPagePath();
        if (this.lastPath !== path) {
            sendMessage({ path: path });
            this.lastPath = path;
        }
    }

    getPagePath(path, type, setState) {
        type = type || 'old';
        var inverseType = (type === 'old' ? 'new' : 'old');
        var auxPath = (path || this.props.location.pathname).split('/');
        setState = isset(setState, true);

        var allReactPaths = [...clone(this.reactPaths)];

        var mainPath = '';
        var state = {};

        if (auxPath.length > 1) mainPath = auxPath[1];

        var excludePath = false;
        for (let tempExcludePath of this.excludePaths) {
            if ((path || this.props.location.pathname).includes(tempExcludePath)) {
                excludePath = true;
            }
        }

        if (allReactPaths.indexOf(mainPath) !== -1 && !excludePath) {
            state = { showIframe: false };
        } else {
            state = { showIframe: true };
            for (let paths of this.pathMatch) {
                if (paths[inverseType + 'Path'] === mainPath) {
                    mainPath = paths[type + 'Path']; break;
                }
            }
        }

        auxPath[1] = mainPath;

        if (setState) {
            this.preventHistoryRender = true;
            this.setState(state);
            setTimeout(() => this.preventHistoryRender = false, 100);
        }

        return auxPath.join('/');
    }

    isIframeLoaded = false;
    setPostHogIdentify() {
        var data = Session.getParams();

        if (isset(data.id) && this.isIframeLoaded) {
            var teams = Array.isArray(data.teams) ? data.teams.map(function (o) { return o.id; }) : [];

            sendMessage({
                type: 'postHogIdentify',
                id: data.id.toString(),
                set: {
                    name: data.name,
                    email: data.email,
                    activeTeam: data.activeTeam,
                    language: data.language,
                    teams
                }
            });
        }
    }

    preventHistoryRender = true;

    render() {
        if (!this.preventHistoryRender) {
            this.syncPaths();
        }

        return <iframe
            title="Page"
            id="oldPageIframe"
            allow="clipboard-write"
            src={this.target + '#' + this.getPagePath(null, 'old', false)}
            ref={this.ref}
            style={{ display: (this.state.showIframe ? 'block' : 'none') }}
            onLoad={() => {
                this.isIframeLoaded = true;
                this.setPostHogIdentify();
            }}
        ></iframe>;
    }
}

export function sendMessage(data) {
    if (iframe) {
        iframe.postMessage(data, Config.oldHost);
    }
}

export function deleteFakeElement() {
    if (document.getElementById('legacyElementFaker') !== null) {
        document.getElementById('legacyElementFaker').remove();
    }
}

export function sendMessageFenix(action) {
    var parentTarget = Config.fenixAppHost;
    var parentWindow = window.parent;
    action = action || {};
    var path = window.location.hash;

    parentWindow.postMessage({ path: path, action: action }, parentTarget);
}
