import { clone, isset } from '../../base/Utils';
import pptxgen from 'pptxgenjs';
import { jsPDF } from 'jspdf';
import { toCanvas } from 'html-to-image';
import { chineseNormal, noMediaImage, robotoBold, robotoItalic, robotoNormal, robotoBlack } from './Base64Files';

class ExportReports {
    mainElement = null;
    pageSelector = null;
    currentPageSelected = null;
    elements = [];
    pages = [];
    dpi = 0;
    layoutWidth = 0;
    layoutHeight = 0;
    imagesBase64 = {};
    exportName = '';
    textThreshold = 1.1;
    stageCallback = () => { };
    save = false;
    currentPage = 0;

    constructor(element, exportName, currentElement) {
        this.exportName = exportName;
        this.mainElement = element;
        this.dpi = this.getDPI();
        this.layoutWidth = this.convertToInch(currentElement.getBoundingClientRect().width);
        this.layoutHeight = this.layoutWidth / 1.77777777778;
    }

    async getElements(currentElement, save) {
        var pages = this.mainElement.querySelectorAll(currentElement);
        var page = pages[0];
        try {
            this.save = save;
            this.stageCallback('loadingPages');
            await this.getPages(page);
        } catch (e) {
            console.error(e);
        }
    }

    async getPages(pageElement) {
        this.currentPageSelected = pageElement;
        var promises = [];

        var pageElements = pageElement.querySelectorAll('*');
        for (let node of Object.values(pageElements)) {
            if (await this.validateElement(node)) {
                var promise = this.getElementProperties(node);
                promises.push(promise);
            }
        }

        const pageObjects = await Promise.all(promises);
        pageObjects.sort((a, b) => {
            if (a.style.zIndex < b.style.zIndex) {
                return -1;
            }

            if (a.style.zIndex > b.style.zIndex) {
                return 1;
            }

            return 0;
        });

        this.pages.push(pageObjects);
    }


    async getPseudoElements(element) {
        var elementStyleAfter = window.getComputedStyle(element, ':after');
        if (elementStyleAfter.content !== 'none') {
            this.elements.push(await this.addPseudoElement(elementStyleAfter, element, 'after'));
        }
        var elementStyleBefore = window.getComputedStyle(element, ':before');
        if (elementStyleBefore.content !== 'none') {
            this.elements.push(await this.addPseudoElement(elementStyleBefore, element, 'before'));
        }
    }

    positionAddAbsolutePosition(position, styleElement) {
        var width = styleElement.width;
        var height = styleElement.height;
        var finalPosition = position;
        if (parseFloat(styleElement.top) !== 0) {
            finalPosition.top += parseFloat(styleElement.top);
        }
        if (parseFloat(styleElement.left) !== 0) {
            finalPosition.left += parseFloat(styleElement.left);
        }
        if (parseFloat(styleElement.bottom) !== 0) {
            finalPosition.top += height;
            finalPosition.top -= parseFloat(styleElement.bottom);
        }
        if (parseFloat(styleElement.right) !== 0) {
            finalPosition.left += width;
            finalPosition.left -= parseFloat(styleElement.right);
        }
        return finalPosition;
    }

    async addPseudoElement(elementStyle, elementParent, pseudoType = '') {
        var $properties = {
            type: null,
            x: null,
            xInch: null,
            y: null,
            yInch: null,
            width: null,
            widthInch: null,
            height: null,
            heightInch: null,
            nodeType: 'pseudoElement',
            tagName: pseudoType,
            style: {},
            text: null,
            imageData: null,
        };

        var elementPosition = this.getElementPosition(elementParent);
        elementPosition = this.positionAddAbsolutePosition(elementPosition, elementStyle);

        var xPosition = elementPosition.left; // X
        if (xPosition !== null) {
            $properties.x = xPosition;
            $properties.xInch = this.convertToInch($properties.x);
        }

        var yPosition = elementPosition.top; // Y
        if (yPosition !== null) {
            $properties.y = yPosition;
            $properties.yInch = this.convertToInch($properties.y);
        }

        var width = elementStyle.width; // Width
        if (width !== null) {
            $properties.width = width;
            $properties.widthInch = this.convertToInch(parseFloat($properties.width));
        }

        var height = elementStyle.height; // Height
        if (height !== null) {
            $properties.height = height;
            $properties.heightInch = this.convertToInch(parseFloat($properties.height));
        }

        $properties.style = await this.getElementStyle(elementStyle);

        if (elementStyle.content !== '') {
            $properties.text = elementStyle.content;
        }

        $properties.type = 'styleDiv';

        return $properties;
    }

    convertToInch(number) {
        return number / this.dpi;
    }

    convertToPoints(number) {
        return Math.floor(number / 1.333333);
    }

    convertPointsToInch(number) {
        return this.convertToInch(number * 1.333333);
    }

    async validateElement(element) {
        var isValid = false;
        switch (element.tagName) {
        case 'CANVAS':
        case 'svg':
        case 'I':
        case 'IMG':
        case 'TABLE':
        case 'IFRAME':
        case 'VIDEO':
            isValid = true;
            break;
        default:
            break;
        }
        if (this.getElementText(element)) {
            isValid = true;
        }
        else if (await this.isStyleDiv(element)) {
            isValid = true;
        }
        var elementStyle = window.getComputedStyle(element);
        if (elementStyle.getPropertyValue('visibility') === 'hidden' || elementStyle.getPropertyValue('opacity') === 0 || elementStyle.getPropertyValue('display') === 'none') {
            isValid = false;
        }
        return isValid;
    }

    async loadImage(imageUrl, width, height) {
        let img;
        const imageLoadPromise = new Promise(resolve => {
            img = new Image();
            if (width && height) {
                img.width = width;
                img.height = height;
            }
            try {
                img.onerror = () => resolve(img);
                img.onload = resolve;
                img.setAttribute('crossOrigin', '*');
                img.src = imageUrl;
            } catch (e) {
                resolve(img);
            }
        });

        await imageLoadPromise;
        return img;
    }

    async getImageBase64(imageUrl, hasRadius, width, height) {
        var imageBase64 = this.imagesBase64[`${imageUrl}`];
        if (isset(imageBase64)) {
            return imageBase64;
        } else {
            try {
                var img = await this.loadImage(imageUrl, width, height);
                var canvas = document.createElement('CANVAS');
                window.devicePixelRatio = 2;
                canvas.style.width = img.width + 'px';
                canvas.style.height = img.height + 'px';

                var scale = window.devicePixelRatio;

                canvas.width = Math.floor(img.width * scale);
                canvas.height = Math.floor(img.height * scale);
                var ctx = canvas.getContext('2d');
                ctx.scale(scale, scale);
                var x = 0, y = 0;
                if (img.height === img.width && hasRadius) {
                    var radius = img.height * 0.5;
                    ctx.beginPath();
                    ctx.arc(img.width * 0.5, img.height * 0.5, radius, 0, 2 * Math.PI);
                    ctx.closePath();
                    ctx.clip();
                }
                ctx.imageSmoothingEnabled = false;
                ctx.drawImage(img, x, y, img.width, img.height);
                var dataURL = canvas.toDataURL('image/png');
                canvas = null;
                imageBase64 = dataURL;
                return imageBase64;
            } catch (e) {
                return '';
            }
        }
    }

    async getElementBlob(element) {
        try {
            var canvas = await toCanvas(element);
            return canvas.toDataURL();
        } catch (e) {
            return noMediaImage;
        }
    }

    async captureImage(element) {
        try {
            var canvas = await toCanvas(element);
            canvas.width = element.offsetWidth;
            canvas.height = element.offsetHeight;
            canvas.getContext('2d').drawImage(element, 0, 0, canvas.width, canvas.height);
            return canvas.toDataURL();
        }
        catch (e) {
            return noMediaImage;
        }
    }

    async isStyleDiv(element) {
        var elementStyle = window.getComputedStyle(element);
        var styleDiv = await this.getElementStyle(elementStyle);
        var elementStyleAfter = window.getComputedStyle(element, ':after');
        var styleDivAfter = await this.getElementStyle(elementStyleAfter);
        var elementStyleBefore = window.getComputedStyle(element, ':before');
        var styleDivBefore = await this.getElementStyle(elementStyleBefore);

        return this.hasBoxStyles(styleDiv) || this.hasBoxStyles(styleDivAfter) || this.hasBoxStyles(styleDivBefore);
    }

    handleOverflowText(element) {
        while (element.parentNode.offsetWidth > 0 && element.offsetWidth > element.parentNode.offsetWidth) {
            element.innerText = element.innerText.slice(0, -1);
        }
        return element.innerText;
    }

    hasBoxStyles(styleArray) {
        return styleArray.background !== null || styleArray.backgroundImage !== null || styleArray.boxShadow.opacity !== null || styleArray.border.width !== null || (styleArray.line.color && styleArray.line.width > 0 && styleArray.line.style !== 'none');
    }

    convertRGBAToRGB(rgba, bRgba) {
        bRgba = (bRgba || '255,255,255').split(',');
        var rgb = rgba.split(',');
        var opacity = parseFloat(rgb.pop());

        for (var key in rgb) {
            rgb[`${key}`] = Math.round((rgb[`${key}`] * (opacity || 0.0001)) + (bRgba[`${key}`] * ((1 - opacity) || 0.0001)));
        }

        return rgb.join(',');
    }

    async getElementStyle(elementStyle) {
        var $style = {
            fontSize: null,
            color: null,
            textAlign: 'left',
            lineHeight: null,
            textStyle: 'normal',
            fontWeight: 'normal',
            padding: [0, 0, 0, 0],
            background: null,
            backgroundImage: null,
            textOverflow: 'clip',
            whiteSpace: 'normal',
            zIndex: 0,
            boxShadow: {
                type: null,
                angle: null,
                blur: null,
                color: null,
                offset: null,
                opacity: null,
            },
            border: {
                color: null,
                width: null,
                radius: null,
                isRadiusPecentage: null
            },
            line: {
                color: null,
                width: null,
                radius: null,
                isRadiusPecentage: null,
                style: 'normal'
            },
        };

        if (elementStyle.getPropertyValue('font-size')) {
            var fontSize = elementStyle.getPropertyValue('font-size');
            fontSize = parseFloat(fontSize);
            $style.fontSize = this.convertToPoints(fontSize);
        }

        if (elementStyle.getPropertyValue('z-index')) {
            var zIndex = elementStyle.getPropertyValue('z-index');
            if (zIndex !== 'auto') {
                zIndex = parseInt(zIndex);
                $style.zIndex = zIndex;
            }
        }

        if (elementStyle.getPropertyValue('color')) {
            if (elementStyle.getPropertyValue('color') !== 'rgba(0, 0, 0, 0)') {
                $style.color = this.colorToHex(elementStyle.getPropertyValue('color'));
            }
        }

        if (elementStyle.getPropertyValue('font-style')) {
            var textStyle = elementStyle.getPropertyValue('font-style');
            $style.textStyle = this.convertToPoints(textStyle);
        }

        if (elementStyle.getPropertyValue('font-weight')) {
            var fontWeight = elementStyle.getPropertyValue('font-weight');
            $style.fontWeight = fontWeight;
        }

        if (elementStyle.getPropertyValue('text-align')) {
            $style.textAlign = elementStyle.getPropertyValue('text-align');
        }
        if (elementStyle.getPropertyValue('line-height')) {
            $style.lineHeight = this.convertToPoints(parseFloat(elementStyle.getPropertyValue('line-height')));
        }

        if (elementStyle.getPropertyValue('padding-left')) {
            var padding = elementStyle.getPropertyValue('padding-left');
            padding = parseFloat(padding);
            padding = this.convertToPoints(padding);
            $style.padding[0] = padding;
        }

        if (elementStyle.getPropertyValue('padding-right')) {
            padding = elementStyle.getPropertyValue('padding-right');
            padding = parseFloat(padding);
            padding = this.convertToPoints(padding);
            $style.padding[1] = padding;
        }

        if (elementStyle.getPropertyValue('padding-bottom')) {
            padding = elementStyle.getPropertyValue('padding-bottom');
            padding = parseFloat(padding);
            padding = this.convertToPoints(padding);
            $style.padding[2] = padding;
        }

        if (elementStyle.getPropertyValue('padding-top')) {
            padding = elementStyle.getPropertyValue('padding-top');
            padding = parseFloat(padding);
            padding = this.convertToPoints(padding);
            $style.padding[3] = padding;
        }

        if (elementStyle.getPropertyValue('background-color')) {
            var color = elementStyle.getPropertyValue('background-color');
            if (color !== 'rgba(0, 0, 0, 0)') {
                var colorRgba = this.colorToRGBA(color);
                var opacity = typeof colorRgba[3] !== 'undefined' ? parseFloat(colorRgba[3]) : 1;
                opacity = Math.floor((opacity * 100) / 255);
                color = this.colorToHex(color);
                $style.background = color;
                $style.backgroundTransparency = 100 - opacity;
            }
        }

        if (elementStyle.getPropertyValue('box-shadow')) {
            var shadow = elementStyle.getPropertyValue('box-shadow');
            var colorRegex = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/gi;
            color = colorRegex.exec(shadow);
            var shadowDimentions = shadow.replace(colorRegex, '').trim();
            var shadowParts = shadowDimentions.split(' ');
            if (Array.isArray(shadowParts) && shadowParts.length > 0 && Array.isArray(color)) {
                if (Array.isArray(color) && color.length > 0) {
                    if (color[0] !== 'rgba(0, 0, 0, 0)') {
                        colorRgba = this.colorToRGBA(color[0]);
                        opacity = typeof colorRgba[3] !== 'undefined' ? parseFloat(colorRgba[3]) : 1;
                        opacity = Math.floor((opacity * 100) / 255);
                        $style.boxShadow['opacity'] = (opacity * 0.70) / 100;
                        color = this.colorToHex(color[0]);
                        $style.boxShadow['color'] = color;
                    }
                }
                $style.boxShadow['offset'] = this.convertToPoints(parseFloat(shadowParts[0]));
                $style.boxShadow['blur'] = this.convertToPoints(parseFloat(shadowParts[1]));
            }
        }

        if (elementStyle.getPropertyValue('border-color')) {
            color = elementStyle.getPropertyValue('border-color');
            if (color !== 'rgba(0, 0, 0, 0)') {
                color = this.colorToHex(color);
                $style.border.color = color;
            }
        }

        if (elementStyle.getPropertyValue('border-width')) {
            var borderWidth = parseFloat(elementStyle.getPropertyValue('border-width'));
            if (borderWidth > 0) {
                $style.border.width = this.convertToPoints(borderWidth);
            }
        }

        if (elementStyle.getPropertyValue('border-top-left-radius')) {
            var borderRadius = elementStyle.getPropertyValue('border-top-left-radius');
            var isRadiusPecentage = borderRadius.includes('%');
            borderRadius = parseFloat(borderRadius);

            if (borderRadius > 0) {
                $style.border.radius = borderRadius;
                $style.line.radius = borderRadius;
                $style.border.isRadiusPecentage = isRadiusPecentage;
                $style.line.isRadiusPecentage = isRadiusPecentage;
            }
        }

        if (parseFloat(elementStyle.getPropertyValue('border-bottom-width')) > 0) {
            $style.line.width = parseFloat(elementStyle.getPropertyValue('border-bottom-width'));
            if (elementStyle.getPropertyValue('border-bottom-color')) {
                color = elementStyle.getPropertyValue('border-bottom-color');
                if (color !== 'rgba(0, 0, 0, 0)') {
                    color = this.colorToHex(color);
                    $style.line.color = color;
                }
            }
            if (elementStyle.getPropertyValue('border-bottom-style')) {
                $style.line.style = elementStyle.getPropertyValue('border-bottom-style');
            }
        }
        else if (parseFloat(elementStyle.getPropertyValue('border-top-width')) > 0) {
            $style.line.width = parseFloat(elementStyle.getPropertyValue('border-top-width'));
            if (elementStyle.getPropertyValue('border-top-color')) {
                color = elementStyle.getPropertyValue('border-top-color');
                if (color !== 'rgba(0, 0, 0, 0)') {
                    color = this.colorToHex(color);
                    $style.line.color = color;
                }
            }
            if (elementStyle.getPropertyValue('border-top-style')) {
                $style.line.style = elementStyle.getPropertyValue('border-top-style');
            }
        }

        if (parseFloat(elementStyle.getPropertyValue('border-top-width')) > 0 && parseFloat(elementStyle.getPropertyValue('border-right-width')) > 0) {
            $style.border.width = parseFloat(elementStyle.getPropertyValue('border-top-width'));
            $style.line.width = null;
            if (elementStyle.getPropertyValue('border-top-color')) {
                color = elementStyle.getPropertyValue('border-top-color');
                if (color !== 'rgba(0, 0, 0, 0)') {
                    color = this.colorToHex(color);
                    $style.border.color = color;
                    $style.line.color = null;
                }
            }
        }

        if (elementStyle.getPropertyValue('background-image')) {
            var image = elementStyle.getPropertyValue('background-image');
            const regexImageCss = /\burl\s*\(\s*["']?([^"'\r\n)(]+)["']?\s*\)/gi;
            var imageMatches = regexImageCss.exec(image);
            if (Array.isArray(imageMatches) && imageMatches.length > 0) {
                var imageUrl = imageMatches[1].trim();
                if (imageUrl !== '') {
                    $style.backgroundImage = await this.getImageBase64(imageUrl, $style.border.radius !== null, parseFloat(elementStyle.getPropertyValue('width')), parseFloat(elementStyle.getPropertyValue('height')));
                    if (!$style.backgroundImage) {
                        imageMatches = regexImageCss.exec(image);
                        if (Array.isArray(imageMatches) && imageMatches.length > 0) {
                            $style.backgroundImage = await this.getImageBase64(imageMatches[1], $style.border.radius !== null, parseFloat(elementStyle.getPropertyValue('width')), parseFloat(elementStyle.getPropertyValue('height')));
                        }
                    }
                }
            }
        }

        if (elementStyle.getPropertyValue('text-overflow')) {
            var overflow = elementStyle.getPropertyValue('text-overflow');
            if (overflow.trim() !== '') {
                $style.textOverflow = overflow.trim();
            }
        }
        if (elementStyle.getPropertyValue('white-space')) {
            var whiteSpace = elementStyle.getPropertyValue('white-space');
            if (whiteSpace.trim() !== '') {
                $style.whiteSpace = whiteSpace.trim();
            }
        }

        return $style;
    }

    getElementPosition(element) {
        var parentPos = this.currentPageSelected.getBoundingClientRect(),
            childPos = element.getBoundingClientRect(),
            relativePos = {};

        relativePos.top = childPos.top - parentPos.top;
        relativePos.left = childPos.left - parentPos.left;
        if (relativePos.left < 0)
            relativePos.left = childPos.left;
        if (relativePos.top < 0)
            relativePos.top = childPos.top;
        return relativePos;
    }

    async getElementProperties(element) {
        var $properties = {
            type: null,
            x: null,
            xInch: null,
            y: null,
            yInch: null,
            width: null,
            widthInch: null,
            height: null,
            heightInch: null,
            nodeType: null,
            tagName: null,
            style: {},
            text: null,
            imageData: null,
        };


        if (element.parentNode && element.parentNode.nodeName.includes('TEXT')) {
            var elementBounds = element.parentNode.getBoundingClientRect();
            var elementStyle = window.getComputedStyle(element.parentNode);
            var elementPosition = this.getElementPosition(element.parentNode);
        }
        else {
            elementPosition = this.getElementPosition(element);
            elementBounds = element.getBoundingClientRect();
            elementStyle = window.getComputedStyle(element);
        }

        var xPosition = elementPosition.left; // X
        if (xPosition !== null) {
            $properties.x = xPosition;
            $properties.xInch = this.convertToInch($properties.x);
        }

        var yPosition = elementPosition.top; // Y
        if (yPosition !== null) {
            $properties.y = yPosition;
            $properties.yInch = this.convertToInch($properties.y);
        }

        var width = elementBounds.width; // Width
        if (width !== null) {
            $properties.width = width;
            $properties.widthInch = this.convertToInch($properties.width);
        }

        var height = elementBounds.height; // Height
        if (height !== null) {
            $properties.height = height;
            $properties.heightInch = this.convertToInch($properties.height);
        }

        if (element.nodeType !== null && element.nodeType !== '') {
            $properties.nodeType = element.nodeType;
        }

        if (element.tagName !== null && element.tagName !== '') {
            $properties.tagName = element.tagName;
        }

        $properties.style = await this.getElementStyle(elementStyle);

        $properties.text = element.innerText || element.textContent;
        if (this.getElementText(element).trim() !== '') {
            $properties.type = 'text';
        }

        if (typeof $properties.text === 'string' && $properties.text.substr(0, 4) === 'http') {
            $properties.link = $properties.text;
        }

        switch ($properties.tagName) {
        case 'CANVAS':
            var imageDataUrl = element.toDataURL();

            var blank = document.createElement('canvas');
            blank.width = element.width;
            blank.height = element.height;
            blank.getContext('2d').clearRect(0, 0, element.width, element.height);

            $properties.type = 'image';
            $properties.imageData = imageDataUrl === blank.toDataURL() ? noMediaImage : imageDataUrl;
            break;
        case 'svg':
        case 'I':
            imageDataUrl = await this.getElementBlob(element);
            $properties.type = 'image';
            $properties.imageData = imageDataUrl ? imageDataUrl : noMediaImage;
            break;
        case 'IMG':
            var notNull = $properties.style.border.radius !== null;
            imageDataUrl = await this.getImageBase64(element.src, notNull, $properties.width, $properties.height);
            $properties.type = 'image';
            $properties.imageData = imageDataUrl ? imageDataUrl : noMediaImage;
            break;
        case 'VIDEO':
            imageDataUrl = await this.captureImage(element);
            $properties.type = 'image';
            $properties.imageData = imageDataUrl ? imageDataUrl : noMediaImage;
            break;
        case 'IFRAME':
            imageDataUrl = noMediaImage;
            $properties.type = 'image';
            $properties.imageData = imageDataUrl;
            break;
        default:
            break;
        }

        if (((isset($properties.style.textOverflow, '') === 'ellipsis')
            && (isset($properties.style.whiteSpace, '') === 'nowrap' || !($properties.text || '').includes(' '))
            && isset($properties.text, null)) || (isset(element.parentNode) && (element.parentNode.nodeName === 'TD' || element.parentNode.nodeName.includes('TEXT')))) {
            if (this.handleOverflowText(element) !== '<empty string>') {
                $properties.text = this.handleOverflowText(element);
            }
        }

        var elementStyleAfter = window.getComputedStyle(element, ':after');
        var styleDivAfter = await this.getElementStyle(elementStyleAfter);
        var elementStyleBefore = window.getComputedStyle(element, ':before');
        var styleDivBefore = await this.getElementStyle(elementStyleBefore);

        if (this.hasBoxStyles(styleDivAfter)) {
            $properties.style.boxShadow = styleDivAfter.boxShadow;
            $properties.style.border = styleDivBefore.border;
            $properties.style.line = styleDivAfter.line;
        }
        else if (this.hasBoxStyles(styleDivBefore)) {
            $properties.style.boxShadow = styleDivBefore.boxShadow;
            $properties.style.border = styleDivBefore.border;
            $properties.style.line = styleDivAfter.line;
        }

        if (this.hasBoxStyles($properties.style) && $properties.type === null) {
            $properties.type = 'styleDiv';
        }

        await this.getPseudoElements(element);
        return $properties;
    }

    getDPI() {
        const el = document.createElement('div');
        el.style = 'width: 1in;';
        document.body.appendChild(el);
        const dpi = el.offsetWidth;
        document.body.removeChild(el);
        return dpi;
    }

    getElementText(element) {
        var child = element.firstChild,
            texts = [];

        while (child) {
            if (child.nodeType === 3) {
                texts.push(child.data);
            }
            child = child.nextSibling;
        }

        var text = texts.join('');
        return text;
    }

    byteToHex(num) {
        // Turns a number (0-255) into a 2-character hex number (00-ff)
        return ('0' + num.toString(16)).slice(-2);
    }

    colorNameToHex(color) {
        var colors = {
            'aliceblue': '#f0f8ff', 'antiquewhite': '#faebd7', 'aqua': '#00ffff', 'aquamarine': '#7fffd4', 'azure': '#f0ffff',
            'beige': '#f5f5dc', 'bisque': '#ffe4c4', 'black': '#000000', 'blanchedalmond': '#ffebcd', 'blue': '#0000ff', 'blueviolet': '#8a2be2', 'brown': '#a52a2a', 'burlywood': '#deb887',
            'cadetblue': '#5f9ea0', 'chartreuse': '#7fff00', 'chocolate': '#d2691e', 'coral': '#ff7f50', 'cornflowerblue': '#6495ed', 'cornsilk': '#fff8dc', 'crimson': '#dc143c', 'cyan': '#00ffff',
            'darkblue': '#00008b', 'darkcyan': '#008b8b', 'darkgoldenrod': '#b8860b', 'darkgray': '#a9a9a9', 'darkgreen': '#006400', 'darkkhaki': '#bdb76b', 'darkmagenta': '#8b008b', 'darkolivegreen': '#556b2f',
            'darkorange': '#ff8c00', 'darkorchid': '#9932cc', 'darkred': '#8b0000', 'darksalmon': '#e9967a', 'darkseagreen': '#8fbc8f', 'darkslateblue': '#483d8b', 'darkslategray': '#2f4f4f', 'darkturquoise': '#00ced1',
            'darkviolet': '#9400d3', 'deeppink': '#ff1493', 'deepskyblue': '#00bfff', 'dimgray': '#696969', 'dodgerblue': '#1e90ff',
            'firebrick': '#b22222', 'floralwhite': '#fffaf0', 'forestgreen': '#228b22', 'fuchsia': '#ff00ff',
            'gainsboro': '#dcdcdc', 'ghostwhite': '#f8f8ff', 'gold': '#ffd700', 'goldenrod': '#daa520', 'gray': '#808080', 'green': '#008000', 'greenyellow': '#adff2f',
            'honeydew': '#f0fff0', 'hotpink': '#ff69b4',
            'indianred ': '#cd5c5c', 'indigo': '#4b0082', 'ivory': '#fffff0', 'khaki': '#f0e68c',
            'lavender': '#e6e6fa', 'lavenderblush': '#fff0f5', 'lawngreen': '#7cfc00', 'lemonchiffon': '#fffacd', 'lightblue': '#add8e6', 'lightcoral': '#f08080', 'lightcyan': '#e0ffff', 'lightgoldenrodyellow': '#fafad2',
            'lightgrey': '#d3d3d3', 'lightgreen': '#90ee90', 'lightpink': '#ffb6c1', 'lightsalmon': '#ffa07a', 'lightseagreen': '#20b2aa', 'lightskyblue': '#87cefa', 'lightslategray': '#778899', 'lightsteelblue': '#b0c4de',
            'lightyellow': '#ffffe0', 'lime': '#00ff00', 'limegreen': '#32cd32', 'linen': '#faf0e6',
            'magenta': '#ff00ff', 'maroon': '#800000', 'mediumaquamarine': '#66cdaa', 'mediumblue': '#0000cd', 'mediumorchid': '#ba55d3', 'mediumpurple': '#9370d8', 'mediumseagreen': '#3cb371', 'mediumslateblue': '#7b68ee',
            'mediumspringgreen': '#00fa9a', 'mediumturquoise': '#48d1cc', 'mediumvioletred': '#c71585', 'midnightblue': '#191970', 'mintcream': '#f5fffa', 'mistyrose': '#ffe4e1', 'moccasin': '#ffe4b5',
            'navajowhite': '#ffdead', 'navy': '#000080',
            'oldlace': '#fdf5e6', 'olive': '#808000', 'olivedrab': '#6b8e23', 'orange': '#ffa500', 'orangered': '#ff4500', 'orchid': '#da70d6',
            'palegoldenrod': '#eee8aa', 'palegreen': '#98fb98', 'paleturquoise': '#afeeee', 'palevioletred': '#d87093', 'papayawhip': '#ffefd5', 'peachpuff': '#ffdab9', 'peru': '#cd853f', 'pink': '#ffc0cb', 'plum': '#dda0dd', 'powderblue': '#b0e0e6', 'purple': '#800080',
            'rebeccapurple': '#663399', 'red': '#ff0000', 'rosybrown': '#bc8f8f', 'royalblue': '#4169e1',
            'saddlebrown': '#8b4513', 'salmon': '#fa8072', 'sandybrown': '#f4a460', 'seagreen': '#2e8b57', 'seashell': '#fff5ee', 'sienna': '#a0522d', 'silver': '#c0c0c0', 'skyblue': '#87ceeb', 'slateblue': '#6a5acd', 'slategray': '#708090', 'snow': '#fffafa', 'springgreen': '#00ff7f', 'steelblue': '#4682b4',
            'tan': '#d2b48c', 'teal': '#008080', 'thistle': '#d8bfd8', 'tomato': '#ff6347', 'turquoise': '#40e0d0',
            'violet': '#ee82ee',
            'wheat': '#f5deb3', 'white': '#ffffff', 'whitesmoke': '#f5f5f5',
            'yellow': '#ffff00', 'yellowgreen': '#9acd32'
        };

        if (typeof colors[color.toLowerCase()] !== 'undefined')
            return colors[color.toLowerCase()];

        return false;
    }

    hexToRGBA(hex) {
        var c;

        if (/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
            c = hex.substring(1).split('');

            if (c.length === 3) {
                c = [c[0], c[0], c[1], c[1], c[2], c[2]];
            }

            c = '0x' + c.join('');

            return [(c >> 16) & 255, (c >> 8) & 255, c & 255, 1];
        }

        return false;
    }

    colorToRGBA(color) {
        if (color.substr(0, 3) === 'rgb') {
            var auxColor = color.split('(')[1];
            auxColor = auxColor.split(')')[0];
            auxColor = auxColor.split(',');

            for (var key in auxColor) {
                auxColor[`${key}`] = parseFloat(auxColor[`${key}`].toString().trim());
            }

            if (auxColor.length === 3) {
                auxColor.push(255);
            } else {
                auxColor[3] = (255 * auxColor[3] / 1);
            }

            return auxColor;
        }

        auxColor = this.colorNameToHex(color);

        if (!auxColor) auxColor = color;

        auxColor = this.hexToRGBA(auxColor);

        if (!auxColor) auxColor = [255, 255, 255, 1];

        return auxColor;
    }

    colorToHex(color) {
        // Convert any CSS color to a hex representation
        // Examples:
        // colorToHex('red')            # '#ff0000'
        // colorToHex('rgb(255, 0, 0)') # '#ff0000'
        var _this = this;
        var rgba, hex;
        rgba = this.colorToRGBA(color);

        hex = [0, 1, 2].map(
            function (idx) { return _this.byteToHex(rgba[`${idx}`]); }
        ).join('');

        return hex;
    }

    /* eslint-disable */
    pSBC(p, c0, c1, l) {
        let r, g, b, P, f, t, h, i = parseInt, m = Math.round, a = typeof (c1) === 'string';
        if (typeof (p) !== 'number' || p < -1 || p > 1 || typeof (c0) !== 'string' || (c0[0] !== 'r' && c0[0] !== '#') || (c1 && !a)) return null;
        if (!this.pSBCr) this.pSBCr = (d) => {
            let n = d.length, x = {};
            if (n > 9) {
                [r, g, b, a] = d = d.split(',');
                n = d.length;
                if (n < 3 || n > 4) return null;
                x.r = i(r[3] === 'a' ? r.slice(5) : r.slice(4));
                x.g = i(g);
                x.b = i(b);
                x.a = a ? parseFloat(a) : -1;
            } else {
                if (n === 8 || n === 6 || n < 4) return null;
                if (n < 6) d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
                d = i(d.slice(1), 16);
                if (n === 9 || n === 5) {
                    x.r = d >> 24 & 255;
                    x.g = d >> 16 & 255;
                    x.b = d >> 8 & 255;
                    x.a = m((d & 255) / 0.255) / 1000;
                } else {
                    x.r = d >> 16;
                    x.g = d >> 8 & 255;
                    x.b = d & 255;
                    x.a = -1;
                }
            } return x;
        };
        h = c0.length > 9;
        h = a ? c1.length > 9 ? true : c1 === 'c' ? !h : false : h;
        f = this.pSBCr(c0);
        P = p < 0;
        t = c1 && c1 !== 'c' ? this.pSBCr(c1) : P ? { r: 0, g: 0, b: 0, a: -1 } : { r: 255, g: 255, b: 255, a: -1 };
        p = P ? p * -1 : p;
        P = 1 - p;
        if (!f || !t) return null;
        if (l) {
            r = m(P * f.r + p * t.r);
            g = m(P * f.g + p * t.g);
            b = m(P * f.b + p * t.b);
        } else {
            r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5);
            g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5);
            b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5);
        }
        a = f.a;
        t = t.a;
        f = a >= 0 || t >= 0;
        a = f ? a < 0 ? t : t < 0 ? a : a * P + t * p : 0;
        if (h) return 'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')';
        else return '#' + (4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0)).toString(16).slice(1, f ? undefined : -2);
    }
    /* eslint-enable */

    generatePdf() {
        var callAddFont = function () {
            this.addFileToVFS('chinese-normal.ttf', chineseNormal);
            this.addFont('chinese-normal.ttf', 'chinese', 'normal');
            this.addFileToVFS('Roboto-normal.ttf', robotoNormal);
            this.addFileToVFS('Roboto-italic.ttf', robotoItalic);
            this.addFileToVFS('Roboto-bold.ttf', robotoBold);
            this.addFileToVFS('Roboto-black.ttf', robotoBlack);
            this.addFont('Roboto-normal.ttf', 'Roboto', 'normal');
            this.addFont('Roboto-italic.ttf', 'Roboto', 'italic');
            this.addFont('Roboto-bold.ttf', 'Roboto', 'bold');
            this.addFont('Roboto-black.ttf', 'Roboto', 'black');
        };

        jsPDF.API.events.push(['addFonts', callAddFont]);

        const pdfDoc = new jsPDF({
            orientation: 'landscape',
            unit: 'in',
            format: [this.layoutWidth, this.layoutHeight]
        });

        pdfDoc.setFont('Roboto');
        pdfDoc.setFontSize(this.convertToPoints(14));

        this.stageCallback('generatingFile');
        for (let page of this.pages) {
            for (let currentElement of page) {
                switch (currentElement.type) {
                case 'text':
                case 'styleDiv':
                    var textSettings = {
                        maxWidth: currentElement.widthInch,
                        maxHeight: currentElement.heightInch,
                        align: currentElement.style.textAlign,
                        margin: currentElement.style.padding || 0
                    };

                    if (!['left', 'center', 'right', 'justify'].includes(textSettings.align)) {
                        textSettings.align = 'left';
                    }
                    if (currentElement.style.fontSize) {
                        pdfDoc.setFontSize(currentElement.style.fontSize);
                    }
                    if (currentElement.style.color) {
                        pdfDoc.setTextColor('#' + currentElement.style.color);
                    }
                    if (currentElement.style.background) {
                        var newFillColor = this.pSBC(currentElement.style.backgroundTransparency / 100, '#' + currentElement.style.background, false, true);
                        pdfDoc.setFillColor(newFillColor);
                    }
                    if (currentElement.style.boxShadow.color) {
                        textSettings.shadow = currentElement.style.boxShadow;
                    }
                    if (currentElement.text === '""') {
                        currentElement.text = '';
                    }
                    var fontStyle = 'normal';
                    if (currentElement.style.textStyle === 'italic') {
                        fontStyle = 'italic';
                    }

                    if (currentElement.style.fontWeight === 'bold' || parseFloat(currentElement.style.fontWeight) > 400 && parseFloat(currentElement.style.fontWeight) <= 600) {
                        fontStyle = 'bold';
                    }
                    else if (parseFloat(currentElement.style.fontWeight) > 600)
                        fontStyle = 'black';

                    if (currentElement.text.match(/[\u3400-\u9FBF]/)) {
                        pdfDoc.setFont('chinese', fontStyle);
                    }
                    else {
                        pdfDoc.setFont('Roboto', fontStyle);
                    }
                    var radius = 0;
                    if (currentElement.style.border.radius) {
                        if (currentElement.style.border.isRadiusPecentage) {
                            radius = currentElement.heightInch * (currentElement.style.border.radius / 100);
                        } else {
                            radius = this.convertToInch(currentElement.style.border.radius);
                            if (currentElement.heightInch < currentElement.widthInch) {
                                var smallerSide = currentElement.heightInch;
                            } else {
                                smallerSide = currentElement.widthInch;
                            }
                            var halfHeight = smallerSide / 2;
                            if (radius > halfHeight) {
                                radius = halfHeight;
                            }
                        }
                    }

                    if (currentElement.style.background) {
                        var typeFill = 'F';

                        if (currentElement.style.border.width) {
                            typeFill = 'DF';
                            pdfDoc.setDrawColor('#' + currentElement.style.border.color);
                            pdfDoc.setLineWidth(this.convertPointsToInch(currentElement.style.border.width));
                            pdfDoc.setLineDashPattern([], 0);
                        }
                        pdfDoc.roundedRect(
                            currentElement.xInch || 0,
                            currentElement.yInch || 0,
                            currentElement.widthInch || 0,
                            currentElement.heightInch || 0,
                            radius,
                            radius,
                            typeFill
                        );
                    }
                    if ((currentElement.text || '') !== '' && currentElement.type !== 'text') {
                        currentElement.text = '';
                    }
                    var posX = currentElement.xInch || 0;
                    var posY = currentElement.yInch || 0;
                    posY += this.convertPointsToInch(pdfDoc.getFontSize());
                    if (textSettings.align === 'center') {
                        posX += currentElement.widthInch / 2;
                    }
                    if (textSettings.align === 'right') {
                        posX += currentElement.widthInch;
                    }
                    if (currentElement.style.padding[0] > 0) {
                        var paddingInch = this.convertPointsToInch(currentElement.style.padding[0]);
                        textSettings.maxWidth -= paddingInch;
                        if (textSettings.align === 'left') {
                            posX += paddingInch;
                        }
                    }
                    if (currentElement.style.padding[1] > 0) {
                        paddingInch = this.convertPointsToInch(currentElement.style.padding[1]);
                        textSettings.maxWidth -= paddingInch;
                        if (textSettings.align === 'right') {
                            posX -= paddingInch;
                        }
                    }
                    if (currentElement.style.padding[3] > 0) {
                        paddingInch = this.convertPointsToInch(currentElement.style.padding[3]);
                        textSettings.maxHeight -= paddingInch;
                        posY += paddingInch;
                    }
                    if (currentElement.style.padding[2] > 0) {
                        paddingInch = this.convertPointsToInch(currentElement.style.padding[2]);
                        textSettings.maxHeight -= paddingInch;
                    }
                    if (currentElement.style.verticalAlign === 'middle') {
                        textSettings.baseline = 'middle';
                        posY += textSettings.maxHeight * 0.5;
                        posY -= this.convertPointsToInch(pdfDoc.getFontSize());
                    }

                    if (textSettings.maxWidth < pdfDoc.getTextWidth('M')) {
                        textSettings.maxWidth = pdfDoc.getTextWidth('M');
                    }

                    textSettings.maxWidth *= this.textThreshold;
                    if (textSettings.maxWidth >= this.layoutWidth) {
                        textSettings.maxWidth /= this.textThreshold;
                    }
                    if (currentElement.link) {
                        pdfDoc.textWithLink(currentElement.text, posX, posY, { url: currentElement.link });
                    }
                    else {
                        pdfDoc.text(currentElement.text || '', posX, posY, textSettings);
                    }
                    if (currentElement.style.backgroundImage) {
                        pdfDoc.addImage(currentElement.style.backgroundImage, 'JPEG', currentElement.xInch, currentElement.yInch, currentElement.widthInch, currentElement.heightInch);
                    }


                    if (currentElement.tagName === 'TABLE') {
                        pdfDoc.setDrawColor('#' + currentElement.style.border.color);
                        pdfDoc.setLineWidth(this.convertPointsToInch(currentElement.style.border.width));
                        pdfDoc.setLineDashPattern([], 0);
                        pdfDoc.roundedRect(
                            currentElement.xInch || 0,
                            currentElement.yInch || 0,
                            currentElement.widthInch || 0,
                            currentElement.heightInch || 0,
                            radius,
                            radius);
                    }

                    if (currentElement.style.boxShadow.color) {
                        var color = this.colorToRGBA('#' + currentElement.style.boxShadow.color);
                        pdfDoc.setDrawColor(color[0], color[1], color[2], currentElement.style.boxShadow.opacity);
                        pdfDoc.setLineWidth(this.convertPointsToInch(currentElement.style.boxShadow.blur));
                        pdfDoc.setLineDashPattern([], 0);
                        pdfDoc.roundedRect(
                            currentElement.xInch || 0,
                            currentElement.yInch || 0,
                            currentElement.widthInch || 0,
                            currentElement.heightInch || 0,
                            radius,
                            radius);
                    }

                    if (currentElement.style.line.color && currentElement.style.line.style !== 'none') {
                        switch (currentElement.style.line.style) {
                        case 'dashed':
                            var dash1 = this.convertToInch(8);
                            pdfDoc.setLineDashPattern([dash1]);
                            break;
                        case 'dotted':
                            dash1 = this.convertToInch(2);
                            pdfDoc.setLineDashPattern([dash1]);
                            break;
                        default:
                            pdfDoc.setLineDashPattern([], 0);
                            break;
                        }

                        var yAxis = currentElement.yInch + currentElement.heightInch;
                        pdfDoc.setDrawColor('#' + currentElement.style.line.color);
                        pdfDoc.setLineWidth(this.convertPointsToInch(currentElement.style.line.width));
                        pdfDoc.line(currentElement.xInch, yAxis, currentElement.xInch + currentElement.widthInch, yAxis);
                    }
                    break;
                case 'image':
                    if (currentElement.widthInch > 0 && currentElement.heightInch > 0)
                        pdfDoc.addImage(currentElement.imageData, 'JPEG', currentElement.xInch, currentElement.yInch, currentElement.widthInch, currentElement.heightInch);
                    break;
                default:
                    break;
                }
            }
            pdfDoc.addPage();
        }
        //pdfDoc.removePage(currentPage);
        if (this.save) {
            this.pages = [];
            var pdfPages = pdfDoc.internal.getNumberOfPages();
            pdfDoc.deletePage(pdfPages);
            pdfDoc.save(this.exportName + '.pdf');
        }
    }

    generatePPtx() {
        this.stageCallback('generatingFile');

        let pptx = new pptxgen();

        // Define new layout for the Presentation
        pptx.defineLayout({ name: 'Report', width: this.layoutWidth, height: this.layoutHeight });

        // Set presentation to use new layout
        pptx.layout = 'Report';

        for (let page of this.pages) {
            let slide = pptx.addSlide();
            for (var elementIndex in page) {
                var currentElement = page[`${elementIndex}`];

                switch (currentElement.type) {
                case 'text':
                case 'styleDiv': {
                    var radius = 0;
                    if (currentElement.style.border.radius) {
                        if (currentElement.style.border.isRadiusPecentage) {
                            radius = currentElement.heightInch * (currentElement.style.border.radius / 100);
                        } else {
                            radius = this.convertToInch(currentElement.style.border.radius);
                            if (currentElement.heightInch < currentElement.widthInch) {
                                var smallerSide = currentElement.heightInch;
                            } else {
                                smallerSide = currentElement.widthInch;
                            }
                            var halfHeight = smallerSide / 2;
                            if (radius > halfHeight) {
                                radius = halfHeight;
                            }
                        }
                    }

                    let textSettings = {
                        x: currentElement.xInch,
                        y: currentElement.yInch,
                        w: currentElement.widthInch,
                        h: currentElement.heightInch,
                        fontSize: currentElement.style.fontSize,
                        fontFace: 'Arial',
                        align: currentElement.style.textAlign,
                        margin: currentElement.style.padding || 0,
                        italic: currentElement.style.textStyle === 'italic',
                        bold: currentElement.style.fontWeight === 'bold' || parseInt(currentElement.style.fontWeight) > 400,
                    };
                    if (currentElement.style.padding[0] > 0) {
                        var paddingInch = this.convertPointsToInch(currentElement.style.padding[0]);
                        textSettings.w -= paddingInch;
                    }
                    if (currentElement.style.padding[1] > 0) {
                        paddingInch = this.convertPointsToInch(currentElement.style.padding[1]);
                        textSettings.w -= paddingInch;
                    }

                    if (currentElement.style.color) {
                        textSettings.color = { color: currentElement.style.color };
                    }
                    if (currentElement.style.background) {
                        if (currentElement.style.border.width) {
                            slide.addShape(pptx.ShapeType.roundRect, {
                                x: currentElement.xInch || 0,
                                y: currentElement.yInch || 0,
                                w: currentElement.widthInch || 0,
                                h: currentElement.heightInch || 0,
                                line: { color: '#' + currentElement.style.border.color, width: this.convertPointsToInch(currentElement.style.border.width) },
                                rectRadius: radius
                            });
                        }
                        textSettings.fill = { color: currentElement.style.background, alpha: currentElement.style.backgroundTransparency, transparency: currentElement.style.backgroundTransparency };
                    }
                    if (currentElement.style.boxShadow.color) {
                        textSettings.shadow = { size: currentElement.style.boxShadow.blur, opacity: currentElement.style.boxShadow.opacity, color: currentElement.style.boxShadow.color };
                    }
                    if (currentElement.style.border.radius) {
                        if (currentElement.style.border.isRadiusPecentage) {
                            textSettings.rectRadius = textSettings.h * (currentElement.style.border.radius / 100);
                        } else {
                            textSettings.rectRadius = this.convertToInch(currentElement.style.border.radius);
                        }
                        textSettings.shape = pptx.shapes.ROUNDED_RECTANGLE;
                    }
                    if (currentElement.text === '""') {
                        currentElement.text = '';
                    }
                    if ((currentElement.text || '') !== '' && currentElement.type !== 'text') {
                        currentElement.text = '';
                    }

                    if (isset(currentElement.text, '') !== '') {
                        textSettings.w *= this.textThreshold;
                        if (textSettings.w > this.layoutWidth) {
                            textSettings.w /= this.textThreshold;
                        }
                    }
                    var nextElement = page[`${parseInt(elementIndex) + 1}`];
                    if (nextElement && nextElement.tagName === 'HIGHLIGHT') {
                        let nextTextSettings = clone({ ...textSettings, breakLine: false });
                        if (nextElement.style.color) {
                            nextTextSettings.color = { color: nextElement.style.color };
                        }
                        if (nextElement.style.background) {
                            nextTextSettings.fill = { color: nextElement.style.background, alpha: nextElement.style.backgroundTransparency, transparency: nextElement.style.backgroundTransparency };
                        }

                        let textArray = [{ text: currentElement.text, options: { ...textSettings, breakLine: false } }];
                        while (nextElement && nextElement.tagName === 'HIGHLIGHT') {
                            const tempNextElement = nextElement;
                            textArray.map((textObject, key) => {
                                var text = textObject.text;
                                var splittedText = text.split(tempNextElement.text);
                                if (text !== tempNextElement.text) {
                                    let tempTextArray = [];
                                    if (splittedText.length > 1) for (let id in splittedText) {
                                        if (parseInt(id) !== 0) {
                                            tempTextArray.push({ text: tempNextElement.text, options: nextTextSettings });
                                            if (splittedText[`${id}`].length > 1)
                                                tempTextArray.push({ text: splittedText[`${id}`], options: { ...textSettings, breakLine: false } });
                                        }
                                        else {
                                            if (splittedText[`${id}`].length > 1) tempTextArray.push({ text: splittedText[`${id}`], options: { ...textSettings, breakLine: false } });
                                        }
                                    }
                                    if (tempTextArray.length > 1) textArray.splice(key, 1, ...tempTextArray);
                                }
                                return null;
                            });

                            page.splice(parseInt(elementIndex) + 1, 1);
                            nextElement = page[parseInt(elementIndex) + 1];
                        }
                        var maxLength = textArray.length;
                        for (let id = 0; id < maxLength; id++) {
                            let textObject = textArray[`${id}`];
                            if (textObject.text.includes('\n') && textObject.text.length > 2) {
                                let tempTextArray = [];
                                let splittedTexArray = textObject.text.split('\n');
                                splittedTexArray.map((text, key) => {
                                    tempTextArray.push({ text: text, options: textObject.options });
                                    if (parseInt(key) !== splittedTexArray.length - 1) tempTextArray.push({ text: '\n', options: textObject.options });
                                    return null;
                                });
                                textArray.splice(id, 1, ...tempTextArray);
                                maxLength = textArray.length;
                            }
                        }
                        slide.addText(textArray, { x: textSettings.x, y: textSettings.y, w: textSettings.w, h: textSettings.h });
                    } else {
                        if (currentElement.text.trim().length >= 1) {
                            slide.addText(currentElement.text || '', textSettings);
                        }
                        else {
                            var rectObject = {
                                x: currentElement.xInch || 0,
                                y: currentElement.yInch || 0,
                                w: currentElement.widthInch || 0,
                                h: currentElement.heightInch || 0,
                                fill: textSettings.fill,
                                rectRadius: radius
                            };
                            if (radius > 0) {
                                slide.addShape(pptx.ShapeType.roundRect, rectObject);
                            }
                            else {
                                slide.addShape(pptx.ShapeType.rect, rectObject);
                            }
                        }
                    }
                    if (currentElement.style.backgroundImage) {
                        slide.addImage({
                            x: currentElement.xInch || 0,
                            y: currentElement.yInch || 0,
                            w: currentElement.widthInch || 0,
                            h: currentElement.heightInch || 0,
                            rounding: currentElement.style.border.radius && currentElement.style.border.radius >= 50,
                            data: currentElement.style.backgroundImage
                        });
                    }

                    if (currentElement.tagName === 'TABLE') {
                        slide.addShape(pptx.ShapeType.roundRect, {
                            x: currentElement.xInch || 0,
                            y: currentElement.yInch || 0,
                            w: currentElement.widthInch || 0,
                            h: currentElement.heightInch || 0,
                            line: { color: '#' + currentElement.style.border.color, width: this.convertPointsToInch(currentElement.style.border.width) },
                            rectRadius: radius
                        });
                    }

                    if (currentElement.style.boxShadow.color) {
                        var color = this.colorToRGBA('#' + currentElement.style.boxShadow.color);
                        color = this.convertRGBAToRGB(color[0] + ',' + color[1] + ',' + color[2] + ',' + parseFloat(currentElement.style.boxShadow.opacity));
                        color = this.colorToHex('rgb(' + color + ')');
                        slide.addShape(pptx.ShapeType.roundRect, {
                            x: currentElement.xInch || 0,
                            y: currentElement.yInch || 0,
                            w: currentElement.widthInch || 0,
                            h: currentElement.heightInch || 0,
                            line: { color: '#' + color, width: currentElement.style.boxShadow.blur },
                            rectRadius: radius
                        });
                    }
                    if (currentElement.style.line.color && currentElement.style.line.style !== 'none') {
                        switch (currentElement.style.line.style) {
                        case 'dashed':
                            var lineStyle = 'dash';
                            break;
                        case 'dotted':
                            lineStyle = 'dot';
                            break;
                        default:
                            lineStyle = 'solid';
                            break;
                        }
                        slide.addShape(pptx.ShapeType.line, {
                            x: currentElement.xInch,
                            y: currentElement.yInch + currentElement.heightInch,
                            w: currentElement.widthInch,
                            h: 0,
                            line: {
                                color: currentElement.style.line.color,
                                width: currentElement.style.line.width > 1 ? this.convertToPoints(currentElement.style.line.width) : currentElement.style.line.width,
                                dashType: lineStyle
                            }
                        });
                    }
                }
                    break;
                case 'image':
                    slide.addImage({
                        x: currentElement.xInch,
                        y: currentElement.yInch,
                        w: currentElement.widthInch,
                        h: currentElement.heightInch,
                        rounding: currentElement.style.border.radius && currentElement.style.border.radius >= 50,
                        data: currentElement.imageData,
                        sizing: { type: 'contain', w: currentElement.widthInch, h: currentElement.heightInch }
                    });
                    break;
                default:
                    break;
                }
            }
        }

        if (this.save) {
            this.pages = [];
            pptx.writeFile(this.exportName + '.pptx'); // eslint-disable-line
        }
    }
}

export default ExportReports;
