import Config from '../../../base/config/Config';
import { isset, issetDot } from '../../../base/Utils';
import i18n from '../../../i18n';
import Request from '../../classes/Request';

class AnalyticsRequest {
    static getReports(area, auxData, pagination, callback) {
        for (let key in auxData) {
            if (!isset(auxData[`${key}`]) || auxData[`${key}`] === null || auxData[`${key}`] === '') {
                delete auxData[`${key}`];
            }
        }

        var url = Config.backendHost + 'insights/' + area + '/reports' + (Object.keys(auxData).length > 0 ? '?' + Request.encodeQueryData(auxData) : '');
        if (pagination && pagination.length > 0) {
            url = pagination + (Object.keys(auxData).length > 0 ? '&' + Request.encodeQueryData(auxData) : '');
        }

        Request.send({ method: 'get', url: url },
            (data) => {
                data = isset(data, {});
                var reports = Object.values(isset(data.data, []));
                var status = isset(data.status, '');
                var states = {};

                switch (status) {
                case 'ok':
                    states.planLimit = isset(data.planLimit, {});
                    states.notPermitted = false;
                    states.rules = isset(data.rules, {});
                    states.reportsList = reports;
                    states.pagination = isset(data.pagination, {});
                    states.sortBy = isset(data.sortBy, 'desc');
                    states.reportsSources = isset(data.sources, []);

                    states.planReports = isset(states.planLimit.reports, {});
                    var limitReports = isset(states.planReports.limit, 0);

                    if (limitReports <= 0) {
                        states.limitedArea = true;
                    }
                    else {
                        states.limitedArea = false;
                    }
                    break;
                case 'teamPermissions':
                    states.notPermitted = true;
                    break;
                case 'planLimits':
                    states.limitedArea = true;
                    break;
                default:
                    break;
                }
                callback(states);
            });
    }

    static getReport(area, reportId, callback) {
        Request.send({ method: 'get', url: Config.backendHost + 'insights/' + area + '/reports/' + reportId },
            (response) => {
                callback(isset(response, {}));
            });
    }

    static getChannels(area, callback) {
        Request.send({ method: 'get', url: Config.backendHost + 'channels?area=' + area }, (channelsResponse) => {
            var selectChannels = [{ value: '', label: i18n.t('All Channels') }];
            if (isset(channelsResponse.status, '') === 'ok') {
                var data = isset(channelsResponse.data, []);
                data.map((channel) => {
                    selectChannels.push({ value: channel.id, label: channel.name, photo: channel.photo });
                    return null;
                });
            }
            callback(selectChannels);
        });
    }

    static deleteReport(area, reportId, params, callback) {
        Request.send({ method: 'delete', url: Config.backendHost + 'insights/' + area + '/reports/' + reportId, params: params },
            (data) => {
                var states = {};
                data = isset(data, {});
                var status = isset(data.status, '');
                switch (status) {
                case 'ok':
                    states.planLimit = isset(data.planLimit, {});
                    states.rules = isset(data.rules, {});
                    states.reportsList = isset(data.data, []);
                    states.pagination = isset(data.pagination, {});
                    states.sortBy = isset(data.sortBy, 'desc');
                    states.deleteReportPopup = false;
                    states.reportsSources = isset(data.sources, []);
                    break;
                case 'error':
                    break;
                case 'teamPermissions':
                    states['noPermissions'] = true;
                    break;
                default:
                }
                callback(states);
            });
    }

    static getReportDashboard(area, id, auxData, isOverView, callback) {
        var url = isOverView ? 'insights/' + area + '/overview' : 'insights/' + area + '/reports/' + id + '/dashboard';
        for (let key in auxData) {
            if (!isset(auxData[`${key}`]) || auxData[`${key}`] === null || auxData[`${key}`] === '') {
                delete auxData[`${key}`];
            }
        }
        Request.send({
            method: 'get',
            url: Config.backendHost + url + (Object.keys(auxData).length > 0 ? '?' + Request.encodeQueryData(auxData) : '')
        },
        (response) => {
            response = isset(response, {});
            var status = isset(response.status, '');
            var states = { profile: {} };
            var data = isset(response.data, {});

            switch (status) {
            case 'ok':
                if (typeof isset(data.categories, []) === 'object') {
                    var categories = Object.values(data.categories);
                    states.categories = categories;
                }
                else
                    states.categories = isset(data.categories, []);
                states.startDate = issetDot(data, 'insights.startDate', null);
                states.endDate = issetDot(data, 'insights.endDate', null);
                states.vsStartDate = issetDot(data, 'insights.vsStartDate', null);
                states.vsEndDate = issetDot(data, 'insights.vsEndDate', null);
                states.boxes = issetDot(data, 'insights.boxes', []);
                states.profile = isset(data.profile, {});
                states.vsProfile = isset(data.vs, null);
                states.accountId = isset(data.accountId, '');
                states.brand = isset(data.brand, {});
                states.exportLanguage = isset(data.exportLanguage, '');
                states.postsDetails = isset(data.postsDetails, 'full');
                states.rules = isset(response.rules, {});
                states.isEmpty = false;
                states.limitedArea = false;
                states.notPermitted = false;
                states.wentWrong = false;
                states.profilesNotFound = false;
                break;
            case 'teamPermissions':
                states.notPermitted = true;
                states.isEmpty = false;
                states.limitedArea = false;
                states.wentWrong = false;
                states.profilesNotFound = false;
                states.boxes = [];
                break;
            case 'planLimits':
                states.limitedArea = true;
                states.isEmpty = false;
                states.notPermitted = false;
                states.wentWrong = false;
                states.profilesNotFound = false;
                states.boxes = [];
                break;
            case 'empty':
                states.isEmpty = true;
                states.limitedArea = false;
                states.notPermitted = false;
                states.wentWrong = false;
                states.profilesNotFound = false;
                states.boxes = [];
                break;
            case 'profilesNotFound':
                states.isEmpty = false;
                states.limitedArea = false;
                states.notPermitted = false;
                states.wentWrong = false;
                states.profilesNotFound = true;
                states.boxes = [];
                break;
            default:
                states.boxes = [];
                states.wentWrong = true;
                states.isEmpty = false;
                states.limitedArea = false;
                states.notPermitted = false;
                states.profilesNotFound = false;
                break;
            }
            callback(states);
        });
    }

    static postReport(area, params, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'insights/' + area + '/reports',
            params: params
        },
        (response) => {
            var data = isset(response, {});
            callback(data);
        });
    }

    static putReport(area, reportId, params, callback) {
        Request.send({
            method: 'put',
            url: Config.backendHost + 'insights/' + area + '/reports/' + reportId,
            params: params
        }, (response) => {
            var states = {};
            var data = isset(response, {});
            states.planLimit = isset(data.planLimit, {});
            states.rules = isset(data.rules, {});
            states.reportsList = isset(data.data, []);
            states.pagination = isset(data.pagination, {});
            states.sortBy = isset(data.sortBy, 'desc');
            states.reportsSources = isset(data.sources, []);
            states.editReportPopup = false;
            callback(states);
        });
    }

    static getMetrics(area, source, callback) {
        Request.send({ method: 'get', url: Config.backendHost + 'insights/' + area + '/metrics?source=' + source },
            (metricsResponse) => {
                var states = {};
                if (isset(metricsResponse.status, '') === 'ok') {
                    var metricsData = isset(metricsResponse.data, {});

                    var stateMetrics = isset(metricsData.metrics, []);
                    states['metrics'] = stateMetrics;
                }
                callback(states);
            });
    }

    static putMetrics(area, reportId, params, callback) {
        Request.send({
            method: 'put',
            url: Config.backendHost + 'insights/' + area + '/reports/' + reportId + '/metrics',
            params: params
        }, (response) => {
            var data = isset(response, {});
            callback(data);
        });
    }
    static getEmailSchedules(area, callback) {
        var areaParams = '';
        if (isset(area) && area.toString().trim().length > 0) {
            areaParams = '?area=' + area;
        }
        Request.send({ method: 'get', url: Config.backendHost + 'emails/schedules' + areaParams },
            (response) => {
                var states = {};
                if (isset(response.status, '') === 'ok') {
                    states['data'] = isset(response.data, []);
                    states['planLimit'] = isset(response.planLimit, {});
                }
                callback(states);
            });
    }

    static getConnectedReportEmailSchedule(scheduleId, area, callback) {
        var areaParams = '';
        if (isset(area) && area.toString().trim().length > 0) {
            areaParams = '?area=' + area;
        }
        Request.send({ method: 'get', url: Config.backendHost + 'emails/schedules/connectedReport/' + scheduleId + areaParams },
            (response) => {
                callback(isset(response.data, {}));
            });
    }

    static getEmailSchedule(scheduleId, callback) {
        Request.send({ method: 'get', url: Config.backendHost + 'emails/schedules/' + scheduleId },
            (response) => {
                callback(isset(response.data, {}));
            });
    }

    static postEmailSchedule(params, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'emails/schedules',
            params: params
        },
        (response) => {
            switch (response.status) {
            case 'ok':
                callback(isset(response.data, {}));
                break;
            case 'planLimit':
                callback(isset(response.planLimit, {}));
                break;
            default:
                break;
            }
        });
    }

    static deleteEmailSchedule(scheduleId, callback) {
        Request.send({ method: 'delete', url: Config.backendHost + 'emails/schedules/' + scheduleId },
            (response) => {
                callback(isset(response, {}));
            });
    }

    static putEmailSchedule(scheduleId, params, callback) {
        Request.send({
            method: 'put',
            url: Config.backendHost + 'emails/schedules/' + scheduleId,
            params: params
        },
        (response) => {
            callback(isset(response.data, {}));
        });
    }

    static getPageLink(area, id, callback) {
        Request.send({ method: 'get', url: Config.backendHost + 'link/' + area + '/' + id },
            (response) => {
                callback(isset(response, {}));
            });
    }

    static postExternal(params, callback) {
        Request.send({ method: 'post', url: Config.backendHost + 'externalShare', params },
            (response) => {
                callback(isset(response, {}));
            });
    }

    static getExternal(params, pagination, callback) {
        var url = Config.backendHost + 'externalShare';
        if (pagination && pagination.length > 0) {
            url = Config.backendHost + pagination;
        }
        Request.send({ method: 'get', url, params },
            (response) => {
                callback(isset(response, {}));
            });
    }

    static getCompetitors(source, q = '', profileId, callback) {
        Request.send({
            method: 'get',
            url: Config.backendHost + 'competitors?source=' + source + (isset(q, '') !== '' ? '&q=' + q : '') + '&profileId=' + profileId
        },
        (data) => {
            callback(isset(data, {}));
        });
    }

    static getAdsCampaigns(auxData, callback) {
        for (let key in auxData) {
            if (!isset(auxData[`${key}`]) || auxData[`${key}`] === null || auxData[`${key}`] === '') {
                delete auxData[`${key}`];
            }
        }
        Request.send({
            method: 'get',
            url: Config.backendHost + 'ads/campaigns' + (Object.keys(auxData).length > 0 ? '?' + Request.encodeQueryData(auxData) : '')
        },
        (data) => {
            callback(isset(data, {}));
        });
    }

    static getAdsAccounts(auxData, callback) {
        for (let key in auxData) {
            if (!isset(auxData[`${key}`]) || auxData[`${key}`] === null || auxData[`${key}`] === '') {
                delete auxData[`${key}`];
            }
        }
        Request.send({
            method: 'get',
            url: Config.backendHost + 'ads/accounts' + (Object.keys(auxData).length > 0 ? '?' + Request.encodeQueryData(auxData) : '')
        },
        (data) => {
            callback(isset(data, {}));
        });
    }
}
export default AnalyticsRequest;
