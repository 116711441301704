import React from 'react';
// import './Button.scss';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../icon/Icon';
// import { NavHashLink } from 'react-router-hash-link';
import { isset, withSkeleton } from '../../../base/Utils';
import styled from 'styled-components';
import Config from '../../../base/config/Config';

export const types = {
    'Default': {
        hover: Config.colors.veniceblue,
        color: Config.colors.white,
        iconColor: 'white',
        background: Config.colors.blue,
        disabledBackground: Config.colors.alto
    },
    'Secondary': {
        hover: Config.colors.lightgrey,
        color: Config.colors.blue,
        iconColor: 'blue',
        background: Config.colors.white,
        borderColor: Config.colors.blue,
        disabledBackground: Config.colors.alto
    },
    'Tertiary': {
        hover: Config.colors.lightgrey,
        color: Config.colors.blue,
        iconColor: 'blue',
        background: 'transparent',
        borderColor: 'transparent',
    },
    'Success': {
        hover: Config.colors.mountainmeadow,
        color: Config.colors.white,
        iconColor: 'white',
        background: Config.colors.green,
        borderColor: 'transparent',
        disabledBackground: Config.colors.alto
    },
    'Warning': {
        hover: Config.colors.valencia,
        color: Config.colors.white,
        iconColor: 'white',
        background: Config.colors.red,
        borderColor: 'transparent',
        disabledBackground: Config.colors.alto
    },
    'SecondarySuccess': {
        hover: Config.colors.lightgrey,
        color: Config.colors.green,
        iconColor: 'green',
        background: 'transparent',
        borderColor: Config.colors.green,
        disabledBackground: Config.colors.alto
    },
    'SecondaryWarning': {
        hover: Config.colors.lightgrey,
        color: Config.colors.red,
        iconColor: 'red',
        background: 'transparent',
        borderColor: Config.colors.red,
        disabledBackground: Config.colors.alto
    },
    'TertiarySuccess': {
        hover: Config.colors.lightgrey,
        color: Config.colors.green,
        iconColor: 'green',
        background: 'transparent',
        borderColor: 'transparent',
        disabledBackground: Config.colors.alto
    },
    'TertiaryWarning': {
        hover: Config.colors.lightgrey,
        color: Config.colors.red,
        iconColor: 'red',
        background: 'transparent',
        borderColor: 'transparent',
        disabledBackground: Config.colors.alto
    },
    'Link': {
        hover: 'transparent',
        background: 'transparent',
        borderColor: 'transparent',
        color: Config.colors.blue,
        iconColor: 'blue',
    },
    'Fourth': {
        hover: Config.colors.darkblue,
        color: Config.colors.white,
        iconColor: 'white',
        background: Config.colors.darkblue,
        disabledBackground: Config.colors.alto
    },
    'Fifth': {
        hover: Config.colors.lightgrey,
        color: Config.colors.darkblue,
        iconColor: 'darkblue',
        background: Config.colors.white,
        borderColor: Config.colors.darkblue,
        disabledBackground: Config.colors.alto
    },
    'Sixth': {
        hover: Config.colors.lightgrey,
        color: Config.colors.darkblue,
        iconColor: 'darkblue',
        background: Config.colors.white,
        borderColor: 'transparent',
    },
    'Seventh': {
        hover: Config.colors.lightgrey,
        color: Config.colors.black,
        iconColor: 'black',
        background: Config.colors.white,
        borderColor: Config.colors.grey,
        disabledBackground: Config.colors.alto
    },
    'Yellow': {
        hover: Config.colors.yellow,
        color: Config.colors.black,
        iconColor: 'black',
        background: Config.colors.yellow,
        borderColor: 'transparent',
    },
    'SecondaryYellow': {
        hover: Config.colors.yellowopacity,
        color: Config.colors.yellow,
        iconColor: 'yellow',
        background: 'transparent',
        borderColor: 'transparent',
    },
    'Brinfer': {
        hover: Config.colors.ruby,
        color: Config.colors.white,
        iconColor: Config.colors.white,
        background: Config.colors.ruby,
        borderColor: 'transparent',
    },
    'Tour': {
        hover: Config.colors.rosePink,
        color: Config.colors.white,
        iconColor: 'white',
        background: Config.colors.pink,
        disabledBackground: Config.colors.alto
    },
    'verifyEmail': {
        hover: Config.colors.darkorange,
        color: Config.colors.white,
        iconColor: 'white',
        background: Config.colors.darkorange,
    },
    'TourSecondary': {
        hover: Config.colors.lightgrey,
        color: Config.colors.pink,
        iconColor: 'pink',
        background: Config.colors.white,
        borderColor: Config.colors.pink,
        disabledBackground: Config.colors.alto
    },
    'TourLink': {
        hover: 'transparent',
        background: 'transparent',
        borderColor: 'transparent',
        color: Config.colors.pink,
        iconColor: 'pink',
    },
};

const MainButton = styled.button`
    ${props => props.disabled && 'opacity: 0.3; pointer-events: none; user-select: none;'}
    display: flex;
    padding: 0px 16px;
    text-decoration: none;
    color: ${props => props.disabled ? Config.colors.grey : isset(types[props.type].color, Config.colors.white)};
    font-size: 14px;
    height: 36px;
    transition: 0.3s;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 136px;
    border:1px solid transparent;
    background: ${props => props.disabled ? isset(types[props.type].disabledBackground, Config.colors.white) : isset(types[props.type].background, Config.colors.white)};
    border-color: ${props => isset(types[props.type].borderColor, 'transparent')};
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    &:hover{
        background: ${props => isset(types[props.type].hover, Config.colors.white)};
        ${props => props.type === 'Link' && 'text-decoration: underline'}
    }
`;

const Wrapper = styled.div`
    display: inline-block;
    ${props => props.iconSide === 'Left' ? 'margin-right: 6px' : 'margin-left: 6px'}
`;

const Button = ({ text, to, children, disabled, icon, iconSide, iconColor, type, tooltip, ...props }) => {

    var checkLink = (html, to) => {
        if (to !== '') {
            if (to.substr(0, 1) === '/')
                return <Link to={to}>{html}</Link>;
            else if (to.substr(0, 4) === 'http')
                return <a href={to} target="_blank" rel="noopener noreferrer">{html}</a>;
        } else {
            return html;
        }
    };

    if (typeof icon === 'string') {
        icon = <Wrapper iconSide={iconSide}><Icon color={isset(iconColor, types[`${type}`].iconColor)} name={icon} /></Wrapper>;
    } else {
        icon = <Wrapper iconSide={iconSide}>{icon}</Wrapper>;
    }

    var buttonTypesKeys = Object.keys(types);

    type = buttonTypesKeys.includes(type) ? type : 'Default';

    var html = <span tooltip={tooltip} tooltip-position='center'>
        <MainButton ph="true" disabled={disabled && true} type={type} {...props}>
            {iconSide === 'Left' && icon}
            {text}
            {iconSide === 'Right' && icon}
            {children}
        </MainButton>
    </span>;

    if (to !== '') html = checkLink(html, to);

    return html;
};


Button.propTypes = {
    /**
     * Type of the button
     */
    type: PropTypes.oneOf(Object.keys(types)),
    /**
     * Button contents
     */
    text: PropTypes.string.isRequired,
    /**
     * Button to
     */
    to: PropTypes.string,
    /**
     * Disable button
     */
    disabled: PropTypes.bool,
    /**
     * Icon
     */
    icon: PropTypes.string,
    /**
     * Icon position (side)
     */
    iconSide: PropTypes.oneOf(['Left', 'Right', 'None']),
};

Button.defaultProps = {
    type: 'Default',
    to: '',
    disabled: false,
    text: '',
    icon: null,
    iconSide: 'None',
};

Button.skeleton = {
    text: 'invisible'
};

export default Button;
export const ButtonSkeleton = (props) => withSkeleton(props, Button);


