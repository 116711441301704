import { getCookie, isset, setCookie } from '../../../base/Utils';
import i18n from '../../../i18n';
import TourController from '../TourController';

export default () => ([
    {
        disableBeacon: false,
        title: i18n.t('Create something brilliant'),
        content: i18n.t('One of the most powerful tools in Swonkie is the Content Producer, where you can create social media posts, stories, reels, and much more.'),
        placement: 'center',
        target: 'body',
        nextButtonText: i18n.t('Show me how')
    },
    {
        target: '[data-id="topbar-menu-produce-content"]',
        title: i18n.t('Let\'s get started!'),
        content: i18n.t('Click here to create your content'),
        showSkipButton: false,
        spotlightClicks: true,
        hideNextButton: true,
        afterStep: () => {
            if (isset(getCookie('contentProducer'), null) === null) {
                TourController.load('Basic.ContentProducer');
                setCookie('contentProducer', 'PublishModal');
            }
            else {
                TourController.load('Basic.PublishModal');
            }
        }
    }
]);
