import Config from '../../../base/config/Config';
import { clone, isset } from '../../../base/Utils';
import i18n from '../../../i18n';
import Request from '../../classes/Request';

class LabelsRequests {
    static postLabel(params, area, labels, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'labels',
            params: params
        },
        (response) => {
            var states = {};
            var data = isset(response.data, {});
            var status = isset(response.status, '');
            switch (status) {
            case 'ok':
                data.areas = [{
                    area: area,
                    count: 0
                }];
                labels.push(data);
                states['inputValue'] = '';
                states['errorInputMessage'] = '';
                states['searchedLabels'] = labels;
                break;
            case 'error':
                if (isset(response.error)) {
                    var code = isset(response.error.code, 0);
                    switch (code) {
                    case 15:
                        states['errorInputMessage'] = i18n.t('A label with the same name already exists');
                        break;
                    default:
                        break;
                    }
                }
                break;
            default:
            }
            states['labels'] = labels;

            callback(states);
        });
    }

    static getLabels(callback) {
        Request.send({
            method: 'get',
            url: Config.backendHost + 'labels'
        },
        (response) => {
            var states = {};
            states['labels'] = [];
            response = isset(response, {});
            var status = isset(response.status, '');
            switch (status) {
            case 'ok':
                states['labels'] = isset(response.data, []);
                break;
            default:
                break;
            }
            callback(states);
        });
    }

    static putLabel(params, labels, id, callback) {
        labels = clone(labels);
        Request.send({
            method: 'put',
            url: Config.backendHost + 'labels/' + id,
            params: params
        }, (response) => {
            var states = {};
            response = isset(response, {});
            var status = isset(response.status, '');
            var data = isset(response.data, {});
            switch (status) {
            case 'ok':
                for (var key in labels) {
                    var label = labels[`${key}`];
                    if (label.id === data.id) {
                        labels.splice(key, 1, { ...label, ...data });
                    }
                }
                states['labels'] = labels;
                states['searchedLabels'] = labels;
                states['inputValue'] = '';
                states['errorInputMessage'] = '';
                break;
            case 'error':
                if (isset(response.error)) {
                    var code = isset(response.error.code, 0);
                    switch (code) {
                    case 15:
                        states['errorInputMessage'] = i18n.t('A label with the same name already exists');
                        break;
                    default:
                        break;
                    }
                }
                break;
            default:
                break;
            }
            callback(states);
        });
    }

    static deleteLabel(labels, labelId, callback) {
        Request.send({
            method: 'delete',
            url: Config.backendHost + 'labels/' + labelId,
        }, (response) => {
            var states = {};
            response = isset(response, {});
            var status = isset(response.status, '');
            switch (status) {
            case 'ok':
                for (var id in labels) {
                    var label = labels[`${id}`];
                    if (label.id === labelId) {
                        labels.splice(id, 1); break;
                    }
                }
                break;
            default:
                break;
            }
            states['labels'] = labels;
            callback(states);
        });
    }

    static postAreaLabel(area, referenceId, label, params, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'labels/' + area + '/' + referenceId,
            params: { id: label.id, ...params }
        },
        (response) => {
            var label = {};
            response = isset(response, {});
            var status = isset(response.status, '');
            switch (status) {
            case 'ok':
                label = isset(response.data, {});
                break;
            default:
                break;
            }
            callback(label);
        });
    }

    static putAreaLabel(area, referenceId, label, callback) {
        Request.send({
            method: 'put',
            url: Config.backendHost + 'labels/' + area + '/' + referenceId,
            params: label
        },
        (response) => {
            var label = {};
            response = isset(response, {});
            var status = isset(response.status, '');
            switch (status) {
            case 'ok':
                label = isset(response.data, {});
                break;
            default:
                break;
            }
            callback(label);
        });
    }

    static deleteAreaLabel(area, referenceId, label, params, callback) {
        Request.send({
            method: 'delete',
            url: Config.backendHost + 'labels/' + area + '/' + referenceId + '/' + label.id,
            params
        },
        (response) => {
            callback(isset(response, {}));
        });
    }

    static getProjectLabels(area, referenceId, callback) {
        Request.send({
            method: 'get',
            url: Config.backendHost + 'labels/' + area + '/project/' + referenceId,
        }, (requestData) => {
            var data = isset(requestData, {});

            switch (isset(data.status, '')) {
            case 'ok':
                callback(isset(data.data, []));
                break;
            default:
                callback([]);
                break;
            }
        });
    }
}

export default LabelsRequests;
