import React, { Component } from 'react';
import Text from '../text/Text';
import ProfilePicture from '../profilePicture/ProfilePicture';
import Icon from '../icon/Icon';
import { capitalize, clone, colorApplication, convertDate, convertDateUnix, formatNumberSpaces, highlightKeyword, isset, issetDot, redirect, t, time } from '../../../base/Utils';
import i18n from '../../../i18n';
import Button from '../button/Button';
import Carousel from 'react-elastic-carousel';
import Dropdown, { DropdownItem } from '../dropdown/Dropdown';
import Config from '../../../base/config/Config';
import Popup from '../popup/Popup';
import styled from 'styled-components';
import ProgressBar from '../progressBar/ProgressBar';
import Media from '../media/Media';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';
import { TextArea } from '../input/Input';
import PostRequests from './PostRequest';
import Feed from '../feed/Feed';
import ProfileRequest from '../profilePicture/ProfileRequest';
import Spinner from '../spinner/Spinner';
import Analytics from '../analytics/Analytics';
import Select from '../select/Select';
import Sentiment from '../sentiment/Sentiment';

const PostArea = styled.div`
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 24px 0px;
    box-shadow: ${props => props.noShadow ? null : '0px 1px 4px rgba(0, 0, 0, 0.26)'};
    border-radius: 4px;
    width: min-content;
    position: relative;
    flex-direction: ${props => props.horizontal ? 'row' : 'column'};
    height: fit-content;
`;

const BestPostArea = styled.div`
    display: flex;
    align-items: baseline;
    padding: 5px 14px;
    margin: 8px;
    background-color: ${Config.colors.orange};
    border-radius: 4px;
`;

const TopPostArea = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => props.hasBestProfile ? '28px 16px 0px 16px' : '16px 16px 0px 16px'};
`;

const ProfileArea = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    border: 0;
    justify-content: flex-start;
`;

const ProfileTextArea = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 8px;
`;


const CouponArea = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 16px;
    margin: 0px 22px;
    padding: 16px 22px;
    align-items: center;
    box-sizing: border-box;
    background: #F8F9FA 0% 0% no-repeat padding-box;
    border: 1px dashed #BDBDBD;
    opacity: 1;
    justify-content: center;
`;

const TitleArea = styled.div`
    display: flex;
    flex-direction: column;
    margin: 12px 22px;
`;

const TypePostArea = styled.div`
    display: flex;
    flex-direction: column;
`;

const MediaNSFWArea = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const MediaNSFWContentArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const MediaNSFWButtonArea = styled.div`
    position: absolute;
    bottom: 14px;
`;

export const CarouselArea = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 8px;

    .CarouselLeftButton,
    .CarouselRightButton {
        position: absolute;
        z-index: 1;
        height: auto;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .CarouselLeftButton,
    .CarouselRightButton,
    .rec.rec-dot {
        visibility: collapse;
    }

    .CarouselLeftButton > * {
        padding-left: 12px;
        transform: rotate(90deg);
        font-size: 60px !important;
        text-shadow: 0 0 10px gray;
    }

    .CarouselRightButton {
        right: 0px;
    }

    .CarouselRightButton > * {
        padding-right: 12px;
        transform: rotate(-90deg);
        font-size: 60px !important;
        text-shadow: 0 0 10px gray;
    }

    .rec-slider-container {
        margin: 0px;
    }

    .sc-iBPRYJ.bkVSTc.rec.rec-pagination {
        position: absolute;
        bottom: -10px;
        border-radius: 20px;
        background-color: lightgray;
    }

    .rec.rec-dot {
        position: relative;
        width: 6px;
        height: 6px;
        padding: 5px;
        opacity: 1;
        background-color: lightgray;
        margin-top: -40px;
        box-shadow: 0 0 5px black;
    }

    .rec.rec-dot_active {
        background: #0f31f2;
        opacity: 1;
    }

    :hover{
        .CarouselLeftButton,
        .CarouselRightButton,
        .rec.rec-dot {
            visibility: visible;
        }
    }
`;

export const checkLine = (text, maxWidth, maxLines) => {
    var span = document.createElement('span');
    document.body.appendChild(span);
    var fit = text.length;
    for (var i = 0; i < fit; ++i) {
        span.innerHTML += text[`${i}`];
        if (span.offsetWidth > maxWidth) {
            fit = i - 1;
            break;
        }
    }
    document.body.removeChild(span);
    return stringDivider(text, fit, maxLines);
};

var messageArray = [];
const stringDivider = (text, width, maxLines) => {
    var regex = /[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu;
    if (!isset(messageArray.find(message => message === text))) {
        text = text.replace(regex, '');
        if (text.length > width) {
            text = text.split(' ');
            var tempWidth = 0;
            var beforeKeywordId = null;
            var lines = 1;
            for (var id in text) {
                if (text[`${id}`].includes('\n')) {
                    tempWidth = 0;
                    lines += text[`${id}`].match(/\n/g).length;
                }
                if (tempWidth === 0 && text[`${id}`].length >= width - 1) {
                    var middle = Math.floor(width - 5);
                    var before = text[`${id}`].lastIndexOf('', middle);
                    var after = text[`${id}`].indexOf('', middle + 1);

                    if (middle - before < after - middle) {
                        middle = before;
                    } else {
                        middle = after;
                    }

                    var newText = text[`${id}`].substr(middle);
                    text[`${id}`] = text[`${id}`].substr(0, middle);
                    text.splice(parseInt(id) + 1, 0, newText);
                    lines++;
                }
                tempWidth += text[`${id}`].length + 1;
                if (tempWidth >= width) {
                    if (beforeKeywordId) {
                        text.splice(beforeKeywordId, 0, '\n');
                        beforeKeywordId = null;
                    }
                    else {
                        text.splice(id, 0, '\n');
                    }
                    lines++;
                    tempWidth = 0;
                }
                if (lines > maxLines) {
                    text = text.slice(0, id); break;
                }
            }
            text = text.join(' ');
        }
        else if (text.includes('\n')) {
            text = text.split(/\n/g);
            text = text.splice(0, maxLines);
            text = text.join('\n');
        }
        messageArray.push(text);
    }
    messageArray = [];
    return text;
};

export var postSizes = {
    'Small': '380px',
    'Medium': '500px'
};

export default class Post extends Component {
    constructor() {
        super();
        this.state = { showText: false, showDesc: false, showItem: false, loading: true, mediaLoaded: false, showExtra: false };
    }

    toggleText() {
        var showMoreText = document.getElementById('Text');
        showMoreText.classList.toggle('truncate');
    }

    render() {
        var { data, children, horizontal, noTopData, isExporting, isInsights, postSize, ...props } = this.props;
        data = clone(data);
        var lessText = '';
        var lessDesc = '';
        var maxLength = 200;
        var description = clone(data.text);
        var secondaryDescription = clone(data.description);
        var size = isset(postSizes[`${postSize}`], postSizes['Medium']);
        horizontal = postSize === 'Small' ? false : horizontal;

        var limit = false;
        var limitDesc = false;
        if (isExporting) {
            if (!isInsights)
                description = description ? checkLine(clone(description), 250, 5) : '';
            else
                description = description ? checkLine(clone(description), 450, 5) : '';
            data.media = [isset(data.media[0], {})];
            if (issetDot(data.media[0], 'type', '') === 'iframe') {
                data.media[0].type = 'photo';
                data.media[0].url = require('../../../resources/images/no-media.svg');
            }
        }
        if (description && description.length > maxLength) {
            limit = true;
            lessText = description.substr(0, maxLength) + '...';
        }
        else {
            lessText = description;
        }

        if (secondaryDescription && secondaryDescription.length > maxLength) {
            limitDesc = true;
            lessDesc = secondaryDescription.substr(0, maxLength) + '...';
        }
        else {
            lessDesc = secondaryDescription;
        }
        if (typeof description === 'string' && typeof lessText === 'string') {
            description = highlightKeyword(description, isset(data.highlights) && data.highlights.map((keyword) => (keyword.text)));
            lessText = highlightKeyword(lessText, isset(data.highlights) && data.highlights.map((keyword) => (keyword.text)));
        }
        return <PostArea noShadow={data.story} horizontal={horizontal} {...props} >
            <div style={{ width: horizontal ? '300px' : size }}>
                {!noTopData &&
                    <>{data.bestProfile && <BestPostArea>
                        <Icon color='White' size='Tiny' name='Heart' style={{ marginRight: '10px' }} />
                        <Text color='White' size='Tiny' weight='SemiBold' >{i18n.t('BEST PUBLICATION')}</Text>
                    </BestPostArea>}
                    <TopPostArea hasBestProfile={isset(data.betProfile)}>
                        {isset(data.profile) && <ProfileArea>
                            <ProfilePicture type='Big' background={data.profile.photo} source={postSize === 'Small' ? '' : data.profile.source} />
                            <ProfileTextArea>
                                <Text size="Medium" >{data.profile.name}</Text>
                                <div style={{ display: 'flex' }}>
                                    <Text size="Small" color="Grey">{postSize !== 'Small' ? t(time(data.publishedTime)) : convertDate(data.publishedTime, true, true)}</Text>
                                    {(!isExporting && postSize !== 'Small' && !window.location.pathname.includes('demo')) && <Button style={{ fontSize: '12px', padding: '8px', height: '14px', overflow: 'visible' }} type="Link" onClick={() => redirect(data.url, '_blank')} >{capitalize(i18n.t('See publication'))}</Button>}
                                </div>
                            </ProfileTextArea>
                        </ProfileArea>}
                        {isset(data.type) && <div>
                            <TypePostArea>
                                {postSize !== 'Small' && <Text align="right" size="Small" color={data.paid ? 'Orange' : 'Blue'}>{capitalize(data.paid ? i18n.t('Paid') : i18n.t('Organic'))}</Text>}
                                <Text align="right" size="Small" color="Grey">{i18n.t(capitalize(data.type))}</Text>
                            </TypePostArea>
                        </div>}
                    </TopPostArea>
                    </>}
                <div>
                    {(description || lessText) && <div style={{ margin: '22px' }}>
                        <span style={{ display: 'inline' }}>{this.state.showText ? description : lessText}</span>
                        {(limit && !isExporting) && <Button style={{ padding: '0px 4px', display: 'inline', height: 'auto' }} type="Link" onClick={() => this.setState({ showText: !this.state.showText })} >{this.state.showText ? i18n.t('Show less') : i18n.t('Show more')}</Button>}
                    </div>}
                    {data.type !== 'text' &&
                        <CarouselArea >
                            {(data.nsfw && !this.state.showItem) && <MediaNSFWArea>
                                <MediaNSFWContentArea >
                                    <Icon style={{ marginBottom: '16px' }} color="white" size="Giant" name="Eye Off" />
                                    <Text style={{ marginBottom: '8px' }} color="White" align="center" >{i18n.t('Sensitive Content')}</Text>
                                    <Text color='White' align="center" >{i18n.t('This media contains sensitive content which')}</Text>
                                    <Text color="White" align="center">{i18n.t('some people may find offensive or disturbing')}</Text>
                                    <MediaNSFWButtonArea>
                                        <Text color="White" style={{ cursor: 'pointer' }} align="center" onClick={() => this.setState({ showItem: !this.state.showItem })}> {i18n.t('See media')} </Text>
                                    </MediaNSFWButtonArea>
                                </MediaNSFWContentArea>
                            </MediaNSFWArea>}
                            {(!data.nsfw || this.state.showItem) && isset(data.media, []).length > 1 && <> <div className="CarouselLeftButton" onClick={() => this.carousel.slidePrev()}> <Icon name="Caret Down" color="white" size="Giant" /> </div>
                                <div className="CarouselRightButton" onClick={() => this.carousel.slideNext()}><Icon name="Caret Down" color="white" size="Giant" /></div> </>}
                            <div style={(data.nsfw && !this.state.showItem) ? { filter: 'blur(20px)', height: (isExporting && postSize === 'Small') ? '100px' : '300px' } : { height: (isExporting && postSize === 'Small') ? '100px' : 'auto' }}>
                                <Carousel ref={ref => (this.carousel = ref)} showArrows={false} pagination={(!data.nsfw || this.state.showItem) && isset(data.media, []).length > 1 ? true : false} enableSwipe={false} itemsToScroll={1} itemsToShow={1}>
                                    {isset(data.media, []).map((media, index) => {
                                        if (isExporting && data.thumbnail) {
                                            media = { type: 'photo', url: data.thumbnail };
                                        }
                                        return <Media fullHeight={data.story} source={issetDot(data, 'profile.source', '')} postType={data.postType} postSize={postSize} isExporting={isExporting} horizontal={horizontal} data={media} key={index} />;
                                        //TODO: make an Album
                                    })}
                                </Carousel>
                            </div>
                        </CarouselArea>
                    }
                    {data.gmb && <>
                        {data.title &&
                            <TitleArea>
                                <Text size="Biggest" color="Black">{data.title}</Text>
                                {data.schedule && data.schedule.startTime && <Text size="Small" color="Grey">{convertDateUnix(data.schedule.startTime, Config.dateFormatHourNoYear)} - {convertDateUnix(data.schedule.endTime, Config.dateFormatHourNoYear)}</Text>}
                            </TitleArea>
                        }
                        {data.description &&
                            <div style={{ marginBottom: '16px', padding: '0px 22px' }}>
                                <span style={{ display: 'inline' }}>{this.state.showDesc ? secondaryDescription : lessDesc}</span>
                                {limitDesc && <Button style={{ padding: '0px 4px', display: 'inline', height: 'auto' }} type="Link" onClick={() => this.setState({ showDesc: !this.state.showDesc })} >{this.state.showDesc ? i18n.t('Show less') : i18n.t('Show more')}</Button>}
                            </div>
                        }
                        {data.linkButton && data.linkButton.url && <Button type="Link" to={data.linkButton.url} style={{ padding: '0px 22px', textTransform: 'uppercase', fontWeight: 'bold' }} text={i18n.t(data.linkButton.text)} />}
                        {data.code &&
                            <CouponArea>
                                {data.code.text &&
                                    <>
                                        <Text size="Medium" color="Grey">{i18n.t(data.code.name)}</Text>
                                        <Text size="Huge" color="Black">{data.code.text} </Text>
                                    </>}
                                {data.code.schedule.startTime &&
                                    <Text size="Medium" color="Grey">{i18n.t('Validity {{startDate}} - {{endDate}}', { startDate: convertDateUnix(data.code.schedule.startTime, Config.dateFormatHourGMB), endDate: convertDateUnix(data.code.schedule.endTime, Config.dateFormatHourGMB) })}</Text>
                                }
                            </CouponArea>
                        }
                        {data.extra && data.extra.text &&
                            <div style={{ marginTop: '16px', padding: '0px 22px' }} >
                                <Dropdown arrow={true} triggerOnClick={() => this.setState({ showExtra: !this.state.showExtra })} trigger={<Text size="Medium" color="Grey" style={{ width: '100%' }}>{i18n.t(data.extra.name)}</Text>} />
                                {this.state.showExtra && <Text size="Medium" color="Grey" style={{ width: '100%', }}>{i18n.t(data.extra.text)}</Text>}
                            </div>
                        }
                        {data.button && data.button.text &&
                            <div style={{ padding: '0px 22px' }}>
                                <Button type='Primary' to={data.button.url} text={i18n.t(data.button.text)} />
                            </div>
                        }
                    </>}
                </div>
            </div >
            {children}
        </PostArea >;
    }
}

Post.propTypes = {
    /**
     * Data of the post
     */
    data: PropTypes.object,
};

export class ExportPost extends Component {
    constructor() {
        super();
        this.ref = React.createRef();
    }
    componentDidMount() {
        if (this.props.postBounding) this.props.postBounding(this.ref.current.getBoundingClientRect());
    }

    render() {
        var mention = isset(this.props.mention, {});
        var engagementNumber = 0;
        var engagement = issetDot(mention, 'engagement', {});

        for (let comment of isset(engagement.comments, [])) {
            engagementNumber += parseInt(comment.count);
        }
        for (let reaction of isset(engagement.reactions, [])) {
            engagementNumber += parseInt(reaction.count);
        }
        for (let share of isset(engagement.shares, [])) {
            engagementNumber += parseInt(share.count);
        }

        return <div ref={this.ref} style={{ padding: '12px 0px', margin: '0px 24px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                <div style={{ display: 'flex' }}>
                    <ProfilePicture size='Small' background={issetDot(mention, 'profile.photo', '')} />
                    <div style={{ marginLeft: '16px' }}>
                        <Text>{issetDot(mention, 'profile.name', '')}</Text>
                        <div style={{ display: 'flex' }}>
                            {/*<Text size='Small' >{newUrl.host}</Text>*/}
                            <Text style={{ marginRight: '16px' }} size='Small' color='Grey'>{convertDate(isset(mention.publishedTime, ''), true, true)}</Text>
                            <Text style={{ maxWidth: '270px' }} singleLine color='lightblue' size='Small' >{isset(mention.url, '')}</Text>
                        </div>
                    </div>
                </div>
                <div className={'ExportSocialBackground ' + capitalize(issetDot(mention, 'profile.source', ''))} style={{ display: 'flex', alignItems: 'center', borderRadius: '24px', padding: '4px 12px 4px 4px' }}>
                    <Icon size='Big' name={capitalize(issetDot(mention, 'profile.source', ''))} />
                    <Text style={{ marginLeft: '8px' }} color={capitalize(issetDot(mention, 'profile.source', ''))}>{capitalize(issetDot(mention, 'profile.source', ''))}</Text>
                </div>
            </div>
            <span style={{ color: mention.text.trim() === '' && '#bdbdbd', display: 'inline-block', fontSize: '16px', whiteSpace: 'break-spaces' }}>{mention.text.trim() === '' ? i18n.t('Content without preview available') : highlightKeyword(mention.text, isset(mention.highlights) && mention.highlights.map((keyword) => (keyword.text)))}</span>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '8px' }}>
                <div style={{ display: 'flex' }}>
                    <Text size='Small' color={isset(engagementNumber, 0) > 0 ? 'Black' : 'lightgrey'}>{i18n.t('Engagement')}: {isset(engagementNumber, 0) > 0 ? engagementNumber : 'N/A'}</Text>
                    <Text size='Small' style={{ marginLeft: '16px' }} color={isset(mention.estReach, 0) > 0 ? 'Black' : 'lightgrey'}>{i18n.t('Reach')}: {isset(mention.estReach, 0) > 0 ? formatNumberSpaces(mention.estReach) : 'N/A'}</Text>
                </div>
                <div>
                    <Sentiment readOnly sentiment={mention.sentiment} />
                </div>
            </div>
        </div>;
    }
}

const MetricArea = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: start;
    margin: ${props => props.horizontal ? '12px 24px' : '0px 0px 24px 0px'};
    width: ${props => props.horizontal ? '300px' : '100%'};
`;

const MetricBox = styled.div`
    display: ${props => props.area === 'competitors' ? 'block' : 'flex'};
    align-items: ${props => props.horizontal ? 'baseline' : 'center'};
    width: auto;
    flex-direction: ${props => props.horizontal ? 'column' : 'row'};
    margin: ${props => props.area === 'competitors' && !props.subMetric ? '8px 48px 8px 0px' : !props.subMetric && (props.horizontal ? '4px 24px' : '8px 24px')};
    ${props => props.subMetric ? props.area === 'competitors' ? { width: '100%' } : { borderBottom: '1px solid #E0E0E0', width: '100%', padding: '0px 16px 0px 24px' } : {}};
`;

const Metric = styled.div`
    margin-top: ${props => props.horizontal ? '8px' : '0px'};
    display: flex;
    flex-direction: column;
    width: ${props => props.subMetric ? '115px' : 'auto'};
    justify-content: end;
    margin-left: ${props => props.area === 'competitors' ? '16px' : '0px'};

`;

const MetricDescriptionArea = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

const SubMetricArea = styled.div`
    display: grid;
    max-width: ${props => props.area === 'competitors' ? '100%' : '345px'};
    grid-template-columns: 1fr 1fr 1fr;
    width: ${props => props.area === 'competitors' ? 'auto' : '100%'};
    margin: ${props => props.horizontal ? '8px 8px 8px 0px' : '8px'};
    background-color: #512DA80A;
    border-radius: 4px;

    > *{
        padding: ${props => props.area === 'competitors' ? '8px' : '8px'};
    }
`;

export const Metrics = ({ data, horizontal, area, isExporting, ...props }) => {
    return <MetricArea horizontal={horizontal} {...props}>
        {data.map((item, key) => {
            var auxCounts = {};
            return <MetricBox horizontal={horizontal} key={key} area={area} subMetric={isset(item.metrics, []).length > 0} >
                <Metric horizontal={horizontal} area={area} subMetric={isset(item.metrics, []).length > 0} key={key} >
                    <Text size="Big" weight="SemiBold" color={isset(item.color) ? capitalize(item.color) : 'Black'}>{item.value > 0 ? formatNumberSpaces(item.value) + (item.type === 'percentage' ? '%' : '') : i18n.t(item.value)}</Text>
                    <MetricDescriptionArea>
                        <Text size="Medium" style={{ marginRight: isset(item.metrics, []).length > 0 ? '0px' : '8px' }} color="Grey">{i18n.t(item.title)}</Text>
                        {!isExporting && <Icon name="Circle Help" size="Small" color="Grey" tooltip={i18n.t(item.description)} />}
                    </MetricDescriptionArea>
                </Metric>
                {isset(item.metrics, []).length > 0 &&
                    <SubMetricArea horizontal={horizontal} area={area} >
                        {item.metrics.map((metric, key) => {
                            var color = isset(metric.color, '');
                            if (color && color.includes('primaryPallet')) {
                                var auxColor = colorApplication(color, auxCounts, 'title');
                                color = auxColor.color;
                                auxCounts = auxColor.counts;
                            }
                            return <Metric style={{ overflow: horizontal ? 'hidden' : 'visible' }} key={key}>
                                {isset(metric.value) && <>
                                    <Text size="Medium" color="Black">{metric.value > 0 ? formatNumberSpaces(metric.value) : i18n.t(metric.value)}</Text>
                                    <div style={horizontal ? { width: '100%', overflow: 'hidden' } : { width: '100%' }}>
                                        <Text style={{ width: '100%' }} singleLine={horizontal} size="Medium" color="Grey">{i18n.t(metric.title)}</Text>
                                        <ProgressBar style={{ width: '75px' }} noText color={color} type="Tiny" width={metric.value} total={item.value} />
                                    </div>
                                </>}
                            </Metric>;
                        })}
                    </SubMetricArea>
                }
            </MetricBox >;
        })}
    </MetricArea >;
};



const EngagementArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${props => props.spaceTogether ? 'left' : 'space-between'};
    width: ${props => props.spaceTogether ? 'auto' : '100%'};
`;
const EngagementLeftArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px;
    cursor: pointer;
    ${props => props.readOnly && { pointerEvents: 'none', cursor: 'default' }}
    > * {
        margin-right: 10px;
    }
`;

const EngagementTypeArea = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    ${props => props.readOnly && { pointerEvents: 'none', cursor: 'default' }}
`;

const EngagementRightArea = styled.div`
    display: flex;
    align-items: center;
    margin: 16px;
`;

const EngagementProfileArea = styled.div`
    display: flex;
    align-items: center;
    margin: 20px 20px 10px;
`;

export class Engagement extends Component {
    constructor(props) {
        super(props);
        this.state = { feedData: [], engagement: {}, userProfiles: [], activeProfile: {}, disableAction: false, showPopup: false, showCommentArea: false, commentLoading: false, commentData: [], comments: { details: false, interactable: false }, shares: { details: false, interactable: false }, reactions: { details: false, interactable: false } };

        var comments = this.state.comments;
        var reactions = this.state.reactions;
        var shares = this.state.shares;
        var data = props.data;

        if (isset(props.data.engagement)) {
            this.state.engagement = data.engagement;
            var commentsData = isset(data.engagement.comments, []);
            var reactionsData = isset(data.engagement.reactions, []);
            var sharesData = isset(data.engagement.shares, []);

            for (let item of commentsData) {
                if (item.details) {
                    comments.details = true; break;
                }
                if (item.interactable) {
                    comments.interactable = true; break;
                }
            }
            for (let item of reactionsData) {
                if (item.interactable) {
                    reactions.interactable = true; break;
                }
                if (item.details) {
                    reactions.details = true; break;
                }
            }
            for (let item of sharesData) {
                if (item.interactable) {
                    shares.interactable = true; break;
                }
                if (item.details) {
                    shares.details = true; break;
                }
            }
        }

        if ((shares.interactable || comments.interactable || reactions.interactable) && !props.readOnly) {
            this.showProfiles();
        }
        else {
            if (isset(props.data))
                this.state.activeProfile = isset(data.profile, {});
        }
    }

    showSharesReactions(area, referenceId, typeEngagement) {
        var feedData = this.state.feedData;
        var engagement = this.state.engagement;
        var profileId = this.state.activeProfile.id;
        this.setState({ disableAction: true }, () => {
            var states = { disableAction: false };
            PostRequests.showSharesOrReactions(area, referenceId, typeEngagement, feedData, engagement, profileId, (postState) => {
                states = { ...states, ...postState };
                this.setState(states);
            });
        });
    }

    changeReaction = (reactions) => {
        var engagement = this.state.engagement;
        if (isset(reactions)) {
            engagement.reactions = reactions;
            this.setState({ engagement: engagement });
        }
    }

    handleComment(area, referenceId) {
        var engagement = this.state.engagement;
        var comments = engagement.comments || [];
        this.setState({ disableAction: true }, () => {
            var profileId = this.state.activeProfile.id;
            PostRequests.getCommentFromProfile(Config.backendHost + 'engagement/' + area + '/' + referenceId + '/comments?profileId=' + profileId, (responseData) => {
                var states = { disableAction: false };
                if (isset(responseData.status, '') === 'ok') {
                    var commentData = responseData;
                    var responseStates = { showCommentArea: true, commentLoading: false, commentData: commentData };
                    for (let comment of comments) {
                        comment.count = commentData.data.length;
                    }
                    states = { ...states, ...responseStates };
                }
                this.setState(states);
            });
        });
    }

    showProfiles() {
        var area = this.props.area;
        var data = this.props.data;
        this.setState({ disableAction: true }, () => {
            if (isset(data.profile.source, '')) {
                var states = { disableAction: false };
                var source = data.profile.source;
                ProfileRequest.getProfiles(area, '', source, '', '', (responseData) => {
                    if (isset(responseData.status) === 'ok') {
                        states['userProfiles'] = responseData.data;
                        states['activeProfile'] = responseData.data[0];
                    }
                    this.setState(states);
                });
            }
        });
    }

    render() {
        var { area, type = '', data = {}, spaceTogether } = this.props;
        var { engagement, disableAction, userProfiles, activeProfile, showPopup, feedData } = this.state;
        var source = issetDot(data.profile, 'source', '');

        var reactionsInteractable = this.state.reactions.interactable;
        var commentsDetails = this.state.comments.details;
        var reactionsDetails = this.state.reactions.details;
        var sharesDetails = this.state.shares.details;

        if (isset(engagement)) {
            var comments = isset(engagement.comments, []);
            var reactions = isset(engagement.reactions, []);
            var shares = isset(engagement.shares, []);
        }

        return (
            <>
                <EngagementArea spaceTogether={spaceTogether} data-disable={disableAction}>
                    <EngagementLeftArea readOnly={!reactionsDetails || type === 'readOnly'} onClick={() => this.showSharesReactions(area, data.referenceId, 'reactions')} >
                        {isset(reactions) && Array.isArray(reactions) && reactions.map((item, key) => {
                            if (isset(item.count)) {
                                var reactionText = item.id;
                                if (source === 'facebook' && item.id === 'like') {
                                    reactionText = 'fbLike';
                                }
                                return <EngagementTypeArea key={key}>
                                    <Icon style={{ marginRight: '4px' }} tooltip={i18n.t(item.id)} size="Small" name={reactionText} color="Grey" />
                                    <Text size='Medium' color="Grey">{formatNumberSpaces(item.count !== null ? item.count : 0)}</Text>
                                </EngagementTypeArea>;
                            }
                            return null;
                        })}
                    </EngagementLeftArea>
                    <EngagementRightArea >
                        {isset(comments) && Array.isArray(comments) && comments.map((item, key) => {
                            return <EngagementTypeArea readOnly={!commentsDetails || type === 'readOnly'} key={key} onClick={() => this.handleComment(area, data.referenceId)} >
                                <Icon style={{ marginRight: '4px' }} size='Small' tooltip={i18n.t(item.id)} name={item.id} color={item.done && type !== 'readOnly' ? 'Blue' : 'Grey'} />
                                <Text color="Grey">{formatNumberSpaces(item.count !== null ? item.count : 0)}</Text>
                            </EngagementTypeArea>;
                        })}
                        {isset(shares) && Array.isArray(shares) && shares.map((item, key) => {
                            return <EngagementTypeArea readOnly={!sharesDetails || type === 'readOnly'} onClick={() => this.showSharesReactions(area, data.referenceId, 'shares')} className={'shares ' + (sharesDetails ? '' : 'ReadOnly')} key={key}>
                                <Icon style={{ marginRight: '4px' }} size='Small' tooltip={i18n.t(item.id)} name={item.id} color={item.done && type !== 'readOnly' ? 'Blue' : 'Grey'} />
                                <Text color="Grey">{formatNumberSpaces(item.count !== null ? item.count : 0)}</Text>
                            </EngagementTypeArea>;
                        })}
                    </EngagementRightArea>

                    <Popup
                        isOpen={showPopup}
                        title={i18n.t('Publication Reactions')}
                        closeButton
                        onCallbackPopup={(isOpen) => this.setState({ showPopup: isOpen })}
                    >
                        <Feed data={feedData} />
                    </Popup>
                </EngagementArea>
                {type !== 'readOnly' &&
                    <>
                        <EngagementProfileArea data-disable={disableAction} >
                            <Dropdown style={{ marginRight: '20px' }} width="240px" arrow={userProfiles.length > 1} trigger={<ProfilePicture type='Normal' background={activeProfile.photo} />}>
                                {!disableAction && <div className="ItemsArea">
                                    {userProfiles.map((profile, key) => {
                                        return <DropdownItem selected={activeProfile.id === profile.id} key={key} onClick={() => this.setState({ activeProfile: profile })} > <ProfilePicture size='Big' background={profile.photo} style={{ marginRight: '4px' }} /> <Text>{profile.name}</Text> </DropdownItem>;
                                    })}
                                </div>}
                            </Dropdown>
                            {reactionsInteractable && <Reactions area={area} profileId={activeProfile.id} changeReaction={this.changeReaction} reactions={reactions} data={data} referenceId={data.id} />}
                        </EngagementProfileArea>
                        {commentsDetails && <CommentArea activeProfile={activeProfile} area={area} closeCreateComment={this.closeCreateComment} showCommentArea={this.state.showCommentArea} commentLoading={this.state.commentLoading} createComment={this.state.createComment} data={data} commentData={this.state.commentData} receiveReaction={this.receiveReaction} />}
                    </>
                }
            </>
        );
    }
}

export class Reactions extends Component {
    constructor() {
        super();
        this.state = { focusReactionText: '', likedPost: false, disableAction: false };
    }

    componentDidMount() {
        var reactions = this.props.data.engagement.reactions;
        this.setState({ focusReactionText: reactions[0].id });

        for (let reaction of reactions) {
            if (reaction.focus) {
                this.setState({ focusReactionText: reaction.id, likedPost: true }); break;
            }
        }
    }

    likePost() {
        this.handleReaction(this.state.likedPost, this.state.focusReactionText);
    }

    handleReaction(likedPost, reactionText) {
        var { profileId, area, referenceID } = this.props;
        this.setState({ disableAction: true });
        if (!likedPost) {
            this.PostReaction(reactionText, referenceID, profileId, area);
        }
        else {
            this.DeleteReaction(reactionText, referenceID, profileId, area);
        }
    }

    PostReaction(reactionText, referenceId, profileId, area) {
        this.setState({ disableAction: true }, () => {
            var params = { reaction: reactionText, profileId: profileId };
            PostRequests.postReaction(area, referenceId, params, (responseData) => {
                var states = { disableAction: false };
                if (isset(responseData.status, '') === 'ok') {
                    this.receiveReaction(reactionText, 'post');
                    var responseState = { likedPost: true, focusReactionText: reactionText };
                }
                states = { ...states, ...responseState };
                this.setState(states);
            });
        });
    }

    DeleteReaction(reactionText, referenceId, profileId, area) {
        this.setState({ disableAction: true }, () => {
            var params = { reaction: reactionText, profileId: profileId };
            PostRequests.deleteReaction(area, referenceId, params, (responseData) => {
                var states = { disableAction: false };
                if (isset(responseData.status, '') === 'ok') {
                    this.receiveReaction(reactionText, 'delete');
                    states['likedPost'] = false;
                }

                this.setState(states);
            });
        });
    }

    receiveReaction(reaction, event) {
        var reactions = this.props.reactions;
        if (event === 'post') {
            for (let tempReaction of reactions) {
                if (tempReaction.id === reaction) {
                    tempReaction.count++; break;
                }
            }
        }
        else if (event === 'delete') {
            for (let tempReaction of reactions) {
                if (tempReaction.id === reaction) {
                    tempReaction.count--; break;
                }
            }
        }
        this.props.changeReaction(reactions);
    }

    render() {
        var data = this.props.data;

        if (!isset(data.engagement)) {
            data.engagement = { reactions: [] };
        }

        var source = data.profile.source;

        var interactables = 0;

        interactables = data.engagement.reactions.filter(function (reaction) { return reaction.interactable; }).length;

        return <Dropdown horizontal subDropdown trigger={<div onClick={() => this.likePost()} ><Icon size='Small' color={this.state.likedPost ? 'blue' : 'grey'} name={this.state.focusReactionText === 'like' && source === 'facebook' ? 'fbLike' : this.state.focusReactionText} ></Icon> <Text color='Grey' >{capitalize(i18n.t(this.state.focusReactionText))}</Text> </div>}>
            {interactables > 1 && data.engagement.reactions.map((reaction, key) => {
                if (reaction.interactable)
                    return <DropdownItem key={key} onClick={() => this.handleReaction(this.state.likedPost, reaction.id)} > <Icon name={(reaction.id === 'like' && source === 'facebook') ? 'fbLike' : reaction.id} size="Large" color="Grey" /> </DropdownItem>;
                return null;
            })}
        </Dropdown>;
    }
}

const CommentInputArea = styled.div`
    display: flex;
    align-items: center;
`;
const EmojiPicker = styled.div`
    position: absolute;
    bottom: 30px;
    right: 10px;
    display: flex;
`;

export class InputComment extends Component {
    constructor() {
        super();
        this.state = { chosenEmoji: '', showEmojiPicker: false, commentAreaText: '', createComment: false, disableAction: false };
    }

    container = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({ showEmojiPicker: false });
        }
    };

    onEmojiClick = (event, emojiObject) => {
        this.setState({ commentAreaText: this.state.commentAreaText + emojiObject.emoji });
    };

    handleChangeCommentBox(e) {
        var text = e.target.value;
        this.setState({ commentAreaText: text });
    }

    handleKeyPress(e) {
        if (e.nativeEvent.keyCode === 13) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
                this.addComment(this.state.commentAreaText);
                this.setState({ createComment: false, commentAreaText: '' });
            }
        }
    }

    addComment(data) {
        var area = this.props.area;
        var referenceId = this.props.referenceId || '';
        var activeProfile = this.props.activeProfile;
        var params = { text: data, profileId: activeProfile.id };
        this.setState({ disableAction: true }, () => {
            PostRequests.postComment(area, referenceId, params, (responseData) => {
                var states = { disableAction: false };
                if (isset(responseData.status, '') === 'ok') {
                    this.props.addToCommentArea(responseData.data);
                    states['commentAreaText'] = '';
                }
                this.setState(states);
            });
        });
    }

    render() {
        var activeProfile = this.props.activeProfile || {};
        return (
            <div style={{ width: '100%' }}>
                <CommentInputArea data-disable={this.state.disableAction}>
                    <ProfilePicture style={{ marginRight: '10px' }} type="Medium" background={activeProfile.photo} />
                    <TextArea maxCharacters="2200" value={this.state.commentAreaText} onInput={(e) => this.handleChangeCommentBox(e)} onKeyDown={(e) => this.handleKeyPress(e)} placeholder={i18n.t('Write a review')} />
                    <div style={{ marginLeft: '10px', position: 'relative', cursor: 'pointer' }} ref={this.container}>
                        <Icon name="Emoji" color="grey" onClick={() => this.setState({ showEmojiPicker: !this.state.showEmojiPicker })} />
                        {this.state.showEmojiPicker && <EmojiPicker onMouseLeave={() => this.setState({ showEmojiPicker: true })} > <Picker onEmojiClick={this.onEmojiClick} /> </EmojiPicker>}
                    </div>
                </CommentInputArea >
            </div>
        );
    }
}

const NoDisplayComment = styled.div`
    display: flex;
    justify-content: center;
    margin: 20px;
`;

const CommentDataArea = styled.div`
    margin: 10px 20px 20px;
    width: 100%;
    display: flex;
    box-sizing: border-box;
`;

export class CommentArea extends Component {
    constructor(props) {
        super(props);
        this.state = { createComment: false, commentData: [], loading: false };
    }

    addToCommentArea = (data) => {
        var commentData = this.state.commentData;
        if (!isset(commentData.data)) {
            commentData.data = [];
        }
        commentData.data.unshift(data);
        this.setState({ commentData: commentData });
    }

    loadMoreComments(nextPage) {
        var commentData = this.state.commentData;
        this.setState({ loading: true }, () => {
            PostRequests.getCommentFromProfile(nextPage, (responseData) => {
                var states = { loading: false };
                if (isset(responseData.status, '') === 'ok') {
                    if (isset(responseData.data))
                        for (let data of responseData.data) {
                            commentData.data.push(data);
                        }
                    if (isset(responseData.pagination))
                        commentData.pagination = responseData.pagination;
                    states['commentData'] = commentData;
                }
                this.setState(states);
            });
        });
    }

    componentDidUpdate() {
        var commentData = this.state.commentData;
        if (commentData !== this.props.commentData) {
            this.setState({ commentData: this.props.commentData });
        }
    }

    render() {
        var data = this.props.data;
        var area = this.props.area;
        var commentData = this.state.commentData.data || [];
        var commentPage = this.state.commentData.pagination || {};
        var activeProfile = this.props.activeProfile;
        var commentInteractable = false;

        if (isset(data.engagement)) {
            for (let comment of data.engagement.comments) {
                if (comment.interactable) {
                    commentInteractable = true; break;
                }
            }
        }

        return (
            <CommentDataArea data-disable={this.state.loading} >
                {commentInteractable && <InputComment addToCommentArea={this.addToCommentArea} referenceId={data.referenceId} commentData={commentData} area={area} activeProfile={activeProfile} />}
                {isset(commentData) && commentData.length > 0 ? this.props.commentLoading ?
                    <Spinner /> :
                    <>
                        {commentData.map((data, key) => {
                            return <CommentDataArea key={key} >
                                <UserComments activeProfile={activeProfile} area={area} data={data} />
                            </CommentDataArea>;
                        })}
                        <commentSeeMoreArea>
                            {isset(commentPage.next) && commentPage.next !== null && <Button type="Link" text={i18n.t('See More')} onClick={() => this.loadMoreComments(commentPage.next)} />}
                        </commentSeeMoreArea>
                    </>
                    : <NoDisplayComment> <Text size="Big" color="Grey" text={i18n.t('No comments to display')} /> </NoDisplayComment>}
            </CommentDataArea>
        );
    }
}

const BottomCommentArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export class UserComments extends Component {
    constructor(props) {
        super(props);
        this.state = { createComment: false, showPopup: false, feedData: [], disableAction: false, data: {}, activeProfile: this.props.activeProfile };
    }

    showSharesReactions(area, referenceId, typeEngagement) {
        var feedData = this.state.feedData;
        var engagement = this.state.engagement;
        var profileId = this.state.activeProfile.id;
        this.setState({ disableAction: true }, () => {
            PostRequests.showSharesOrReactions(area, referenceId, typeEngagement, feedData, engagement, profileId, (responseStates) => {
                var states = { disableAction: false };
                states = { ...states, ...responseStates };
                this.setState(states);
            });
        });
    }

    loadMoreComments(nextPage) {
        var commentData = this.state.data;
        this.setState({ disableAction: true }, () => {
            this.setState({ loading: true }, () => {
                PostRequests.getCommentFromProfile(nextPage, (responseData) => {
                    var states = { loading: false };
                    if (isset(responseData.status, '') === 'ok') {
                        if (isset(responseData.data))
                            for (let data of responseData.data) {
                                commentData.comments.data.push(data);
                            }
                        if (isset(responseData.pagination))
                            commentData.comments.pagination = responseData.pagination;

                        states['commentData'] = commentData;
                    }
                    this.setState(states);
                });
            });
        });
    }

    handleComment(area, referenceId) {
        var profileId = this.state.activeProfile.id;
        this.setState({ disableAction: true }, () => {
            PostRequests.getCommentFromProfile(Config.backendHost + 'engagement/' + area + '/' + referenceId + '/comments?profileId=' + profileId, (responseData) => {
                var states = { disableAction: false };
                if (isset(responseData.status, '') === 'ok') {
                    var commentData = this.state.data;
                    commentData.comments = responseData.data;
                    var responseState = { showCommentArea: true, commentLoading: false, data: commentData };
                    states = { ...states, ...responseState };
                }
                this.setState(states);
            });
        });
    }

    changeReaction = (reactions) => {
        var data = this.state.data;
        if (isset(reactions)) {
            data.engagement.reactions = reactions;
            this.setState({ data: data });
        }
    }

    addToCommentArea = (data) => {
        var commentData = this.state.data;
        if (!isset(commentData.comments)) {
            commentData.comments = { data: [] };
        }
        commentData.comments.data.push(data);
        this.setState({ data: commentData });
    }

    componentDidMount() {
        this.setState({ data: this.props.data });
    }

    componentDidUpdate() {
        if (this.props.data !== this.state.data) {
            this.setState({ data: this.props.data });
        }
    }

    render() {
        var data = this.state.data;
        var media = data.media || [];
        var area = this.props.area || '';

        if (isset(data.engagement)) {
            var comments = isset(data.engagement.comments, []);
            var reactions = data.engagement.reactions || [];
            var shares = data.engagement.shares || [];
        }

        var CommentsInteractable = false;
        var reactionsInteractable = false;
        var commentsDetails = false;
        var reactionsDetails = false;
        var sharesDetails = false;

        for (let comment of comments) {
            if (comment.details) {
                commentsDetails = true; break;
            }
            if (comment.interactable) {
                CommentsInteractable = true; break;
            }
        }
        for (let reaction of reactions) {
            if (reaction.interactable) {
                reactionsInteractable = true; break;
            }
            if (reaction.details) {
                reactionsDetails = true; break;
            }
        }

        for (let share of shares) {
            if (share.details) {
                sharesDetails = true; break;
            }
        }

        area = this.props.area;
        return (
            <div className="commentArea">
                <div className="topCommentArea" >
                    <ProfilePicture type="Medium" background={data.photo} />
                    <Text color="Black" >{data.name}</Text>
                    <Text color="Grey" >{convertDate(data.time, false, true)}</Text>
                </div>
                <div className="middleCommentArea">
                    <div className="middleCommentTextArea">
                        <Text color="Grey" >{data.text}</Text>
                    </div>
                    <div style={{ height: '100px' }}>
                        {data.type !== 'text' && media.map((media, index) => {
                            return <Media data={media} key={index} />;
                        })}
                    </div>
                </div>
                <BottomCommentArea data-disable={this.state.disableAction} >
                    {reactionsInteractable && <Reactions profileId={this.state.activeProfile.id} changeReaction={this.changeReaction} reactions={reactions} data={data} referenceId={data.id} />}
                    <div className="bottomCommentRightArea">
                        <EngagementTypeArea readOnly={!reactionsDetails} onClick={() => this.showSharesReactions(area, data.id, 'reactions')} >
                            {isset(reactions) && reactions.map((item, key) => {
                                if (item.interactable) {
                                    var reactionText = item.id;
                                    if (this.state.activeProfile.source === 'facebook' && item.id === 'like') {
                                        reactionText = 'fbLike';
                                    }
                                    return <EngagementTypeArea key={key} style={{ marginRight: '10px' }} >
                                        <Icon style={{ marginRight: '4px' }} tooltip={i18n.t(item.id)} size="Medium" set={reactionText} color="Grey" />
                                        <Text color="Grey">{isset(data.engagement) && item.count !== null ? item.count : 0}</Text>
                                    </EngagementTypeArea>;
                                }
                                return null;
                            })}
                        </EngagementTypeArea>
                        {isset(comments) && comments.map((item, key) => {
                            if (item.interactable) {
                                return <EngagementTypeArea readOnly={!commentsDetails} style={{ marginRight: '10px' }} key={key} onClick={() => this.handleComment(area, data.id)} >
                                    <Icon style={{ marginRight: '4px' }} tooltip={i18n.t(item.id)} name={item.id} color="Grey" />
                                    <Text color="Grey">{isset(data.engagement) && item.count !== null ? item.count : 0}</Text>
                                </EngagementTypeArea>;
                            }
                            return null;
                        })}
                        {isset(shares) && shares.map((item, key) => {
                            if (item.interactable)
                                return <EngagementTypeArea readOnly={!sharesDetails} style={{ marginRight: '10px' }} onClick={() => this.showSharesReactions(area, data.id, 'shares')} key={key}>
                                    <Icon style={{ marginRight: '4px' }} tooltip={i18n.t(item.id)} name={item.id} color={item.done ? 'Blue' : 'Grey'} />
                                    <Text color="Grey">{isset(data.engagement) && item.count !== null ? item.count : 0}</Text>
                                </EngagementTypeArea>;
                            return null;
                        })}
                    </div>
                </BottomCommentArea>
                <div style={{ marginLeft: '40px' }} data-disable={this.state.disableAction} >
                    {CommentsInteractable && <InputComment addToCommentArea={this.addToCommentArea} referenceId={data.id} commentData={this.props.data.comments} area={area} activeProfile={this.props.activeProfile} />}
                    {issetDot(data, 'comments.data') && data.comments.data.map((data, key) => {
                        return <UserComments key={key} data={data} activeProfile={this.state.activeProfile} area={area} />;
                    })}
                    <commentSeeMoreArea>
                        {isset(data.comments) && isset(data.comments.pagination) && data.comments.pagination.next !== null && <Button type="Link" text={i18n.t('See More')} onClick={() => this.loadMoreComments(data.comments.pagination.next, data)} />}
                    </commentSeeMoreArea>
                </div>
                <Popup
                    isOpen={this.state.showPopup}
                    title="Publication Reactions"
                    closeButton
                    onCallbackPopup={(showPopup) => this.setState({ showPopup: showPopup })}
                >
                    <Feed data={this.state.feedData} />
                </Popup>
                <>
                </>
            </div>
        );
    }
}

const PopupContentArea = styled.div`
    padding-top: 45px;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`;

const TopContentArea = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #eeeeee;
`;

const TopContent = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
`;

const BottomContentArea = styled.div`
    height: 100%;
    overflow: auto;
    display: flex;
`;

export class PostPopup extends Component {
    constructor(props) {
        super(props);
        this.state = { labels: props.labels, activeLabels: props.activeLabels, data: [], disabled: false, isLoading: false };
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => {
            var auxData = {};
            if (this.props.startDate && this.props.endDate) {
                auxData = { startDate: this.props.startDate, endDate: this.props.endDate };
            }
            PostRequests.getDetailedPost(this.props.area, this.props.postId, this.props.source, auxData, (responseData) => {
                var states = { isLoading: false };
                if (isset(responseData.status, '') === 'ok') {
                    states.data = isset(responseData.data, []);
                }
                this.setState(states);
            });
        });
    }

    handleExport(exportType) {
        var exportData = {};
        var { data } = this.state;

        exportData.title = 'Post Report';
        exportData.type = 'Insights';
        exportData.noBoxShadow = true;
        exportData.exportType = exportType;
        exportData.profiles = [];
        exportData.profiles.push({ label: issetDot(data, 'post.profile.name', ''), photo: issetDot(data, 'post.profile.photo', ''), source: issetDot(data, 'post.profile.source', '') });
        exportData.date = convertDate(issetDot(data, 'post.publishedTime', 0), false, true);
        exportData.boxes = [{
            columns: 12,
            rows: 1,
            items: [{ type: 'dashboard', boxes: issetDot(data, 'insights.boxes', []) }]
        }];
        exportData.rowBox = { dashboard: 4 };
        localStorage.setItem('data', JSON.stringify(exportData));
        redirect(Config.host + 'export', '_blank');
    }

    changeLabelsPost(e) {
        this.setState({ activeLabels: e });
    }

    addToRequestData = {}
    addToRequest(data, boxData) {
        var auxData = Object.assign(this.addToRequestData, data);

        boxData = boxData || {};
        auxData = Object.assign(clone(auxData), boxData);

        this.setState({ disabled: true }, () => {
            PostRequests.getDetailedPost(this.props.area, this.props.postId, this.props.source, auxData, (responseData) => {
                var states = { disabled: false };
                if (isset(responseData.status, '') === 'ok') {
                    states.data = isset(responseData.data, []);
                }
                this.setState(states);
            });
        });
    }

    render() {
        var { post, isOpen, onClose } = this.props;
        var { labels, activeLabels, data } = this.state;
        return <Popup
            isOpen={isOpen}
            width='1100'
            title={i18n.t('Publication Details')}
            closeButton
            onCallbackPopup={onClose}
            fullHeight
            closeOutside
            side='Right'
        >
            <PopupContentArea >
                {this.state.isLoading ? <Spinner /> : <>
                    <TopContentArea>
                        <TopContent>
                            <ProfileArea>
                                <ProfilePicture type='Normal' background={issetDot(data, 'post.profile.photo', '')} />
                                <ProfileTextArea >
                                    <Text size="Medium" >{issetDot(data, 'post.profile.name', '')}</Text>
                                    <Text size="Small" color="Grey">{t(convertDate(issetDot(data, 'post.publishedTime', 0), true, true))}</Text>
                                </ProfileTextArea>
                            </ProfileArea>
                            <div>
                                <TypePostArea style={{ marginLeft: '110px' }}>
                                    <Text align="right" size="Small" color={post.paid ? 'Orange' : 'Blue'}>{capitalize(issetDot(data, 'post.paid', '') ? i18n.t('Paid') : i18n.t('Organic'))}</Text>
                                    <Text align="right" size="Small" color="Grey">{i18n.t(capitalize(issetDot(data, 'post.type', '')))}</Text>
                                </TypePostArea>
                            </div>
                        </TopContent>
                        <TopContent>
                            <div style={{ width: '200px', margin: '0px 24px 24px 0px', maxWidth: '350px' }}>
                                {this.props.area === 'projectAnalytics' && <Select isSearchable isMulti options={labels} value={activeLabels} onChange={(e) => this.changeLabelsPost(e)} placeholder={i18n.t('Add Labels')} />}
                            </div>
                            <Button type='Tertiary' onClick={() => redirect(issetDot(data, 'post.url', ''), '_blank')} text={i18n.t('See on {{source}}', { source: capitalize(issetDot(data, 'post.profile.source', '')) })} />
                            <Dropdown width="auto" trigger={<Button icon="Download" iconSide='Left' text={i18n.t('Export')} isDropdown type="Tertiary" > <Icon style={{ paddingLeft: '8px' }} name='Caret Down' /> </Button>}>
                                <DropdownItem onClick={() => this.handleExport('PDF')}> PDF </DropdownItem>
                                <DropdownItem onClick={() => this.handleExport('PowerPoint')} > PowerPoint </DropdownItem>
                            </Dropdown>
                        </TopContent>
                    </TopContentArea>
                    <BottomContentArea>
                        <Analytics disabled={this.state.disabled} type='Insights' onFiltersCallback={(boxId, data) => this.addToRequest({}, { boxId: boxId, boxFilters: data })} data={issetDot(data, 'insights.boxes', [])} noBoxShadow={true} />
                    </BottomContentArea>
                </>}
            </PopupContentArea>
        </Popup>;
    }
}
