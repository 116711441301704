import { Component } from 'react';

export default class PreventUpdate extends Component {
    lastData = null

    shouldComponentUpdate(nextProps) {
        return (this.lastData !== JSON.stringify(nextProps.data));
    }

    render() {
        this.lastData = JSON.stringify(this.props.data || {});
        var children = this.props.children || null;

        return (children);
    }
}
