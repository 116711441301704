import React from 'react';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import PropTypes from 'prop-types';
import { clone, colorApplication, hexToRgb, isset } from '../../../base/Utils';
import i18n from '../../../i18n';
import Text from '../text/Text';

const MainChartLegends = styled.div`
    margin-top: 15px;
    width: 100%;
    display: flex;
    flex-direction: column ;
    height: fit-content;
    float: left;
    cursor: default;
    overflow: hidden;
`;

const MainChartLegendGroup = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const MainHeatMapBox = styled.div`
    width:40px;
    height:24px;
    display: inline-block;
    background-color: ${props => props.color};
`;

const MainChartLegendData = styled.div`
    &:first-child{
        margin-left: 0;
    }
    display: ${props => props.type === 'Heatmap' ? 'block' : 'flex'};
    align-items: center;
    margin-left: 24px;
    margin-bottom: ${props => props.type === 'Heatmap' ? '0px' : '8px'};
    ${props => props.type === 'Heatmap' && 'margin-left: 48px;'}
`;

const MainLegend = styled.div`
    border-top: 3px;
    height: 0px;
    border-radius: 2px;
    min-width: 24px;
    ${props => props.type === 'Bubble' && ' border-radius: 100%  !important; border: 1px solid #212121 !important; background-color: rgba(33, 33, 33, 0.16);'}
    ${props => props.type !== 'Heatmap' && `border-top-color: ${props.legend.color}; border-top-style: ${props.legend.style || 'solid'}; min-width: ${props.legend.size}; width: ${props.legend.size}; height: ${props.legend.size};`}
`;

const MainTexts = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin-top: 8px;
`;

const ChartLegends = ({ data, type, color, parentType, ...props }) => {
    var colorCounts = {};
    var heatMapCount = 11;
    var heatMapBoxes = [];
    var heatMapColor = clone(color);

    if (isset(heatMapColor)) heatMapColor = hexToRgb(isset(Config.colors[colorApplication(heatMapColor, {}).color], Config.colors.purple));
    else heatMapColor = { r: 117, g: 78, b: 208 };

    for (var i = 1; i < heatMapCount; i++) {
        var percentage = '0.' + i;
        if (i === 10) {
            percentage = '1';
        }

        color = 'rgba(' + heatMapColor.r + ',' + heatMapColor.g + ',' + heatMapColor.b + ',' + percentage + ')';

        heatMapBoxes.push(<MainHeatMapBox color={color} />);
    }

    return (
        <MainChartLegends {...props}>
            {type === 'Heatmap' ?
                <MainChartLegendData type="Heatmap">
                    <div>
                        {heatMapBoxes}
                    </div>
                    <MainTexts>
                        <Text singleLine size="Small" color="grey">{i18n.t('Less Mentions')}</Text>
                        <Text singleLine size="Small" color="grey">{i18n.t('More Mentions')}</Text>
                    </MainTexts>
                </MainChartLegendData>
                : data.map((group, key) => {
                    return <MainChartLegendGroup key={key}>
                        {group.map((legend, key) => {
                            if (isset(legend.color) && isset(Config.colors[legend.color])) {
                                var auxColor = colorApplication(legend.color, colorCounts, 'chartLegends');
                                colorCounts = auxColor.counts;
                                legend.color = isset(Config.colors[auxColor.color], Config.colors.purple);
                            }
                            return <MainChartLegendData style={{ overflow: 'hidden', flex: '1 1 0px', maxWidth: 'fit-content' }} key={key}>
                                <MainLegend type={type} legend={legend} />
                                <Text tooltip={i18n.t(legend.label)} singleLine color={parentType === 'BigScreen' ? 'White' : 'HeavyGrey'} size={isset(parentType, '').includes('Insights') ? 'Small' : 'Medium'} style={{ marginLeft: '8px' }}>{i18n.t(legend.label)}</Text>
                            </MainChartLegendData>;
                        })}
                    </MainChartLegendGroup>;
                })}
        </MainChartLegends>
    );
};

ChartLegends.propTypes = {
    /**
     * Legends data
     */
    data: PropTypes.object,
    /**
     * Type of legends
     */
    type: PropTypes.oneOf(['Heatmap', 'Bubble', 'Normal']),
    /**
     * Legends color(only for heatMap)
     */
    color: PropTypes.string
};

ChartLegends.defaultProps = {

};

export default ChartLegends;
