import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { isset } from '../../../base/Utils';
import i18n from '../../../i18n';
import Button from '../button/Button';
import Spinner from '../spinner/Spinner';

const MainProgressiveLoading = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;`;

const MainProgressiveLoadingButton = styled.div`
    display: flex;
    justify-content: center;
`;

class ProgressiveLoading extends Component {
    callback = function () { }
    isLoading = false

    constructor(props) {
        super(props);
        this.state = { showMoreButton: isset(props.showMoreButton, true) };

        this.callback = this.props.onTriggerCallback || function () { };
    }

    handleScroll(event) {

        if (this.isLoading)
            return false;

        const target = event.target;
        var height = (target.scrollHeight - window.innerHeight);

        if (height * 0.3 < 500) {
            height *= 0.7;
        }
        else {
            height -= 500;
        }

        var pagination = isset(this.props.pagination, {});

        if (pagination === {}) {
            pagination.next = null;
        }

        if (target.scrollTop >= height && pagination.next !== null) {
            this.callback();
        }

        this.setState({ showMoreButton: false });
    }

    render() {
        this.isLoading = this.props.isLoading || false;

        var { children = '', pagination = {}, ...props } = this.props;

        if (pagination === {}) {
            pagination.next = null;
        }


        return (
            <MainProgressiveLoading onScroll={(event) => this.handleScroll(event)} className="ProgressiveLoadingArea" {...props}>
                {children}
                {this.isLoading ? <Spinner /> : this.state.showMoreButton && pagination.next !== null ? <MainProgressiveLoadingButton> <Button disabled={this.isLoading} type="Link" text={i18n.t('Show more')} onClick={() => this.callback()} /> </MainProgressiveLoadingButton> : null}
            </MainProgressiveLoading>
        );
    }
}

ProgressiveLoading.propTypes = {
    isLoading: PropTypes.bool,
    children: PropTypes.any,
    pagination: PropTypes.object
};

ProgressiveLoading.defaultProps = {
    isLoading: false,
    children: '',
    pagination: {},
};

export default ProgressiveLoading;
