import React from 'react';
import { isset, issetDot } from '../../../base/Utils';
import Spinner from '../../components/spinner/Spinner';
import AnalyticsRequest from '../analytics/AnalyticsRequest';
import Export from '../export/Export';

export default class External extends React.Component {
    constructor(props) {
        super(props);
        this.state = { exportData: {}, type: '', loading: false };
    }

    componentDidMount() {
        var params = {};
        var urlParams = (new URL(document.location)).searchParams;
        var entries = urlParams.entries();
        for (var entry of entries) {
            params[entry[0]] = entry[1];
        }
        this.setState({ loading: true }, () => {
            this.handleGetExternal(params);
        });
    }

    handleGetExternal(params, nextPage, exportData = []) {
        AnalyticsRequest.getExternal(params, nextPage, (response) => {
            var states = { loading: false };
            if (response.status === 'ok') {
                var responseData = isset(response.data, {});
                nextPage = issetDot(response, 'pagination.next', '');
                switch (issetDot(response, 'settings.type', '')) {
                case 'export':
                    exportData = this.handleExportItems(responseData, response.settings);
                    states = { ...states, exportData, type: 'export' };
                    break;
                case 'multipleExports':
                    exportData.push(this.handleExportItems(responseData, response.settings));
                    if (nextPage !== null && nextPage.length > 0) {
                        states.loading = true;
                        this.handleGetExternal(params, nextPage, exportData);
                    }
                    else
                        states = { ...states, exportData, type: 'export' };
                    break;
                }
            }

            this.setState(states);
        });
    }

    handleExportItems(item, setting) {
        var tempExportData = {};
        tempExportData.categories = item.categories;
        tempExportData.startDate = issetDot(item, 'insights.startDate');
        tempExportData.endDate = issetDot(item, 'insights.endDate');
        tempExportData.title = issetDot(setting, 'title', 'Reports \nExport');
        tempExportData.exportType = issetDot(setting, 'exportType', 'pdf');
        tempExportData.profiles = [];
        tempExportData.brand = item.brand;
        tempExportData.exportLanguage = item.exportLanguage;
        tempExportData.timeToLive = issetDot(setting, 'timeToLive', 0);

        tempExportData.profiles.push(item.profile);
        if (item.vsProfile && Object.keys(item.vsProfile).length > 1) {
            tempExportData.profiles.push(item.vsProfile);
        }

        tempExportData.boxes = issetDot(item, 'insights.boxes');

        if (item.postsDetails === 'compact') {
            var index = tempExportData.boxes.findIndex((box) => box.category.toLowerCase().includes('topposts'));
            if (index >= 0) {
                tempExportData.boxes.splice(index, 1);
            }
        }
        return tempExportData;
    }
    render() {
        var { exportData, type, loading } = this.state;
        if (loading)
            return <Spinner />;
        switch (type) {
        case 'export':
            return <Export external={true} data={exportData} />;
        default:
            return null;
        }
    }
}
