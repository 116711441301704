import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import Request from '../../classes/Request';

class ProfileRequest {
    static getProfiles(area, subArea, source, channel, reportId, callback){
        var sourceParams = '';
        if (isset(source, '') !== '') {
            sourceParams = '&source=' + source;
        }
        if (isset(channel, '') !== '') {
            sourceParams += '&channel=' + channel;
        }
        if (isset(subArea, '') !== '') {
            sourceParams += '&subArea=' + subArea;
        }
        if (isset(reportId, '') !== '') {
            sourceParams += '&reportId=' + reportId;
        }
        Request.send({ method: 'get',
            url: Config.backendHost + 'profiles?area=' + area + '&status=connected' + sourceParams },
        (data) => {
            callback(isset(data, {}));
        });
    }
}

export default ProfileRequest;
