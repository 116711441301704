import React from 'react';
import i18n from '../../../../../i18n';
import Icon from '../../../../components/icon/Icon';
import Label from '../../../../components/label/Label';
import Text from '../../../../components/text/Text';


const FilterLabel = ({ onClose }) => {
    return <Label background='Purple' >
        <Icon tooltip={i18n.t('The listening project has filters active that can change the results.')} style={{ marginRight: '8px' }} color='White' size='Medium' name='Warning Circle Outline' />
        <Text color='white'>{i18n.t('Data Filtered')}</Text>
        {onClose && <Icon style={{ marginLeft: '8px', cursor: 'pointer' }} name='Remove Circle' color='White' size='Medium' onClick={() => onClose()} />}
    </Label>;
};

export default FilterLabel;
