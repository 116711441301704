import React, { Component } from 'react';
import Text from '../text/Text';
import ProfilePicture from '../profilePicture/ProfilePicture';
import { capitalize, clone, formatNumberSpaces, highlightKeyword, isset, issetDot, t, time } from '../../../base/Utils';
import Post, { Metrics, PostPopup } from '../post/Post';
import Sentiment from '../sentiment/Sentiment';
import Select from '../select/Select';
import i18n from '../../../i18n';
import Icon from '../icon/Icon';
import styled from 'styled-components';
import { types } from '../analytics/Analytics';
import PropTypes from 'prop-types';
import LabelsRequests from '../label/LabelsRequest';
import Button from '../button/Button';

const FeedArea = styled.div`
    width: 100%;
    box-sizing: border-box;
`;

const FeedPosts = styled.div`
    margin-bottom: 32px;
    display: flex;
    align-items: flex-start;
    justify-content: ${props => isset(props.postsAlign, 'flex-start')};
    ${props => props.type === 'BigScreen' && { backgroundColor: 'hsla(0,0%,100%,.09)', borderRadius: '4px', padding: '16px', marginBottom: '16px' }};
    &:last-child{
        margin-bottom: 0px;
    }
`;

const FullBox = styled.div`
    display: ${props => props.isExporting ? 'flex' : 'grid'};
    grid-gap: 10px;
    grid-template-columns: ${props => props.area === 'competitors' ? 'repeat(auto-fit, minmax(380px, 380px))' : 'repeat(auto-fill, minmax(500px,500px))'};
    width: 100%;
    justify-content: ${props => props.isExporting ? 'flex-start' : 'center'};
    ${props => props.comparison && { width: '500px', justifyContent: 'center' }}
`;

const FeedRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
`;

const BottomContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0px 8px 8px 24px;
    box-sizing: border-box;
`;

export default class Feed extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: []
        };
        this.labels = [];
        this.initialLabels = [];

    }

    componentDidMount() {
        this.setState({ data: this.props.data });
        if (this.props.data.feedType === 'posts') {
            var data = this.props.data;
            var dataLabels = this.props.labels;
            var labels = issetDot(dataLabels, 'data', []);
            var finalLabels = [];

            data.items.map((post) => {
                this.initialLabels = issetDot(post, 'labels.data', []);
                return null;
            });

            isset(labels, []).map((label) => {
                finalLabels.push({ value: label.id, label: label.text, color: label.color, id: label.id });
                return null;
            });

            this.labels = finalLabels;
            setTimeout(() => { this.resizeAllGridItems(); }, 100);
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.componentDidMount();
        }
    }

    resizeGridItem(item, parentElement) {
        var rowGap = parseInt(window.getComputedStyle(parentElement).getPropertyValue('grid-row-gap'));
        var rowSpan = Math.ceil(parseInt(item.offsetHeight) / rowGap);
        item.style.gridRowEnd = 'span ' + rowSpan;
    }

    resizeAllGridItems() {
        var parentElement = document.getElementById('FullBox');
        var allItems = parentElement ? parentElement.children : null;
        if (allItems) {
            for (var x = 0; x < allItems.length; x++) {
                this.resizeGridItem(allItems[`${x}`], parentElement);
            }
        }
    }

    generateDetail(data) {
        var { value = '', type = '' } = data;
        switch (type) {
        case 'estReach':
            return <Text size="Medium" >{i18n.t('{{number}} Est. Reach', { number: formatNumberSpaces(value) })}</Text>;
        case 'icon':
            return <Icon name={value} color="Blue" />;
        default:
        }
    }

    setNames(value, type) {
        if (type === 'followers') {
            return value + ' ' + i18n.t('Followers');
        } else if (type === 'monthlyVisits') {
            return value + ' ' + i18n.t('Monthly Visitors');
        } else {
            return value + type;
        }
    }


    changeLabelsPost(e, postId) {
        var data = this.state.data;
        var postLabels = [];
        var removedLabels = [];

        for (let item of data.items) {
            if (item.id === postId) {
                postLabels = clone(item.labels.data);
                if (e !== null) {
                    item.labels.data = e.map((obj) => ({
                        text: obj.label, color: obj.color, id: obj.id
                    }));
                } else {
                    item.labels.data = [];
                    e = [];
                }
                break;
            }
        }


        for (let label in postLabels) {
            var exists = false;
            for (var eKey in e) {
                if (label.id === e[`${eKey}`].id) {
                    exists = true;
                    e.splice(eKey, 1);
                    break;
                }
            }

            if (!exists) {
                removedLabels.push(label);
            }
        }

        this.manageLabels('post', postId, e);
        this.manageLabels('delete', postId, removedLabels);

        this.setState({ data: data });
    }

    manageLabels(method, postId, data) {
        method = capitalize(method);
        data = data || [];
        var area = this.props.area || '';

        if (method === 'Delete') {
            data.map((label) => {
                LabelsRequests.deleteAreaLabel(area, postId, label);
                return null;
            });
        } else if (method === 'Post') {
            data.map((label) => {
                LabelsRequests.postAreaLabel(area, postId, label);
                return null;
            });
        }
    }

    handlePopup(id) {
        var data = this.state.data;
        for (let item of data.items) {
            if (item.id === id) {
                item.showPopup = !item.showPopup; break;
            }
        }
        this.setState({ data });
    }

    render() {
        var data = this.state.data || {};

        var type = this.props.type || '';
        var isExporting = this.props.isExporting || false;

        var style = {};

        if (type === 'Dashboard') {
            style['position'] = 'absolute';
            style['paddingTop'] = '20px';
            style['paddingRight'] = '16px';
        }

        if (data.feedType === 'mentions') {
            return (
                <FeedArea style={style}>
                    {data.items.map((mention, index) => (
                        <FeedPosts type={type} key={index}>
                            <ProfilePicture style={{ marginRight: '16px' }} type={type === 'BigScreen' ? 'Huge' : 'Big'} background={mention.profile.photo} source={capitalize(mention.profile.source)} />
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                                    <div>
                                        <Text color={type === 'BigScreen' ? 'Grey' : 'Black'} size={type === 'BigScreen' ? 'Huge' : 'Medium'} >{mention.profile.name}</Text>
                                        {type === 'BigScreen' && <Text size='Bigger' color="Grey" style={{ marginLeft: '8px', marginRight: '8px' }}>{t(time(mention.publishedTime))}</Text>}
                                        {mention.new === true && <Text color="Green" size={type === 'BigScreen' ? 'Bigger' : 'Small'} style={{ marginLeft: '4px' }} >{i18n.t('NEW!')}</Text>}
                                    </div>
                                    {type === 'BigScreen' ? <div className="absoluteSentiment">
                                        <Icon name={'Emoji ' + (mention.sentiment === 'positive' ? 'Happy' : mention.sentiment === 'negative' ? 'Sad' : 'Neutral')} color={mention.sentiment === 'positive' ? 'Green' : mention.sentiment === 'negative' ? 'Red' : 'Grey'} />
                                        <Text size='Bigger' style={{ marginLeft: '4px' }} color={mention.sentiment === 'positive' ? 'Green' : mention.sentiment === 'negative' ? 'Red' : 'Grey'} >{i18n.t(mention.sentiment.toUpperCase())}</Text>
                                    </div> : <Text size="Tiny" color="mediumGrey" >{t(time(mention.publishedTime))}</Text>}
                                </div>
                                <Text style={{ marginTop: type === 'BigScreen' ? '8px' : '0px', marginBottom: '12px' }} size={type === 'BigScreen' ? 'Huge' : 'Small'} color={type === 'BigScreen' ? 'White' : 'Grey'}>{highlightKeyword(mention.text, isset(mention.highlights) && mention.highlights.map((keyword) => (keyword.text)))}</Text>
                                {type !== 'BigScreen' && <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Sentiment readOnly={true} disabled={this.state.disabled} sentiment={mention.sentiment} />
                                </div>}
                            </div>
                        </FeedPosts>
                    ))}
                </FeedArea>
            );
        } else if (data.feedType === 'profiles') {
            return (
                <FeedArea style={style}>
                    {data.items.map((mention, index) => {
                        var { photo = '', source = '', name = '', reach = {}, detail = {} } = mention;
                        return (
                            <FeedRow key={index}>
                                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                    <ProfilePicture style={{ marginRight: '8px' }} type="Big" background={photo} source={capitalize(source)} />
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Text size="Medium" tooltip={name} singleLine={true} style={{ maxWidth: '160px' }} >{name}</Text>
                                        <Text color="Grey" size="Small" >{this.setNames(formatNumberSpaces(reach.value), reach.type)}</Text>
                                    </div>
                                </div>
                                <div>
                                    {this.generateDetail(detail)}
                                </div>
                            </FeedRow>
                        );
                    })}
                </FeedArea>
            );
        } else if (data.feedType === 'posts') {
            return (
                <FeedPosts postsAlign={this.props.postsAlign} style={style}>
                    <FullBox comparison={this.props.comparison} isExporting={isExporting} area={this.props.area} id='FullBox'>
                        {data.items.map((mention, index) => (
                            <>
                                <Post noTopData={mention.story} postSize={this.props.postSize} area={this.props.area} parentType={type} isInsights={type.includes('Insights')} isExporting={isExporting} key={index} horizontal={isExporting && !type.includes('Insights')} data={mention} style={{ margin: type.includes('Insights') ? '2px 4px 4px 4px' : '8px 0px' }}>
                                    {isset(mention.metrics, []).length > 0 && <Metrics style={this.props.seeDetails ? { margin: '0px' } : {}} area={this.props.area} horizontal={this.props.postSize !== 'Small' && isExporting} isExporting={isExporting} data={isset(mention.metrics, [])} />}
                                    {type === 'Analytics' && <BottomContent >
                                        <div style={{ width: '205px' }}>
                                            {this.props.area === 'projectAnalytics' && <Select isSearchable isMulti options={this.labels} value={issetDot(mention, 'labels.data', []).map((label) => { return ({ value: label.id, label: label.text, color: label.color, id: label.id }); })} onChange={(e) => this.changeLabelsPost(e, mention.id)} placeholder={i18n.t('Add Labels')} />}
                                        </div>
                                        {(!this.props.noFilters && this.props.seeDetails) && <Button type='Tertiary' text={i18n.t('See Details')} onClick={() => this.handlePopup(mention.id)} />}
                                    </BottomContent>}
                                </Post>
                                {mention.showPopup && <PostPopup startDate={mention.startDate} endDate={mention.endDate} area={this.props.area} isOpen={mention.showPopup} labels={this.labels} activeLabels={issetDot(mention, 'labels.data', [])} post={mention} postId={mention.id} source={issetDot(mention, 'profile.source', '')} onClose={() => this.handlePopup(mention.id)} />}
                            </>
                        ))}
                    </FullBox>
                </FeedPosts>
            );
        } else {
            return '';
        }
    }
}

Feed.propTypes = {
    /**
     * Feed data
     */
    data: PropTypes.object,
    /**
     * Feed type
     */
    type: PropTypes.oneOf(types)
};
