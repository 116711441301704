import { posthog } from 'posthog-js';
import Session from './base/Session';
import { getCookie, isset, setCookie } from './base/Utils';
import { sendMessage } from './common/containers/OldPages';

export default function posthogActionProperties() {

    try {
        if (typeof window.posthogTriggerProperties !== 'undefined') {
            setCookie('posthogTriggerProperties', JSON.stringify(window.posthogTriggerProperties), 365);
        }

        var cookiePHTriggerProperties = getCookie('posthogTriggerProperties');
        if (cookiePHTriggerProperties) {
            cookiePHTriggerProperties = JSON.parse(cookiePHTriggerProperties);
        }
    } catch (e) {
        console.log('Could not load Posthog Trigger Properties');
        cookiePHTriggerProperties = [];
    }

    if (!cookiePHTriggerProperties && typeof cookiePHTriggerProperties !== 'object') {
        cookiePHTriggerProperties = [];
    }

    /*actions:'click', 'mousedown', 'mouseup', 'mouseenter', 'mouseleave', 'mousemove', 'mouseout', 'mouseover', 'contextmenu', 'keydown',
            'keyup', 'keypress', 'submit', 'reset', 'change', 'input', 'focus', 'blur', 'touchstart', 'touchmove', 'touchend', 'dragstart',
            'drag', 'dragend', 'dragenter', 'dragleave', 'dragover', 'drop'*/
    const targetArray = cookiePHTriggerProperties || [];
    const uniqueActions = [...new Set(targetArray.map(item => item.action))];
    for (var uniqueAction of uniqueActions) {
        document.addEventListener(uniqueAction, (event) => {
            const params = Session.getParams();
            for (var item of targetArray) {
                if (isset(event.target) && typeof event.target.closest === 'function') {
                    const target = event.target.closest(item.target);
                    if (isset(target) && target !== null) {
                        if (target.matches(item.target)) {
                            var object = {};
                            for (const [key, value] of Object.entries(item.properties)) {
                                object[`${key}`] = value;
                            }
                            posthog.identify(params.id || 0, object);
                            sendMessage({
                                type: 'postHogSetPropertyFlags',
                                set: object
                            });
                            break;
                        }
                    }
                }
            }
        });
    }
}
