import { clone, colorApplication, isset, issetDot } from '../../../base/Utils';

class RearrangeBox {
    static boxesTemp = []
    static rowBox = ''

    static handleBox(boxes, rowBox) {
        this.boxesTemp = boxes;
        this.rowBox = rowBox;

        this.checkBoxes();
        this.checkForNotes();

        return this.boxesTemp;
    }

    static checkBoxes() {
        var boxes = this.boxesTemp;
        var rowBox = this.rowBox;

        boxes.map((box, key) => {
            key = parseInt(key);
            this.handleBoxContent(box.items, box, key, 'boxes', boxes[key + 1], false, rowBox);
            return null;
        });
    }

    static checkDescriptiveItemsOrFilters(parentItem) {
        var isDescriptiveItemOrFilter = false;

        if (parentItem.type === 'group') {
            parentItem.items.map((item) => {
                isDescriptiveItemOrFilter = this.checkDescriptiveItemsOrFilters(item);
                return null;
            });
        } else if (parentItem.type === 'descriptiveItems' || parentItem.type === 'label' || parentItem.type === 'filters') {
            isDescriptiveItemOrFilter = true;
        }

        return isDescriptiveItemOrFilter;
    }


    static handleBoxContent(items, parentBox, parentKey, parentType, nextBox, hasLabel, rowBox = {}) {
        var newBox = {};
        var boxes = this.boxesTemp;
        var tableRows = rowBox.table ? rowBox.table : hasLabel ? 4 : 5;
        var feedRows = rowBox.feed ? rowBox.feed : 2;

        if (parentType === 'group' && items.length > 1) {
            var auxItems = [];
            var type = '';
            var insertNewBox = false;
            for (var itemId in items) {
                var item = items[`${itemId}`];
                var prevItem = isset(items[parseInt(itemId) - 1], {});
                var nextItem = isset(items[parseInt(itemId) + 1], {});
                if (item.type === 'table') {
                    type = 'table';
                    var splicedItems = [];
                    if (item.body.length > tableRows || isset(prevItem.body, []).length > tableRows || isset(nextItem.body, []).length > tableRows) {
                        insertNewBox = true;
                        if (!isset(parentBox.page)) {
                            parentBox.page = 1;
                        }
                        var auxItem = clone(item);
                        splicedItems = auxItem.body.splice(0, tableRows);
                        auxItems.push(auxItem);
                        item.body = splicedItems;
                    }
                    else {
                        auxItems.push(item);
                    }
                }
                else if (item.type === 'group') {
                    this.handleBoxContent(item.items, parentBox, parentKey, 'group', nextBox, hasLabel, rowBox);
                }
            }
            if (insertNewBox) {
                newBox = this.insertToNewItem(clone(parentBox), type, auxItems, splicedItems);
                if (isset(nextBox) && nextBox.id === parentBox.id) {
                    nextBox.page++;
                }
                newBox.page += 1;
                boxes.splice(parentKey + 1, 0, newBox);
                this.checkBoxes();
            }
        } else {
            var newBoxItems = [];
            var descriptiveItemsFiltersItems = [];
            for (itemId in items) {
                itemId = parseInt(itemId);
                item = items[`${itemId}`];
                nextItem = items[itemId + 1];
                splicedItems = [];
                hasLabel = descriptiveItemsFiltersItems.findIndex((tempItem) => tempItem.type === 'label') >= 0;
                if (isset(nextItem)) {
                    if (nextItem.type === 'label') {
                        var tempKey = descriptiveItemsFiltersItems.findIndex((tempItem) => tempItem.type === nextItem.type);
                        if (tempKey >= 0)
                            descriptiveItemsFiltersItems.splice(tempKey, 1, nextItem);
                    }
                    if (!this.checkDescriptiveItemsOrFilters(item)) {
                        if (!isset(parentBox.page)) {
                            parentBox.page = 1;
                        }

                        if (isset(nextBox) && nextBox.id === parentBox.id) {
                            nextBox.page++;
                        }

                        newBox = clone(parentBox);
                        var newItem = parentBox.items.splice((parentBox.items.length - 1), 1);
                        if (newItem[0].type === 'label') {
                            descriptiveItemsFiltersItems.push(newItem[0]);
                        }
                        else {
                            if (newItem[0].type !== 'chart') {
                                for (var id in descriptiveItemsFiltersItems) {
                                    newBoxItems.push(descriptiveItemsFiltersItems[`${id}`]);
                                }
                            }
                            newBoxItems.push(newItem[0]);

                            newBox.items = newBoxItems;
                            newBox.page += 1;
                            boxes.splice(parentKey + 1, 0, newBox);
                        }
                        this.checkBoxes(); break;
                    }
                    else {
                        descriptiveItemsFiltersItems.push(item);
                    }
                }
                else {
                    switch (item.type) {
                    case 'dashboard':
                        var columns = 0;
                        var boxCount = 0;
                        var rows = 0;
                        insertNewBox = false;
                        for (id in item.boxes) {
                            columns += item.boxes[`${id}`].columns;
                            rows += item.boxes[`${id}`].rows;
                            if (columns <= 12) {
                                boxCount++;
                            }
                            else {
                                if (rows < isset(rowBox.dashboard, 0)) {
                                    columns = item.boxes[`${id}`].columns;
                                    boxCount++;
                                }
                                else {
                                    rows = item.boxes[`${id}`].rows;
                                    columns = item.boxes[`${id}`].columns;
                                    insertNewBox = true;
                                    break;
                                }
                            }
                        }
                        if (insertNewBox) {
                            splicedItems = item.boxes.splice(0, boxCount);

                            if (!isset(parentBox.page)) {
                                parentBox.page = 1;
                            }

                            if (isset(nextBox) && nextBox.id === parentBox.id) {
                                nextBox.page++;
                            }

                            newBox = clone(parentBox);
                            newBox.page += 1;
                            item.boxes = splicedItems;
                            boxes.splice(parentKey + 1, 0, newBox);
                            this.checkBoxes();
                        }
                        break;
                    case 'table':
                        if (item.body.length > tableRows) {
                            splicedItems = item.body.splice(0, tableRows);

                            if (!isset(parentBox.page)) {
                                parentBox.page = 1;
                            }

                            if (isset(nextBox) && nextBox.id === parentBox.id) {
                                nextBox.page++;
                            }

                            newBox = clone(parentBox);
                            newBox.page += 1;
                            item.body = splicedItems;
                            boxes.splice(parentKey + 1, 0, newBox);
                            this.checkBoxes();
                        }
                        break;
                    case 'feed':
                        if (this.checkDescriptiveItemsOrFilters(parentBox.items[0]))
                            parentBox.items.splice(0, 1);

                        if (item.items.length > feedRows) {
                            splicedItems = item.items.splice(0, feedRows);

                            if (!isset(parentBox.page)) {
                                parentBox.page = 1;
                            }

                            if (isset(nextBox) && nextBox.id === parentBox.id) {
                                nextBox.page++;
                            }

                            newBox = clone(parentBox);
                            newBox.page += 1;
                            item.items = splicedItems;
                            boxes.splice(parentKey + 1, 0, newBox);
                            this.checkBoxes();
                        }
                        break;
                    case 'group':
                        var hasFeed = item.items.some(item => item.type === 'feed');
                        var hasTable = item.items.some(item => item.type === 'table' && item.head.length >= 4);
                        if (item.items.length >= 2 && (hasFeed || hasTable) && parentBox.labels.length > 1) {
                            var tempItems = [];
                            var splicedLabels = [];
                            var colorCount = {};
                            for (id in parentBox.labels) {
                                if (parentBox.labels[`${id}`].color !== 'primaryPallet') {
                                    break;
                                }
                                var label = parentBox.labels[`${id}`];
                                var tempColor = colorApplication(label.color, colorCount, 'labels');
                                label.color = tempColor.color;
                                colorCount = tempColor.counts;
                            }
                            splicedLabels = parentBox.labels.splice(0, 1);
                            for (id in parentBox.items) {
                                item = parentBox.items[`${id}`];
                                if (isset(item.items)) {
                                    if (item.items[0].type === 'descriptiveItems' || item.items[0].type === 'feed' || (item.items[0].type === 'table' && item.items[0].head.length >= 4)) {
                                        splicedItems = item.items.splice(0, 1);
                                        tempItems.push(splicedItems[0]);
                                        if (item.items.length < 2) {
                                            item = item.items[0];
                                        }
                                    }
                                }
                                else {
                                    tempItems.push(item);
                                }
                            }

                            if (!isset(parentBox.page)) {
                                parentBox.page = 1;
                            }
                            if (isset(nextBox) && nextBox.id === parentBox.id) {
                                nextBox.page++;
                            }

                            newBox = clone(parentBox);
                            newBox.page += 1;
                            parentBox.items = tempItems;
                            parentBox.labels = splicedLabels;
                            boxes.splice(parentKey + 1, 0, newBox);
                            this.checkBoxes();
                        }
                        else
                            this.handleBoxContent(item.items, parentBox, parentKey, 'group', nextBox, hasLabel, rowBox);
                        break;
                    default:
                        break;
                    }
                }
            }
        }

        this.boxesTemp = boxes;
    }


    static insertToNewItem(item, type, insertItem, splicedItems) {
        item.items = item.items.map((subItem) => {
            if (subItem.type === 'group') {
                if (subItem.items.findIndex(subSubItem => subSubItem.type === type) < 0) {
                    subItem = this.insertToNewItem(subItem, type, insertItem, splicedItems);
                }
                else {
                    for (var id in subItem.items) {
                        if (isset(subItem.items[`${id}`])) {
                            var body = subItem.items[`${id}`].body;
                            if (JSON.stringify(body) === JSON.stringify(splicedItems)) {
                                subItem.items = insertItem;
                            }
                        }
                    }
                }
            }
            return subItem;
        });

        return item;
    }


    static noteBoxes = {};
    static checkForNotes() {
        var boxes = this.boxesTemp;

        for (var id in boxes) {
            var box = boxes[`${id}`];
            if (isset(box.notes)) {
                var noteBox = clone(box);
                if (issetDot(noteBox, 'notes.text') && !isset(this.noteBoxes[noteBox.id + 'Note'])) {
                    noteBox.notes.type = 'notes';
                    noteBox.category = noteBox.category + 'Notes';
                    noteBox.items = [noteBox.notes];

                    delete box.notes;
                    delete noteBox.notes;
                    delete noteBox.labels;
                    this.noteBoxes[noteBox.id + 'Note'] = noteBox;
                }
            }
        }

        this.noteBoxes = Object.values(this.noteBoxes);

        if (this.noteBoxes.length > 0) {
            for (var key in this.noteBoxes) {
                noteBox = this.noteBoxes[`${key}`];
                for (id in boxes) {
                    box = boxes[`${id}`];
                    var nextBox = isset(boxes[parseInt(id) + 1], {});

                    if (noteBox.id === box.id && box.id !== nextBox.id) {
                        boxes.splice(parseInt(id) + 1, 0, noteBox);
                        delete this.noteBoxes[`${key}`]; break;
                    }
                }
            }

            this.boxesTemp = boxes;
        }
    }
}

export default RearrangeBox;
