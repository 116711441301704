import React, { Component } from 'react';
import Text from '../text/Text';
import PropTypes from 'prop-types';
import { isset } from '../../../base/Utils';
import i18n from '../../../i18n';
import styled from 'styled-components';
import Icon from '../icon/Icon';
import Config from '../../../base/config/Config';

const types = ['Primary', 'Secondary'];

const MainInputWrapper = styled.div`
    ${props => props.disabled && 'opacity: 0.3; pointer-events: none; user-select: none;'}
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    border: 1px solid ${props => props.errorMessage !== '' ? Config.colors.red : props.type === types[1] ? Config.colors.blue : 'rgba(34, 36, 38, 0.15)'};
    border-radius: 40px;
    height: 38px;
    font-weight: 400;
    font-style: normal;
    color: rgba(0, 0, 0, 0.87);
    background: 0 0 !important;
    padding: 5px;
    box-sizing: border-box;

    > div{
        border: none;
    }
`;

const MainInput = styled.input`
    box-sizing: border-box;
    margin: 0;
    max-width: 100%;
    flex: 1 0 auto;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    text-align: left;
    background: #fff;
    color: rgba(0, 0, 0, 0.87);
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    box-shadow: none;
    font-size: 14px;
    padding-left: 16px;
    border: 0;
    width: 100%;
`;

const MainInputTopText = styled.div`
    position: absolute;
    top: -20px;
    ${props => props.rightSide ? 'right: 16px' : 'left: 16px'};
`;

const MainInputBottomText = styled.div`
    position: absolute;
    bottom: -18px;
    left: 16px;
`;

//FIXME: The input structure is not made well and does not support displaying messages with multiple lines.
export default class Input extends Component {
    constructor() {
        super();
        this.state = { showPass: false };
    }

    handleKeyPress(e) {
        if (e.nativeEvent.keyCode === 13) {
            if (!e.nativeEvent.shiftKey) {
                e.preventDefault();
                if (typeof this.props.onEnter === 'function') {
                    return this.props.onEnter();
                }
            }
        }
    }

    render() {
        var { placeholder = '',
            name = '',
            disabled = false,
            onChange = function () { },
            title = '',
            bottomText = '',
            errorMessage = '',
            type = types[0],
            readOnly,
            ...props
        } = this.props;

        if (isset(this.props.value)) {
            props.value = this.props.value;
        }

        if (!isset(errorMessage, false)) {
            errorMessage = '';
        }

        return (<MainInputWrapper disabled={disabled} type={type} errorMessage={errorMessage} {...props}>
            <MainInputTopText>
                {title !== '' && <Text color={errorMessage !== '' ? 'red' : type === types[1] ? 'blue' : 'black'} size="Small">{title}</Text>}
            </MainInputTopText>

            <MainInputTopText rightSide>
                {type === 'password' ? <Text style={{ cursor: 'pointer', userSelect: 'none' }} onClick={() => this.setState({ showPass: !this.state.showPass })} color="darkblue" size="Small" > <Icon name={this.state.showPass ? 'Eye Off' : 'Eye'} color="darkblue" size="Tiny" /> {this.state.showPass ? i18n.t('Hide') : i18n.t('Show')} </Text> : null}
            </MainInputTopText>

            <MainInput
                readOnly={readOnly}
                onKeyDown={(e) => this.handleKeyPress(e)}
                placeholder={placeholder}
                name={name}
                type={this.state.showPass ? 'text' : type}
                onChange={onChange}
                id={name}
                {...props}
            />
            <MainInputBottomText>
                {errorMessage !== '' ? <Text className='error' color="red" size="Small">{errorMessage}</Text> : bottomText !== '' && <Text color="grey" size="Small">{bottomText}</Text>}
            </MainInputBottomText>
        </MainInputWrapper>);
    }
}

Input.propTypes = {
    /**
     * Placeholder of Input
     */
    placeholder: PropTypes.string,
    /**
     * Name for Input
     */
    name: PropTypes.string,
    /**
     * Type of input
     */
    type: PropTypes.oneOf(types),
    /**
     * Title of input
     */
    title: PropTypes.string,
    /**
    * Error message on input
    */
    errorMessage: PropTypes.string,
    /**
    * Tips message for input
    */
    bottomText: PropTypes.string,
    /**
     * Do an action when the key is typed in input
     */
    onChange: PropTypes.func,
    /**
    * disable actions on input
    */
    disabled: PropTypes.bool
};

Input.defaultProps = {
    placeholder: '',
    name: '',
    title: '',
    errorMessage: '',
    bottomText: '',
    onChange: function () { },
    onInput: function () { },
    disabled: false,
    type: types[0]
};

export const InputWrapper = ({ children, title, type, errorMessage, bottomText, disabled, ...props }) => {
    if (!isset(errorMessage, false)) {
        errorMessage = '';
    }
    return (
        <MainInputWrapper type={type} disabled={disabled} errorMessage={errorMessage}  {...props}>
            <MainInputTopText>
                {title !== '' && <Text color={errorMessage !== '' ? 'red' : type === types[1] ? 'blue' : 'black'} size="Small">{title}</Text>}
            </MainInputTopText>
            {children}
            <MainInputBottomText>
                {errorMessage !== '' ? <Text color="red" size="Small">{errorMessage}</Text> : bottomText !== '' && <Text color="grey" size="Small">{bottomText}</Text>}
            </MainInputBottomText>
        </MainInputWrapper>
    );
};

InputWrapper.propTypes = {
    /**
     * children content in inputWrapper
     */
    children: PropTypes.any,
    /**
    * Error message for input
    */
    errorMessage: PropTypes.string,
    /**
    * Tips message for input
    */
    bottomText: PropTypes.string,
    /**
    * disable actions on input
    */
    disabled: PropTypes.bool,
};

InputWrapper.defaultProps = {
    children: '',
    errorMessage: '',
    bottomText: '',
    disabled: false
};

const MainInputSide = styled.div`
    display: flex;
    flex: 0 0 auto;
    margin: 0px;
`;

export const InputSide = ({ children, ...props }) => {
    return (
        <MainInputSide {...props}>
            {children}
        </MainInputSide>
    );
};

InputSide.propTypes = {
    /**
     * children content in inputSide
     */
    children: PropTypes.any,
};

InputSide.defaultProps = {
    children: '',
};

const MainTextArea = styled.textarea`
    width: 100%;
    border: 1px solid ${props => props.errorMessage !== '' ? Config.colors.red : props.type === types[1] ? Config.colors.blue : Config.colors.grey};
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    padding: 11px 16px 3px 16px;
    color: #212121;
    overflow: auto;
    border-radius: 20px;
    line-height: 14px;
    min-height: 38px;
    outline: none;
    resize: none;
`;

export class TextArea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rows: 1,
            minRows: 1,
            maxRows: 10,
        };
    }

    baseHeight = null;

    componentDidMount() {
        var textarea = document.getElementById('TextArea');
        setTimeout(() => this.handleChange(textarea), 0);
    }

    handleChange(target) {
        const textareaLineHeight = 16;
        const { minRows, maxRows } = this.state;

        if (this.baseHeight === null) {
            this.baseHeight = target.clientHeight - 14;
        }

        target.rows = minRows;

        var scrollHeight = target.scrollHeight - this.baseHeight;

        const currentRows = Math.ceil(scrollHeight / textareaLineHeight);

        target.rows = currentRows < maxRows ? (currentRows < minRows ? minRows : currentRows) : maxRows;

    }

    render() {
        var { type = types[0], onInput, placeholder, maxCharacters, onSelect, onKeyDown, title = '', errorMessage = '', bottomText = '', disabled = false, rows = 1, ...props } = this.props;

        if (isset(this.props.value)) {
            props.value = this.props.value;
        }

        return (
            <div style={{ position: 'relative', width: '100%' }}>
                <MainInputTopText>
                    {title !== '' && <Text color={errorMessage !== '' ? 'red' : type === types[1] ? 'blue' : 'black'} size="Small">{title}</Text>}
                </MainInputTopText>
                <MainTextArea
                    id='TextArea'
                    errorMessage={errorMessage}
                    type={type}
                    rows={rows}
                    disabled={disabled}
                    onChange={(event) => this.handleChange(event.target)}
                    onInput={onInput}
                    onselect={onSelect}
                    onKeyDown={onKeyDown}
                    maxLength={maxCharacters}
                    placeholder={placeholder}
                    {...props} />
                <MainInputBottomText>
                    {errorMessage !== '' ? <Text color="red" size="Small">{errorMessage}</Text> : bottomText !== '' && <Text color="grey" size="Small">{bottomText}</Text>}
                </MainInputBottomText>
            </div>
        );
    }
}
