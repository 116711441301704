import i18n from '../../../i18n';
import TourController from '../TourController';
import React from 'react';
import andie from '../../../resources/videos/andie.mp4';
import Text from '../../components/text/Text.js';

// eslint-disable-next-line
export default () => ([
    {
        title: i18n.t('Supercharged Content Creation'),
        content: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ marginBottom: '8px' }} size='Medium' color='heavygrey'>{i18n.t('Now in Swonkie you can')}:</Text>
            <Text size='Big' >{i18n.t('You can start using ANDIE now! Create your new post for Facebook, Instagram, LinkedIn, Twitter, and more in a few minutes. ANDIE, your new social media assistant, will adjust it to your tone of voice, business area, target, and much more. If you want to save time in content creation, ANDIE is the answer!')}</Text>
            <video src={andie} controls={false} autoPlay={true} style={{ width: '600px', height: '320px' }} />
        </div>,
        placement: 'center',
        target: 'body',
        style: { minWidth: '600px', minHeight: '320px' }
    },
    {
        title: i18n.t('Supercharged Content Creation'),
        content: <div style={{ display: 'flex', flexDirection: 'column', }}>
            <Text style={{ marginBottom: '16px' }} size='Medium' color='heavygrey'>{i18n.t('Now in Swonkie you can')}:</Text>
            {[
                {
                    title: '✨ ' + i18n.t('Create Content with AI Assistance'),
                    message: i18n.t('Generate engaging content effortlessly using AI\'s power. No more writer\'s block!')
                },
                {
                    title: '✍️ ' + i18n.t('Generate Customized Content'),
                    message: i18n.t('Give ANDIE your tone of voice, language, format, keywords, and more, and let it do the work for you.')
                },
                {
                    title: '📣 ' + i18n.t('Enhance Content with Creative Elements'),
                    message: i18n.t('Add hashtags, emojis, and suggested images for captivating content.')
                },
                {
                    title: '🚀 ' + i18n.t('Optimize Content for every Social Networks'),
                    message: i18n.t('Improve your results with content tailored to the characteristics of each social network.')
                },
                {
                    title: '💡 ' + i18n.t('Refine your content after it\'s created'),
                    message: i18n.t('Revise, improve, shorten, or lengthen your text effortlessly.')
                },
                {
                    title: '🌈 ' + i18n.t('Experience the Future of Content Creation'),
                    message: i18n.t('Unleash creativity and watch your content flourish!')
                }
            ].map((item, key) => (
                <div key={key} style={{ marginBottom: '24px' }}>
                    <Text size='Big' weight='SemiBold' >{item.title}</Text>
                    <Text size='Medium' color='heavygrey'>{item.message}</Text>
                </div>
            ))}
        </div>,
        showSkipButton: true,
        nextButtonText: i18n.t('Try Now'),
        placement: 'center',
        target: 'body',
        style: { minWidth: '600px', minHeight: '360px' },
        afterStep: () => {
            setTimeout(() => TourController.load('AndieProduceContent'), 100);
        }
    }
]);
