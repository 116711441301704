import i18n from '../../../i18n';
import { redirect } from '../../../base/Utils';
import confetti from 'canvas-confetti';
import { sendMessage } from '../../containers/OldPages';

export default () => ([
    {
        beforeStep: () => {
            sendMessage({ type: 'closePopups' });
            redirect('/produce-content');
        },
        target: '[data-id="produce-content-andie-compose-button"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Click to start creating.'),
        content: i18n.t('Click on "Create with ANDIE" to explore ANDIE\'s content creation capabilities.'),
        hideNextButton: true,
        disableBeacon: true,
        spotlightClicks: true
    },
    {
        target: '.andie-container:first-of-type',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Welcome to ANDIE'),
        content: i18n.t('This is where the magic unfolds, where your ideas come to reality with the help of ANDIE.'),
        showSkipButton: true,
        disableBeacon: true,
        floaterProps: { hideArrow: true },
        sleepBeforeStart: 500
    },
    {
        target: '[data-id="andie-compose-form"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Tailor your content'),
        content: i18n.t('Fill in the form below with your desired tone, length, keywords, or target audience.'),
        showSkipButton: true,
        hideBackButton: false,
        disableBeacon: true
    },
    {
        target: '[data-id="andie-compose-form-optimize-social-network"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Optimize your content for each social network'),
        content: i18n.t('This option will customize your content to suit the unique characteristics of each network. It\'s available only if you have two or more social networks selected.'),
        showSkipButton: true,
        hideBackButton: false,
        disableBeacon: true
    },
    {
        target: '[data-id="andie-chat-credits"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Your ANDIE Credits'),
        content: i18n.t('Keep track of your credit count for all actions. The credits are available on your personal account or shared if you are on a team account.'),
        showSkipButton: true,
        hideBackButton: false,
        disableBeacon: true
    },
    {
        target: '[data-id="andie-compose-submit"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Let ANDIE work its magic'),
        content: i18n.t('Click here to create your content'),
        showSkipButton: true,
        hideBackButton: false,
        disableBeacon: true
    },
    {
        target: '.sweet-alert [data-id="andie-side-chat"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Your content results at a glance'),
        content: i18n.t('Your results will be displayed in this location for your use'),
        showSkipButton: true,
        hideBackButton: false,
        disableBeacon: true
    },
    {
        target: 'body',
        placement: 'center',
        title: i18n.t('Now create amazing content!'),
        content: i18n.t('Congratulations! You now have a solid grasp of ANDIE\'s capabilities. It\'s time to dive in, and start creating content with ANDIE\'s assistance.'),
        hideBackButton: false,
        disableBeacon: true,
        afterStep: () => {
            confetti({
                particleCount: 250,
                spread: 100
            });
        }
    }
]);
