import { posthog } from 'posthog-js';
import { isset } from '../../base/Utils';
import Session from '../../base/Session';

const Store = {
    'Example': require('./store/Example'),
    'PopupNewsAndieProduceContent': require('./store/PopupNewsAndieProduceContent'),
    'AndieProduceContent': require('./store/AndieProduceContent'),
    'AndieProduceContentChatApply': require('./store/AndieProduceContentChatApply'),
    'AndieProduceContentChatHistory': require('./store/AndieProduceContentChatHistory'),
    'AndieProduceContentChatImages': require('./store/AndieProduceContentChatImages'),
    'AndieProduceContentChatSeeContent': require('./store/AndieProduceContentChatSeeContent'),
    'ConnectProfile': require('./store/ConnectProfile'),
    'Basic.ContentProducer': require('./store/Basic.ContentProducer'),
    'Basic.AdvancedReport': require('./store/Basic.AdvancedReport'),
    'SchedulePost': require('./store/SchedulePost'),
    'CreateFirstReport': require('./store/CreateFirstReport'),
    'CreateFirstPost': require('./store/CreateFirstPost'),
    'Basic.PublishModal': require('./store/Basic.PublishModal'),
    'Basic.ScheduleModal': require('./store/Basic.ScheduleModal'),
    'PopupNewsLinkedInDocumentPost': require('./store/PopupNewsLinkedInDocumentPost'),
    'PopupNewsTiktokDirectPublishing': require('./store/PopupNewsTiktokDirectPublishing'),
    'LinkedInDocumentPost': require('./store/LinkedInDocumentPost')
};

class TourController {
    static tourSteps = null;
    static currentTour = null;

    static callback = null;
    static stepsCallback = null;
    static runCallback = null;
    static indexCallback = null;
    static getParamsCallback = null;

    static load(tour, callback = null) {
        if (isset(Store[`${tour}`])) {
            this.currentTour = tour;

            this.tourSteps = Store[`${tour}`].default();
            this.stepsCallback(this.tourSteps);

            if (callback) this.setCallback(callback);

            this.start();
        }
    }

    static start() {
        this.runCallback(true);
    }

    static stop() {
        this.currentTour = null;
        this.tourSteps = null;
        this.runCallback(false);
    }

    static setStatus(status = 'skipped', tour = this.currentTour) {
        const params = Session.getParams();
        posthog.identify((params.id || 0).toString(), { ['productTours.' + tour]: status });
    }

    static status() {
        return {
            name: this.currentTour,
            running: (this.currentTour !== null)
        };
    }

    static setCallback(callback) {
        this.callback = callback;
    }

    static lastData = null;
    static callCallback(data) {
        if (this.lastData === JSON.stringify(data)) {
            return;
        }

        this.lastData = JSON.stringify(data);

        if (isset(data.status, null) === 'ended' && isset(data.index, 0) < isset(data.size, 0)) {
            data.status = 'skipped';
            posthog.capture('productTour', Object.assign(data, {
                name: this.currentTour,
            }));
        } else {
            posthog.capture('productTour', Object.assign(data, {
                name: this.currentTour,
            }));
        }

        if (['ended', 'skipped'].includes(isset(data.status, null))) {
            this.setStatus(data.status);
            this.stop();
        }

        if (this.callback) {
            this.callback(data);
        }
    }

    static setStepsCallback(callback) {
        this.stepsCallback = callback;
    }

    static setRunCallback(callback) {
        this.runCallback = callback;
    }

    static setIndexCallback(callback) {
        this.indexCallback = callback;
    }

    static setGetParamsCallback(callback) {
        this.getParamsCallback = callback;
    }
}

export default TourController;
