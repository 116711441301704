import React from 'react';
import { t } from '../../../base/Utils';
import i18n from '../../../i18n';

export default () => ([
    {
        target: '[data-id="produce-content-schedule-button"]',
        disabledBeacon: false,
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Configure your scheduled post'),
        content: i18n.t('The schedule button shows the date you are scheduling your post for. Inside, you have a lot more options.'),
        showSkipButton: true,
        hideNextButton: true,
        spotlightClicks: true
    },
    {
        target: '[data-id="produce-content-schedule-options-box"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Scheduling options'),
        content: i18n.t('Here, you can see the options available to you for scheduling your posts. Some options depend on the social network you are using.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
    },
    {
        target: '[onclick="confirm_swal();"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Ready to Schedule'),
        content: t(<>Once you have everything set up for your scheduled post, hit <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>Confirm</span> and you&apos;re done. It will then be available on the calendar in the planning menu.</>),
        sleepBeforeStart: 500,
        nextButtonText: i18n.t('Finish'),
    }
]);
