import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import { isset, withSkeleton } from '../../../base/Utils';

const colors = Config.colors;

export const sizes = {
    'Tiny': '10px',
    'Small': '12px',
    'Medium': '14px',
    'Big': '16px',
    'Bigger': '18px',
    'Biggest': '20px',
    'Huge': '24px',
    'Giant': '36px',
    'Colossal': '48px',
    'ColossalAlt': '2rem',
    'Titanic': '77px'
};

export const weight = {
    'Thin': '100',
    'Regular': '400',
    'SemiBold': '500',
    'Bold': '600',
    'Black': '800'
};

export const aligns = {
    'left': 'left',
    'center': 'center',
    'right': 'right'
};

export const fontStyles = {
    'normal': 'normal',
    'italic': 'italic',
    'oblique': 'oblique'
};

const MainText = styled.text`
    display: inline-block;
    text-align: ${props => isset(aligns[props.align], aligns['left'])};
    font-size: ${props => isset(sizes[props.size], sizes['Small'])};
    ${props => props.color && 'color: ' + props.color};
    font-weight: ${props => isset(weight[props.weight], weight['Regular'])};
    ${props => props.singleLine ? 'overflow: hidden; text-overflow: ellipsis; white-space: nowrap;' : 'word-break: normal'}
`;

const Text = ({ color, children, size, weight, singleLine, align, tooltip, fontStyle, ...props }) => {
    color = color.toLowerCase();
    color = isset(colors[`${color}`], 'black');

    var spanStyle = { fontStyle: isset(fontStyles[`${fontStyle}`], fontStyles.normal) };
    if (singleLine) {
        spanStyle['whiteSpace'] = 'nowrap';
        spanStyle['overflow'] = 'hidden';
        spanStyle['textOverflow'] = 'ellipsis';
    }

    var html = (
        <MainText weight={weight} size={size} color={color} singleLine={singleLine} align={align} {...props}>
            <span style={spanStyle} tooltip={tooltip} >
                {children}
            </span >
        </MainText>

    );

    return html;
};

Text.propTypes = {
    /**
     * Text as Children (< Text>Lorem Ipsum</Text>)
     */
    children: PropTypes.node.isRequired,
    /**
     * Color of the text
     */
    color: PropTypes.any,
    /**
     * Weight of the text
     */
    weight: PropTypes.oneOf(Object.keys(weight)),
    /**
     * Font size
     */
    size: PropTypes.oneOf(Object.keys(sizes)),
    /**
     *  font alignment
     */
    align: PropTypes.oneOf(Object.keys(aligns)),
    /**
     *  choose if text can break to multi lines or not
     */
    singleLine: PropTypes.bool,
    /**
     * style of the font
     */
    fontStyle: PropTypes.oneOf(Object.keys(fontStyles))
};

Text.defaultProps = {
    children: '',
    color: 'Black',
    size: 'Medium',
    weight: 'Regular',
    align: 'left',
    singleLine: false
};

Text.skeleton = {
    children: 'str:50:200'
};

export default Text;
export const TextSkeleton = (props) => withSkeleton(props, Text);
