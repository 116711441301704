import React from 'react';
import { clone, isset } from '../../base/Utils';
import ComponentTable from '../components/table/Table';

class Table {
    static type = 'normal';

    static start(data) {
        data = data || {};
        this.type = data.type || 'normal';

        this.rows = [];

        return clone(this);
    }

    static row(data) {
        data = data || {};

        return clone(Row.start(data));
    }

    static rows = [];

    static addRow(row) {
        this.rows.push(row.get());
    }

    static get(type, sortType) {
        type = type || 'object'; //can be "component" to

        var tableData = {};
        var rows = this.rows || [];

        if (isset(sortType)) {
            tableData.sortType = sortType;
        }

        if (isset(rows[0]) && rows[0].type === 'head') {
            tableData.head = rows[0].data;
            rows.splice(0, 1);
        }

        tableData.body = [];
        for (let row of rows) {
            tableData.body.push(row.data);
        }

        if (type === 'object') {
            return tableData;
        } else {
            return <ComponentTable data={tableData} />;
        }
    }
}

class Row {
    static type = 'body';

    static start(data) {
        data = data || {};
        this.type = data.type || 'body';

        this.data = [];

        return clone(this);
    }

    static data = []

    static cell(data, type, extra, sortable, defaultSort) {
        data = isset(data, '');
        type = type || 'string';
        sortable = isset(sortable, false);

        var cellData = Object.assign({
            type: 'value',
            value: {
                type: 'string',
                value: '',
                sortable: sortable,
                defaultSort: defaultSort
            },
        }, extra);

        if (typeof data !== 'object' || type === 'component') {
            cellData.value.value = data;
            cellData.value.type = type;
        } else {
            cellData.type = data.type || 'value';

            switch (cellData.type) {
            case 'value':
                cellData.value = data.value || {
                    type: 'string',
                    value: ''
                };

                cellData.value.type = data.value.type || 'string';
                cellData.value.value = data.value.value || '';
                break;
            case 'icon':
                cellData.icon = data.icon || {
                    value: ''
                };

                cellData.icon.value = data.icon.value || '';
                break;
            case 'photo':
                cellData.photo = data.photo || {
                    type: 'normal',
                    value: ''
                };

                cellData.photo.type = data.photo.type || 'normal';
                cellData.photo.value = data.photo.value || '';
                break;
            case 'action':
                cellData.action = data.action || {
                    type: 'redirect',
                    text: 'See More',
                    value: '/'
                };

                cellData.action.type = data.action.type || 'redirect';
                cellData.action.text = data.action.text || 'See More';
                cellData.action.value = data.action.value || '/';
                break;
            default:
                cellData = data || {};
            }
        }

        this.data.push(cellData);
    }

    static get() {
        return { type: this.type, data: this.data };
    }
}

export default Table;
