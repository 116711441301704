import React from 'react';
import styled from 'styled-components';
import Config from './base/config/Config';
import { redirect } from './base/Utils';
import Button from './common/components/button/Button';
import Text from './common/components/text/Text';
import fatalErrorImage from './resources/images/fatalErrorImage.png';
import * as Sentry from '@sentry/react';

var ErrorPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
`;

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error) { //, errorInfo
        Sentry.captureException(error);
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage>
                <img alt="fatalError" className="imageCenter" src={fatalErrorImage} />
                <Text align='center' size='Giant' color="Black" weight="Bold">Something went wrong</Text>
                <Text align='center' size='Bigger' color="Grey" style={{ marginTop: '24px' }}>
                    Looks like something didn&apos;t work as intended. We are working on it.<br />Meanwhile, you can go back to your <a href="/dashboard" style={{ color: '#0A5C9C' }}>Swonkie</a> dashboard or contact our support.
                </Text>
                <div style={{ display: 'flex', marginTop: '24px' }}>
                    <Button style={{ marginRight: '24px' }} text="Go to Dashboard" onClick={() => redirect(Config.host + 'dashboard', '_self')} type="Default" />
                    <Button text="Contact Support" type="Inverse" onClick={() => window.toggleChat()} />
                </div>
            </ErrorPage>;
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
