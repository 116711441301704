import React from 'react';
import { t } from '../../../base/Utils';
import i18n from '../../../i18n';
import confetti from 'canvas-confetti';

export default () => ([
    {
        disableBeacon: false,
        title: i18n.t('Let\'s start with the basics'),
        content: i18n.t('Swonkie helps you manage and improve your social media performance. But first, you need to connect your social network accounts.'),
        placement: 'center',
        target: 'body',
        nextButtonText: i18n.t('Let’s do it'),
        afterStep: () => {
            document.getElementById('navbarOptionsDropdown').click();
        }
    },
    {
        target: '[data-id="option-social-networks"]',
        title: i18n.t('Manage Your Social Media'),
        content: t(<>You can access your accounts from the Options menu, under <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>Manage Social Media</span> Give it a try.</>),
        showSkipButton: true,
        spotlightClicks: true,
        hideNextButton: true
    },
    {
        target: '[data-id="social-network-add-list"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Connect with all your social networks'),
        content: t(<>Choose which social network you want to connect. Just click <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>Add</span> to begin.</>),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideNextButton: true,
        spotlightClicks: true
    },
    {
        target: '[data-id="social-network-add-functionalities"]',
        waitForTarget: true,
        getFromLegacy: true,
        content: t(<>Before we continue, please read the following information about what the social network account can do, as well as some other important information. Click <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>I understand</span> when you are ready to continue.</>),
        showSkipButton: true,
        sleepBeforeStart: 500,
        timeoutTargetLegacy: 500,
        hideNextButton: true,
        spotlightClicks: true
    },
    {
        target: '[data-id="social-network-select-profiles"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Almost done!'),
        content: i18n.t('Now that your account is connected, select the profiles and/or pages you want to use on Swonkie.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideNextButton: true,
        spotlightClicks: true
    },
    {
        title: i18n.t('Your journey has just begun.'),
        content: i18n.t('Now that you have connected your account, you are ready to use Swonkie to its full potential.'),
        placement: 'center',
        target: 'body',
        nextButtonText: i18n.t('Finish'),
        afterStep: () => {
            confetti({
                particleCount: 250,
                spread: 100
            });
        }
    }
]);
