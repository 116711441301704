import React, { PureComponent } from 'react';
import { Line, Bar, Bubble } from 'react-chartjs-2';
import { capitalize, clone, colorApplication, formatNumberSpaces, getKBUrl, hexToRgb, isNumeric, isset, issetDot, number, redirect, removeDuplicate, t } from '../../../../base/Utils';
import PropTypes from 'prop-types';
import Text from '../../text/Text';
import './Chart.scss';
import { VectorMap } from 'react-jvectormap';
import ReactWordcloud from 'react-wordcloud';
import ProgressBar from '../../progressBar/ProgressBar';
import ToolTip from '../../toolTip/Tooltip';
import ChartLegends from '../../chartLegends/ChartLegends';
import HeatMap from 'react-heatmap-grid';
import PreventUpdate from '../../PreventUpdate';
import i18n from '../../../../i18n';
import styled from 'styled-components';
import { types } from '../Analytics';
import Config from '../../../../base/config/Config';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import AnalyticsToolTip from '../../../containers/listening/components/tooltip/Tooltip';
import ErrorBox from '../../errorBox/ErrorBox';

const ChartArea = styled.div`
    width: 100%;
    max-width: 100%;
    position: relative;
    ${props => props.parentType === 'BigScreen' ? { height: 'calc(50vh - 330px)', minHeight: '100px' } : { minHeight: '213px', height: 'auto', flex: '1' }}
`;

const Cell = styled.div`
    position: relative;
    top: -26px;
    height: 40px;
`;

const HeatMapArea = styled.div`
    position: relative;
    div {
            div {
                div {
                    font-size: 12px;
                    color: #757575;
                    div {
                        border-radius: 4px;
                        margin: 8px 8px 0px 0px !important;
                    }
                }
            }
            > div:last-child {
                > div {
                    width: 40px !important;
                    margin-right: 8px;
                    margin-top: 4px;
                }
            }
        }
`;

class Chart extends PureComponent {
    colorCounts = {};
    data = {};

    constructor(props) {
        super(props);

        this.colorCounts = {};

        this.state = {
            tooltip: this.defaultTooltip,
            annotationTooltip: {},
            parentHeight: 'auto'
        };
    }

    chartRef = React.createRef();
    chartTooltip = React.createRef();
    annotationTooltipRef = React.createRef();
    parentRef = React.createRef();

    defaultTooltip = {
        show: false,
        top: 0,
        left: 0,
        title: '',
        label: '',
        total: null,
        data: [],
    }

    toolTipModelOpacity = 0
    defaultOptions = {
        elements: {
            line: {
                tension: 0
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        title: {
            display: false
        },
        legend: {
            display: false
        },
        scales: {
            yAxes: [],
            xAxes: [
                {
                    ticks: {
                        callback: (value) => {
                            return (isNumeric(value) ? value >= 1E6 ? number(value, 1, 1E6, 1E6) : formatNumberSpaces(value) : value);
                        }
                    },
                    gridLines: {
                        display: false
                    }
                }
            ]
        },
        parsing: false,
        normalized: true,
        spanGaps: true,
        tooltips: {
            enabled: false,
            custom: (tooltipModel) => {
                var chart = this.chartRef.current;

                if (!chart) return false;

                this.toolTipModelOpacity = tooltipModel.opacity;
                if (tooltipModel.opacity === 0) {
                    window.addEventListener('mousemove', this.handleTooltipHover);
                    return;
                }

                var auxData = clone(this.defaultTooltip);
                auxData.show = true;
                auxData.label = tooltipModel.title;

                if (tooltipModel.body) {
                    tooltipModel.body.map((data) => (
                        data.lines.map((item) => {
                            var type = item.type || '';
                            item.label = i18n.t(item.label);
                            switch (type) {
                            case 'mention':
                                item.simpleData.date = (this.type !== 'bubble' || this.type !== 'heatMap') ? isset(tooltipModel.title, [])[0] : item.simpleData.date;
                                item.detailedData.date = item.simpleData.date;
                                auxData.data.push(item);
                                break;
                            case 'total':
                                if (item.value === 0) item.value = null;
                                auxData.total = item.value;
                                break;
                            case 'title':
                                auxData.title = item.value;
                                break;
                            default:
                                auxData.data.push(item);
                                break;
                            }
                            return null;
                        })
                    ));
                }

                const position = chart.chartInstance.canvas.getBoundingClientRect();

                auxData.left = position.left + tooltipModel.caretX;
                auxData.top = position.top + tooltipModel.caretY;

                auxData.data = removeDuplicate(auxData.data);

                this.setState({ tooltip: auxData });
            },
            callbacks: {
                title: (tooltipItems) => {
                    return typeof tooltipItems[0].xLabel === 'string' ? tooltipItems[0].xLabel : null;
                },
                label: (tooltipItem, data) => {
                    var auxData = [];
                    var baseDataset = data.datasets[tooltipItem.datasetIndex];
                    //for (let baseDataset of data.datasets) {
                    if (baseDataset.details) {
                        var tempData = {
                            type: 'mention',
                            simpleData: {
                                type: capitalize(isset(baseDataset.tooltipType, 'Normal')),
                                nonAdvanced: baseDataset.nonAdvanced,
                                value: this.dataValueConverter(baseDataset.data[`${tooltipItem.index}`]) + isset(baseDataset.valueType, ''),
                                label: (baseDataset.realLabel || baseDataset.label),
                                date: baseDataset.label,
                                hour: this.type === 'bubble' ? data.labels[tooltipItem.datasetIndex] : undefined
                            },
                            detailedData: {
                                value: this.dataValueConverter(baseDataset.data[`${tooltipItem.index}`]) + isset(baseDataset.valueType, ''),
                                label: (baseDataset.realLabel || baseDataset.label),
                                date: baseDataset.label,
                                hour: this.type === 'bubble' ? data.labels[tooltipItem.datasetIndex] : undefined
                            }
                        };

                        var value = 0;
                        var extraData = [];
                        data.datasets.map((dataset) => {
                            if (dataset.onDetail) {
                                value += this.dataValueConverter(dataset.data[`${tooltipItem.index}`]);
                                extraData.push({ label: dataset.label, value: this.dataValueConverter(dataset.data[`${tooltipItem.index}`]) });

                                tempData.simpleData.value = this.dataValueConverter(value);
                                tempData.simpleData.label = 'Total';
                                tempData.simpleData.extraData = extraData;
                                tempData.detailedData.value = tempData.simpleData.value;
                                tempData.detailedData.label = tempData.simpleData.label;
                            }
                        });

                        Object.entries(baseDataset.details).map((value) => {
                            var detail = value[1];
                            switch (detail.type) {
                            case 'topSentiment':
                                tempData.detailedData.value = detail.data[`${tooltipItem.index}`];
                                tempData.detailedData.label = detail.label;
                                tempData.detailedData.subLabel = detail.subDataLabel;
                                tempData.detailedData.subValue = this.dataValueConverter(detail.subData[`${tooltipItem.index}`]);
                                break;
                            case 'keywords':
                                var keywords = [];
                                detail.data[`${tooltipItem.index}`].keywordKeys.map((keywordKey, index) => {
                                    var keyword = { label: keywordKey, value: detail.data[`${tooltipItem.index}`].keywordValues[`${index}`] };
                                    if (detail.data[`${tooltipItem.index}`].keywordSentiment) {
                                        keyword.sentiment = detail.data[`${tooltipItem.index}`].keywordSentiment[`${index}`];
                                    }
                                    return keywords.push(keyword);
                                });
                                tempData.simpleData.topKeyword = isset(keywords[0], {}).label;
                                tempData.detailedData.topDataTitle = detail.label;
                                tempData.detailedData.topData = keywords;
                                break;
                            case 'lastMention':
                                tempData.detailedData.mention = detail.data[`${tooltipItem.index}`];
                                tempData.detailedData.projectId = detail.projectId;
                                break;
                            default:
                                if (value[0] === 'label') {
                                    tempData.detailedData.title = value[1];
                                }
                                break;
                            }
                        });
                        auxData.push(tempData);
                        return auxData;
                    }

                    if (isset(baseDataset.tooltips) && baseDataset.tooltips[`${tooltipItem.index}`]) {
                        Object.values(baseDataset.tooltips[`${tooltipItem.index}`]).map((tooltip) => {
                            return auxData.push({
                                label: tooltip.label,
                                type: 'value',
                                value: this.dataValueConverter(tooltip.value) + (baseDataset.valueType === 'percentage' ? '%' : ''),
                            });
                        });

                        if (auxData.length > 1) {
                            auxData.push({
                                type: 'total',
                                value: this.dataValueConverter(baseDataset.data[`${tooltipItem.index}`]) + (baseDataset.valueType === 'percentage' ? '%' : ''),
                            });

                            auxData.push({
                                type: 'title',
                                value: (baseDataset.realLabel || baseDataset.label),
                            });
                        }
                    } else if (baseDataset.stacked) {
                        var axis = baseDataset.yAxisID;
                        var total = 0;
                        var roundUp = false;

                        data.datasets.map((dataset) => {
                            if (dataset.yAxisID === axis) {
                                var value = this.dataValueConverter(dataset.data[`${tooltipItem.index}`]);
                                total += value;
                                roundUp = dataset.roundUp;

                                auxData.push({
                                    label: dataset.label,
                                    type: 'value',
                                    stacked: true,
                                    value: formatNumberSpaces(value) + isset(baseDataset.valueType, ''),
                                });
                            }

                            return null;
                        });

                        if (auxData.length > 1) {
                            auxData.push({
                                type: 'total',
                                value: formatNumberSpaces(this.dataValueConverter(roundUp ? Math.round(total) : total)) + isset(baseDataset.valueType, ''),
                            });
                        }
                    } else {
                        auxData.push({
                            label: (baseDataset.realLabel || baseDataset.label),
                            type: 'value',
                            value: formatNumberSpaces(this.dataValueConverter(baseDataset.data[`${tooltipItem.index}`])) + isset(baseDataset.valueType, ''),
                        });
                    }
                    //}
                    return auxData;
                }
            },
            mode: 'nearest'
        },
        onHover: (evt, elements) => {
            if (evt.type === 'click' && elements.length < 1) {
                this.destroyTooltip();
            }
        }
    };

    handleTooltipHover = (evt) => {
        if (this.chartTooltip.current && this.toolTipModelOpacity < 1) {
            if ((evt.clientX < this.chartTooltip.current.props.left - 20 || evt.clientX > this.chartTooltip.current.props.left + this.chartTooltip.current.ref.current.clientWidth) || (evt.clientY < this.chartTooltip.current.props.top - 20 || evt.clientY > this.chartTooltip.current.props.top + this.chartTooltip.current.ref.current.clientHeight)) {
                clearTimeout(this.tooltipTimeout);
                this.destroyTooltip();
                window.removeEventListener('mousemove', this.handleTooltipHover);
            }
        }
    }

    dataValueConverter(data) {
        if (data && typeof data === 'object' && isset(data.value)) {
            return data.value;
        }

        return isNumeric(data) ? data : 0;
    }

    defaultStack = {
        stacked: false,
        display: true,
        position: 'left',
        type: 'linear',
        gridLines: {
            drawBorder: false,
            zeroLineColor: '#EEEEEE'
        },
        ticks: {
            beginAtZero: false,
            maxTicksLimit: 6,
            fontColor: '#757575'
        }
    };

    defaultDataset = {
        pointStyle: 'circle',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        pointColor: 'transparent',
        pointHoverBackgroundColor: 'transparent',
        pointRadius: 5,
        hoverRadius: 5,
        borderWidth: 2
    };

    findMaxValue(datasets, type) {
        var maxValue = 0;
        type = isset(type, 'max');

        for (let dataset of datasets) {
            if (typeof dataset.data === 'object') dataset.data = Object.values(dataset.data);
            for (let item of dataset.data) {
                var value = isNumeric(item) ? item : 0;
                if (value > maxValue && type === 'max') {
                    maxValue = value;
                }
                if (value < maxValue && type === 'min') {
                    maxValue = value;
                }
            }
        }
        return maxValue;
    }

    findMinValue(datasets) {
        return this.findMaxValue(datasets, 'min');
    }

    generateChartData(data) {
        this.colorCounts = {};
        var finalData = {
            chart: {
                labels: data.chartLabels || [],
                datasets: []
            },
            options: this.defaultOptions,
            legends: []
        };

        if (this.props.instantRender) {
            finalData.options.animation = {
                duration: 0,
            };
            finalData.options.hover = {
                animationDuration: 0
            };
            finalData.options.responsiveAnimationDuration = 0;
            finalData.options.animation = false;
            finalData.options.showLine = false;
            finalData.options.elements = { line: { tension: 0 }, point: { radius: 0 } };
        }

        var options = data.chartOptions || { type: 'line' };
        var stacks = data.chartStacks || [{}];
        var datasets = data.chartDatasets || [{}];
        options.maxValue = options.maxValue || this.findMaxValue(datasets);
        var globalMinValue = this.findMinValue(datasets);

        if (globalMinValue > 0) {
            globalMinValue = 0;
        }

        var datasetsPos = {};
        var bubbleLabels = [];

        var valueType = '';
        for (let stack of stacks) {
            if (valueType === '') {
                switch (stack.valueType) {
                case 'percentage':
                    valueType = '%';
                    break;
                case 'currency':
                    valueType = isset(stack.currency, '');
                    break;
                default:
                    break;
                }
            } else if (stack.valueType === 'currency' && valueType !== isset(stack.currency, '')) {
                valueType = ''; break;
            }
        }

        finalData.options.scales.yAxes = Object.values(stacks).map((stack, key) => {
            stack.ticks = {
                callback: (value) => {
                    return (isNumeric(value) ? value >= 1E6 ? number(value, 1, 1E6, 1E6) : formatNumberSpaces(value) : value);
                }
            };

            stack.ticks.maxTicksLimit = 6;
            stack.ticks.fontColor = '#757575';

            stack = Object.assign(clone(this.defaultStack), stack);
            stack.id = 'stack' + key;

            datasetsPos[`${key}`] = 0;
            stack.stacked = isset(stacks.stacked, isset(options.stacked, false));

            if (isset(options.gridColor)) {
                stack.ticks.fontColor = stack.gridLines.color = options.gridColor;
            }

            stack.position = stack.axisSide || options.axisSide || this.defaultStack.position;

            if (this.props.parentType.includes('Insights')) {
                stack.position = 'right';
            }

            var minValue = stack.minValue = stack.minValue || options.minValue || globalMinValue;
            if (minValue !== null) stack.ticks.min = minValue;

            var maxValue = stack.maxValue = stack.maxValue || options.maxValue;
            maxValue = maxValue + 0.5;
            if (maxValue !== null) stack.ticks.suggestedMax = maxValue;

            delete stack.type;
            return stack;
        });


        var annotations = [];

        var colorCounts = {};
        var flags = isset(options.flags, []);
        flags.map((flag) => {
            var auxColor = colorApplication(flag.color, colorCounts, 'annotation');
            auxColor.color = auxColor.color.includes('#') ? auxColor.color : isset(Config.colors[auxColor.color], Config.colors.purple);
            flag.data = [...new Map(flag.data.map(item => [item['index'], item])).values()];
            flag.data.map((data) => {
                colorCounts = auxColor.counts;

                var defaultAnnotation = {
                    drawTime: 'afterDraw',
                    borderColor: auxColor.color,
                    borderDash: [10, 10],
                    borderWidth: 2,
                    mode: 'vertical',
                    type: 'line',
                    value: data.index,
                    scaleID: 'x-axis-0',
                    onMouseenter: (context) => {
                        var tooltip = this.state.annotationTooltip;
                        if (context.clientY <= (this.parentRef.current.getBoundingClientRect().top + 20)) {
                            tooltip = { url: data.url, details: data.details, text: data.text, value: data.index, top: this.parentRef.current.getBoundingClientRect().top + 30, left: context.clientX - (300 / 2), show: true };
                            this.setState({ annotationTooltip: tooltip });
                        }
                    },
                    label: {
                        fontFamily: 'Roboto, sans-serif',
                        backgroundColor: 'white',
                        fontSize: 20,
                        content: '⚑',
                        fontColor: auxColor.color,
                        position: 'top',
                        enabled: true,
                        xAdjust: -5,
                        yAdjust: 0,
                        xPadding: 1,
                        yPadding: 1,
                        cornerRadius: 0
                    }
                };
                if (this.type === 'line' && finalData.chart.labels.length > 2) {
                    if (defaultAnnotation.value === finalData.chart.labels.length - 1) {
                        defaultAnnotation.value -= 0.5;
                    }
                    else if (defaultAnnotation.value === 0) {
                        defaultAnnotation.value += 0.5;
                    }
                }
                else if (finalData.chart.labels.length < 2) {
                    defaultAnnotation.value = 1;
                }
                var annotationIndex = annotations.findIndex((annotation) => annotation.value === data.index);
                if (annotationIndex >= 0) {
                    defaultAnnotation.label.xAdjust = -10;
                    defaultAnnotation.value += 0.1;
                    defaultAnnotation.borderColor = 'transparent';
                    annotations[`${annotationIndex}`].label.xAdjust = 8;
                    annotations.splice(annotationIndex, 0, annotations[`${annotationIndex}`]);
                }
                annotations.push(defaultAnnotation);
                return null;
            });
            return null;
        });

        finalData.options.annotation = {
            events: ['mouseenter'],
            annotations: annotations
        };

        var axes = clone(finalData.options.scales.yAxes);
        finalData.options.scales.yAxes = axes.map((axe, key) => {
            for (var i = 0; i < key; i++) {
                if (issetDot(axe, 'ticks.min', null) === issetDot(axes, i + '.ticks.min', null)
                    && issetDot(axe, 'ticks.suggestedMax', null) === issetDot(axes, i + '.ticks.suggestedMax', null)
                    && isset(axe.position, 'left') === issetDot(axes, i + '.position', 'left')) {
                    axe.display = false; break;
                }
            }

            return axe;
        });

        finalData.chart.datasets = datasets.map((dataset) => {
            dataset = Object.assign(clone(this.defaultDataset), dataset);

            var stack = dataset.stack || 0;
            dataset.yAxisID = 'stack' + stack;
            var group = dataset.group = dataset.group || 0;

            dataset.data = Object.values(dataset.data);
            dataset.fill = isset(dataset.filled, isset(stacks[`${stack}`].filled, isset(options.filled, false)));
            dataset.type = dataset.type || stacks[`${stack}`].type || options.type || this.defaultDataset.type;
            dataset.style = dataset.style || stacks[`${stack}`].style || options.style || 'solid';
            dataset.stacked = finalData.options.scales.yAxes[`${stack}`].stacked;
            dataset.valueType = valueType;
            dataset.roundUp = isset(options.roundUp, false);

            switch (dataset.style) {
            case 'dashed':
                dataset.borderDash = [8, 6];
                break;
            case 'dotted':
                dataset.borderDash = [2, 2];
                break;
            default:
            }

            if (dataset.type !== 'bubble') {
                var auxColor = colorApplication((dataset.color || stacks[`${stack}`].color || options.color), this.colorCounts, 'chart-' + stack + '-' + group);
                this.colorCounts = auxColor.counts;
                auxColor.color = isset(auxColor.color, '').includes('#') ? auxColor.color : isset(Config.colors[auxColor.color], Config.colors.purple);
                dataset.borderColor = dataset.backgroundColor = dataset.pointHoverBackgroundColor = auxColor.color;

                if (!isset(finalData.legends[`${group}`])) {
                    finalData.legends[`${group}`] = [];
                }

                if (isset(dataset.showLegends, true))
                    finalData.legends[`${group}`].push({
                        label: dataset.label,
                        color: dataset.borderColor,
                        style: dataset.style
                    });

            } else {
                auxColor = colorApplication((dataset.color || stacks[`${stack}`].color || options.color), this.colorCounts, 'chart-' + datasetsPos[`${stack}`]);
                this.colorCounts = auxColor.counts;
                auxColor.color = auxColor.color.includes('#') ? auxColor.color : isset(Config.colors[auxColor.color], Config.colors.purple);
                dataset.borderColor = auxColor.color;
                var color = hexToRgb(auxColor.color);
                dataset.backgroundColor = 'rgba(' + color.r + ',' + color.g + ',' + color.b + ', 0.5)';

                if (isset(dataset.data)) {
                    dataset.data = dataset.data.map((value, key) => {
                        value = isNumeric(value) ? value : 0;
                        var auxValue = (36 * value) / options.maxValue;
                        if (auxValue < 8) {
                            auxValue = 8;
                        }
                        if (value === 0) {
                            auxColor = 0;
                        }

                        return { x: key, y: datasetsPos[`${stack}`], r: (Math.ceil(auxValue) / 2), value: value };
                    });
                }
                dataset.borderWidth = 1;

                bubbleLabels.push(dataset.label);

                if (stacks.length > 1) {
                    dataset.realLabel = stacks[`${stack}`].label;
                }

                datasetsPos[`${stack}`]++;
            }

            if (dataset.type === 'line' && dataset.data.length < 2) {
                dataset.pointBackgroundColor = auxColor.color;
                dataset.pointBorderColor = auxColor.color;
                dataset.pointColor = auxColor.color;
                dataset.pointHoverBackgroundColor = auxColor.color;
            }
            if (!dataset.stacked) {
                delete dataset.stack;
            }

            if (dataset.fill && this.type === 'line') {
                dataset.borderColor = Config.colors.white;
            }

            return dataset;
        });

        //Applying bubble options
        if (datasetsPos[0] > 0) {
            finalData.options.layout = {
                padding: {
                    top: 20,
                    right: 20
                }
            };

            var defaultTicks = {
                padding: 20,
                stepSize: 1,
                autoSkip: false,
                min: 0
            };

            finalData.legends[0] = [];

            finalData.options.scales.yAxes = finalData.options.scales.yAxes.map((stack, key) => {
                stack.ticks.max = datasetsPos[`${key}`] - 1;
                stack.ticks.callback = (value) => {
                    return t(isset(bubbleLabels[isset(value, 0).toString().trim()], ''));
                };

                var auxColor = colorApplication((stack.color || options.color), this.colorCounts, 'bubbleLegends');
                auxColor.color = auxColor.color.includes('#') ? auxColor.color : isset(Config.colors[auxColor.color], Config.colors.purple);
                this.colorCounts = auxColor.counts;

                finalData.legends[0].push({
                    label: stack.label || '',
                    color: auxColor.color,
                    style: stack.stack || 'solid'
                });

                delete stack.ticks.maxTicksLimit;
                Object.assign(stack.ticks, defaultTicks);

                return stack;
            });

            Object.assign(finalData.options.scales.xAxes[0].ticks, defaultTicks);
            finalData.options.scales.xAxes[0].ticks.max = finalData.chart.labels.length - 1;
            finalData.options.scales.xAxes[0].ticks.callback = (value) => {
                return finalData.chart.labels[`${value}`];
            };
            delete finalData.options.scales.xAxes[0].ticks.maxTicksLimit;
            finalData.options.scales.xAxes[0].gridLines.display = true;
        }

        finalData.chart.labels = finalData.chart.labels.map((label) => {
            return t(label);
        });

        finalData.chart.datasets = this.arrangeDatasets(finalData.chart.datasets);

        return finalData;
    }

    arrangeDatasets(axes) {
        var auxAxes = [];
        var auxAxesBar = [];

        for (let axe of axes) {
            var type = isset(axe.type, 'line');

            if (type !== 'bar') {
                auxAxes.push(axe);
            } else {
                auxAxesBar.push(axe);
            }
        }

        return auxAxes.concat(auxAxesBar);
    }

    mergeDatasets(datasets) {
        datasets = datasets || [];
        var mergedDatasets = [];

        datasets.map((dataset) => {
            dataset.data.map((data, index) => {
                if (!isset(mergedDatasets[`${index}`])) {
                    mergedDatasets[`${index}`] = 0;
                }
                mergedDatasets[`${index}`] += parseFloat(data);
                return null;
            });
            return null;
        });

        return mergedDatasets;
    }

    openTooltip(e, value, label, title, single, useMousePos) {
        single = (isset(single) ? single : false);
        useMousePos = (isset(useMousePos) ? useMousePos : false);

        var rect = (!useMousePos ? e.target.getBoundingClientRect() : null);

        var tpData = [];

        var index = this.data.chartLabels.indexOf(label);
        this.data.chartDatasets.map((dataset) => {
            if (dataset.details) {
                var tempData = {
                    type: 'mention',
                    simpleData: {
                        date: dataset.label,
                        hour: label,
                        value: this.dataValueConverter(dataset.data[`${index}`]) + (dataset.valueType === 'percentage' ? '%' : ''),
                        label: 'Total',
                    },
                    detailedData: {
                        value: this.dataValueConverter(dataset.data[`${index}`]) + (dataset.valueType === 'percentage' ? '%' : ''),
                        label: i18n.t(dataset.label) + ' ' + label.replace('H', ':00'),
                    }
                };
                Object.entries(dataset.details).map((value) => {
                    var detail = value[1];
                    switch (detail.type) {
                    case 'keywords':
                        var keywords = [];
                        detail.data[`${index}`].keywordKeys.map((keywordKey, id) => {
                            return keywords.push({ label: keywordKey, value: detail.data[`${index}`].keywordValues[`${id}`] });
                        });
                        tempData.simpleData.topKeyword = isset(keywords[0], {}).label;
                        tempData.detailedData.topDataTitle = detail.label;
                        tempData.detailedData.topData = keywords;
                        break;
                    case 'lastMention':
                        tempData.detailedData.mention = detail.data[`${index}`];
                        tempData.detailedData.projectId = detail.projectId;
                        break;
                    default:
                        if (value[0] === 'label') {
                            tempData.detailedData.title = value[1];
                        }
                        break;
                    }
                });
                tpData.push(tempData);
            }
            if (isset(dataset.label, '') === title && isset(dataset.tooltips)) {
                dataset.tooltips[`${index}`].map((tooltip) => {
                    return tpData.push({
                        label: tooltip.label,
                        type: 'value',
                        value: tooltip.value,
                    });
                });
            }
            return null;
        });

        if (tpData.length === 0 && single) {
            if (this.data.chartDatasets.length > 1) {
                this.data.chartDatasets.map((dataset) => {
                    return tpData.push({
                        label: dataset.label,
                        type: 'value',
                        value: dataset.data[`${index}`],
                    });
                });
            }

            if (tpData.length === 0) {
                tpData.push({
                    label: (title || label),
                    type: 'value',
                    value: value,
                });
                title = '';
                label = '';
                value = null;
            }

            if (tpData.length > 1) {
                label = '';
            }
        }

        var tooltip = {
            show: true,
            top: (!useMousePos ? rect.top : e.pageY),
            left: (!useMousePos ? rect.right : e.pageX),
            title: title,
            label: label,
            total: value,
            data: tpData
        };
        for (var tempData of tpData) {
            if (issetDot(tempData, 'detailedData.label', '').includes(title)) {
                tooltip.data = [tempData];
                delete tooltip.title;
                delete tooltip.label;
                delete tooltip.total;
                break;
            }
        }

        this.setState({ tooltip: tooltip });
    }

    destroyTooltip() {
        this.setState({ tooltip: this.defaultTooltip });
    }

    mapTooltip = null;

    componentDidMount() {
        if (this.type === 'worldMap') {
            var el = document.getElementsByClassName('jvectormap-tip');
            el = el[el.length - 1];
            this.mapTooltip = el;

            var chart = this.chartRef.current;

            if (chart) {
                var labels = this.data.chartLabels || null;

                for (let label of labels) {
                    el = chart.$node[0].querySelector('svg g path[data-code="' + label + '"]');

                    if (el) {
                        el = el.parentElement.querySelector('path');
                        el.style.fill = this.getColorForLabelDataset(label);
                    }
                }
            }
        }

        if (this.parentRef.current) {
            this.setState({ parentHeight: this.parentRef.current.offsetHeight + 'px' });
        }
    }

    componentWillUnmount() {
        if (this.mapTooltip) {
            this.mapTooltip.remove();
        }
    }

    getBiggerLabelDataset(datasets, label) {
        var index = this.data.chartLabels.indexOf(label);
        var color = null;
        var biggerValue = 0;

        for (let dataset of datasets) {
            var data = dataset.data || [];
            var value = data[`${index}`] || 0;

            if (biggerValue < value) {
                color = dataset.color || null;
                biggerValue = value;
            }
        }

        return { color: color, value: biggerValue };
    }

    getColorForLabelDataset(label, minOpacity = 0.1) {
        var datasets = this.data.chartDatasets || [];
        var data = this.getBiggerLabelDataset(datasets, label);
        var maxValue = this.data.chartOptions.maxValue || this.findMaxValue(datasets);

        var color = data.color || this.data.chartOptions.color || null;
        color = hexToRgb(isset(Config.colors[colorApplication(color, {}).color], Config.colors.purple));

        var opacity = (data.value / maxValue);
        opacity = (opacity < minOpacity ? minOpacity : opacity);

        color = 'rgba(' + color.r + ',' + color.g + ',' + color.b + ',' + opacity + ')';

        return color;
    }

    renderTooltip() {
        var tooltip = this.state.annotationTooltip;
        var text = '';
        switch (issetDot(tooltip, 'details.type', '')) {
        case 'projectsHistory':
            text = t(<>Changes to the project made on this day. <span onClick={() => this.props.onChartsCallback(tooltip.value)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>See more</span></>);
            break;
        default:
            text = <>{i18n.t(tooltip.text)} {tooltip.url ? <span onClick={() => redirect(getKBUrl(tooltip.url, true), '_blank')} style={{ textDecoration: 'underline', cursor: 'pointer' }}>{i18n.t('See More')}</span> : ''}</>;
            break;
        }
        return tooltip.show ? <ToolTip align='center' type='Secondary' top={tooltip.top} left={tooltip.left} ref={this.annotationTooltipRef} >
            <Text align='center' color='White' size='Small'>{text}</Text>
        </ToolTip> : null;
    }

    handleMouseMovement(e) {
        var tooltip = this.state.annotationTooltip;
        if (tooltip.show && ((e.clientX < tooltip.left || e.clientX > tooltip.left + (this.annotationTooltipRef.current ? this.annotationTooltipRef.current.ref.current.clientWidth : 0)) || e.clientY <= this.parentRef.current.getBoundingClientRect().top || e.clientY > tooltip.top + (this.annotationTooltipRef.current ? this.annotationTooltipRef.current.ref.current.clientHeight : 0))) {
            this.setState({ annotationTooltip: { ...tooltip, show: false } });
        }
    }

    render() {
        this.data = this.props.data;
        this.type = this.props.type || this.data.chartOptions.type || 'line';
        var parentType = this.props.parentType;
        var isExporting = this.props.isExporting;
        var legendsStyle = {};

        if (parentType === 'Analytics') {
            legendsStyle['marginBottom'] = '24px';
        }

        const finalData = {};

        var getChartLabels = clone(this.data.chartLabels || []);

        var tooltip = this.state.tooltip;
        var toolTip = (tooltip.show ? issetDot(tooltip.data[0], 'simpleData') ? <AnalyticsToolTip tooltipRef={this.chartTooltip} onMouseLeave={() => this.destroyTooltip()} onClose={() => this.destroyTooltip()} top={tooltip.top} left={tooltip.left} data={tooltip.data} /> :
            <ToolTip data={tooltip.data}
                title={tooltip.title}
                label={tooltip.label}
                top={tooltip.top}
                total={this.data.chartOptions.noTotal ? undefined : tooltip.total}
                left={tooltip.left}
                ref={this.chartTooltip}
                onMouseLeave={() => this.destroyTooltip()}
            /> : null);

        switch (this.type) {
        case 'worldMap': {
            const regionStyle = {
                initial: {
                    fill: '#EEEEEE',
                    'fill-opacity': 1,
                    stroke: '#BDBDBD',
                    'stroke-width': 0.3,
                    'stroke-opacity': 1,
                },
                hover: {
                    'fill-opacity': 0.8,
                    cursor: 'pointer',
                },
            };

            var dataset = this.mergeDatasets(this.data.chartDatasets);

            for (let index = 0; index < dataset.length; ++index) {
                finalData[getChartLabels[`${index}`]] = dataset[`${index}`];
            }

            const mapSeries = {
                regions: [
                    {
                        values: finalData,
                        scale: ['#EEEEEE'],
                        normalizeFunction: 'polynomial'
                    }
                ],
            };

            return (<div style={{ minHeight: '500px', height: '500px', position: 'relative' }}>
                {isset(this.data.empty) && <ErrorBox data={this.data.empty} type={this.props.type} />}
                <PreventUpdate data={mapSeries}>
                    <VectorMap map={'world_mill'}
                        backgroundColor="white"
                        zoomOnScroll={false}
                        ref={this.chartRef}
                        containerStyle={{ width: '100%', height: '100%' }}
                        containerClassName="map"
                        regionStyle={regionStyle}
                        series={mapSeries}
                        onRegionTipShow={(event, _, code) => {
                            var value = (finalData[`${code}`] || 0);

                            var el = this.mapTooltip;
                            event.pageY = parseInt(el.style.top.slice(0, -2)) + 50;
                            event.pageX = parseInt(el.style.left.slice(0, -2)) + 20;

                            if (value && event.pageY && event.pageX) {
                                this.openTooltip(event, value, code, el.textContent, true, true);
                            }
                        }}
                        onRegionOut={() => this.destroyTooltip()}
                    />
                </PreventUpdate>
                {toolTip}
            </div>);
        }
        case 'wordCloud': {
            const options = {
                enableTooltip: false,
                rotations: 0,
                rotationAngles: [0, 0],
                fontFamily: 'Roboto',
                fontSizes: [16, 48],
                padding: 1,
            };

            dataset = this.mergeDatasets(this.data.chartDatasets);
            var words = getChartLabels.map((text, index) => ({ text: text, value: dataset[`${index}`] }));

            return (<>
                <PreventUpdate data={words}>
                    <ReactWordcloud
                        words={words}
                        callbacks={{
                            onWordMouseOver: (word, event) => this.openTooltip(event, word.value, word.text, '', true),
                            onWordMouseOut: () => this.destroyTooltip(),
                            getWordColor: (word) => this.getColorForLabelDataset(word.text),
                        }}
                        options={options}
                    />
                </PreventUpdate>
                {toolTip}
            </>);
        }
        case 'heatMap': {
            var chartData = this.data;

            const xLabels = chartData.chartLabels;

            var yLabels = [];
            chartData.chartDatasets.map((dataset) => {
                return yLabels.push(i18n.t(dataset.label.substring(0, 3)));
            });

            var data = [];
            chartData.chartDatasets.map((dataset) => {
                return data.push(dataset.data);
            });

            return (<div onMouseLeave={() => this.destroyTooltip()}>
                <HeatMapArea>
                    {isset(this.data.empty) && <ErrorBox data={this.data.empty} type={this.props.type} />}
                    <HeatMap
                        xLabels={xLabels}
                        yLabels={yLabels}
                        xLabelsLocation={'bottom'}
                        data={data}
                        squares
                        height={40}
                        // onClick={(x, y) => alert(`Clicked ${x}, ${y}`)}
                        cellStyle={(background, value, min, max) => ({
                            background: `rgb(117, 78, 208, ${1 - (max - value) / (max - min)})`,
                            fontSize: '12px',
                            color: '#757575'
                        })}
                        cellRender={(value, label, title) => <Cell onMouseMove={(e) => this.openTooltip(e, value, label, title)}></Cell>}
                    />
                </HeatMapArea>
                <ChartLegends parentType={parentType} type="Heatmap" data={data} />
                {toolTip}
            </div>);
        }
        case 'line': {
            data = this.generateChartData(this.data);

            var labels = data.chart.labels;
            var datasets = data.chart.datasets;

            if (labels.length === 1) {
                var auxLabels = ['', ''];
                auxLabels.splice(1, 0, labels[0]);
                data.chart.labels = auxLabels;
            }
            for (var id in datasets) {
                var datasetData = datasets[`${id}`].data;
                if (datasetData.length === 1) {
                    var auxData = [null, null];

                    auxData.splice(1, 0, datasetData[0]);
                    data.chart.datasets[`${id}`].data = auxData;
                }
            }

            return (<PreventUpdate data={{ data, toolTip: this.state.tooltip, parentHeight: this.state.parentHeight, annotationTooltip: this.state.annotationTooltip }} > <ChartArea style={parentType !== 'BigScreen' ? { maxHeight: this.state.parentHeight } : {}} onMouseLeave={() => this.setState({ annotationTooltip: { ...this.state.annotationTooltip, show: false } })} onMouseMove={(e) => this.handleMouseMovement(e)} ref={this.parentRef} parentType={parentType}>
                {isset(this.data.empty) && <ErrorBox data={this.data.empty} type={this.props.type} />}
                <Line data={data.chart} options={data.options} ref={this.chartRef} plugins={ChartAnnotation} />
                {toolTip}
                {this.renderTooltip()}
            </ChartArea>
            <ChartLegends style={legendsStyle} parentType={parentType} data={data.legends} />
            </PreventUpdate>);
        }
        case 'bar': {
            data = this.generateChartData(this.data);
            return (<PreventUpdate data={{ data, toolTip: this.state.tooltip, parentHeight: this.state.parentHeight, annotationTooltip: this.state.annotationTooltip }}><ChartArea style={parentType !== 'BigScreen' ? { maxHeight: this.state.parentHeight } : {}} onMouseLeave={() => this.setState({ annotationTooltip: { ...this.state.annotationTooltip, show: false } })} onMouseMove={(e) => this.handleMouseMovement(e)} ref={this.parentRef} parentType={parentType}>
                {isset(this.data.empty) && <ErrorBox data={this.data.empty} type={this.props.type} />}
                <Bar data={data.chart} options={data.options} ref={this.chartRef} plugins={ChartAnnotation} />
                {toolTip}
                {this.renderTooltip()}
            </ChartArea>
            <ChartLegends style={legendsStyle} parentType={parentType} data={data.legends} />
            </PreventUpdate>);
        }
        case 'bubble': {
            data = this.generateChartData(this.data);
            datasets = isset(this.data.chartDatasets, []);

            var min = this.findMinValue(datasets);
            var max = this.findMaxValue(datasets);

            var bubbleLegends = [[
                {
                    label: '> ' + max,
                    style: 'solid',
                    size: '36px',
                },
                {
                    label: '> ' + Math.round(((max - min) * 0.66) + min),
                    style: 'solid',
                    size: '26px',
                },
                {
                    label: '> ' + Math.round(((max - min) * 0.33) + min),
                    style: 'solid',
                    size: '17px',
                },
                {
                    label: '> ' + min,
                    style: 'solid',
                    size: '8px',
                }
            ]];

            return (<><ChartArea style={parentType !== 'BigScreen' ? { maxHeight: this.state.parentHeight } : {}} parentType={parentType}>
                {isset(this.data.empty) && <ErrorBox data={this.data.empty} type={this.props.type} />}
                <Bubble data={data.chart} options={data.options} ref={this.chartRef} />
                {toolTip}
            </ChartArea>
            <ChartLegends style={legendsStyle} parentType={parentType} data={data.legends} />
            <ChartLegends style={legendsStyle} parentType={parentType} data={bubbleLegends} type="Bubble" />
            </>);
        }
        case 'horizontalBar': {
            var isThick = (this.data.chartOptions.horizontalBarType === 'thick');
            if (parentType === 'BigScreen') {
                for (let chartDataset of this.data.chartDatasets) {
                    data = chartDataset;
                    data.color = 'azureBlue';
                }
            }
            return (<ProgressBar
                background={parentType === 'BigScreen' ? 'bigscreengrey' : isThick ? 'White' : 'alto'}
                data={this.data.chartDatasets}
                color={this.data.chartOptions.color}
                square={isThick ? true : false}
                valueType={this.data.chartOptions.valueType}
                showText={parentType === 'BigScreen' ? false : this.data.chartOptions.showValue}
                total={this.data.chartOptions.maxValue}
                width={(parentType.includes('Analytics') && isExporting) ? '50px' : !parentType.includes('Analytics') ? '100%' : isThick ? '100%' : '78px'}
                type={!parentType.includes('Analytics') ? 'Normal' : isThick ? 'Big' : 'Tiny'}
            />);
        }
        default:
            return null;
        }
    }
}

Chart.propTypes = {
    /**
     * Type of the chart
     */
    type: PropTypes.oneOf(['line', 'bar', 'horizontalBar', 'wordCloud', 'worldMap', 'bubble', 'heatMap']),
    /**
     *  Charts data
     */
    data: PropTypes.object,
    /**
     * Type of parent
     */
    parentType: PropTypes.oneOf(types)
};

Chart.defaultProps = {};

export default Chart;
