import React, { PureComponent } from 'react';
import { capitalize, colorApplication, isset, issetDot, redirect, t } from '../../../base/Utils';
import Chart from '../analytics/chart/Chart';
import DescriptiveItems from '../analytics/descriptiveItems/DescriptiveItems';
import Button from '../button/Button';
import Feed from '../feed/Feed';
import Icon from '../icon/Icon';
import Input, { InputWrapper, InputSide } from '../input/Input';
import Label from '../label/Label';
import Note, { AddNote } from '../note/Note';
import Popup from '../popup/Popup';
import Select from '../select/Select';
import Table from '../table/Table';
import Text from '../text/Text';
import Toggle from '../toggle/Toggle';
import Tabs from '../../components/tabs/Tabs';
import i18n from '../../../i18n';
import ChartLegends from '../chartLegends/ChartLegends';
import ErrorBox from '../errorBox/ErrorBox';
import styled from 'styled-components';
import FullPage, { PageContent, PagePriority } from '../fullPage/FullPage';
import Analytics, { types } from '../analytics/Analytics';
import PropTypes from 'prop-types';
import NoteRequests from '../note/NoteRequest';
import ObjectivesRequest from './ObjectivesRequest';
import Spinner from '../spinner/Spinner';
import Status from '../../containers/analytics/components/status/Status';
import DataDetails from '../dataDetails/DataDetails';
import { exportComponentAsPNG } from 'react-component-export-image';
import Checkbox from '../checkbox/Checkbox';
import FilterLabel from '../../containers/listening/components/filterLabel/FilterLabel';
import Config from '../../../base/config/Config';

var BoxContainerArea = styled.div`
    width: 100%;
    border-spacing: 16px;
    position: relative;
    ${props => props.type.includes('Analytics') && { height: '100%' }}
    ${props => props.type === 'BigScreen' && { overflow: 'hidden' }}
    ${props => props.overflow && { overflow: 'auto' }}
`;

export class BoxContainer extends PureComponent {
    render() {
        var { children, type, ...props } = this.props;
        return (
            <BoxContainerArea type={type} {...props} >
                {children}
            </BoxContainerArea>
        );
    }
}

var disable = false;

var BoxArea = styled.div`
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: ${props => props.bigScreenFeed ? 'calc(100vh - 126px) !important' : '100%'};
    vertical-align: top;
    padding-right: ${props => props.type === 'Dashboard' && '16px'};
`;

var BoxTopContentArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${props => props.type === 'BigScreen' ? '24px' : '16px 24px'};
    ${props => props.type === 'BigScreen' && { backgroundColor: 'rgba(255, 255, 255, 0.09)', marginBottom: '16px', borderRadius: '4px' }};
`;

var BoxNotes = styled.div`
    width: 336px;
    border-left: 1px solid #eee;
    height: 100%;
    margin-left: 24px;
    box-sizing: border-box;
    padding: 24px 8px 24px 24px;
`;
class Box extends PureComponent {
    id = '';
    filters = {};
    projectId = '';
    colorCounts = {};

    constructor(props) {
        super(props);
        this.componentRef = React.createRef();
        this.state = {
            showObjectivePopup: false,
            boxId: '',
            objective: 0,
            isError: '',
            clickedOnNotes: false,
            data: {},
            averageToggle: false,
            showDataDetails: false,
        };
        if (isset(props.instantRender, false)) {
            this.state.data = props.data;
            this.state.objective = issetDot(this.props.data, 'objective.value', '0');
            this.state.averageToggle = issetDot(this.props.data, 'average', false);
        }
    }

    filters = {};
    componentDidMount() {
        if (!isset(this.props.instantRender, false)) {
            setTimeout(() => this.setState({ objective: issetDot(this.props.data, 'objective.value', '0'), data: this.props.data, averageToggle: issetDot(this.props.data, 'average', false) }), 1);
        }
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({ data: this.props.data, averageToggle: issetDot(this.props.data, 'average', false), objective: issetDot(this.props.data, 'objective.value', '0') });
        }
    }

    handleShowObjectivePopup(boxId) {
        this.setState({ showObjectivePopup: true, boxId: boxId });
    }

    handleConfirmButtonObjectivePopup() {
        var area = this.props.area || '';
        if (area === 'analytics' || area === 'ads' || area === 'competitors') area = 'insights';

        if (this.state.objective !== '0' && this.state.objective !== '') {
            var params = { 'referenceIds': [this.state.boxId], value: this.state.objective.toString() };

            ObjectivesRequest.postObjective(params, area, (responseData) => {
                if (isset(responseData.status, '') === 'ok') {
                    this.onFilters('requestObjective', this.state.objective);
                    this.setState({ showObjectivePopup: false, isError: false });
                }
            });
        }
        else {
            params = {
                'referenceIds': [
                    this.state.boxId
                ]
            };
            ObjectivesRequest.deleteObjective(params, area, (responseData) => {
                if (isset(responseData.status, '') === 'ok') {
                    this.onFilters('requestObjective', this.state.objective);
                    this.setState({ showObjectivePopup: false, isError: false });
                }
            });
        }
    }

    handleCloseObjectivePopup() {
        this.setState({ showObjectivePopup: false });
    }

    addFilters(data) {
        for (let item of data) {
            if (item) {
                this.filters[item.id] = isset(item.default, '');
            }
        }
    }

    onFilters(id, value, removeFilterId) {
        this.addFilters([{ id: id, default: value }]);
        if (removeFilterId)
            delete this.filters[`${removeFilterId}`];
        var callback = this.props.onFiltersCallback || function () { };
        for (var filter of Object.keys(this.filters)) {
            if (typeof this.filters[`${filter}`] === 'object' && !Array.isArray(this.filters[`${filter}`])) {
                this.filters[`${filter}`] = JSON.stringify(this.filters[`${filter}`]);
            }
        }
        callback(this.id, this.filters);
    }

    processOptions(data) {
        if (data === null) {
            return '';
        }

        if (!Array.isArray(data)) {
            return data.value;
        } else {
            return data.map((options) => (
                options.value
            )).join(',');
        }
    }

    handlePagination(pagination, id) {
        Object.keys(pagination).map((key, index) => {
            if (id) {
                this.filters[`${id}`][`${key}`] = pagination[`${key}`];
                if (parseInt(index) === Object.keys(pagination).length - 1) {
                    this.onFilters(id, this.filters[`${id}`]);
                }
            }
            else {
                if (parseInt(index) === Object.keys(pagination).length - 1) {
                    this.onFilters(key, pagination[`${key}`]);
                }
                else
                    this.addFilters([{ id: key, default: pagination[`${key}`] }]);
            }
            return null;
        });
    }

    generateFilters(data, loading) {
        data = Object.values(data);
        var items = data.map((item, key) => {
            if (item) {
                switch (item.type) {
                case 'dropdown':
                    var defaultValues = [];
                    var values = item.values;

                    item.values = item.values.map((value) => {
                        value.label = t(value.text);
                        return value;
                    });

                    if (typeof item.default === 'string') {
                        var splittedItems = item.default.split(',') || item.default;
                        for (let splittedItem of splittedItems) {
                            defaultValues.push(item.values.find(x => x.value === splittedItem));
                        }
                    }
                    if (Array.isArray(item.default)) {
                        defaultValues = [];
                        item.default.map((defaultItem) => {
                            defaultValues.push(item.values.find(o => o.value === defaultItem));
                            return null;
                        });
                    }

                    if (item.selectAll) {
                        var selectAllItem = { label: i18n.t('Select all'), value: 'selectAll' };
                        if (defaultValues.length === item.values.length) {
                            selectAllItem.checked = true;
                        } else if (defaultValues.length >= 1) {
                            selectAllItem.checked = 0;
                        }

                        values.unshift(selectAllItem);
                    } else if (isset(item.maxCount) && defaultValues.length >= item.maxCount) {
                        for (let value of values) {
                            var index = defaultValues.findIndex((defaultValue) => defaultValue.value === value.value);
                            if (index < 0) {
                                value.isDisabled = true;
                                value.checked = false;
                            }
                        }
                    }

                    var type = 'Normal';
                    if (isset(item.multipleChoice, false)) {
                        type = 'Multiple';
                        if (isset(item.multipleType) && item.multipleType === 'labels') {
                            type = 'MultipleLabels';
                        }
                    }

                    return <div tooltip={t(item.tooltip)} tooltip-position='center' style={{ display: 'inline-block', verticalAlign: 'middle', maxWidth: '250px', margin: ' 0px 8px', width: '100%' }}>
                        <Select showPlaceholderText={item.showPlaceholderText && isset(item.placeholder)} multiPlaceholderText={item.placeholder} key={key} type={type} isClearable={isset(item.cleanable, true)} options={values} onChange={(option) => this.onFilters(item.id, this.processOptions(option), item.removeFilterId)} value={defaultValues.length > 0 ? defaultValues : item.values.find(o => o.value === item.default)} defaultValue={defaultValues.length > 0 ? defaultValues : item.values.find(o => o.value === item.default)} placeholder={i18n.t(item.placeholder) || null} />
                    </div>;
                case 'switch':
                    var leftOption = { label: item.values[0].text, value: item.values[0].value };
                    var rightOption = { label: item.values[1].text, value: item.values[1].value };
                    return <div tooltip={t(item.tooltip)} tooltip-position='center' style={{ margin: '0px 8px', display: 'inline-block' }}>
                        <Toggle key={key} leftOption={leftOption} rightOption={rightOption} defaultVal={item.default} onChange={(option) => this.onFilters(item.id, option.value, item.removeFilterId)} />
                    </div>;
                case 'tab':
                    values = [];
                    item.values.map((value) => {
                        values.push({ label: value.text, value: value.value });
                        return null;
                    });
                    return <div tooltip={t(item.tooltip)} tooltip-position='center' style={{ margin: '0px 8px', float: 'right' }}>
                        <Tabs data={values} key={key} active={item.default} onChange={(value) => this.onFilters(item.id, value, item.removeFilterId)}></Tabs>
                    </div>;
                case 'iconSwitch':
                    var icon = item.values[0].icon;
                    var value = '';
                    var text = '';
                    for (let tempValue of item.values) {
                        if (tempValue.value === item.default) {
                            icon = tempValue.icon;
                            text = tempValue.text;
                        }
                        else {
                            value = tempValue.value;
                        }
                    }
                    return <div style={{ cursor: 'pointer', margin: '0px 8px', display: 'inline-block', verticalAlign: 'middle' }}><Icon tooltip={t(text)} name={icon} onClick={() => this.onFilters(item.id, value, item.removeFilterId)} size="Big" color="Black" /></div>;
                case 'checkbox':
                    return <div style={{ margin: '0px 8px', display: 'inline-flex', alignItems: 'center' }}>
                        <Checkbox isChecked={item.default} onCallback={(value) => this.onFilters(item.id, value, item.removeFilterId)} />
                        <Text style={{ marginLeft: '8px' }} >{i18n.t(item.label)}</Text>
                    </div>;
                case 'button':
                    return <div tooltip={t(item.tooltip)} tooltip-position='center' style={{ margin: '0px 8px', display: 'inline-flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}><Button icon={item.icon} type='Secondary' text={i18n.t(item.label)} onClick={() => this.onFilters(item.id, true, item.removeFilterId)} /></div>;
                default:
                    break;
                }
            }
            return '';
        });

        return <div data-disable={loading} style={{ paddingBottom: '24px' }}>{items}</div>;
    }

    handleTableOnclick(item, id) {
        if (Array.isArray(item.urls)) {
            redirect(item.urls[`${id}`], '_blank');
        }
    }

    generateItems(item, comparison, key, loading) {
        var type = isset(item.type, '');

        switch (type) {
        case 'descriptiveItems':
            return <div style={{ marginBottom: this.props.type.includes('Analytics') ? '42px' : '0px', display: 'flex' }}>
                <DescriptiveItems isExporting={isset(this.props.isExporting, this.props.type.includes('Export'))} formatNumber={this.props.formatNumber} descriptiveTitleVisible={this.props.descriptiveTitleVisible} data={item} area={this.props.area} type={this.props.type} />
            </div>;
        case 'chart':
            var filters = null;
            var options = isset(item.chartOptions, {});
            var filtersData = isset(options.filters, {});

            if (isset(options.filters) && (!this.props.noFilters && !this.state.removeFilter)) {
                this.addFilters(filtersData);
                filters = this.generateFilters(filtersData, loading);
            }

            if (this.props.type === 'BigScreen') {
                options.gridColor = '#EEEEEE';
            }

            item.chartOptions = options;

            return <div style={{ overflow: this.props.type.includes('Export') && 'hidden', marginTop: this.props.type.includes('Summary') ? '24px' : '0px', height: '100%', display: 'flex', flexDirection: 'column', boxSizing: 'border-box' }}>
                {filters}{loading ? <Spinner size='Big' /> : <Chart data={item} parentType={this.props.type} type={isset(options.type, '')} instantRender={this.props.instantRender} onFiltersCallback={this.onFilters} onChartsCallback={(item) => this.props.onChartsCallback(item)} />}
            </div>;
        case 'feed':
            var labels = this.props.labels;
            return <div style={{ position: 'relative', height: '100%', overflow: comparison ? 'visible' : 'auto' }}>
                {isset(item.empty) && <ErrorBox data={item.empty} type={this.props.type} />}
                <Feed isExporting={this.props.type.includes('Export')} comparison={comparison} postsAlign={comparison ? (key < 1) ? 'right' : 'left' : 'center'} seeDetails={this.props.seeDetails} postSize={this.props.postSize} data={item} labels={labels} noFilters={this.props.noFilters} area={this.props.area} type={this.props.type} projectId={this.projectId} />
            </div>;
        case 'table':
            var tableFilters = [];
            var tempObject = {};
            if (item.orderBy) {
                this.addFilters([{ id: 'orderBy', default: item.orderBy }]);
            }
            if (item.sortedBy) {
                this.addFilters([{ id: 'sortedBy', default: item.sortedBy }]);
            }
            if (issetDot(item.pagination, 'current')) {
                tempObject['page'] = item.pagination.current;
                tableFilters.push({ id: 'page', default: item.pagination.current });
            }
            if (item.rowsPerPage) {
                tempObject['rowsPerPage'] = item.rowsPerPage;
                tableFilters.push({ id: 'rowsPerPage', default: item.rowsPerPage });
            }
            var rowsPerPageOptions = [];
            if (item.rowsPerPageOptions) {
                for (var option of item.rowsPerPageOptions) {
                    rowsPerPageOptions.push({ value: option, label: option });
                }
            }
            if (item.id) {
                this.addFilters([{ id: item.id, default: tempObject }]);
            }
            else {
                this.addFilters(tableFilters);
            }
            var style = {};
            if (item.spacing) {
                if (item.spacing === 'bottom')
                    style.marginBottom = '45px';
                else if (item.spacing === 'top')
                    style.marginTop = '45px';
            }

            return <div style={{ ...style, position: 'relative', overflow: 'visible', display: 'flex', flexDirection: 'column' }}>
                {isset(item.empty) && <ErrorBox data={item.empty} type={this.props.type} />}
                <Table isExporting={this.props.type.includes('Export')} fixTable={this.props.fixTable} sortType='request' noFilters={this.props.noFilters} rowsPerPageOptions={rowsPerPageOptions} defaultRowsPerPage={item.rowsPerPage} pagination={(!this.props.noFilters && !this.props.type.includes('Export')) ? item.pagination : null} orderBy={!this.props.noFilters ? item.orderBy : null} sortedBy={!this.props.noFilters ? item.sortedBy : null} onPaginationCallback={(pagination, notTableFilter) => this.handlePagination(pagination, !notTableFilter ? item.id : null)} handleChildRow={(item.urls && !this.props.noFilters) ? (id) => this.handleTableOnclick(item, id) : undefined} area={this.props.area} data={item} type={this.props.type} />
            </div>;
        case 'filters':
            filters = null;

            if (isset(item.filters) && (!this.props.noFilters && !this.state.removeFilter)) {
                this.addFilters(item.filters);

                filters = this.generateFilters(item.filters, loading);
            }
            return <>{filters}</>;
        case 'group':
            var data = isset(item.items, []);

            if (data.length > 1) {
                var items = item.items;
                comparison = item.comparison;
                return <table style={{ width: '100%', tableLayout: 'fixed' }}>
                    <tr>
                        {item.items.map((item, key) =>
                            <>
                                <td style={{ verticalAlign: 'top' }} key={key}>
                                    {this.generateItems(item, comparison, key)}
                                </td>
                                {parseInt(key) + 1 !== items.length && <td style={{ width: item.type === 'descriptiveItems' ? '8px' : '24px' }}></td>}
                            </>
                        )}
                    </tr>
                </table>;
            } else {
                return this.generateItems(isset(data[0], false, 0));
            }
        case 'chartLegends':
            return <ChartLegends parentType={this.props.type} data={[isset(item.items, [])]} />;
        case 'notes':
            return <div style={{ padding: '24px' }}>
                <div style={{ paddingBottom: '8px' }}>
                    <Text weight="SemiBold" size="Bigger" color="Blue" >{i18n.t('Notes')}</Text>
                </div>
                <Text size="Normal" color="Black" >{item.text}</Text>
            </div >;
        case 'dashboard':
            return <Analytics isExporting={this.props.type.includes('Export')} noBoxShadow={this.props.noBoxShadow} type={this.props.type === 'Insights Export' ? this.props.type : 'Dashboard'} noFilters={this.props.noFilters} data={item.boxes} instantRender={this.props.instantRender} projectId={isset(this.props.projectId, '')} onChartsCallback={(item) => this.props.onChartsCallback(item)} />;
        case 'label':
            return <div style={{ display: 'flex', width: '100%', margin: '12px 0px', justifyContent: comparison ? (key < 1) ? 'right' : 'left' : 'center' }}><Label borderRadiusTypes='SemiRounded' style={item.labelType === 'post' ? { width: '500px', marginLeft: key > 0 ? '0px' : '16px' } : {}} fullWidth background={item.color} color='white' ><span style={{ margin: '4px' }}>{t(item.value)}</span></Label></div>;
        case 'loading':
            return <Spinner size='Big' />;
        default:
            return;
        }
    }

    deleteClickedNote(note, area) {
        var data = this.state.data;
        disable = true;
        var noteId = issetDot(note, 'notes.id');
        var referenceId = isset(note.id, '');

        if (noteId !== '') {
            NoteRequests.deleteNote((area === 'analytics' || area === 'ads' || area === 'competitors') ? 'insights' : area, referenceId, noteId, (responseData) => {
                if (isset(responseData.status, '') === 'ok') {
                    data.notes = {};
                    this.setState({ data }, () => this.props.onUpdatedBoxCallback(data));
                }
                disable = false;
            });
        }
    }

    addNote(data) {
        var boxData = this.state.data;
        boxData.notes = isset(data, {});
        this.setState({ data: boxData }, () => this.props.onUpdatedBoxCallback(this.state.data));
    }

    hasItemType(items = [], type) {
        for (let item of items) {
            var iType = isset(item.type, '');
            if (iType === type) {
                return true;
            } else if (isset(item.items) && iType === 'group') {
                var hasType = this.hasItemType(item.items, type);
                if (hasType) return hasType;
            }
        }

        return false;
    }

    openNotes() {
        this.setState({ showNotes: true }, () => {
            var textArea = document.getElementById('TextArea');
            if (textArea) textArea.focus();
        });
    }

    render() {
        this.colorCounts = {};
        var data = this.state.data || {};
        this.filters = {};
        var area = this.props.area;
        var objectiveValue = this.state.objective !== 0 ? this.state.objective : isset(data.objective) ? isset(data.objective.value, 0) : '';
        var averageToggle = this.state.averageToggle;

        this.id = data.id;
        this.projectId = this.props.projectId || '';

        var { objective = null, notes = null, average, dataDetails = false, download } = data;

        var hasFeed = (this.props.type === 'Dashboard' && this.hasItemType(data.items, 'feed'));
        var style = {};
        var styleContent = { overflow: 'auto', overflowX: 'hidden' };

        if (hasFeed) {
            style['display'] = 'flex';
            style['height'] = '100%';
            style['paddingRight'] = '0px';
        }

        if (isset(average)) {
            this.addFilters([{ id: 'showAverage', default: average }]);
        }

        if (isset(data.empty) || hasFeed) styleContent['minHeight'] = '350px';

        var fileName = '';
        if (typeof data.title === 'string') fileName = capitalize(isset(data.title, ' ')).replaceAll(' ', '');

        return (<BoxArea bigScreenFeed={this.props.type === 'BigScreen' && this.hasItemType(data.items, 'feed')} type={this.props.type} style={style}>
            {Object.keys(data).length !== 0 ?
                <>
                    {(!this.props.noFilters && !data.loading) ? isset(data.failedData) ?
                        < ErrorBox >
                            <Icon style={{ marginBottom: '16px' }} size='Huge' name='Warning Shield' color='Orange' />
                            <Text size='Big'>{t(<>Could not collect data for this chart. Please <Text style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => redirect()} color='Blue'>refresh</Text> this page to try again.</>)}</Text>
                        </ErrorBox>
                        : isset(data.empty) && <ErrorBox data={data.empty} type={this.props.type} />
                        : null
                    }
                    <FullPage horizontal>
                        <PagePriority>
                            <FullPage ref={this.componentRef} style={styleContent}>
                                {isset(data.title) ?
                                    <PageContent style={{ zIndex: 2, position: 'sticky', top: '0', backgroundColor: this.props.type.includes() ? 'white' : 'transparent' }}>
                                        <BoxTopContentArea type={this.props.type}>
                                            <div style={{ display: 'flex', flexDirection: 'column', minWidth: '300px' }}>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Text size="Huge" color={this.props.type === 'BigScreen' ? 'white' : 'black'}>{i18n.t(data.title)}</Text>
                                                    {!this.state.removeFilter && !window.location.pathname.includes('demo') && isset(data.status) && !this.props.type.includes('Export') ? <Status style={{ marginLeft: '8px' }} data={data.status} /> : ''}
                                                </div>
                                                {isset(data.description) ? <Text size="Medium" color={this.props.type === 'BigScreen' ? 'white' : 'mediumgrey'}>{i18n.t(data.description)} {data.descriptionLink && <a style={{ color: Config.colors.blue, textDecoration: 'underline' }} href={data.descriptionLink} target='_blank' rel="noopener noreferrer">{i18n.t('See More')}</a>}</Text> : null}
                                            </div>
                                            {(!this.state.removeFilter && !this.props.noFilters) && (isset(average) || objective || notes || dataDetails || download) ?
                                                <div data-disable={data.loading} style={{ display: 'flex', alignItems: 'center', marginLeft: '150px' }}>
                                                    {isset(average) && <div style={{ display: 'flex', marginRight: '24px' }}> <Text singleLine style={{ marginRight: '8px' }} color={averageToggle ? 'Black' : 'Grey'}>{i18n.t('Show Graph Avarage')}</Text> <Toggle defaultVal={averageToggle} onChange={(checked) => this.setState({ averageToggle: checked }, () => { this.onFilters('showAverage', this.state.averageToggle); })} /> </div>}
                                                    {dataDetails && <Button style={{ marginRight: '8px' }} type='Tertiary' icon='List View' iconSide='Left' text={i18n.t('Data Details')} onClick={() => this.setState({ showDataDetails: true })} />}
                                                    {objective && <Button style={{ marginRight: '8px' }} text={i18n.t('Objective')} icon="Objective Analytic" iconSide="Left" onClick={() => this.handleShowObjectivePopup(data.id)} type="Tertiary" />}
                                                    {notes && <Button text={i18n.t('Notes')} onClick={() => this.openNotes()} icon="Note" iconSide="Left" type="Tertiary" />}
                                                    {download && <Icon data-disable={this.state.downloadLoading} style={{ marginLeft: '24px', cursor: 'pointer' }} tooltip={i18n.t('Export chart in PNG')} size='Big' name='Download' color='blue' onClick={() => this.setState({ removeFilter: true, downloadLoading: true }, () => {
                                                        exportComponentAsPNG(this.componentRef, { fileName });
                                                        this.setState({ removeFilter: false });
                                                        setTimeout(() => { this.setState({ downloadLoading: false }); }, 5000);
                                                    })} />}
                                                </div>
                                                : null}
                                        </BoxTopContentArea>
                                    </PageContent> : null}
                                <PagePriority style={{ position: 'relative', height: '100%', flexGrow: '1' }}>
                                    <FullPage horizontal >
                                        <PagePriority noOverflow style={{ overflowX: this.props.type === 'BigScreen' && 'auto', boxSizing: 'border-box', display: 'flex', flexDirection: 'column', width: '100%', padding: this.props.type.includes('Analytics') && '0px 24px' }}>
                                            {this.props.type === 'Dashboard' && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                {data.filtered && <FilterLabel />}
                                            </div>}
                                            {isset(data.labels, []).length > 0 &&
                                                <table style={{ tableLayout: 'fixed', marginBottom: '32px', display: 'table', width: '100%' }}>
                                                    <tr>
                                                        {data.labels.map((label, key) => {
                                                            var color = colorApplication(label.color, this.colorCounts, 'labels');
                                                            this.colorCounts = color.counts;
                                                            return <>
                                                                {parseInt(key) !== 0 && <td style={{ width: '8px' }}></td>}
                                                                <td>
                                                                    <Label tooltip={label.name} image={label.photo ? label.photo : null} closeIcon={area === 'competitors' && !window.location.pathname.includes('demo') && !this.props.type.includes('Export') && key > 0} onClick={() => this.props.handleLabel ? this.props.handleLabel(key) : function () { }} singleLine profiles={isset(label.profiles, null)} fullWidth style={{ boxSizing: 'border-box', display: 'flex' }} key={key} type='Secondary' color={color.color} >
                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', overflow: 'hidden' }}>
                                                                            <Text singleLine color={color.color} style={{ marginLeft: (this.props.type.includes('Export') && label.photo) ? '4px' : '0px' }}>{label.name}</Text>
                                                                            {data.filtered && <FilterLabel />}
                                                                        </div>
                                                                    </Label>
                                                                </td>
                                                            </>;
                                                        })}
                                                    </tr>
                                                </table>}
                                            {data.items.map((item) => (this.generateItems(item, false, 0, data.loading)))}
                                            <Popup
                                                isOpen={this.state.showObjectivePopup}
                                                title={i18n.t('Set a value as objective')}
                                                width='560'
                                                data={[{
                                                    text: i18n.t('Set Objective'),
                                                    type: 'Default',
                                                    disabled: objectiveValue.length < 1,
                                                    align: 'right',
                                                    onClick: () => this.handleConfirmButtonObjectivePopup()
                                                },
                                                {
                                                    text: i18n.t('Cancel'),
                                                    type: 'Secondary',
                                                    align: 'right',
                                                    onClick: () => this.handleCloseObjectivePopup()
                                                }
                                                ]}
                                            >
                                                <div style={{ padding: '24px 0px' }}>
                                                    <InputWrapper>
                                                        <Input type="number" placeholder={i18n.t('Enter a numeric value for this graph')} errorMessage={this.state.isError} value={objectiveValue} onChange={(e) => this.setState({ objective: e.target.value.replace(/\D/, '') })} />
                                                        {objectiveValue.length > 1 && <InputSide style={{ paddingRight: '6px', cursor: 'pointer' }} onClick={() => this.setState({ objective: '' })}  > <Icon size='Large' name="Remove" /> </InputSide>}
                                                    </InputWrapper>
                                                </div>
                                            </Popup>
                                        </PagePriority>
                                    </FullPage>
                                </PagePriority>
                            </FullPage>
                        </PagePriority>
                        <PageContent>
                            {this.state.showNotes ? <BoxNotes>
                                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                                    <Text style={{ fontSize: '20px' }} weight="SemiBold" color="Black">{i18n.t('Notes')}</Text>
                                    <Icon name="Remove" style={{ cursor: 'pointer' }} color="Black" onClick={() => this.setState({ showNotes: false })} />
                                </div>
                                {Object.values(data.notes).length > 0
                                    ? <Note disabled={disable} data={data.notes} area={(area === 'analytics' || area === 'ads' || area === 'competitors') ? 'insights' : area} noteId={data.id} ProjectId={this.projectId} onCheckClick={() => this.deleteClickedNote(data, area)} />
                                    : <AddNote area={(area === 'analytics' || area === 'ads' || area === 'competitors') ? 'insights' : area} referenceId={data.id} onAdd={(data) => this.addNote(data)} onCancel={() => this.setState({ showNotes: false })} />
                                }
                            </BoxNotes> : null}
                            {this.state.showDataDetails ? <DataDetails onClose={() => this.setState({ showDataDetails: false })} /> : null}
                        </PageContent>
                    </FullPage>
                </>
                : <Spinner />
            }
        </BoxArea>);
    }
}

Box.propTypes = {
    /**
     * Box data
     */
    data: PropTypes.object,
    /**
     * Box type
     */
    type: PropTypes.oneOf(types),
    /**
     * Project Id
     */
    projectId: PropTypes.string,
    /**
     * Remove Filters on box area
     */
    noFilters: PropTypes.bool,
    /**
     * Box area
     */
    area: PropTypes.string,
    /**
     * Remove loading from boxes
     */
    instantRender: PropTypes.bool,
    /**
     * filters area callback
     */
    onFiltersCallback: PropTypes.func,
    /**
     * charts area callback
     */
    onChartsCallback: PropTypes.func
};

Box.defaultProps = {
    type: 'Analytics'
};
export default Box;
