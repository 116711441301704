import React from 'react';
import i18n from '../../../i18n';
import Icon from '../icon/Icon';
import Text from '../text/Text';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isset } from '../../../base/Utils';

export const types = {
    'BigScreen': '#171717',
    'None': 'rgba(255,255,255, 0.90)'
};

const ErrorBoxMain = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: ${props => isset(types[props.type], 'rgba(255,255,255, 0.90)')};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const ErrorBox = ({ type, data, children, ...props }) => {
    data = isset(data, {});


    return (
        <ErrorBoxMain type={type} {...props}>
            {children ?
                children
                :
                <>
                    <Icon name={data.status + ' Sign'} color="Grey" size="Huge" />
                    <Text size="Big" style={{ marginBottom: '8px', marginTop: '8px' }} color={type === 'BigScreen' ? 'White' : 'Black'}>{i18n.t(data.title)}</Text>
                    <Text size="Medium" color='Grey' align="center">{i18n.t(data.message)}</Text>
                    <Text size="Medium" color='Grey' align="center">{i18n.t('If you think this is a error contact our support.')}</Text>
                </>
            }
        </ErrorBoxMain>
    );
};

ErrorBox.propTypes = {
    /**
     * Type of the Error Box
     */
    type: PropTypes.oneOf(Object.keys(types)),
    /**
     * Data
     */
    data: PropTypes.object,
};

ErrorBox.defaultProps = {
    type: 'None',
};

export default ErrorBox;
