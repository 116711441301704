
import { clone, isset, issetDot, redirect, getCookie, setCookie, fullPath } from './Utils';
import Config from './config/Config';
import Request from '../common/classes/Request';
import { posthog } from 'posthog-js';
import Broker from '../common/classes/Broker';
import * as Sentry from '@sentry/react';

class Session {
    static params = {};

    static apiStatus = null;

    static callback = [];

    static clear() {
        this.params = {};
    }

    static start() {
        this.clear();
        this.checkLogin(true);
    }

    static setCallback(callback) {
        this.callback.push(callback);
    }

    static callCallbacks() {
        this.callback.map((callback) => { return callback(); });
    }

    static set(params) {
        var { id = '', name = '', email = '', activeTeam = null, loggedIn = null } = this.params = params;

        isset(window.defineChat, function () { })(id, name, email, activeTeam);

        if (loggedIn && id && isset(window.io)) {
            Broker.start(id, null);
        }
    }

    static setSentry() {
        var params = this.getParams();

        if (params.loggedIn) {
            Sentry.setUser({ id: params.id});
            Sentry.setTag('id_team', params.activeTeam);
        }
    }

    static checkLogin(redirectAfter, callback) {
        redirectAfter = isset(redirectAfter, false);
        callback = callback || function () { };
        const redirectWhiteList = ['/', '/login', '/register'];

        Request.send({
            url: `${Config.backendHost}me`,
            method: 'get',
        }, (response) => {
            response = isset(response, {});
            var data = {};

            // Check if is inside Swonkie Fenix
            const parentTarget = Config.fenixAppHost;
            var insideAppCheck = parentTarget;

            try {
                insideAppCheck = window.top.location.origin;
            } catch (e) {
                console.log(e);
            }

            switch (isset(response.status, '')) {
            case 'ok':
                data = isset(response.data, {});
                data.loggedIn = true;

                var teams = Array.isArray(data.teams) ? data.teams.map(function (o) { return o.id; }) : [];

                posthog.identify(data.id.toString(), {
                    name: data.name,
                    email: data.email,
                    activeTeam: data.activeTeam,
                    language: data.language,
                    teams
                });

                posthog.onFeatureFlags(() => {
                    if (posthog.isFeatureEnabled('enableSessionRecording')) {
                        posthog.startSessionRecording();
                    }
                });

                if (posthog.isFeatureEnabled('enableSessionRecording')) {
                    posthog.startSessionRecording();
                }

                if (redirectAfter && redirectWhiteList.indexOf(window.location.pathname) > -1) {
                    setTimeout((path) => {
                        redirect(path);
                    }, 100, (getCookie('redirectAfter') || (data.loggedIn && !isset(data.checkpoint, false) ? Config.fenixAppHost : '/dashboard')));
                }

                setCookie('redirectAfter', null, -1);
                break;
            case 'checkpoint':
                switch (issetDot(response, 'checkpoint.reason', '')) {
                case 'incompleteRegister':
                    data.loggedIn = true;
                    data.hideTopBar = true;
                    data.checkpoint = response.checkpoint.reason;
                    data.checkpointData = isset(response.checkpoint.data, null);
                    data.id = issetDot(response, 'checkpoint.data.id', null);
                    break;
                case 'expiredPlan':
                    data.loggedIn = true;
                    data.hideTopBar = true;
                    break;
                default:
                }
                break;
            default:
                if (redirectWhiteList.indexOf(window.location.pathname) === -1) {
                    setCookie('redirectAfter', fullPath(), 0.85);
                }
            }



            if (parentTarget.indexOf(insideAppCheck) !== -1){
                data.hideTopBar = true;
                data.isInsideIframe = true;
            }

            if (Object.keys(data).length > 0) {
                this.set(data);
                this.setSentry();
            }

            callback();
            this.callCallbacks();
        });
    }

    static logout() {
        Request.send({
            url: `${Config.backendHost}logout`,
            method: 'post',
        }, (response) => {
            response = isset(response, {});
            var status = isset(response.status, '');

            if (status === 'ok' || (status === 'error' && issetDot(response, 'error.code', 0) === 11)) {
                this.clear();
                //TODO: Remove this when legacy is 100% deprecated
                var legacy = document.getElementById('oldPageIframe');
                if (legacy !== null) {
                    legacy.contentWindow.postMessage({ type: 'logout' }, Config.oldHost);
                }
                window.endChat();
                setTimeout(() => {
                    posthog.reset();
                    redirect(Config.publicHost + 'logout');
                }, 100);
            }
        });
    }

    static handleTeam(teamId, callback) {
        Request.send({
            url: `${Config.backendHost}team/${teamId}/login`,
            method: 'post',
        }, (response) => {
            response = isset(response, {});
            if (isset(response.status, '') === 'ok') {
                this.checkLogin(false, callback);
            }
        });
    }

    static handleLogoutTeam(callback) {
        Request.send({
            url: `${Config.backendHost}team/logout`,
            method: 'post',
        }, (response) => {
            response = isset(response, {});
            if (isset(response.status, '') === 'ok') {
                this.checkLogin(false, callback);
            }
        });
    }

    static getActiveParams() {
        var data = clone(this.params);
        var { activeTeam = null, teams = [] } = data;

        if (activeTeam) {
            for (let team of teams) {
                if (isset(team.id, null) === activeTeam) {
                    return team;
                }
            }
        }

        delete data.activeTeam;
        delete data.teams;

        return data;
    }

    static getParams() {
        //this.params.loggedIn = (isset(this.params.loggedIn) ? this.params.loggedIn : false);

        return this.params;
    }
}

export default Session;
