import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import Request from '../../classes/Request';

class PostRequests {
    static showSharesOrReactions(area, referenceId, typeEngagement, feedData, engagement, profileId, callback) {
        if (typeEngagement === 'reactions') {
            var engagementData = engagement.reactions;
        }
        else {
            engagementData = engagement.shares;
        }
        Request.send({ method: 'get', url: Config.backendHost + 'engagement/' + area + '/' + referenceId + '/' + typeEngagement + '?profileId=' + profileId },
            (data) => {
                var states = {};
                if (isset(data) && data.status === 'ok') {
                    feedData.feedType = 'profiles';
                    feedData.items = data.data;
                    for (var id in feedData.items) {
                        feedData.items[`${id}`].reach = {};
                        feedData.items[`${id}`].reach.type = '';
                        feedData.items[`${id}`].reach.value = '';
                        feedData.items[`${id}`].detail = {};
                        feedData.items[`${id}`].detail.type = 'icon';
                        feedData.items[`${id}`].detail.value = data.data[`${id}`].icon.value;
                    }
                    states.feedData = feedData;
                    states.showPopup = false;
                    this.setState({ feedData: feedData, showPopup: true });
                    switch (typeEngagement) {
                    case 'shares':
                        for (let engagement of engagementData) {
                            engagement.count = 0;
                            engagement.count = data.data.map(() => {
                                return engagement.count++;
                            });
                        }
                        break;
                    case 'reactions':
                        for (let engagement of engagementData) {
                            engagement.count = 0;
                            for (let value of data.data) {
                                if (value.icon.value === engagement.id) {
                                    engagement.count++;
                                }
                            }
                        }
                        break;
                    default:
                    }
                }
                states.engagement = engagement;
                callback(states);
            });
    }
    static getCommentFromProfile(url, callback) {
        Request.send({
            method: 'get',
            url: url
        },
        (data) => {
            callback(isset(data, {}));
        });
    }

    static postComment(area, referenceId, params, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'engagement/' + area + '/' + referenceId + '/comments',
            params: params
        },
        (data) => {
            callback(isset(data, {}));
        });
    }

    static postReaction(area, referenceId, params, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'engagement/' + area + '/' + referenceId + '/reactions',
            params: params
        },
        (data) => {
            callback(isset(data, {}));
        });
    }

    static deleteReaction(area, referenceId, params, callback) {
        Request.send({
            method: 'delete',
            url: Config.backendHost + 'engagement/' + area + '/' + referenceId + '/reactions',
            params: params
        },
        (data) => {
            callback(isset(data, {}));
        });
    }

    static getDetailedPost(area, id, source, auxData, callback) {
        for (let key in auxData) {
            if (!isset(auxData[`${key}`]) || auxData[`${key}`] === null || auxData[`${key}`] === '') {
                delete auxData[`${key}`];
            }
        }

        Request.send({
            method: 'get',
            url: Config.backendHost + 'posts/' + area + '/' + id + '/insights?source=' + source + (Object.keys(auxData).length > 0 ? '&' + Request.encodeQueryData(auxData) : '')
        },
        (data) => {
            callback(isset(data, {}));
        });
    }
}
export default PostRequests;
