import i18n from '../../../i18n';

export default () => ([
    {
        target: '[data-id="publish-linkedin-document"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('LinkedIn Carousel'),
        content: i18n.t('Try the new publishing feature where you can create a slide show with your images on your LinkedIn posts.'),
        disableBeacon: true,
        showBackButton: false,
        nextButtonText: i18n.t('Okay')
    },
]);
