import React, { Component } from 'react';
import { formatNumberSpaces, isNumeric, isset } from '../../../base/Utils';
import Text from '../text/Text';
import Config from '../../../base/config/Config';
import styled from 'styled-components';
import PreventUpdate from '../PreventUpdate';
import PropTypes from 'prop-types';

//TODO: Merge tooltips

export class GlobalTooltip extends Component {
    ref = React.createRef();

    maxWidth = 300;
    minHeight = 26;

    state = {
        content: null,
    };

    constructor(props) {
        super(props);

        this.id = this.props.id || null;
        this.template = this.props.template || null;
        this.position = this.props.position || null;
        this.clickable = this.props.clickable || false;
    }

    componentDidMount() {
        document.addEventListener('mousemove', (e) => this.trigger(e), false);
    }

    componentDidUpdate() {
        this.autoAdjust();
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', (e) => this.trigger(e), false);
    }

    autoAdjust() {
        setTimeout(() => {
            if (this.ref && this.ref.current) {
                var el = this.ref.current;
                var { top = 0, bottom = 0, left = 0, right = 0 } = el.getBoundingClientRect();

                var { innerWidth = 0, innerHeight = 0 } = window;
                var outRight = (innerWidth < right);
                var outBottom = (innerHeight < bottom);

                if (outRight) {
                    left = left - (right - innerWidth) - 5;
                }

                if (outBottom) {
                    top = top - (bottom - innerHeight) - 5;
                }

                if (outRight || outBottom) {
                    el.style.left = left + 'px';
                    el.style.top = top + 'px';
                }
            } else {
                this.autoAdjust();
            }
        }, 10);
    }

    destroyTimeout = null;

    trigger(e) {
        var destroy = true;
        var target = e.target || null;

        if (target) {
            if (target.id === 'tooltip') destroy = false;

            if (target.getAttribute && !target.getAttribute('tooltip')) {
                target = target.closest('*[tooltip]:not([tooltip=\'\'])') || null;
            }

            if (target && ((!this.id && (typeof target.getAttribute === 'function' && !target.getAttribute('tooltip-id'))) || ((typeof target.getAttribute === 'function' && target.getAttribute('tooltip-id')) === this.id))) {
                var data = { content: target.getAttribute('tooltip') };

                for (let attr of target.attributes) {

                    if (isset(attr.name, '').indexOf('tooltip-') === 0) {
                        data[attr.name.replace('tooltip-', '')] = attr.value;
                    }
                }

                var { bottom, left, right, width } = target.getBoundingClientRect();

                var position = data.position || this.position || null;

                if (!position) {
                    if ((left + this.maxWidth) > window.innerWidth) {
                        left = 'auto';
                        right = window.innerWidth - right;
                    } else {
                        right = 'auto';
                    }
                } else {
                    switch (position) {
                    case 'right':
                        left = 'auto';
                        break;
                    case 'center':
                        left = left + (width / 2);
                        right = 'auto';
                        break;
                    default:
                        right = 'auto';
                    }
                }

                destroy = false;
                this.setState({ data: data, top: (bottom + 5), left: left, right: right, center: (position === 'center') });
            }
        }

        if (destroy) {
            this.destroyTimeout = setTimeout(() => this.setState({ data: null }), 100);
        } else if (this.destroyTimeout) {
            clearTimeout(this.destroyTimeout);
        }
    }

    render() {
        var {
            data = null,
            top = 'auto',
            left = 'auto',
            right = 'auto',
            center = false,
        } = this.state;

        if (data) {
            const Tooltip = styled.div`
                z-index: 9999;
                position: absolute;
                background: ${Config.colors.darkGrey};
                top: ${isNumeric(top) ? top + 'px' : top};
                left: ${isNumeric(left) ? left + 'px' : left};
                right: ${isNumeric(right) ? right + 'px' : right};
                transform: ${center ? 'translateX(-50%)' : 'unset'};
                max-width: ${this.maxWidth}px;
                min-height: ${this.minHeight}px;
                white-space: pre-wrap;
                word-break: keep-all;
                word-wrap: break-word;
                padding: 6px;
                font-size: 12px;
                color: ${Config.colors.white};
                border-radius: 5px;
                pointer-events: ${this.clickable ? 'all' : 'none'};
                box-sizing: border-box;
                transition: 0.1s;
            `;

            if (this.template) {
                data = this.template(data);
            } else {
                data = data.content;
            }

            return <PreventUpdate data={data}><Tooltip id="tooltip" ref={this.ref}>{data}</Tooltip></PreventUpdate>;
        }

        return null;
    }
}

const Tooltip = styled.div`
    background: ${props => types[props.type].background};
    border-radius: 4px;
    padding: ${props => types[props.type].padding};
    box-shadow: ${props => types[props.type].boxShadow};
    position: fixed;
    min-width: 100px;
    pointer-events: ${props => isset(props.pointerEvent, types[props.type].pointerEvents)};
    max-width: ${props => isset(types[props.type].maxWidth, '300px')};
    width: ${props => isset(types[props.type].width, 'auto')};
    white-space: nowrap;
    z-index: 100;
`;
const TooltipTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
`;

const TooltipData = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    &:last-child{
        margin: 0
    }
`;

const types = {
    Primary: {
        background: 'white',
        boxShadow: '0px 9px 13px rgba(0, 0, 0, 0.26)',
        padding: '16px 12px',
        pointerEvents: 'none'
    },
    Secondary: {
        background: Config.colors.darkGrey,
        boxShadow: '0px',
        padding: '5px 8px',
        pointerEvents: 'visible'
    },
    Mention: {
        background: 'white',
        boxShadow: '0px 9px 13px rgba(0, 0, 0, 0.26)',
        padding: '16px',
        pointerEvents: 'visible',
        width: '100%',
        maxWidth: '170px'
    },
    MentionDetailed: {
        background: 'white',
        boxShadow: '0px 9px 13px rgba(0, 0, 0, 0.26)',
        padding: '16px 12px',
        pointerEvents: 'visible',
        width: '100%',
        maxWidth: '270px'
    },
};
export default class toolTip extends Component {
    ref = React.createRef();

    componentDidMount() {
        this.autoAdjust();
    }

    componentDidUpdate() {
        this.autoAdjust();
    }

    autoAdjust() {
        setTimeout(() => {
            if (this.ref && this.ref.current) {
                var { top = 0, bottom = 0, left = 0, right = 0 } = this.ref.current.getBoundingClientRect();

                var { innerWidth = 0, innerHeight = 0 } = window;
                var outRight = (innerWidth < right);
                var outBottom = (innerHeight < bottom);

                if (outRight) {
                    left = left - (right - innerWidth) - 5;
                }

                if (outBottom) {
                    top = top - (bottom - innerHeight) - 5;
                }

                if (outRight || outBottom) {
                    this.ref.current.style.left = left + 'px';
                    this.ref.current.style.top = top + 'px';
                }
            } else {
                this.autoAdjust();
            }
        }, 10);
    }

    render() {
        var {
            top = 0,
            left = 0,
            data = [],
            title = '',
            label = '',
            total = '',
            children,
            type,
            onMouseLeave = function () { },
            pointerEvent,
            ...props
        } = this.props;

        var tooltipTypes = Object.keys(types);

        type = tooltipTypes.includes(type) ? type : 'Primary';


        if (this.props.setAdjust)
            setTimeout(() => this.autoAdjust(), 50);

        return (
            <Tooltip pointerEvent={pointerEvent} onMouseLeave={onMouseLeave} type={type} style={{ top: top, left: left }} ref={this.ref} {...props}>
                {(title || label || total) && <div>
                    <TooltipTitle>
                        {title && <Text style={{ marginRight: label ? '4px' : '0px' }} singleLine size="Small" color="Grey">{title}</Text>}
                        {label && <Text size="Small" color="Grey">{label}</Text>}
                    </TooltipTitle>
                    {total &&
                        <TooltipData>
                            <Text size="Huge" color="Black" style={{ marginBottom: '4px' }} singleLine>{isNumeric(total) ? formatNumberSpaces(total) : total}</Text>
                            <Text size="Medium" color="Grey" style={{ marginBottom: '16px' }} >Total</Text>
                        </TooltipData>
                    }
                </div>}
                {data.map((item, key) => {
                    var { value = '-', label = '', stacked = false } = item;

                    return (<TooltipData key={key}>
                        <Text size={stacked ? 'Big' : 'Huge'} style={{ marginRight: '4px' }} color="Black">{isNumeric(value) ? formatNumberSpaces(value) : value}</Text>
                        <Text size="Small" color="Grey">{label}</Text>
                    </TooltipData>);
                })}
                {children}
            </Tooltip>
        );
    }
}

toolTip.propTypes = {
    /**
     * Top position tooltip
     */
    top: PropTypes.number,
    /**
     * Left position tooltip
     */
    left: PropTypes.number,
    /**
     * tooltip data
     */
    data: PropTypes.array,
    /**
     * tooltip title
     */
    title: PropTypes.string,
    /**
     * tooltip label
     */
    label: PropTypes.string,
    /**
     * Total value inside tooltip
     */
    total: PropTypes.string,
    /**
     *  children tooltip
     */
    children: PropTypes.any,
};

toolTip.defaultProps = {
    type: 'Primary'
};
