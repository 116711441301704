import i18n from '../../../i18n';

export default () => ([
    {
        target: '[data-id="andie-side-chat"] .andie-chat-message-outside:last-of-type .andie-chat-message[andie-message-id] .andie-chat-message-images',
        waitForTarget: true,
        getFromLegacy: true,
        content: i18n.t('Choose and insert images to enhance your content. Image suggestions won\'t be accessible once applied and in chat history.'),
        disableBeacon: true,
        nextButtonText: i18n.t('Okay')
    }
]);
