import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { isset, redirect } from '../../../base/Utils';
import Text from '../text/Text';
import Popup from '../popup/Popup';
import { CarouselArea } from '../post/Post';
import Carousel from 'react-elastic-carousel';
import Icon from '../icon/Icon';

const MediaArea = styled.div`
    max-width: ${props => props.type === 'table' ? '48px' : props.isExporting ? '300px' : '500px'};
    height: ${props => props.fullHeight ? '100%' : props.type === 'table' ? '48px' : props.postSize === 'Small' && props.isExporting ? '100px' : props.horizontal ? '150px' : props.source === 'tikTok' ? '600px' : props.postType === 'instaStory' ? '700px' : '300px'};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
`;

const MediaInfoArea = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px;
    width: 100%;
    overflow: hidden;
`;

const MediaImage = styled.img`
    width: ${props => props.type === 'link' ? '75px' : 'auto'};
    max-width: 100%;
    height: ${props => props.type === 'link' ? '75px' : '100%'};
    object-fit: contain;
    object-position: center;
`;

const MediaVideo = styled.video`
    width: auto;
    max-width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
`;

const Media = ({ data = {}, horizontal, postType, postSize, isExporting, source, fullHeight, ...props }) => {
    switch (data.type) {
    default:
        break;
    case 'link':
        var newUrl = new URL(data.url);
        return <MediaArea fullHeight={fullHeight} onClick={() => redirect(data.url, '_blank')} source={source} postType={postType} postSize={postSize} isExporting={isExporting} horizontal={horizontal} style={{ background: '#F5F5F5', height: '75px', }} {...props}>
            {isset(data.image) && data.image && <MediaImage type={data.type} style={{ objectFit: 'fill' }} src={data.image} alt='' onError={(e) => e.target.src = require('../../../resources/images/no-media.svg')} />}
            <MediaInfoArea>
                <Text singleLine size="Medium" color={isset(data.title) && isset(data.description) ? 'Grey' : 'Black'} >{newUrl.host.toUpperCase()}</Text>
                <Text singleLine size="Big" >{data.title}</Text>
                <Text singleLine size="Medium" color="Grey" >{data.description}</Text>
            </MediaInfoArea>
        </MediaArea>;
    case 'photo':
        return <>
            {data.url && <MediaArea fullHeight={fullHeight} postSize={postSize} isExporting={isExporting} source={source} horizontal={horizontal} {...props}>
                <MediaImage alt="" src={data.url} onError={(e) => e.target.src = require('../../../resources/images/no-media.svg')} />
            </MediaArea>}
        </>;
    case 'video':
        return <MediaArea fullHeight={fullHeight} postSize={postSize} isExporting={isExporting} source={source} horizontal={horizontal} {...props}>
            {data.url ? <MediaVideo controls>
                <source src={data.url} type="video/mp4" />
            </MediaVideo> : <MediaImage alt="" src={data.thumbnail} onError={(e) => e.target.src = require('../../../resources/images/no-media.svg')} />}
        </MediaArea>;
    case 'iframe':
        return <MediaArea fullHeight={fullHeight} postSize={postSize} isExporting={isExporting} source={source} horizontal={horizontal} {...props}>
            <iframe title="IframeTitle" src={data.url} frameBorder="0" />
        </MediaArea>;
    }
    return <> </>;
};

export default Media;

export class TableMedia extends Component {
    constructor() {
        super();
        this.state = { showMediaPopup: false, disableAction: false };
        this.ref = React.createRef();
    }

    addDefaultImage(e) {
        e.target.src = require('../../../resources/images/no-media.svg');
        this.setState({ disableAction: true });
    }

    render() {
        var { data, thumbnail, isExporting, ...props } = this.props;
        if (isExporting && thumbnail)
            var firstData = { type: 'photo', url: thumbnail };
        else
            firstData = data[0];

        return <>
            <MediaArea style={{ cursor: (firstData.type === 'video' || firstData.type === 'photo') && !this.state.disableAction ? 'pointer' : 'default' }} disabled={this.state.disableAction} type='table' onClick={(firstData.type === 'video' || firstData.type === 'photo' || isset(thumbnail)) && !this.state.disableAction && ((e) => { e.stopPropagation(); this.setState({ showMediaPopup: true }); })} {...props}>
                {firstData.type === 'video' && isset(firstData.url) && !isset(thumbnail) ?
                    <video style={{ width: '100%', height: '100%', pointerEvents: 'none', userSelect: 'none' }} id="mediaVideo" src={firstData.url} type="video/mp4" muted></video>
                    : firstData.type === 'photo' || thumbnail ? <MediaImage src={isset(thumbnail, isset(firstData.url, ''))} alt='' onError={(e) => this.addDefaultImage(e)} />
                        : <MediaImage src={require('../../../resources/images/no-media.svg')} alt='' />}
            </MediaArea>
            <Popup closeOutside isOpen={this.state.showMediaPopup} closeButton onCallbackPopup={(isOpen) => this.setState({ showMediaPopup: isOpen })} >
                <div style={{ marginBottom: '12px' }}>
                    <CarouselArea >
                        {isset(data, []).length > 1 && <> <div className="CarouselLeftButton" onClick={() => this.carousel.slidePrev()}> <Icon name="Caret Down" color="white" size="Giant" /> </div>
                            <div className="CarouselRightButton" onClick={() => this.carousel.slideNext()}><Icon name="Caret Down" color="white" size="Giant" /></div> </>}
                        <div style={{ height: 'auto' }}>
                            <Carousel ref={ref => (this.carousel = ref)} showArrows={false} pagination={isset(data, []).length > 1 ? true : false} enableSwipe={false} itemsToScroll={1} itemsToShow={1}>
                                {isset(data, []).map((media, index) => {
                                    return <Media disabled={true} data={media} key={index} />;
                                })}
                            </Carousel>
                        </div>
                    </CarouselArea>
                </div>
            </Popup>
        </>;
    }
}

Media.propTypes = {
    data: PropTypes.object
};
