import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import Request from '../../classes/Request';

class NoteRequests {
    static getNotes(area, referenceId, pagination, callback) {

        var url = Config.backendHost + 'notes/' + area + '/' + referenceId;
        if (pagination && pagination.length > 0) {
            url = pagination;
        }

        Request.send({
            method: 'get',
            url: url
        },
        (response) => {
            response = isset(response, {});
            callback(response);
        });
    }

    static postNote(params, area, referenceId, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'notes/' + area + '/' + referenceId,
            params: params
        },
        (response) => {
            callback(isset(response, {}));
        });
    }

    static putNote(params, area, id, referenceId, checkOverwriteAfter, callback) {
        Request.send({
            method: 'put',
            url: Config.backendHost + 'notes/' + area + '/' + id + '/' + referenceId + '?checkOverwriteAfter=' + checkOverwriteAfter,
            params: params
        },
        (response) => {
            callback(isset(response, {}));
        });
    }

    static deleteNote(area, referenceId, noteId, callback) {
        Request.send({
            method: 'delete',
            url: Config.backendHost + 'notes/' + area + '/' + referenceId + '/' + noteId
        },
        (response) => {
            callback(isset(response, {}));
        });
    }

}

export default NoteRequests;
