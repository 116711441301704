import './instrument'; // Sentry
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './base/app/App';
import TourComponent from './common/tours/TourComponent';
import ErrorBoundary from './errorBoundary';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { PostHogProvider } from 'posthog-js/react';
import { getCookie, setCookie } from './base/Utils';


try {
    if (typeof window.posthogConfig !== 'undefined') {
        setCookie('posthogConfig', JSON.stringify(window.posthogConfig), 365);
    }

    var cookiePHConfig = getCookie('posthogConfig');
    if (cookiePHConfig) {
        cookiePHConfig = JSON.parse(cookiePHConfig);
    }
} catch (e) {
    console.log('Could not load Posthog Config');
    cookiePHConfig = {};
}

if (!cookiePHConfig && typeof cookiePHConfig !== 'object') {
    cookiePHConfig = {};
}

const options = Object.assign({
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    autocapture: false,
    persistence: 'localStorage+cookie',
    disable_session_recording: true,
    session_recording: {
        recordCrossOriginIframes: true,
    },
}, cookiePHConfig);


ReactDOM.render(
    <PostHogProvider
        apiKey={process.env.REACT_APP_POSTHOG_ID}
        options={options}>
        <TourComponent />
        <ErrorBoundary>
            <App />
        </ErrorBoundary>
    </PostHogProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//TODO: get this to work properly
serviceWorker.unregister();
