import Config from '../../base/config/Config';
import Session from '../../base/Session';
import { isset, issetDot } from '../../base/Utils';
import Request from '../../common/classes/Request';
import i18n from '../../i18n';

class RegisterRequest {
    static postRegister(params, callback) {
        Request.send({
            method: 'post',
            url: Config.backendHost + 'register',
            params: params
        },
        (data) => {
            var states = {
                errorEmail: false,
                errorEmailMessage: '',
                errorPassword: false,
                errorPasswordMessage: ''
            };
            switch (data.status) {
            case 'ok':
                Session.start();
                if (typeof window.gtag == 'function') {
                    window.gtag('event', 'Login', {
                        'event_category': 'Sign_in',
                        'event_label': 'customer'
                    });
                }
                break;
            case 'error':
                switch (data.error.code) {
                case 10:
                    states['ipBlocked'] = true;
                    break;
                case 4:
                    if (isset(data.error.fields.email)) {
                        states['errorEmail'] = true;
                        states['errorEmailMessage'] = i18n.t('the email is invalid');
                    }
                    if (isset(data.error.fields.password)) {
                        states['errorPassword'] = true;
                        states['errorPasswordMessage'] = i18n.t('The password must have a minimum of 9 characters and include at least 3 of the following character sets: lowercase letters, uppercase letters, numbers, and special characters.');
                    }
                    if (isset(data.error.fields.captcha)) {
                        states['captchaErrorMessage'] = 'true';
                    }
                    if (isset(data.error.fields.confirmEmail)) {
                        states['errorCode'] = true;
                        states['errorCodeMessage'] = i18n.t('the code is invalid');
                    }
                    break;
                case 6:
                    if (isset(data.error.message)) {
                        states['errorEmail'] = true;
                        states['errorEmailMessage'] = i18n.t('the email already exist');
                    }
                    break;
                default:
                    break;
                }
                break;
            case 'checkpoint':
                switch (data.checkpoint.reason) {
                case 'emailConfirmation':
                    states['confirmEmail'] = true;
                    if (typeof window.gtag == 'function') {
                        window.gtag('event', 'New_User', {
                            'event_category': 'Sign_Up',
                            'event_label': 'new_registration'
                        });
                    }
                    if (typeof window.lintrk == 'function') {
                        window.lintrk('track', { conversion_id: 6382210 });
                    }
                    break;
                default:
                    break;
                }
                break;
            default:
                break;
            }
            callback(states);
        });
    }

    static getSocialRegister(socialMedia, captchaToken, captchaVersion, callback) {
        Request.send({
            url: Config.backendHost + 'register/' + socialMedia + (captchaVersion == 'v2' ? '?captchav2=' : '?captcha=') + captchaToken,
            method: 'get'
        }, (data) => {
            var states = {};
            switch (isset(data.status, '')) {
            case 'ok':
                if (isset(data.data)) {
                    window.location.replace(data.data.url);
                }
                callback();
                return true;
            case 'error':
                switch (data.error.code) {
                case 10:
                    states['ipBlocked'] = true;
                    break;
                case 4:
                    if (isset(data.error.fields.captcha)) {
                        states['captchaErrorMessage'] = 'true';
                    }
                    break;
                default:
                }
                break;
            default:
            }
            callback(states);
        });
    }

    static postSocialRegister(socialMedia, params, callback) {
        Request.send({
            url: Config.backendHost + 'register/' + socialMedia,
            method: 'post',
            params: params,
        }, (data) => {
            var states = {};
            var status = issetDot(data, 'status');
            switch (status) {
            case 'ok':
                Session.start();
                break;
            case 'error':
                var code = issetDot(data, 'error.code', 0);
                switch (code) {
                case 10:
                    states['ipBlocked'] = true;
                    states['firstStep'] = false;
                    break;
                case 6:
                    if (isset(data.error.message)) {
                        states['alertEmailRegister'] = true;
                    }
                    break;
                case 12:
                    if (isset(data.error.message) && data.error.message === 'Missing fields.') {
                        states['missingPermissions'] = socialMedia;
                    }
                    break;
                default:
                    break;
                }
                break;
            default:
                break;
            }
            callback(states);
        });
    }

    static postRegisterOnboard(params, callback) {
        Request.send({
            url: Config.backendHost + 'register/onboard',
            method: 'post',
            params
        }, (data) => {
            callback(data);
        });
    }

    static postRegisterResend(callback) {
        Request.send({
            url: Config.backendHost + 'register/resend',
            method: 'post'
        }, (data) => {
            callback(data);
        });
    }
}

export default RegisterRequest;
