import React, { PureComponent } from 'react';
import { isset, issetDot } from '../../../base/Utils';
import Box, { BoxContainer } from '../box/Box';
import Spinner from '../spinner/Spinner';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import PreventUpdate from '../PreventUpdate';

var BoxTd = styled.td`
    ::before{
        content: "";
        background-color: #fff;
        border-radius: 4px;
        box-shadow: ${props => (props.type === 'Dashboard' && !props.noBoxShadow) && '0 1px 3px rgba(0,0,0,.26) !important'};
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        pointer-events: none;
        ${props => props.type === 'BigScreen' && { backgroundColor: props.noBackground ? 'transparent' : 'hsla(0,0%,100%,.09)' }}
    }
    position: relative;
    vertical-align: top;
    padding: ${props => props.type.includes('Insights') ? '0px' : props.type !== 'Analytics' && props.noBackground ? '0px' : props.type !== 'Dashboard' ? '16px' : '16px 4px 16px 16px'};
    box-sizing: border-box;
    ${props => props.type === 'Analytics' && {
        display: 'block',
        width: '100%',
        height: '100%'
    }}
`;

var AnalyticsTable = styled.table`
    table-layout: fixed;
    width: 100%;
    height: 100%;
    border-spacing: 16px;
    position: relative;
    ${props => props.type === 'Analytics' && { display: 'block', position: 'absolute', overflow: 'auto' }}
    border-collapse: separate;
`;

export var types = ['Dashboard', 'Analytics', 'BigScreen', 'Insights'];

export default class Analytics extends PureComponent {
    maxColumns = 12
    categories = []
    boxesLoading = []

    constructor(props) {
        super(props);
        this.state = { activeId: 0 };
    }

    arrangeBoxRows(boxes = []) {
        this.categories = [];
        this.boxesLoading = [];
        var columnsCount = 0;

        var rowSpans = [];

        var rowsData = [];
        var rowKey = 0;
        for (let box of boxes) {
            box = box ? box : {};
            var columns = box.columns || 1;
            var rows = box.rows || 1;
            var category = box.category || 1;

            if (this.categories.indexOf(category) === -1) {
                this.categories.push(category);
            }

            category = this.categories.indexOf(category);

            var rowDataCategory = rowsData[`${category}`];
            if (!isset(rowDataCategory)) {
                rowKey = 0;
                rowSpans = [];
                columnsCount = 0;

                rowDataCategory = [];
            }
            var rowsDataRow = rowDataCategory[`${rowKey}`];
            if (!isset(rowsDataRow)) {
                rowsDataRow = [];
            }

            rowsDataRow.push(box);
            columnsCount += columns;
            rowDataCategory[`${rowKey}`] = rowsDataRow;
            rowsData[`${category}`] = rowDataCategory;

            if (!isset(this.props.type, '').includes('Insights') && rows > 1) {
                rowSpans.push({
                    rowsLeft: rows,
                    columns: columns
                });
            }

            if (columnsCount >= this.maxColumns) {
                rowKey++;
                columnsCount = 0;

                for (let rowSpan of rowSpans) {
                    if (rowSpan.rowsLeft > 1) {
                        columnsCount += rowSpan.columns;
                        rowSpan.rowsLeft--;
                    }
                }
            }
        }

        return rowsData;
    }

    getVisibleHeight(element) {
        let container = document.getElementById('boxContainer');
        let scrollTop = container.scrollTop;
        let scrollBot = scrollTop + container.clientHeight;
        let containerRect = container.getBoundingClientRect();
        let elementRect = element.getBoundingClientRect();
        let rect = {};
        rect.top = elementRect.top - containerRect.top;
        rect.bottom = elementRect.bottom - containerRect.bottom;
        let elementTop = rect.top + scrollTop;
        let elementBot = elementTop + element.offsetHeight;
        let visibleTop = elementTop < scrollTop ? scrollTop : elementTop;
        let visibleBot = elementBot > scrollBot ? scrollBot : elementBot;

        return visibleBot - visibleTop;
    }

    tempCategory = '';
    handleScroll() {
        let container = document.getElementById('boxContainer');
        let children = container.children;
        var activeId = this.state.activeId;
        var activeCategory = isset(this.props.activeCategory, '');
        for (let id in children) {
            let child = document.getElementById(children[`${id}`].id);
            if (child !== null) {
                let visiblePageHeight = this.getVisibleHeight(child);
                if (visiblePageHeight >= (container.clientHeight / 2) && parseInt(id) !== activeId) {
                    if (typeof this.props.activeCallback === 'function') {
                        this.props.activeCallback(id);
                    }
                    this.setState({ activeId: parseInt(id) }, () => {
                        if (activeCategory !== this.tempCategory)
                            setTimeout(() => {
                                document.getElementById(activeCategory).scrollIntoView();
                            }, 100);
                        this.tempCategory = activeCategory;
                    }); break;
                }
            }
        }
    }

    handleBoxesWithLoading() {
        if (typeof this.props.boxesLoadingCallback === 'function') {
            this.boxesLoading = [];
            for (let box of this.props.data) {
                var boxLoading = box.loading || false;

                if (boxLoading && this.boxesLoading.indexOf(box.id) < 0) {
                    this.boxesLoading.push(box.id);
                }
            }
            if (this.boxesLoading.length > 0)
                this.props.boxesLoadingCallback(this.boxesLoading);
        }
    }

    componentDidMount() {
        this.handleBoxesWithLoading();
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.handleBoxesWithLoading();
        }
    }

    render() {
        const categories = this.arrangeBoxRows(this.props.data);
        var { handleLabel, labels = [], onUpdatedBoxCallback = function () { }, noFilters, overflow = false, instantRender, disabled = false, onFiltersCallback = function () { }, onChartsCallback = function () { }, projectId, type = 'Dashboard', area, noBoxShadow } = this.props;
        var activeId = this.state.activeId;
        var styleBoxTr = {
            width: '100%',
            height: '100%'
        };

        if (type.includes('Analytics')) {
            styleBoxTr.display = 'block';
        }

        if (type === 'BigScreen') {
            styleBoxTr.overflow = 'hidden';
            styleBoxTr.height = '50%';
        }

        return (
            <BoxContainer id='boxContainer' onScroll={() => overflow ? this.handleScroll() : function () { }} overflow={overflow} type={type}>
                {categories.map((rows, key) => {
                    var showElement = (parseInt(key) - 1 === activeId || parseInt(key) === activeId || parseInt(key) + 1 === activeId);
                    return <div id={this.categories[`${key}`]} key={key} data-disable={disabled} style={overflow && showElement ? { display: 'grid', width: 'auto', height: 'fit-content', minHeight: '100%' } : { width: '100%', height: '100%' }}>
                        {((!overflow || showElement) && !this.props.isLoading) && <AnalyticsTable style={overflow && showElement ? { position: 'static' } : {}} type={type} >
                            {rows.map((boxes, rowKey) => {
                                return <tr style={styleBoxTr} key={rowKey}>
                                    {boxes.map((box, boxKey) => {
                                        var columns = box.columns || 1;
                                        var rows = isset(this.props.type, '').includes('Insights') ? 1 : box.rows || 1;
                                        return (<BoxTd noBoxShadow={noBoxShadow} noBackground={issetDot(box, 'items.0.type') === 'feed'} type={type} key={boxKey} colSpan={columns} rowSpan={rows}>
                                            {disabled && <Spinner type='Absolute' />}
                                            <PreventUpdate data={{ box, noFilters, labels, area, projectId, type }}>
                                                <Box isExporting={this.props.isExporting} formatNumber={this.props.formatNumber} seeDetails={this.props.seeDetails} descriptiveTitleVisible={this.props.descriptiveTitleVisible} postSize={this.props.postSize} handleLabel={(labelId) => handleLabel(labelId)} noFilters={noFilters} labels={labels} area={area} key={box.id} data={box} instantRender={overflow ? true : instantRender} projectId={projectId} type={type} onFiltersCallback={onFiltersCallback} onUpdatedBoxCallback={onUpdatedBoxCallback} onChartsCallback={onChartsCallback} />
                                            </PreventUpdate>
                                        </BoxTd>);
                                    })}
                                </tr>;
                            })}
                        </AnalyticsTable>}
                    </div>;
                })}
            </BoxContainer>
        );
    }
}

Analytics.propTypes = {
    /**
     * Analytics data
     */
    data: PropTypes.object,
    /**
     * Analytics type
     */
    type: PropTypes.oneOf(types),
    /**
     * Analytics Labels
     */
    labels: PropTypes.array,
    /**
     * Disable the analytics area
     */
    disabled: PropTypes.bool,
    /**
     * Callback from filters
     */
    onFiltersCallback: PropTypes.func,
    /**
     * Id from project
     */
    projectId: PropTypes.string,
    /**
     * Callback from charts
     */
    onChartsCallback: PropTypes.func
};
