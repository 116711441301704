import React from 'react';
import styled, { keyframes } from 'styled-components';
import Config from '../../../base/config/Config';
import { capitalize, isset } from '../../../base/Utils';
import PropTypes from 'prop-types';

const colors = Config.colors;
export const types = ['Normal', 'Absolute'];
export const sizes = {
    'Medium': {
        border: '1px',
        width: '18px',
        height: '18px'
    },
    'Big': {
        border: '5px',
        width: '40px',
        height: '40px'
    }
};

const SpinnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 12px;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    ${props => props.type === 'Absolute' && { position: 'absolute', top: '0', left: '0' }};
`;

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
`;

const SpinnerLoad = styled.div`
    border: ${props => sizes[`${props.size}`].border} solid transparent;
    border-top: ${props => sizes[`${props.size}`].border} solid ${props => props.color};
    border-right: ${props => sizes[`${props.size}`].border} solid ${props => props.color};
    border-radius: 50%;
    width: ${props => sizes[`${props.size}`].width};
    height: ${props => sizes[`${props.size}`].height};
    animation: ${spin} 0.5s linear infinite;
`;

const Spinner = ({ type, color, size, ...props }) => {
    color = isset(colors[isset(color, '').toLowerCase()], colors.blue);
    var spinnerSizeKeys = Object.keys(sizes);

    size = spinnerSizeKeys.includes(size) ? size : 'Medium';
    return <SpinnerWrapper type={type} {...props}>
        <SpinnerLoad size={capitalize(size)} color={color} />
    </SpinnerWrapper>;
};


Spinner.propTypes = {
    /**
     * Color of the Spinner
     */
    color: PropTypes.string,
    /**
     *Type of spinner
     */
    type: PropTypes.oneOf(types),
    /**
     *
     */
    size: PropTypes.oneOf(Object.keys(sizes))
};

Text.defaultProps = {
    color: 'Blue',
    size: 'Medium'
};

export default Spinner;
