import React from 'react';
import { getCookie, isset, setCookie, t } from '../../../base/Utils';
import i18n from '../../../i18n';
import TourController from '../TourController';
import { sendMessage } from '../../containers/OldPages';

export default () => ([
    {
        disableBeacon: false,
        title: i18n.t('Making your work easier'),
        content: i18n.t('With Swonkie, you can schedule content months ahead. Find more time to do what really matters.'),
        placement: 'center',
        target: 'body',
        nextButtonText: i18n.t('Show me how'),
        style: { minWidth: '600px', },
    },
    {
        target: '[data-id="topbar-menu-schedule"]',
        title: i18n.t('Let’s Plan'),
        content: i18n.t('Click here to start planning your social media content.'),
        showSkipButton: false,
        spotlightClicks: true,
        hideNextButton: true
    },
    {
        target: '#schedule_calendar',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Everything in one place'),
        content: i18n.t('In the calendar, you can see all of your scheduled posts, as well as the ones that are still in the planning phase.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
    },
    {
        target: '.cal-menu',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Filter content'),
        content: i18n.t('Filter your calendar by status, social network, profile, and more.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '.hc-view-mode',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Change the calendar view'),
        content: i18n.t('You can change how you see your calendar. Choose between monthly, weekly, or even a timeline view.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '[data-id="schedule-export-menu"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Export your calendar'),
        content: i18n.t('You can export your calendar in PowerPoint or PDF format, and you can customize what you export.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '[data-id="schedule-share-menu"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Share your calendar with others'),
        content: i18n.t('Share your calendar with others externally, choosing what you want them to see. The link is also completely white-labeled, with no references to Swonkie.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '.hc-today',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Start creating directly on the calendar'),
        content: t(<>Select the day you want to create your scheduled post directly on the calendar. Click the <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>plus icon</span> to see more options.</>),
        showSkipButton: true,
        sleepBeforeStart: 500,
        spotlightClicks: true,
        hideNextButton: true,
        beforeStep: () => {
            sendMessage({ type: 'toggleClass', selector: '.hc-today', className: 'tour-hover' });
        },
        afterStep: () => {
            sendMessage({ type: 'toggleClass', selector: '.hc-today', className: 'tour-hover' });
        }
    },
    {
        target: '[data-id="schedule-context-menu-create-post"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Creating a scheduled post'),
        content: t(<>Click on <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>Publication</span> to see how you can schedule your content for this day.</>),
        showSkipButton: true,
        sleepBeforeStart: 250,
        spotlightClicks: true,
        hideNextButton: false,
        afterStep: () => {
            if (isset(getCookie('contentProducer'), null) === null) {
                TourController.load('Basic.ContentProducer');
                setCookie('contentProducer', 'ScheduleModal');
            }
            else {
                TourController.load('Basic.ScheduleModal');
            }
        }
    }
]);
