
import React, { Component } from 'react';
import i18n from '../../../../../i18n';
import Button from '../../../../components/button/Button';
import Icon from '../../../../components/icon/Icon';
import ProfilePicture from '../../../../components/profilePicture/ProfilePicture';
import ToolTip from '../../../../components/toolTip/Tooltip';
import Text from '../../../../components/text/Text';
import { capitalize, convertDate, formatNumberSpaces, highlightKeyword, isNumeric, isset, issetDot, number, redirect, t, time } from '../../../../../base/Utils';
import styled from 'styled-components';


const ToolTipDataTopFlex = styled.div`
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const ToolTipExtraData = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 16px;
`;

const ToolTiLabelKeyword = styled.div`
    background: ${props => props.sentiment === 'Positive' ? '#16E0A429' : props.sentiment === 'Negative' ? '#FC565314' : '#16E0A429'};
    padding: 2px 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #13BA88;
    border-radius: 16px;
`;

const TooltipTopKeywords = styled.div`
    background: ${props => props.sentiment === 'Positive' ? '#16E0A429' : props.sentiment === 'Negative' ? '#FC565314' : '#F5F5F5'} ;
    padding: 8px;
    margin-top: 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


const MentionArea = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    background: #F5F5F5;
    padding: 8px;
    margin-top: 4px;
`;

const MentionTopArea = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    height: 100%;
    overflow: hidden;
    max-height: 50px;
`;

const MentionTopContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
    overflow: hidden;
`;

const NameDateArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const BottomArea = styled.div`
    display: flex;
    justify-content: space-between;
`;


export default class AnalyticsToolTip extends Component {
    constructor() {
        super();
        this.state = { detailedMention: false };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.top !== this.props.top || prevProps.left !== this.props.left) {
            this.setState({ detailedMention: false });
        }
    }

    handleViewMore(id, date) {
        date = new Date(date * 1000);
        date = date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate();
        localStorage.setItem('mentionData', JSON.stringify({ startDate: date, endDate: date }));
        redirect('/listening/projects/' + id + '/mentions/');
    }

    render() {
        var {
            top = 0,
            left = 0,
            data = [],
            onClose = function () { },
            onMouseLeave = function () { },
            tooltipRef
        } = this.props;
        var { detailedMention } = this.state;

        var tooltipData = {};

        if (data[0] && data[0].simpleData && data[0].detailedData) {
            tooltipData = detailedMention ? data[0].detailedData : data[0].simpleData;
        }

        var yesterdaysDate = Math.round(new Date().getTime() / 1000) - (24 * 3600);

        return <ToolTip ref={tooltipRef} setAdjust={detailedMention} onMouseLeave={onMouseLeave} top={top} left={left} type={detailedMention ? 'MentionDetailed' : 'Mention'}>
            {onClose && detailedMention && <Icon name='Remove' size='Medium' style={{
                position: 'absolute',
                right: '16px',
                top: '16px'
            }} onClick={onClose} color={'Black'} />}
            <>{detailedMention && <Text size='Small' color="Blue">{tooltipData.title}</Text>}
                <ToolTipDataTopFlex>
                    <Text size='Small' color="Grey">{t(tooltipData.date)}</Text>
                    {tooltipData.hour && <Text size='Small' color="Grey">{tooltipData.hour}</Text>}
                </ToolTipDataTopFlex>
                <div style={{ marginBottom: '16px' }}>
                    {detailedMention && <Text size='Medium' color="Black">{i18n.t(tooltipData.label)}</Text>}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {(detailedMention && typeof tooltipData.value === 'object') && <Icon style={{ marginLeft: '-2px' }} size='Big' name={'Emoji ' + (tooltipData.value.sentiment === 'positive' ? 'Happy' : tooltipData.value.sentiment === 'negative' ? 'Sad' : 'Neutral')} color={tooltipData.value.sentiment === 'positive' ? 'Green' : tooltipData.value.sentiment === 'negative' ? 'Red' : 'Grey'} />}
                        <Text style={{ fontSize: '22px' }} color={typeof tooltipData.value === 'object' ? capitalize(tooltipData.value.sentiment) : 'black'}>{typeof tooltipData.value === 'object' ? tooltipData.value.value + '%' : isNumeric(tooltipData.value) ? formatNumberSpaces(tooltipData.value) : tooltipData.value}</Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {detailedMention && tooltipData.subValue && <Text style={{ marginTop: '3px' }} size='Medium' color="black">{isNumeric(tooltipData.subValue) ? formatNumberSpaces(tooltipData.subValue) : tooltipData.subValue}</Text>}
                        {((!detailedMention && tooltipData.label) || tooltipData.subLabel) && <Text size={detailedMention ? 'Tiny' : 'Medium'} color="heavygrey">{i18n.t((!detailedMention ? tooltipData.label : tooltipData.subLabel))}</Text>}
                    </div>
                </div>
                {(!detailedMention && tooltipData.topKeyword) && <div style={{ marginBottom: '16px' }}>
                    <Text size='Tiny' color="Grey">{i18n.t('Most used keyword').toUpperCase()}</Text>
                    <span toolTip={tooltipData.topKeyword} toolTip-position='center'>
                        <ToolTiLabelKeyword>
                            {tooltipData.topKeyword}
                        </ToolTiLabelKeyword>
                    </span>
                </div>}
                {(tooltipData.extraData && !detailedMention) &&
                    <ToolTipExtraData>
                        {isset(tooltipData.extraData, []).map((data, key) => {
                            return <div key={key} style={tooltipData.type === 'Bigger' ? { width: '50%', marginBottom: '8px', display: 'flex', flexDirection: 'column' } : { marginBottom: '8px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Text size='Big' color="black">{isNumeric(data.value) ? formatNumberSpaces(data.value) : data.value}</Text>
                                    <Text size='Small' color="heavygrey">{t(capitalize(data.label))}</Text>
                                </div>
                            </div>;
                        })}
                    </ToolTipExtraData>}
                {(detailedMention && isset(tooltipData.topData, []).length > 0) && <div style={{ marginBottom: '16px' }}>
                    <Text size='Medium'>{t(tooltipData.topDataTitle)}</Text>
                    {isset(tooltipData.topData, []).map((data, key) => {
                        return <TooltipTopKeywords key={key} sentiment={(((key < 1 && !data.sentiment) || data.sentiment === 'positive') ? 'Positive' : data.sentiment === 'negative' ? 'Negative' : '')}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {data.sentiment ? <Icon style={{ marginRight: '4px' }} size='Small' name={'Emoji ' + (data.sentiment === 'positive' ? 'Happy' : data.sentiment === 'negative' ? 'Sad' : 'Neutral')} color={data.sentiment === 'positive' ? 'Green' : data.sentiment === 'negative' ? 'Red' : 'Grey'} />
                                    : <Text style={{ marginRight: '8px' }} size='Tiny' color={key < 1 ? 'Positive' : 'Neutral'}>#{key + 1}</Text>}
                                <Text color={isset(data.sentiment, 'neutral') === 'neutral' ? 'Black' : capitalize(data.sentiment)} style={{ fontSize: (key < 1 && !data.sentiment) ? 22 : 14 }} >{data.label}</Text>
                            </div>
                            <Text size={(key < 1 && !data.sentiment) ? 'Medium' : 'Tiny'} >{isNumeric(data.value) ? formatNumberSpaces(data.value) : capitalize(data.value)}</Text>
                        </TooltipTopKeywords>;
                    })}
                </div>}
                {(detailedMention && tooltipData.mention) && <div style={{ marginBottom: '16px' }}>
                    <Text size='Medium' >{i18n.t('Last Mention')}</Text>
                    <MentionArea>
                        <MentionTopArea>
                            <ProfilePicture size='Small' style={{ marginRight: '8px' }} background={issetDot(tooltipData.mention, 'profile.photo', '')} source={capitalize(issetDot(tooltipData.mention, 'profile.source', ''))} />
                            <MentionTopContent>
                                <NameDateArea>
                                    <Text color="Black" size='Medium' className="maxWidthTopText" >{highlightKeyword(issetDot(tooltipData.mention, 'profile.name', ''), isset(tooltipData.mention.highlights) && tooltipData.mention.highlights.map((keyword) => (keyword.text)))}</Text>
                                    <Text size='Tiny' color="Grey" className="maxWidthTopDate">{tooltipData.mention.publishedTime >= new Date(yesterdaysDate).getTime() ? t(time(tooltipData.mention.publishedTime)) : convertDate(tooltipData.mention.publishedTime, true, true)}</Text>
                                </NameDateArea>
                                <Text whiteSpace='break-spaces' color="Grey" size='Small' >{highlightKeyword(tooltipData.mention.text, isset(tooltipData.mention.highlights) && tooltipData.mention.highlights.map((keyword) => (keyword.text)))}</Text>
                            </MentionTopContent>
                        </MentionTopArea>
                        <BottomArea>
                            {tooltipData.mention.estReach ? <Text text={number(tooltipData.mention.estReach, 0) + ' ' + i18n.t('Est. Reach')} color="Grey" size='Tiny' /> : '‎'}
                            <Icon tooltip={i18n.t(capitalize(tooltipData.mention.sentiment))} size='Small' name={tooltipData.mention.sentiment === 'positive' ? 'Emoji Happy' : tooltipData.mention.sentiment === 'neutral' ? 'Emoji Neutral' : 'Emoji Sad'} color={tooltipData.mention.sentiment === 'positive' ? 'Green' : tooltipData.mention.sentiment === 'neutral' ? 'Grey' : 'Red'} />
                        </BottomArea>
                    </MentionArea>
                </div>}
                {(data[0].detailedData.mention) && <Button style={{
                    padding: '0px',
                    margin: '0px',
                    height: 'auto',
                    width: 'fit-content',
                    fontSize: '10px',
                    borderRadius: '0px'
                }} text={detailedMention || tooltipData.nonAdvanced ? i18n.t('View more mentions').toUpperCase() : i18n.t('More details').toUpperCase()} type='Link' onClick={() => detailedMention ? this.handleViewMore(tooltipData.projectId, tooltipData.mention.publishedTime) : this.setState({ detailedMention: true })} />}
            </>
        </ToolTip>;
    }
}
