import React, { Component } from 'react';
import Button from '../button/Button';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Icon from '../icon/Icon';
import i18n from '../../../i18n';
import styled from 'styled-components';
import Text from '../text/Text';
import { getKBUrl, isset, redirect } from '../../../base/Utils';
import FullPage, { PageContent, PagePriority } from '../fullPage/FullPage';
import Config from '../../../base/config/Config';
import ReactPlayer from 'react-player';
import Carousel from 'react-elastic-carousel';
import { sendMessage } from '../../containers/OldPages';
import PopupRequest from './PopupRequest';

export const sides = {
    'Left': 'flex-start',
    'Center': 'center',
    'Right': 'flex-end'
};

const MainPopupArea = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	background: rgba(0, 0, 0, 0.32);
    overflow: auto;
    cursor: default;
`;

const MainPopupCover = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
	display: flex;
	align-items: center;
	justify-content: ${props => isset(sides[props.side], 'center')};
    width: 100%;
    min-height: 100%;
    height: ${props => props.fullHeight ? '100%' : 'auto'};
`;

const MainPopup = styled.div`
    position: relative;
    background: white;
    box-shadow: 0px 19px 38px rgba(0, 0, 0, 0.26);
    border-radius: ${props => isset(sides[props.side], 'center') !== 'center' ? '0px' : '4px'};
    box-sizing: border-box;
    flex-direction: column;
    cursor: default;
    animation: SWONKIE__middle 0.1s;
    width: ${props => props.width && props.width};
    height: ${props => props.fullHeight ? '100%' : 'auto'};
    padding: 0px 16px;
`;

const TopContent = styled.div`
    width: 100%;
    height: auto;
    box-sizing: border-box;
`;

const MainTopContent = styled.div`
    display: flex;
    align-items: center;
    margin-top: 12px;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 1;
`;

const BottomContent = styled.div`
    width: 100%;
    padding: 8px;
    padding-left: 0;
    box-sizing: border-box;
`;

const HelpButton = styled.div`
    border: 1px solid ${Config.colors.grey};
    border-radius: 4px;
    padding: 0px 4px;
    display: flex;
    align-items: baseline;
    cursor: pointer;
`;

const StepBar = styled.div`
    width: 100%;
    height: 8px;
    border-radius: 136px;
    opacity: ${props => props.active ? 1 : 0.16};
    background: #0A5C9C;
`;

class Popup extends Component {

    componentDidMount() {
        Modal.setAppElement('body');
    }

    render() {
        var {
            children = '',
            title = '',
            isOpen = false,
            closeButton = false,
            closeOutside = false,
            data = [],
            bottomContent = [],
            width = '450',
            side = 'center',
            fullHeight = false,
            color = 'Black',
            icon,
            removeOverflow = false,
            helpPage,
            description,
            stepBars,
            activeStepBars,
            ...props
        } = this.props;

        var stepBarsArray = [];
        for (var id = 0; id < stepBars; id++) {
            stepBarsArray.push('');
        }

        data = data.map((set) => {
            var {
                align = '',
                onClick = function () { },
                component = null,
                ...componentProps
            } = set;

            if (component) {
                bottomContent.push(component);
            } else {
                bottomContent.push(<Button {...componentProps} onClick={() => onClick(this)} style={{ float: align === 'right' ? 'right' : 'left', marginLeft: '16px    ' }} />);
            }
            return null;
        });

        if (width !== 450) {
            if (width.indexOf('%') === -1) {
                width = width + 'px';
            }
        }

        var customStyle = {};
        if (removeOverflow) {
            customStyle = { overflow: 'initial' };
        }

        if (isOpen) {
            return <MainPopupArea onClick={closeOutside ? (e) => { e.stopPropagation(); this.props.onCallbackPopup(false); } : null}>
                <MainPopupCover side={side} fullHeight={fullHeight}>
                    <MainPopup onClick={(e) => e.stopPropagation()} side={side} fullHeight={fullHeight} transparent={true} width={width} overlayClassName="Popup" contentLabel={this.props.label} {...props}>
                        <FullPage>
                            <PageContent>
                                <TopContent>
                                    {stepBarsArray.length > 0 && <div style={{ display: 'flex', marginBottom: '16px', marginTop: '24px' }}>
                                        {stepBarsArray.map((stepBar, key) => {
                                            return <StepBar key={key} active={key < activeStepBars} style={{ marginRight: stepBarsArray.length - 1 === key ? '0px' : '10px' }} />;
                                        })}
                                    </div>}
                                    <MainTopContent>
                                        {icon && <Icon color={color} name={icon} style={{ marginRight: '16px' }} />}
                                        <Text color={color} weight="SemiBold" size='Biggest'>{title}</Text>
                                        {helpPage && <HelpButton style={{ marginLeft: '16px' }} onClick={() => redirect(getKBUrl(helpPage), '_blank')}>
                                            <Icon style={{ marginRight: '4px' }} color='Grey' size='Tiny' name='Help Circle' />
                                            <Text color='Grey' size='Tiny'>{i18n.t('Need Help?')}</Text>
                                        </HelpButton>}
                                    </MainTopContent>
                                    {description && <Text color='Grey' style={{ marginTop: '16px', display: 'flex' }} >{i18n.t(description)}</Text>}
                                    <CloseButton>
                                        {closeButton ? <Icon size="Medium" name="Remove" color="Black" onClick={(e) => { e.stopPropagation(); this.props.onCallbackPopup(false); }} /> : null}
                                    </CloseButton>
                                </TopContent>
                            </PageContent>
                            <PagePriority style={customStyle}>
                                {children}
                            </PagePriority>
                            {data.length > 0 && <BottomContent>
                                {bottomContent}
                            </BottomContent>}
                        </FullPage>
                    </MainPopup>
                </MainPopupCover>
            </MainPopupArea >;
        }
        return <></>;
    }
}

Popup.propTypes = {
    /**
     * Choose which side is from the popup
     */
    side: PropTypes.oneOf(Object.keys(sides)),
    /**
     * Trigger button/component to open popup
     */
    trigger: PropTypes.any,
    /**
     * Width of the popup
     */
    width: PropTypes.string,
    /**
     * Title of the popup
     */
    title: PropTypes.string,
    /**
     * Bottom buttons/text
     */
    data: PropTypes.any,
    /**
     * Content of the popup
     */
    children: PropTypes.any,
    /**
     * Close button
     */
    closeButton: PropTypes.bool,
    /**
     *  Height of popup
     */
    fullHeight: PropTypes.bool,
    /**
     *  Popup step bars
     */
    stepBars: PropTypes.number,
    /**
     *  Popup active step bars
     */
    activeStepBars: PropTypes.number,
};

export default Popup;

const MainTextArea = styled.div`
    display: flex;
    flex-direction: column;
`;

export class PermissionsPopup extends Component {
    onConfirmPopup() {
        this.props.onClose(false);
    }
    render() {
        var isOpen = this.props.isOpen || false;
        return (
            <Popup
                isOpen={isOpen}
                width="560"
                title={i18n.t('You dont have permissions to do this')}
                color='Red'
                icon='Warning Sign'
                onClose={this.ClosePopupDeleteProject}
                data={[{
                    text: i18n.t('I Understand'),
                    type: 'Default',
                    align: 'right',
                    onClick: () => this.onConfirmPopup()
                }]}
            >
                <MainTextArea>
                    <Text size='Big' color="Grey" style={{ marginTop: '16px' }}>{i18n.t('Your account does not have permission to do this action.')}</Text>
                    <Text size='Big' color="Grey" style={{ marginBottom: '24px' }}>{i18n.t('Contact your account administrator to review your permissions.')}</Text>
                </MainTextArea>
            </Popup>
        );
    }
}


export class TutorialVideosPopup extends Component {

    tutorialVideos = []
    currentVideo = {}
    autoPlaying = true
    componentDidMount() {
        PopupRequest.getTutorialVideos((response) => {
            if (isset(response.status, '') === 'ok') {
                var data = isset(response.data, {});
                this.tutorialVideos = isset(data.tutorialVideos, []);
                this.tempVideos = isset(data.seenVideos, []);

                this.handleCloseTutorialPopup();

                this.currentVideo = this.tutorialVideos[0];
                for (let tutorialVideo of this.tutorialVideos) {
                    var id = this.tempVideos.findIndex(video => video === tutorialVideo.id);
                    if (id < 0) {
                        this.currentVideo = tutorialVideo;
                        break;
                    }
                }
                this.forceUpdate();
            }
            else {
                this.handleCloseTutorialPopup();
            }
        });
    }

    getOtherVideo(video) {
        this.currentVideo = video;
        this.autoPlaying = false;
        this.forceUpdate();
    }

    tempVideos = []
    previousVideo = {}
    handleTutorialVideo(event) {
        if (this.currentVideo.id === this.previousVideo.id) {
            var seconds = parseInt(event.playedSeconds);
            if (seconds > 10 && this.previousVideo.id === this.currentVideo.id && !this.tempVideos.find(video => video === this.currentVideo.id)) {
                PopupRequest.postTutorialVideos({ videoId: this.currentVideo.id }, (response) => {
                    if (isset(response.status, '') === 'ok') {
                        this.tempVideos.push(this.currentVideo.id);
                        this.forceUpdate();
                    }
                });
            }
        }
        else {
            this.previousVideo = this.currentVideo;
        }
    }

    handleCloseTutorialPopup(scheduleDemo) {
        if (scheduleDemo) {
            sendMessage({ type: 'openDemoForm' });
        }
        if (isset(this.props.onCallback)) {
            this.props.onCallback();
        }
        this.forceUpdate();
    }

    handleNextVideo() {
        var index = this.tutorialVideos.findIndex((tutorialVideo) => tutorialVideo.video === this.currentVideo.video);
        this.currentVideo = isset(this.tutorialVideos[parseInt(index) + 1], this.tutorialVideos[0]);
        this.autoPlaying = false;
        this.forceUpdate();
    }

    render() {
        return <Popup
            removeOverflow
            isOpen={this.tutorialVideos.length > 0 && this.props.isOpen}
            title={i18n.t('Tutorial Videos')}
            width={'1040'}
            data={
                [{
                    text: i18n.t('Schedule a Demo'),
                    type: 'Yellow',
                    align: 'right',
                    onClick: () => this.handleCloseTutorialPopup('schedule')
                },
                {
                    text: i18n.t('Close'),
                    type: 'Tertiary',
                    align: 'right',
                    onClick: () => this.handleCloseTutorialPopup()
                }
                ]}
        >
            <div style={{ width: '100%', height: '555px', background: '#DEDEDE' }}>
                <ReactPlayer onProgress={(e) => this.handleTutorialVideo(e)} onEnded={() => this.handleNextVideo()} controls playing={this.autoPlaying} width='100%' height='100%' url={this.currentVideo.video} />
            </div>
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '16px' }}>
                <div style={{ cursor: 'pointer' }} onClick={() => this.carousel.slidePrev()}> <Icon name="Chevron Left" color="black" size="Big" className="IconShadow" /> </div>
                <div style={{ height: '60px', width: '100%' }}>
                    <Carousel itemPadding={[0, 8]} ref={ref => (this.carousel = ref)} showArrows={false} pagination={false} enableSwipe={false} itemsToScroll={1} itemsToShow={8}>
                        {this.tutorialVideos.map((video, key) => {
                            return <div key={key} style={{ height: '60px', width: '100%', maxWidth: '106px', border: this.currentVideo.id === video.id ? '3px solid #0a5c9c' : '3px solid transparent' }}>
                                <img alt='' onClick={() => this.getOtherVideo(video)} style={{ height: '100%', background: '#9E9E9E', width: '100%', objectFit: 'contain', filter: this.tempVideos.find(tempVideo => tempVideo === video.id) && 'grayscale(100%) opacity(50%)' }} src={video.thumbnail} />;
                            </div>;
                        })}
                    </Carousel>
                </div>
                <div style={{ cursor: 'pointer' }} onClick={() => this.carousel.slideNext()}><Icon name="Chevron Right" color="black" size="Big" className="IconShadow" /></div>
            </div>
        </Popup >;
    }
}
