import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import styled from 'styled-components';
import Icon from '../icon/Icon';
import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';

const positions = ['Left', 'Right', 'Center', 'Normal'];
const MainDropdownContent = styled.div`
    display: ${props => props.horizontal ? 'flex' : 'block'};
    box-shadow:0px 9px 13px #00000042;
    border-radius: 4px;
    width: ${props => props.horizontal ? 'auto' : props.width};
    background: white;
    position: absolute;
    z-index: 200;
    ${props => props.subDropdown && { top: '0', left: '100%' }}
    ${props => props.position === 'Left' ? { left: '-' + props.width } : props.position === 'Right' ? { right: '0px' } : props.position === 'Center' ? { left: 'calc(-' + props.width + ' / 2)' } : ''}
    padding: 8px 0px;
`;

const MainDropdownTrigger = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
    ${props => props.isOpen && `color: ${Config.colors.blue};`}
    position: relative;
    justify-content: ${props => props.hasBorder && 'space-between'};
    padding: ${props => props.hasBorder && '5px 7px 5px 10px'};
`;

const MainDropdown = styled.div`
    position: relative;
    border: ${props => props.hasBorder && `1px solid  ${props.isOpen ? '#0A5C9C' : '#DEDEDE'}`};
    border-radius: 135px;
    &:hover{
        border-color: #0A5C9C;
    }
`;
class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = { isOpen: false };
    }

    container = React.createRef();
    content = React.createRef();

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.container.current && !this.container.current.contains(event.target)) {
            this.setState({ isOpen: false });
            if (isset(this.props.onCallback)) {
                this.props.onCallback(false);
            }
        }
    };

    autoAdjust() {
        var { bottom = 0 } = this.content.current.getBoundingClientRect();

        var { innerHeight = 0 } = window;
        var outBottom = (innerHeight < bottom);

        if (outBottom) {
            this.content.current.style.bottom = this.container.current.clientHeight + 'px';
        }
    }

    toggleOpen(isOpen) {
        this.setState({ isOpen: !isOpen }, () => {
            if (!isOpen)
                this.autoAdjust();
        });
    }

    overOpen(timeout) {
        setTimeout(() => this.setState({ isOpen: true }), timeout);
    }

    overClose() {
        this.setState({ isOpen: false });
    }

    render() {
        var {
            trigger = '',
            children = '',
            isOpen = this.state.isOpen || false,
            disabled = false,
            subDropdown = false,
            timeout = 0,
            horizontal = false,
            arrow = false,
            width = 'auto',
            hasBorder = false,
            position = 'Left',
            triggerOnClick = function () { },
            contentStyle = {},
            tooltip = '',
            arrowColor = 'Black',
            ...props
        } = this.props;

        return <MainDropdown tooltip={tooltip} data-disable={disabled} hasBorder={hasBorder} isOpen={isOpen} onClick={disabled ? function () { } : (e) => { this.toggleOpen(isOpen); e.stopPropagation(); }} onMouseOver={subDropdown ? disabled ? () => this.setState({ isOpen: false }) : () => this.overOpen(timeout) : function () { }} onMouseLeave={subDropdown ? () => this.overClose() : function () { }} ref={this.container} {...props}>
            <MainDropdownTrigger onClick={triggerOnClick} hasBorder={hasBorder} >
                {trigger}
                {arrow && <Icon color={isOpen ? 'Blue' : arrowColor} name={isOpen ? 'Caret Up' : 'Caret Down'} />}
            </MainDropdownTrigger>
            {isOpen && <MainDropdownContent style={contentStyle} ref={this.content} position={position} width={width} horizontal={horizontal} subDropdown={subDropdown}>{children}</MainDropdownContent>}
        </MainDropdown>;
    }
}


Dropdown.propTypes = {
    /**
     *  Dropdown Trigger
     */
    trigger: PropTypes.any,
    /**
     *  Dropdown Children
     */
    children: PropTypes.any,
    /**
     * Make the the dropdown if is Open
     */
    isOpen: PropTypes.bool,
    /**
     *
     */
    disabled: PropTypes.bool,
    /**
     *
     */
    subDropdown: PropTypes.bool,
    /**
     *
     */
    timeout: PropTypes.number,
    /**
     *
     */
    horizontal: PropTypes.bool,
    /**
     *
     */
    arrow: PropTypes.bool,
    /**
     *
     */
    position: PropTypes.oneOf(positions)
};

Dropdown.defaultProps = {
    trigger: '',
    children: '',
    disabled: false,
    subDropdown: false,
    timeout: 0,
    horizontal: false,
    position: 'Normal'
};

const MainDropdownItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    box-sizing: border-box;
    color: #212121;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    width: 100%;
    ${props => props.selected && 'background-color: rgba(10, 92, 156, 0.08);'}
    &:hover {
        background: rgba(0, 0, 0, 0.05);
    }
    .Caret.Down {
        transform: rotate(-90deg);
    }
    `;


export const DropdownItem = ({ children, target, onClick, selected, arrow, tooltip, ...props }) => {
    if (target != null) {
        if (target.substr(0, 4) === 'http') {
            return <a href={target}><MainDropdownItem selected={selected} {...props}>{children}</MainDropdownItem></a>;
        } else {
            return <Link to={target}><MainDropdownItem selected={selected} {...props}>{children}</MainDropdownItem></Link>;
        }
    } else {
        return <span tooltip-position='center' tooltip={tooltip}><MainDropdownItem selected={selected} onClick={onClick} {...props}>{children} {arrow && <Icon size='Big' name="Caret Down" />}</MainDropdownItem></span>;
    }
};

DropdownItem.propTypes = {
    /**
     * children for dropdown content Item
     */
    children: PropTypes.any,
    /**
     * Dropdown item send the user to other external or internal page
     */
    target: PropTypes.string,
    /**
     * Dropdown item make an action event
     */
    onClick: PropTypes.func,
    /**
     * Check if the dropdown is selected or not
     */
    selected: PropTypes.bool
};

DropdownItem.defaultProps = {
    children: '',
    target: null,
    onClick: function () { },
    selected: false
};

const MainDropdownTitle = styled.div`
    font-size: 13px;
    color: #9e9e9e;
    margin-top: 8px;
    margin-left: 8px;
    text-align: left;
`;

export const DropdownTitle = ({ children, ...props }) => {
    return <MainDropdownTitle {...props}>{children}</MainDropdownTitle>;
};

DropdownTitle.propTypes = {
    /**
     * children for dropdown content title
     */
    children: PropTypes.any,
};

DropdownTitle.defaultProps = {
    children: '',
};

const MainDropdownSeparator = styled.hr`
    display: block;
    height: 1px;
    border: 0;
    padding: 0;
    margin: 8px 0 !important;
    border-color: #eeeeee !important;
    border-top: 1px solid #ccc;`;

export const DropdownSeparator = ({ ...props }) => {
    return <MainDropdownSeparator {...props} />;
};

export default Dropdown;
