import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Icon from '../icon/Icon';
import { capitalize, isset } from '../../../base/Utils';
import defaultImage from '../../../resources/images/no_profile.png';

const sizes = {
    'Tiny': '16px',
    'Small': '24px',
    'Medium': '30px',
    'Big': '40px',
    'Huge': '80px'
};

const MainProfilePicture = styled.div`
    min-width: ${props => isset(sizes[props.type], sizes['Small'])};
    width: ${props => isset(sizes[props.type], sizes['Small'])};
    height: ${props => isset(sizes[props.type], sizes['Small'])};
    position: relative;
    display: inline-block;
    margin: 0;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border-color: transparent;
    background-size: cover;
    background-image: ${`url(${defaultImage})`};
    background-position: center;
`;

const Source = styled.div`
    position: absolute;
    background-color: white;
    right: -2px;
    bottom: -2px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ProfilePicture = ({ type, style, source, background, tooltip, ...props }) => {
    source = isset(source) && capitalize(source);
    if (background === null || background === '' || !isset(background)) background = defaultImage;

    return (
        <MainProfilePicture type={type} style={style} {...props}>
            <span style={{ display: 'block', height: '100%', width: '100%' }} tooltip={tooltip} tooltip-position='center'>
                <Image src={background} onError={(e) => {
                    e.target.src = defaultImage;
                }} />
                {source && <Source source><Icon color={source} name={source} size={type === 'Huge' ? 'Huge' : 'Medium'} /></Source>}
            </span>
        </MainProfilePicture>
    );
};


ProfilePicture.propTypes = {
    /**
     * Profile Picture
     */
    background: PropTypes.string,
    /**
     * Social Network icon
     */
    source: PropTypes.oneOf(['Facebook', 'Twitter', 'Pinterest', 'Linkedin', 'Instagram', 'Youtube', 'Web', 'Reddit']),
    /**
     * Type (size) of the Profile Picture
     */
    type: PropTypes.oneOf(['Small', 'Normal', 'Medium', 'Big']),
};

ProfilePicture.defaultProps = {
    background: defaultImage,
    type: 'Normal',
};


export default ProfilePicture;
