import i18n from '../../../i18n';

export default () => ([
    {
        target: '[data-id="andie-side-chat"] #andie-apply-all .andie-options',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Apply content in a snap!'),
        content: i18n.t('Use the generated content on your publications effortlessly by simply clicking the "Yes, apply it" button.'),
        hideNextButton: true,
        disableBeacon: true,
        spotlightClicks: true
    }
]);
