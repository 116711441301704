import i18n from '../../../i18n';

export default () => ([
    {
        target: '[data-id="produce-content-andie-history"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Revisit past content creations'),
        content: i18n.t('Expand the content history and see all the content that ANDIE has generated for you.'),
        disableBeacon: true,
        nextButtonText: i18n.t('Okay')
    }
]);
