import { redirect } from '../../../base/Utils';
import React from 'react';

export default [
    {
        target: '[data-id="topbar-menu-dashboard"]',
        title: 'Me here',
        content: 'É aqui que vês os dados!',
        afterStep: () => {
            redirect('/dashboard');
        }
    },
    {
        target: '[data-id="dashboard-add-networks-button"]',
        waitForTarget: true,
        getFromLegacy: true,
        content: 'Quando clicas aqui vais adicionar redes sociais.',
        disableBeacon: true
    },
    {
        waitForTarget: true, // If the target is excepted not to be on screen and needs to be waiting on (default: false)
        getFromLegacy: true, // If the target is on legacy code or not (default: false, requires waitForTarget option to work)
        disableBeacon: true, // If the target should be pointed with a beacon or highlighted right away (default: false, only available when getFromLegacy: false)
        target: '[data-id="dashboard-calendar-schedules-add-button"]', // Unique target identified, accepts CSS selector but must always be a [data-id=*] (required: true)
        title: 'I have everything!', // The step title (default: empty)
        content: <>A beautiful day to see a <b>Golden Retriever</b> <text style={{ textTransform: 'uppercase', color: 'pink' }}>smile</text>!<br /><br /><img width='360px' src='https://t0.gstatic.com/licensed-image?q=tbn:ANd9GcQkrjYxSfSHeCEA7hkPy8e2JphDsfFHZVKqx-3t37E4XKr-AT7DML8IwtwY0TnZsUcQ' /></>, // The step content (required: true)
        hideBackButton: false, // If the step should hide the back button or not (default: true)
        backButtonText: 'Don\'t Go Back', // The Back Button Text (default: Back, the default is translated, custom wont)
        hideNextButton: false, // If the step should hide the next button or not (default: false)
        nextButtonText: 'Forward is the way', // The Next Button Text (default: Next or Finish, the default is translated, custom wont)
        showSkipButton: true, // If the step should show the ship button or not (default: false)
        skipButtonText: 'Don\'t Click Me Please', // The Skip Button Text (default: Skip, the default is translated, custom wont)
        spotlightClicks: true, // If the user is allowed to click on the spotlighted area or not (default: false)
        nextOnSpotlightClicks: true, // If the tour should continue when the users clicks the spotlighted area or not (default: true, requires spotlightClicks option to work)
        placement: 'left', // Where should the Tooltip appear in relation to the highlighted target (default: auto) [to place like a modal in the center of the screen, set this to 'center' and target: 'body']
        //sleepBeforeStart: 5000, // Time to wait before showing step (default: 0, in milliseconds)
        style: { minWidth: '380px', minHeight: '360px' },
        floaterProps: { hideArrow: true },
        beforeStep: () => { // Callback to execute before the step load (required: false)
            alert('Just showing off before step!');
        },
        afterStep: () => { // Callback to execute after continue to next step (required: false)
            alert('Just showing off after step!');
        }
    },
    {
        target: '[data-id="topbar-menu-produce-content"]',
        content: 'Vamos produzir conteudo!',
        disableBeacon: true,
        spotlightClicks: true,
        hideNextButton: true
    },
    {
        waitForTarget: true,
        getFromLegacy: true,
        target: '[data-id="produce-content-andie-compose-button"]',
        content: 'Clicas aqui para a Andie fazer o trabalho por ti!',
        disableBeacon: true,
        spotlightClicks: true,
        hideNextButton: true
    },
    {
        target: '.sweet-alert:first-of-type', //'[data-id="andie-compose-form"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: 'Optimize Your Content for Every Social Network!',
        content: 'With Optimize Social Networks ANDIE will customize your content to suit the unique characteristics of each network. Whether it\'s adhering to character limits or using the optimal number of hashtags, your content will be tailored to excel on every platform. Once you have two or more social networks selected, you can use this feature.',
        placement: 'top-start',
        disableBeacon: true,
        spotlightClicks: true,
        nextOnSpotlightClicks: false,
        sleepBeforeStart: 500
    },
    {
        target: '[data-id="andie-compose-submit"]',
        content: 'E clicas aqui para gerar o conteúdo!',
        waitForTarget: true,
        getFromLegacy: true,
        spotlightClicks: true,
        hideNextButton: true
    },
    {
        target: '.andie-chat-message:last-of-type .andie-options:first-of-type',
        waitForTarget: true,
        getFromLegacy: true,
        content: 'A andie é demais, aplica',
        disableBeacon: true,
        sleepBeforeStart: 1000
    },
    {
        content: 'Pronto, feito!',
        placement: 'center',
        target: 'body'
    }
];
