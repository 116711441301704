import React, { Component } from 'react';
// import Utils from '../../../base/Utils';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import ProgressBar from '../progressBar/ProgressBar';
import Text from '../text/Text';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { capitalize, convertDate, isset, redirect, t } from '../../../base/Utils';
import ActionUtils from '../../../base/ActionUtils';
import Session from '../../../base/Session';
import styled from 'styled-components';


// SOUND
// import alert from '../../../resources/audio/alert.mp3';

// let audio = new Audio(alert)

const PriorityColors = {
    'success': '#1FBF85',
    'warning': '#FBBD08',
    'danger': '#EF5350'
};


const NotificationBar = styled.div`
	height: auto;
	cursor: pointer;
	border-radius: 4px;
	max-width: 410px;
    padding: 8px;
    background-color: white;
    ${props => props.boxEnable && { boxShadow: '0px 9px 13px rgba(0, 0, 0, 0.16)' }}
`;

const NotificationTopContent = styled.div`
	width: 100%;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	margin-bottom: 16px;
`;

const NotificationPriority = styled.div`
	height: 22px;
    padding: 0px 12px;
	border-radius: 13px;
	color: white;
	font-size: 10px;
	display: flex;
	align-items: center;
	font-weight: 500;
	text-transform: uppercase;
	margin-right: 8px;
    ${props => isset(PriorityColors[props.priority]) ? { background: PriorityColors[props.priority] } : { visibility: 'collapse', display: 'none' }}
`;

const NotSeenCircle = styled.div`
	background: #0E8AE8;
	height: 16px;
	width: 16px;
	border-radius: 50%;
`;

const NotificationBottomContent = styled.div`
	display: flex;
    flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
`;

const NotificationMessageArea = styled.div`
    display: flex;
    align-items: flex-start;
    margin-bottom: ${props => props.type !== 'loading' ? '25px' : '8px'};
    width: 100%;
`;

const NotificationButtonsArea = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

const NotificationImage = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 16px;
`;

export default class Notifications extends Component {
    activeTeam = null;

    constructor() {
        super();
        var { activeTeam = null } = Session.getParams();
        this.activeTeam = activeTeam;
    }

    render() {
        var { title = 'General', date, message = '', type = 'normal', image = '', boxEnable = true, buttonArea = [], onClose = null, seen = false, onClick = function () { }, priority = 'info' } = this.props;

        image = (image === '' ? require('../../../resources/images/notification.png') : image);
        date = convertDate(date, true);

        return <NotificationBar onClick={onClick} boxEnable={boxEnable} >
            <NotificationTopContent>
                <div style={{ display: 'flex', alignItems: 'center' }} >
                    <NotificationPriority priority={priority}>{i18n.t(capitalize(priority))}</NotificationPriority>
                    <Text style={{ marginRight: '4px' }} color="Blue" size="Small">{i18n.t(title)}</Text>
                    <Text color="Grey" size="Small">• {date}</Text>
                </div>
                {boxEnable && <Icon name="Remove" size="Medium" color="Dark" onClick={onClose} />}
                {!seen && !boxEnable && <NotSeenCircle />}
            </NotificationTopContent>
            <NotificationBottomContent>
                <NotificationMessageArea type={type} >
                    {type !== 'loading' && <NotificationImage alt="" src={image} />}
                    <Text color="black" size="Medium">{t(message)}</Text>
                </NotificationMessageArea>
                {type === 'loading' && <div style={{ width: '100%' }}>
                    <ProgressBar type="animated" />
                </div>}
                {type !== 'loading' && <NotificationButtonsArea>
                    <Icon color="Grey" name="Ellipsis Vertical" />
                    <div>
                        {buttonArea.map((button, key) => {
                            var props = {};
                            var {
                                type = 'redirect',
                                text = '',
                                execute = null,
                                data = {},
                                activeTeam = null,
                                reload = false
                            } = button;

                            var callbackFunction = null;

                            if (type === 'function') {
                                if (isset(ActionUtils[`${execute}`])) {
                                    callbackFunction = () => ActionUtils[`${execute}`](data);
                                } else {
                                    return null;
                                }
                            } else {
                                callbackFunction = () => redirect(data);
                            }

                            if (activeTeam === this.activeTeam || !reload) {
                                props.onClick = callbackFunction;
                            } else {
                                if (activeTeam) {
                                    props.onClick = () => {
                                        Session.handleTeam(activeTeam, callbackFunction);
                                    };
                                } else {
                                    props.onClick = () => {
                                        Session.handleLogoutTeam(callbackFunction);
                                    };
                                }
                            }

                            return <Button key={key} type="Tertiary" {...props} >{i18n.t(text)}</Button>;
                        })}
                    </div>
                </NotificationButtonsArea>}
            </NotificationBottomContent>
        </NotificationBar>;
    }
}

export const types = ['normal', 'loading'];

export const priorities = ['info', 'success', 'warning', 'danger', 'loading'];

Notifications.propTypes = {
    /**
     *  Notification title
     */
    title: PropTypes.string,
    /**
     * Notification date
     */
    date: PropTypes.string,
    /**
     * Notification message
     */
    message: PropTypes.string,
    /**
     * Notification type
     */
    type: PropTypes.oneOf(types),
    /**
     * Notification image
     */
    image: PropTypes.string,
    /**
     * Notification it's like a box or not
     */
    boxEnable: PropTypes.bool,
    /**
     * Notification Buttons
     */
    buttonArea: PropTypes.array,
    /**
     * Notification close button function
     */
    onClose: PropTypes.array,
    /**
     * Notification has been seen or not
     */
    seen: PropTypes.bool,
    /**
     * Notification function
     */
    onClick: PropTypes.func,
    /**
     * Notification Priority
     */
    priority: PropTypes.oneOf(priorities)
};
