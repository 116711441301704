import React from 'react';
import './Export.scss';
import { capitalize, clone, convertDate, convertDateFormat, getBrowserLanguage, isset, issetDot, t } from '../../../base/Utils';
import Icon from '../../components/icon/Icon';
import ProfilePicture from '../../components/profilePicture/ProfilePicture';
import Text from '../../components/text/Text';
import i18n from '../../../i18n';
import Button from '../../components/button/Button';
import swonkieLogo from '../../../resources/images/swonkieLogo.svg';
import Dropdown, { DropdownItem } from '../../components/dropdown/Dropdown';
import ExportReports from '../../classes/ExportReports';
import Config from '../../../base/config/Config';
import Box from '../../components/box/Box';
import RearrangeBox from './RearrangeBox';
import ProgressBar from '../../components/progressBar/ProgressBar';
import { checkLine, ExportPost } from '../../components/post/Post';
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import image from '../../../resources/images/reports_adv_illustration_2_en.png';

var zoomValues = [10, 25, 50, 75, 100, 125, 150, 200];

class Export extends React.Component {
    exportGenerator = null;
    constructor(props) {
        super(props);
        this.state = { categories: [], data: {}, buttonType: 'PDF', mentions: [], zoomValue: 100, page: 1, arrayChanged: 0, maxPage: 2, boxes: [], loading: true };
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.csvLink = React.createRef();
        this.state.data = clone(isset(props.data, JSON.parse(localStorage.getItem('data'))));
        if (!Array.isArray(this.state.data)) {
            this.state.data = [this.state.data];
        }
        i18n.changeLanguage(isset(this.state.data[0].exportLanguage, getBrowserLanguage()));
        this.state.loadingText = i18n.t('Getting your file ready...');
        this.parentRef = React.createRef();
    }

    handleCategory(categories, boxes, title) {
        var tempCategories = [];
        if (typeof categories === 'object') {
            categories = Object.values(categories);
        }
        isset(categories, []).map((category) => {
            var boxId = boxes.findIndex(box => box.id === category.id);
            if (boxId >= 0 && tempCategories.findIndex(tempCategory => tempCategory.title === isset(title, category.title))) {
                tempCategories.push({ id: boxes[`${boxId}`].id, title: isset(title, category.title) });
            }
            else {
                tempCategories = [...tempCategories, ...this.handleCategory(isset(category.items, []), boxes, isset(title, category.title))];
            }
            return null;
        });
        return tempCategories;
    }

    handleData(exportType, load) {
        var { data, buttonType, page, mentions } = this.state;
        if ((exportType === 'CSV' || exportType === 'XLS')) {
            data = isset(this.props.data, JSON.parse(localStorage.getItem('data')));
            if (!Array.isArray(data)) {
                data = [data];
            }
        }
        buttonType = data[0].exportType;

        var boxes = [];
        var dataArray = [];

        for (let value of data) {
            exportType = isset(exportType, value.exportType);
            if (value.type === 'Mentions') {
                mentions = isset(value.mentions, []);
                let array = [];
                let maxNumber = 0;
                for (var mention of mentions) {
                    mention.text = checkLine(mention.text, 1300, 15);
                    var span = document.createElement('SPAN');
                    span.innerHTML = mention.text;
                    span.style.width = this.postBounding.width + 'px';
                    span.style.whiteSpace = 'break-spaces';
                    span.style.maxHeight = '350px';
                    span.style.overflow = 'hidden';
                    span.style.display = 'flex';
                    document.body.appendChild(span);
                    var height = span.getBoundingClientRect().height;
                    maxNumber += (height + this.postBounding.height);
                    if (maxNumber <= this.parentRef.current.getBoundingClientRect().height) {
                        array.push(mention);
                    }
                    else {
                        dataArray.push(array);
                        array = [];
                        array.push(mention);
                        maxNumber = (height + this.postBounding.height);
                    }
                    document.body.removeChild(span);
                }
                if (array.length > 0) dataArray.push(array);
                dataArray.unshift([{ ...value, type: 'division', }]);
            }
            else {
                if ((exportType === 'CSV' || exportType === 'XLS') && (load || !this.props.external)) {
                    var tempProfile = [];
                    for (var profile of value.profiles) {
                        tempProfile.push(isset(profile.source, i18n.t(value.title)) + '-' + isset(profile.label, isset(profile.name, '')));
                    }
                    boxes.push({ ...value, type: 'division', });
                    this.xlsData.push(['']);
                    this.xlsData.push(tempProfile);
                    this.handleXls(isset(value.boxes, []));
                }
                value.boxes = RearrangeBox.handleBox(value.boxes, value.rowBox);
                if (value.categories) {
                    value.boxes.unshift({ ...value, type: 'categories', categories: this.handleCategory(value.categories, value.boxes) });
                }
                value.boxes.unshift({ ...value, type: 'division', });
                for (var box of value.boxes) {
                    boxes.push({ ...box, fixTable: value.fixTable, formatNumber: value.formatNumber, noBoxShadow: value.noBoxShadow, noHeaderProfiles: value.noHeaderProfiles, vsStartDate: value.vsStartDate, vsEndDate: value.vsEndDate, startDate: value.startDate, endDate: value.endDate, profiles: value.profiles, brand: value.brand, area: value.area, postSize: value.postSize, descriptiveTitleVisible: value.descriptiveTitleVisible });
                }
            }
        }

        this.setState({ loading: !this.props.external || load ? true : false, data, boxes, buttonType, mentions: dataArray, page, maxPage: isset(dataArray, []).length + parseInt(boxes.length) },
            load || !this.props.external ? () => { setTimeout(() => this.exportToFile(exportType), 100); } : function () { }
        );
    }

    xlsData = []

    flatBox(items, title, isEmpty = false, category) {
        var result = [];
        for (let item of items) {
            if (title) {
                item.title = title;
            }
            item.isEmpty = isEmpty;
            if (item.type === 'feed') item.type = 'tableFeed';
            if (item.type && (item.type === 'tableFeed' || item.type === 'table' || (item.type === 'chart' && category !== 'countryLanguage'))) {
                result.push(item);
            }
            if (Array.isArray(item.items) && isset(item.type, '') !== 'tableFeed') {
                result = [...result, ...this.flatBox(item.items, isset(title, item.title), isset(item.empty, isEmpty), isset(category, item.category))];
            }
            else if (Array.isArray(item.boxes)) {
                result = [...result, ...this.flatBox(item.boxes, isset(title, item.title), isset(item.empty, isEmpty), isset(category, item.category))];
            }
        }
        return result;
    }

    handleXls(boxes, title) {
        var addMetricsTitle = true;
        var addTablesTitle = true;
        boxes = this.flatBox(boxes);
        boxes.sort((a, b) => {
            var typeA = a.type;
            var typeB = b.type;

            if (typeA < typeB) {
                return -1;
            }
            if (typeA > typeB) {
                return 1;
            }
            return 0;
        });

        for (var item of boxes) {
            title = isset(item.title, isset(title, ''));
            switch (item.type) {
            case 'chart':
                if (addMetricsTitle) {
                    if (this.xlsData.length > 0) {
                        this.xlsData.push(['']);
                    }
                    this.xlsData.push([i18n.t('Metrics ⬇')]);
                    addMetricsTitle = false;
                }
                this.xlsData.push([i18n.t(title), ...item.chartLabels]);
                item.chartDatasets.map((chartDataset) => {
                    if (typeof chartDataset.data === 'object') chartDataset.data = Object.values(chartDataset.data);
                    this.xlsData.push([i18n.t(chartDataset.label), ...chartDataset.data]);
                });

                this.xlsData.push(['']);
                break;
            case 'table':
                if (addTablesTitle) {
                    if (this.xlsData.length > 0) {
                        this.xlsData.push(['']);
                    }
                    this.xlsData.push([i18n.t('Tables ⬇')]);
                    addTablesTitle = false;
                }
                this.xlsData.push([i18n.t(title)]);
                var heads = [];
                item.head.map((head) => {
                    heads.push(i18n.t(head.value.value));
                });
                this.xlsData.push(heads);
                item.body.map((body) => {
                    var bodyValue = this.handleBody(body);
                    if (heads.length < bodyValue.length)
                        bodyValue.pop();
                    this.xlsData.push(bodyValue);
                });

                this.xlsData.push(['']);
                break;
            case 'tableFeed':
                if (addTablesTitle) {
                    if (this.xlsData.length > 0) {
                        this.xlsData.push(['']);
                    }
                    this.xlsData.push([i18n.t('Tables ⬇')]);
                    addTablesTitle = false;
                }
                this.xlsData.push([i18n.t(title)]);
                item.items.map((item) => {
                    var title = [i18n.t('Description'), 'Url', i18n.t('Media'), i18n.t('Profile'), i18n.t('Date'), i18n.t('Source'), i18n.t('Type')];
                    var data = [item.text, item.url, item.profile.name, convertDate(item.publishedTime, true, true), item.profile.source, item.type];
                    var media = [];
                    for (let tempMedia of item.media) {
                        media.push(tempMedia.url); break;
                    }
                    data.splice(2, 0, media.toString());
                    for (let metric of item.metrics) {
                        title.push(i18n.t(isset(metric.title, '')));
                        data.push(isset(metric.value, 0));
                    }
                    this.xlsData.push(title);
                    this.xlsData.push(data);
                    this.xlsData.push(['']);
                });
                break;
            default:
                break;
            }
        }
        return boxes;
    }

    handleBody(data, isGroup) {
        var responseData = data.map((item) => {
            switch (item.type) {
            case 'value':
                if (item.value.type === 'date') return convertDate(item.value.value);
                return i18n.t(item.value.value);
            case 'chart':
                var chartDatasetsValues = item.chartDatasets.map((chartDataset) => {
                    return item.chartOptions.valueType === 'number' ? chartDataset.value : (Math.round(chartDataset.value / item.chartOptions.maxValue * 100) + '%');
                });
                chartDatasetsValues = chartDatasetsValues.join(', ');
                return chartDatasetsValues;
            case 'icon':
                return item.icon.tooltip;
            case 'media':
                var media = '';
                for (let media of isset(item.media, [])) {
                    media += media.type + '-' + media.url + '\n';
                }
                return media;
            case 'profile':
                return item.profile.name;
            case 'group':
                return this.handleBody(item.items, true);
            default:
                break;
            }
            return null;
        });
        if (isGroup) {
            responseData = responseData.filter((c, index) => {
                return responseData.indexOf(c) === index;
            });
            responseData = responseData.join(', ');
        }
        if (Array.isArray(responseData)) {
            for (var id in responseData) {
                if (!responseData[`${id}`]) responseData.splice(id, 1);
            }
        }
        return responseData;
    }

    handlePagination(type, callback) {
        var currentPage = this.state.page;
        callback = callback || function () { };

        if (type === 'Down') {
            currentPage++;
        }
        else if (type === 'Up') {
            currentPage--;
        }

        this.setState({ page: currentPage }, () => {
            var element = null;

            element = document.getElementById(currentPage);

            if (element !== null) {
                element.scrollIntoView(true);
            }

            callback();
        });
    }

    handleKeyPress(event) {
        if (event.keyCode === 38 && this.state.page > 1) {
            this.handlePagination('Up');
        }
        else if (event.keyCode === 40 && this.state.page < this.state.maxPage) {
            this.handlePagination('Down');
        }
    }

    handleZoom(zoomType) {
        var value = this.state.zoomValue;
        var index = zoomValues.indexOf(value);
        if (index >= 0 && index <= zoomValues.length - 1) {
            if (zoomType === 'in') {
                if (index < zoomValues.length - 1) {
                    var nextItem = zoomValues[index + 1];
                }
            }
            else {
                if (index > 0) {
                    nextItem = zoomValues[index - 1];
                }
            }
            isset(nextItem) && this.setState({ zoomValue: nextItem });
        }
    }

    exportToFile(fileType) {
        this.setState({ loading: true, page: 1, zoomValue: 100 }, () => {
            if (!this.exportGenerator)
                this.exportGenerator = new ExportReports(document.getElementById('ExportContentArea'), this.state.data[0].title + '_' + (isset(this.state.data[0].startDate) ? (this.state.data[0].startDate + '-' + this.state.data[0].endDate) : this.state.data[0].date), document.getElementById('ExportFullPage'));
            this.generateExportFile(fileType);
        });
    }

    checkAllImages(event) {
        var page = document.getElementById('ExportFullPage');
        var images = page.getElementsByTagName('img');
        var loaded = images.length;
        Object.values(images).forEach(image => {
            if (image) {
                if (image.complete) {
                    loaded--;
                }
                else {
                    image.addEventListener('load', function () {
                        if (image.complete && image.naturalHeight !== 0) {
                            loaded--;
                            if (loaded === 0) {
                                setTimeout(() => event(), 100);
                            }
                        }
                    });
                }
            }
        });
        if (loaded === 0) {
            setTimeout(() => event(), 100);
        }
    }

    generateExportFile(fileType) {
        var currentPage = this.state.page;
        var maxPage = this.state.maxPage;

        this.exportGenerator.stageCallback = (action) => {
            var loadingText = this.state.loadingText;

            switch (action) {
            case 'loadingPages':
                loadingText = i18n.t('Loading pages to process ({{current}}/{{total}})', { current: currentPage.toString(), total: maxPage.toString() });
                break;
            default:
                loadingText = i18n.t('Getting your file ready...');
                break;
            }
            this.setState({ loadingText });
        };

        this.checkAllImages(() => {
            this.exportGenerator.getElements(`[id='${currentPage}'] #ExportFullPage`, (currentPage === maxPage)).then(() => {
                try {
                    if (currentPage === maxPage || fileType === 'CSV' || fileType === 'XLS') {
                        if (fileType === 'PowerPoint') {
                            this.exportGenerator.generatePPtx();
                        }
                        else if (fileType === 'XLS') {
                            const ws = XLSX.utils.aoa_to_sheet(this.xlsData);
                            const wb = XLSX.utils.book_new();
                            XLSX.utils.book_append_sheet(wb, ws, 'Analytics');
                            // eslint-disable-next-line
                            XLSX.writeFile(wb, (this.state.data[0].title + '_' + (isset(this.state.data[0].startDate) ? (this.state.data[0].startDate + '-' + this.state.data[0].endDate) : this.state.data[0].date) + '.xlsx'));
                        }
                        else if (fileType === 'CSV') {
                            this.csvLink.current.link.click();
                        }
                        else {
                            this.exportGenerator.generatePdf();
                        }
                        setTimeout(() => this.setReady({ loading: false, page: 1 }, () => {
                            if (!document.hasFocus())
                                window.close();
                        }), 1000);
                        window.onblur = function () { window.close(); };
                        localStorage.removeItem('data');
                    } else {
                        this.handlePagination('Down', () => {
                            setTimeout(() => this.generateExportFile(fileType));
                        });
                    }
                } catch (error) {
                    this.setReady({ loading: false, page: 1 });
                }
            }).catch(() => {
                this.setReady({ loading: false, page: 1 });
            });
        });
    }

    setReady(params, callback) {
        params.zoomValue = this.calculateZoomValue();

        this.setState(params, callback);
    }

    calculateZoomValue(setState) {
        if (this.state.loading) {
            return 100;
        }

        setState = setState || false;

        var areaRect = document.getElementById('ExportContentArea').getBoundingClientRect();
        var pageStyle = window.getComputedStyle(document.getElementsByClassName('ExportContent')[0]);

        var areaWidth = areaRect.right - areaRect.left;
        var areaHeight = areaRect.bottom - areaRect.top;

        var pageWidth = pageStyle['width'].replace('px', '');
        var pageHeight = pageStyle['height'].replace('px', '');

        var zoomValue = 100;
        var oldZoomValue = zoomValue;

        var zoomValueW = (areaWidth * 100) / pageWidth;
        var zoomValueH = (areaHeight * 100) / pageHeight;

        if (zoomValueH < zoomValueW) {
            zoomValue = zoomValueH;
        } else {
            zoomValue = zoomValueW;
        }

        for (var key in zoomValues) {
            if (zoomValues[`${key}`] === zoomValue) {
                break;
            }

            if (zoomValues[`${key}`] > zoomValue) {
                if (key === 0) {
                    zoomValue = zoomValues[0];
                } else {
                    zoomValue = zoomValues[key - 1];
                }

                break;
            }
        }

        if (oldZoomValue === zoomValue && zoomValues[zoomValues.length - 1] < zoomValue) {
            zoomValue = zoomValues[zoomValues.length - 1];
        }

        if (setState) {
            this.setState({ zoomValue });
        }

        return zoomValue;
    }

    postBounding = {}
    componentDidMount() {
        var { data, page } = this.state;

        var mentions = [];
        if (data[0].type === 'Mentions') {
            page = 1;
            mentions.push([{ 'title': '', 'text': '', 'url': '', 'publishedTime': 0, 'profile': { 'name': '', 'url': '', 'photo': '', 'source': '', 'location': null, }, 'type': 'text', 'sentiment': 'positive', 'highlights': [], 'engagement': [], 'estReach': 0, }]);
        }
        this.setState({ data, mentions, page }, () => this.handleData());
        document.addEventListener('keydown', this.handleKeyPress, false);
        if (window.innerWidth > 1024)
            window.addEventListener('resize', () => this.calculateZoomValue(true), false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress, false);
        window.removeEventListener('resize', () => this.calculateZoomValue(true), false);
    }

    renderCover(data) {
        var { brand = {}, profiles = [] } = data;

        return (
            <div className="ExportCoverArea">
                <div className="ExportBrandArea" style={{ visibility: (isset(brand.picture) || isset(brand.name)) ? 'visible' : 'hidden' }} >
                    {isset(brand.picture) && <ProfilePicture type="Big" background={issetDot(brand.picture, 'url', '')} />}
                    <Text style={{ marginLeft: '8px' }} size="Big" color="Black" >{brand.name}</Text>
                </div>
                <div className="ExportBottomArea">
                    <div className="ExportProfileArea" >
                        {profiles.length > 0 && profiles.map((profile, key) => {
                            return <div className="ExportProfile" key={key} >
                                <div className="ProfileLine" style={{ background: Config.colors.primaryPallet[`${key}`] }} />
                                {isset(profile.photo) && <ProfilePicture type="Big" background={profile.photo} source={profile.source} />}
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
                                    <Text size="Medium" color="Black" >{isset(profile.label, isset(profile.name, ''))}</Text>
                                    <Text size="Small" color="Blue" >{capitalize(isset(profile.source, ''))}</Text>
                                </div>
                            </div>;
                        })}
                    </div>
                    <div className="ExportTitle">
                        <Text weight="Black" size="Titanic" color="Blue" >{i18n.t(data.title).toUpperCase()}</Text>
                    </div>
                    <div className="ExportDatesArea">
                        {isset(data.date) && <Text fontSize="Big" color="Black" >{i18n.t('Exported on') + ' ' + data.date}</Text>}
                        {isset(data.startDate) && <Text size="Big" color="Black" >{t(convertDateFormat(data.startDate) + ' TO ' + convertDateFormat(data.endDate))} </Text>}
                        {isset(data.vsStartDate) && <div className="VsArea">
                            <Text style={{ marginLeft: '8px' }} size="Big" color="Blue" >VS</Text>
                            <Text style={{ marginLeft: '8px' }} size="Big" color="Black" >{t(convertDateFormat(data.vsStartDate) + ' TO ' + convertDateFormat(data.vsEndDate))}</Text>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }

    renderHeader(data) {

        return <head className="ExportHeader">
            <div className="ExportTopHeader">
                <div className="ExportDatesArea">
                    {isset(data.date) && <Text singleLine fontSize="Big" color="Black" >{data.date}</Text>}
                    {isset(data.startDate) && <Text singleLine size="Big" color="Black" >{t(convertDateFormat(data.startDate) + ' TO ' + convertDateFormat(data.endDate))}</Text>}
                    {isset(data.vsStartDate) && <div className="VsArea">
                        <Text style={{ marginLeft: '8px' }} size="Big" color="Blue" >VS</Text>
                        <Text style={{ marginLeft: '8px' }} singleLine size="Big" color="Black" >{t(convertDateFormat(data.vsStartDate) + ' TO ' + convertDateFormat(data.vsEndDate))}</Text>
                    </div>}
                </div>
            </div>
        </head>;
    }

    renderFooter(data, page) {
        var { brand = {} } = data;

        return (isset(brand.picture) || isset(page)) ? <footer className="ExportFooter">
            <div className="ExportBrandArea" >
                {isset(brand.picture) && <ProfilePicture type="Medium" background={issetDot(brand.picture, 'url', '')} />}
                <Text style={{ marginLeft: '8px' }} size="Big" color="Black" >{brand.name}</Text>
            </div>
            <div style={{ marginRight: '24px' }}>
                {isset(page) && <Text size="Big" color="Black" >{page}</Text>}
            </div>
        </footer> : null;
    }

    renderIndex(categories, boxes) {
        var indexCategories = [];

        boxes.map((box, key) => {
            key = parseInt(key);
            for (let category of categories) {
                if (isset(category.items)) {
                    for (let item of category.items) {
                        if (box.id === item.id && (indexCategories.findIndex((indexCategory) => indexCategory.id === category.id) < 0)) {
                            indexCategories.push({ id: category.id, number: key, title: category.title }); break;
                        }
                    }
                }
                else {
                    if (box.id === category.id && (indexCategories.findIndex((indexCategory) => indexCategory.id === category.id) < 0)) {
                        indexCategories.push({ id: category.id, number: key, title: category.title }); break;
                    }
                }
            }
            return null;
        });

        return <div className="ExportIndex">
            <Text size="Giant" color="Blue" weight="Black" >{i18n.t('Index').toUpperCase()}</Text>
            <div className="ExportIndexCategoryArea">
                {indexCategories.map((category, key) => {
                    return <div key={key} className="ExportIndexCategory">
                        <Text weight="SemiBold" style={{ marginRight: '34px' }} color="Blue" size="Big" >{category.number.toString().padEnd(3, ' ')}</Text>
                        <Text style={{ width: '100%' }} weight="SemiBold" color="Black" size="Big" >{i18n.t(category.title).toUpperCase()}</Text>
                    </div>;
                })}
            </div>
        </div>;
    }

    renderAnalytics() {
        var boxes = this.state.boxes;
        var zoomedDiv = this.state.zoomValue / 100;
        var auxBoxes = [];
        var data = isset(this.state.data[0], {});
        var mentions = isset(this.state.mentions, []);

        switch (data.type) {
        case 'Mentions':
            for (var key in mentions) {
                if (parseInt(key) + (this.state.categories.length > 0 ? 2 : 1) === parseInt(this.state.page)) {
                    auxBoxes[0] = mentions[`${key}`]; break;
                }
            }
            return auxBoxes.map((mentions, key) => {
                switch (issetDot(mentions[0], 'type', '')) {
                case 'division':
                    return <div className="ExportContent" id={this.state.page} style={{ transform: `translate(-50%, -50%) scale(${zoomedDiv})` }}>
                        <div id="ExportFullPage" className="ExportFullPage">
                            {this.renderCover(mentions[0])}
                        </div>
                    </div>;
                default:
                    return <div key={key} className="ExportContent" id={this.state.page} style={{ transform: `translate(-50%, -50%) scale(${zoomedDiv})` }} >
                        <div id="ExportFullPage" className="ExportFullPage">
                            {this.renderHeader(auxBoxes[0])}
                            <div ref={this.parentRef} className="ExportAnalyticsArea">
                                {mentions.map((mention, key) => {
                                    return <ExportPost key={key} postBounding={(bounding) => this.postBounding = bounding} mention={mention} />;
                                })}
                            </div>
                            {this.renderFooter(auxBoxes[0], parseInt(this.state.page) - (this.state.categories.length > 1 ? 2 : 1))}
                        </div>
                    </div >;
                }
            });
        default:
            for (key in boxes) {
                if (parseInt(key) + (this.state.categories.length > 0 ? 2 : 1) === parseInt(this.state.page)) {
                    auxBoxes[0] = boxes[`${key}`]; break;
                }
            }
            switch (issetDot(auxBoxes[0], 'type', '')) {
            case 'division':
                i18n.changeLanguage(isset(auxBoxes[0].exportLanguage, getBrowserLanguage()));
                return <div className="ExportContent" id={this.state.page} style={{ transform: `translate(-50%, -50%) scale(${zoomedDiv})` }}>
                    <div id="ExportFullPage" className="ExportFullPage">
                        {this.renderCover(auxBoxes[0])}
                    </div>
                </div>;
            case 'categories':
                return <div className="ExportContent" id={this.state.page} style={{ transform: `translate(-50%, -50%) scale(${zoomedDiv})` }}>
                    <div id="ExportFullPage" className="ExportFullPage">
                        {this.renderHeader(auxBoxes[0])}
                        {this.renderIndex(auxBoxes[0].categories, auxBoxes[0].boxes)}
                        {this.renderFooter(auxBoxes[0])}
                    </div>
                </div>;
            default:
                return auxBoxes.map((box, boxKey) => {
                    var maxPage = boxes.filter(filterBox => filterBox.id === box.id).length;
                    return <div key={boxKey} className="ExportContent" id={this.state.page} style={{ transform: `translate(-50%, -50%) scale(${zoomedDiv})` }} >
                        <div id="ExportFullPage" className="ExportFullPage">
                            {this.renderHeader(auxBoxes[0])}
                            <div className="ExportAnalyticsArea">
                                <Box fixTable={auxBoxes[0].fixTable} formatNumber={auxBoxes[0].formatNumber} area={auxBoxes[0].area} descriptiveTitleVisible={auxBoxes[0].descriptiveTitleVisible} postSize={auxBoxes[0].postSize} instantRender={true} noFilters={true} maxPage={maxPage} hideScroll={true} type={isset(data.type, 'Analytics') + ' Export'} noBoxShadow={auxBoxes[0].noBoxShadow} data={auxBoxes[0]} />
                            </div>
                            {this.renderFooter(auxBoxes[0], parseInt(this.state.page) - (this.state.categories.length > 1 ? 2 : 1))}
                        </div>
                    </div>;
                });
            }
        }
    }

    exportTypes = ['PDF', 'CSV', 'PowerPoint']
    render() {
        return <div className="ExportArea">
            <div style={{ display: 'none' }}>
                <CSVLink filename={issetDot(this.state.data[0], 'title') + '_' + (issetDot(this.state.data[0], 'startDate') ? (issetDot(this.state.data[0], 'startDate') + '-' + issetDot(this.state.data[0], 'endDate')) : issetDot(this.state.data[0], 'date')) + '.csv'} ref={this.csvLink} data={this.xlsData} target='_blank' />
            </div>
            {this.state.loading && <div className="ExportLoadingArea" >
                {!this.props.external ? <img alt="" src={swonkieLogo} style={{ height: '100px', marginBottom: '70px' }} /> : null}
                <div className="LoadingContentArea">
                    <Text align="center" size="Big" color="Black" >{this.state.loadingText}</Text>
                    <div className="ExportProgressBarArea">
                        <ProgressBar type="Animated" background="Grey" square={false} />
                    </div>
                    <Text align="center" size="Medium" color="mediumGrey" style={{ marginTop: '8px' }} >{i18n.t('Please stay on this tab until the content is downloaded.')}</Text>
                </div>
            </div>}
            {!this.state.loading && <div className="ExportPhoneArea">
                {this.props.external && <div style={{ position: 'absolute', top: '10px', left: '10px' }}>
                    <Text>{i18n.t('Page available until:') + ' ' + convertDate(this.state.data[0].timeToLive)} </Text>
                </div>}
                <img src={image} style={{ width: '180px', height: '180px', marginBottom: '36px' }} />
                <Text align='center' style={{ marginBottom: '16px' }} weight='Bold' color='darkblue' size='Huge'>{i18n.t('Report preview not available on this device')}</Text>
                <Text align='center' size='Big'>{i18n.t('You can download the report and open it on your device')}</Text>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '30px' }}>
                    {this.exportTypes.map((exportType, key) => {
                        return <Button key={key} onClick={() => this.handleData(exportType, true)} type="Tertiary" text={i18n.t('Download as {{type}}', { type: exportType })} />;
                    })}
                </div>
            </div>}
            <div style={{ width: '100%', height: '100%' }}>
                <div className="ExportTopBar">
                    {!this.props.external ? <img alt="" src={swonkieLogo} /> : <Text>{i18n.t('Page available until:') + ' ' + convertDate(this.state.data[0].timeToLive)} </Text>}
                    <div className="ExportTopBarMiddleContent">
                        <div className="ExportPaginationArea">
                            <div className="ExportPaginationButtons">
                                <Icon size="Big" name="Arrow Down" color={this.state.page === this.state.maxPage ? 'grey' : 'Black'} onClick={() => this.state.page === this.state.maxPage ? function () { } : this.handlePagination('Down')} />
                                <Icon size="Big" name="Arrow Up" color={this.state.page === 1 ? 'grey' : 'Black'} onClick={() => this.state.page === 1 ? function () { } : this.handlePagination('Up')} />
                            </div>
                            <Text style={{ marginRight: '17px' }} >{this.state.page}</Text>
                            <Text >{`/ ${this.state.maxPage}`}</Text>
                        </div>
                        <div className="ExportZoomArea">
                            <div className="ZoomButtonsArea">
                                <Icon size="Big" color="Black" name="Add Circle Outline" onClick={() => this.handleZoom('in')} />
                                <Icon size="Big" color="Black" name="Minus Circle Outline" onClick={() => this.handleZoom('out')} />
                            </div>
                            <Dropdown trigger={< div style={{ display: 'flex', cursor: 'pointer' }} > <Text color="Black" size="Big" >{this.state.zoomValue + '%'}</Text> <Icon size="Big" color="Black" set="Caret Down" /> </div>} >
                                {zoomValues.map((value, key) => {
                                    return <DropdownItem key={key} selected={this.state.zoomValue === value} onClick={() => this.setState({ zoomValue: value })}> <Text color="Black" size="Big" >{value + '%'}</Text> </DropdownItem>;
                                })}
                            </Dropdown>
                        </div>
                    </div>
                    <div className='ExportTypeButtonArea'>
                        <Button onClick={() => this.handleData(this.state.buttonType, true)} type="Tertiary" text={i18n.t('Download as {{type}}', { type: this.state.buttonType })} />
                        <Dropdown position='Right' width='120px' style={{ marginLeft: '-10px' }} arrowColor='Blue' arrow >
                            {this.exportTypes.map((type, key) => {
                                if (type === this.state.buttonType) return null;
                                return <DropdownItem key={key} onClick={() => this.handleData(type, true)}> <Text color="Black" size="Big" >{type}</Text> </DropdownItem>;
                            })}
                        </Dropdown>
                    </div>
                </div>
                <div id="ExportContentArea" className="ExportContentArea" >
                    {this.renderAnalytics()}
                </div>
            </div>
        </div>;
    }
}

export default Export;
