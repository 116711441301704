import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import Icon from '../icon/Icon';
import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import Text from '../text/Text';
import i18n from '../../../i18n';

const CheckboxArea = styled.div`
    display: flex;
    align-items: center;
    ${props => props.disabled && 'opacity: 0.5; pointer-events: none; user-select: none;'}
`;

const grow = keyframes`
    from {
        background-size: 60% 60%;
    }
    to {
        background-size: 150% 150%;
    }
`;

const MainCheckbox = styled.div`
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border-radius: 50%;
    :hover{
        background: ${props => props.isChecked ? '#0A5C9C0A' : Config.colors.greyopacity};
    }
    :focus:not(:active){
        background: radial-gradient(${props => props.isChecked ? '#0A5C9C0A' : Config.colors.greyopacity} 70%, transparent 20%) center no-repeat;
        animation:${grow} 0.2s linear;
    }
`;

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = { isChecked: false };

        this.state.isChecked = this.props.isChecked;

    }

    handleChange() {
        var onCallback = this.props.onCallback || function () { };
        var isChecked = !this.state.isChecked;

        if (this.state.isChecked <= 0) {
            isChecked = true;
        }
        else {
            isChecked = false;
        }
        this.setState({ isChecked: isChecked });
        onCallback(isChecked);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isChecked !== this.props.isChecked) {
            this.setState({ isChecked: this.props.isChecked });
        }
    }


    render() {
        var {
            disabled = false,
            children = '',
            size = '',
            isChecked = false,
            color = 'Blue',
            stopPropagation = true,
            ...props
        } = this.props;

        isChecked = this.state.isChecked;

        var icon = 'Unmarked';

        if (isChecked === true || isChecked > 0) icon = 'Marked';

        else if (isChecked === false || isChecked < 0) icon = 'Unmarked';

        else icon = 'Minus';

        return (
            <CheckboxArea disabled={disabled} >
                <MainCheckbox isChecked={isChecked} onClick={(e) => {
                    this.handleChange();
                    if (stopPropagation)
                        e.stopPropagation();
                }} tabIndex="1" {...props}>
                    <Icon size={size} color={icon === 'Unmarked' ? color : 'Blue'} name={'Checkbox ' + icon} />
                </MainCheckbox>
                {children}
            </CheckboxArea>
        );
    }
}

Checkbox.propTypes = {
    children: PropTypes.any,
    /**
     *the Checkbox can either use for check or uncheck(true - checked or false - unchecked) our can use for check, uncheck or indeterminate (1 - checked, 0 - indeterminate , -1 - unchecked)
     */
    isChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    /**
     * disabled the CheckboxArea
     */
    disabled: PropTypes.bool,
    /**
     * Size the CheckboxArea
     */
    size: PropTypes.string,
    /**
     *  Make checkbox event to not trigger parent event
     */
    stopPropagation: PropTypes.bool
};

Checkbox.defaultProps = {
    children: '',
    isChecked: false,
    disabled: false,
    size: 'Big'
};

const MultipleCheckBoxArea = styled.div`
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
`;

const SubCheckBoxesArea = styled.div`
    display: flex;
    flex: 1;
    flex-direction: ${props => props.type === 'vertical' ? 'column' : 'row'};
    flex-wrap: wrap;
`;

export class MultipleCheckbox extends Component {
    constructor(props) {
        super(props);
        this.state = { data: props.data, selectAll: 0 };
    }

    handleCreateCheckboxes(checkboxes, activeCheckboxes = []) {
        var actives = 0;
        checkboxes.map((checkbox) => {
            if (isset(checkbox.items)) {
                checkbox.items.map((subCheckbox) => {
                    if (activeCheckboxes.includes(subCheckbox.id)) {
                        actives += 1;
                        subCheckbox.active = true;
                    }
                    else {
                        subCheckbox.active = false;
                    }
                    if (isset(subCheckbox.items) && Array.isArray(subCheckbox.items) && subCheckbox.items.length > 0) {
                        return this.handleCreateCheckboxes(subCheckbox, activeCheckboxes);
                    }
                    return null;
                });
            }
            if ((isset(checkbox.items) && checkbox.items.length === actives) || activeCheckboxes.includes(checkbox.id)) {
                checkbox.active = true;
            }
            else if (actives > 0 && actives < checkbox.items.length) {
                checkbox.active = 0;
            }
            else {
                checkbox.active = false;
            }
            actives = 0;
            return null;
        });

        this.setState({ data: checkboxes }, () => {
            this.checkForAllSelected(this.state.data, false);
        });
    }

    activeData = [];
    checkForAllSelected(data, isChild) {
        var selectAll = this.state.selectAll;
        var checked = 0;
        var notChecked = 0;
        var neutral = 0;
        data.map((checkbox) => {
            if (checkbox.active === true || checkbox.active === 1) {
                checked++;
                if (!isset(checkbox.items)) this.activeData.push(checkbox.id);
            }
            else if (checkbox.active === 0) {
                neutral++;
            }
            else {
                notChecked++;
            }
            if (isset(checkbox.items) && Array.isArray(checkbox.items) && checkbox.items.length > 0) {
                return this.checkForAllSelected(checkbox.items, true);
            }
            return null;
        });

        if (!isChild) {
            if ((checked > 0 && notChecked > 0) || neutral > 0) {
                selectAll = 0;
            }
            else if (checked > 0) {
                selectAll = true;
            }
            else if (notChecked > 0) {
                selectAll = false;
            }

            if (isset(this.props.onCallback)) {
                this.props.onCallback(data, this.activeData);
            }
            this.activeData = [];
            this.setState({ selectAll });
        }
    }

    handleCheckBoxData(id, forceChange, parentChecked) {
        var data = this.state.data;

        data = this.changeCheckBox(id, data, forceChange, parentChecked);

        this.setState({ data }, () => {
            this.checkForAllSelected(this.state.data, false);
        });
    }

    changeCheckBox(id, items, forceChange, parentChecked) {
        for (let item of items) {
            if (item.id === id || forceChange) {
                if (forceChange) {
                    item.active = parentChecked;
                }
                else {
                    if (isset(item.active)) {
                        if (typeof item.active === 'boolean') {
                            item.active = !item.active;
                        }
                        else if (typeof item.active === 'number') {
                            if (item.active <= 0) {
                                item.active = 1;
                            }
                            else {
                                item.active = -1;
                            }
                        }
                    }
                    else {
                        item.active = true;
                    }
                }
                if (isset(item.items) && Array.isArray(item.items)) {
                    for (let subItem of item.items) {
                        subItem.active = item.active;
                        if (isset(subItem.items)) {
                            subItem.items = this.changeCheckBox('', subItem.items, true, item.active);
                        }
                    }
                }
            }
            else if (isset(item.items) && Array.isArray(item.items)) {
                item.items = this.changeCheckBox(id, item.items, false);
                var checked = 0;
                var notChecked = 0;
                for (let subItem of item.items) {

                    if (subItem.active === 1 || subItem.active === true) {
                        checked++;
                    }
                    else {
                        notChecked++;
                    }
                }

                if (checked > 0 && notChecked > 0) {
                    item.active = 0;
                }
                else if (checked > 0) {
                    item.active = 1;
                }
                else if (notChecked > 0) {
                    item.active = -1;
                }
            }
        }

        return items;
    }

    componentDidMount() {
        this.handleCreateCheckboxes(this.state.data, this.props.activeData, true);
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            this.setState({ data: this.props.data }, () => {
                this.handleCreateCheckboxes(this.state.data, this.props.activeData, true);
            });
        }
    }

    renderCheckBoxes(item) {
        var { type } = this.props;
        return <div>
            <div style={{ width: '225px', paddingRight: '8px' }}>
                <Checkbox isChecked={item.active} onCallback={(checked) => this.handleCheckBoxData(item.id, false, checked)} >
                    <Text singleLine tooltip={i18n.t(item.title)} >{isset(item.items) ? i18n.t('All sub-categories') : i18n.t(item.title)}</Text>
                    {item.description && <Icon size='Medium' name='Help Circle' color='Grey' style={{ marginLeft: '16px' }} tooltip={i18n.t(item.description)} />}
                </Checkbox>
            </div>
            <SubCheckBoxesArea type={type} style={{ paddingLeft: '40px' }}>
                {isset(item.items) && item.items.map((subItem) => {
                    return this.renderCheckBoxes(subItem);
                })}
            </SubCheckBoxesArea>
        </div>;
    }

    render() {
        var { data } = this.state;
        return <div>
            <div>
                <Checkbox isChecked={this.state.selectAll} onCallback={(checked) => this.handleCheckBoxData(0, true, checked)} >
                    <Text color="Black" >{i18n.t('Select all data')}</Text>
                </Checkbox>
            </div>
            {data.map((item, key) => {
                return <MultipleCheckBoxArea key={key}>
                    <Text size='Big' weight='SemiBold' >{i18n.t(item.title)}</Text>
                    <Text style={{ marginBottom: '16px' }} color='grey' >{i18n.t(item.description)}</Text>
                    {this.renderCheckBoxes(item)}
                </MultipleCheckBoxArea>;
            })}
        </div>;
    }
}

MultipleCheckbox.propTypes = {
    type: PropTypes.oneOf(['horizontal', 'vertical']),
    data: PropTypes.array
};

MultipleCheckbox.defaultProps = {
    type: 'vertical'
};

export default Checkbox;
