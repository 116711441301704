import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { isset, redirect } from '../../../base/Utils';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import Icon from '../icon/Icon';

export const types = ['Primary', 'Secondary'];

const MainTabList = styled.ol`
    padding: 0;
    margin: 0;
    ${props => props.type === 'Primary' &&
        `width: 100%;
        display: flex;
        align-items: center;
        position: relative;
        top: unset !important;
        right: unset !important;
        border-radius: 0;`}
`;

const MainTab = styled.a`
    list-style: none;
    padding: 8px 12px;
    color: ${Config.colors.white};
    cursor: pointer;
    width: ${props => props.fullWidth ? '100%' : 'auto'};
    border-radius: ${props => props.active ? '20px' : '0'};
    ${props => props.active && 'background: rgba(255, 255, 255, 0.24);'};
    ${props => props.type === 'Primary' && `
    border-bottom: 1px solid ${props.active ? Config.colors.blue : 'transparent'};
    color:  ${props.active ? Config.colors.blue : Config.colors.grey};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 16px;
    box-sizing: border-box;
    font-size: 14px;
    border-radius: 0;
    transition: 0.2s;
    text-transform: uppercase;
    :hover {
        color: ${props.active ? Config.colors.blue : Config.colors.grey}
    }
    `
}
`;

const Tab = ({ label, type, active, icon = null, fullWidth, ...props }) => {
    return (
        <MainTab fullWidth={fullWidth} type={type} active={active} {...props}>
            {icon && <Icon style={{ marginBottom: '7px' }} name={icon} color={active ? 'blue' : 'grey'} />}
            {label}
        </MainTab>
    );
};

Tab.propTypes = {
    active: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.string,
    type: PropTypes.string,
    fullWidth: PropTypes.bool
};

class Tabs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: isset(props.data[0]) && isset(props.active, props.data[0].value),
        };
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            var activeTab = isset(this.props.data[0]) ? isset(this.props.active, this.props.data[0].value) : {};
            this.setState({ activeTab: activeTab });
        }
        else if (this.props.active !== this.state.activeTab) {
            this.setState({ activeTab: this.props.active });
        }
    }

    onClickTabItem = (value, to = null) => {
        if (isset(this.props.onChange)) {
            this.props.onChange(value);
        }
        if (to) {
            redirect(to);
        }
        this.setState({ activeTab: value });
    }
    render() {
        var {
            props: {
                data,
                type = types[0],
                fullWidth = false,
                ...props
            },
            state: {
                activeTab,
            }
        } = this;


        if (!types.includes(type)) {
            type = types[0];
        }

        return (
            <MainTabList type={type} {...props}>
                {data.map((tab) => {
                    var {
                        to = null,
                        icon = null,
                        label = '',
                        value = '',
                    } = tab;
                    return (
                        <Tab
                            icon={icon}
                            type={type}
                            active={activeTab === value}
                            key={label}
                            label={i18n.t(label)}
                            onClick={() => this.onClickTabItem(value, to)}
                            fullWidth={fullWidth}
                        />
                    );
                })}
            </MainTabList>
        );
    }
}

Tabs.propTypes = {
    /**
     * Create the tabs area
     */
    data: PropTypes.instanceOf(Array),
    /**
     *  Type of tabs
     */
    type: PropTypes.oneOf(types),
    /**
     *  add function when is changing betweens tabs
     */
    onChange: PropTypes.func,
    /**
     * Make one of tabs active to know which one you are
     */
    active: PropTypes.string,
    /**
     *  Make all children occupies the full width of parent
     */
    fullWidth: PropTypes.bool
};

export default Tabs;
