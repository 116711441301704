import i18n from '../../../i18n';
import React from 'react';
import tiktokDirectPublishing from '../../../resources/images/tiktokDirectPublishing.png';
import Text from '../../components/text/Text.js';
import { redirect } from '../../../base/Utils';

// eslint-disable-next-line
export default () => ([
    {
        title: i18n.t('Publish Videos on TikTok'),
        content: <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Text style={{ marginBottom: '8px' }} size='Medium' color='heavygrey'>{i18n.t('Now in Swonkie you can')}:</Text>
            <Text size='Big' >{i18n.t('📣 Exciting News! You can now edit and publish videos directly within the app, enhancing your content effortlessly with Swonkie\'s editing tools. No need for additional tools or apps. Elevate your content with ease and share your stories with even more flair. Start creating today!')}</Text>
            <img src={tiktokDirectPublishing} style={{ width: 'calc(100% - 20px)', margin: '20px 10px' }} />
        </div>,
        placement: 'center',
        target: 'body',
        style: { minWidth: '600px', minHeight: '320px' },
        nextButtonText: i18n.t('Try it'),
        skipButtonText: i18n.t('Later'),
        showSkipButton: true,
        afterStep: () => {
            redirect('/produce-content');
        }
    }
]);
