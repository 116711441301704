import { redirect } from './Utils';
import { sendMessage } from '../common/containers/OldPages';

class ActionUtils {
    static instantDownload(data) {
        var { url = '', name = '' } = data;
        var el = document.createElement('a');
        el.style.display = 'none';
        el.target = '_blank';
        el.href = url;
        el.download = name;
        el.click();
        el.remove();
    }

    static multiRedirect(data) {
        Object.values(data).map((url) => redirect(url, '_blank'));
    }

    static createAdNotification(data) {
        sendMessage({ type: 'createAdNotification', data: data });
    }
}


export default ActionUtils;
