import React from 'react';
// import './Banner.scss';
import PropTypes from 'prop-types';
import Button from '../button/Button';
import Text from '../text/Text';
import Icon from '../icon/Icon';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import { isset } from '../../../base/Utils';
import i18n from '../../../i18n';

const colors = Config.colors;

export const types = ['Normal', 'Rounded', 'SemiRounded'];

export const buttonTypes = ['Default', 'Link'];

const MainBanner = styled.div`
    display: flex;
    padding: 8px 24px;
    align-items: center;
    justify-content: space-between;
    border-radius: ${props => (props.type === 'Normal' ? '0px' : props.type === 'SemiRounded' ? '8px' : '40px')};
    width: 100%;
    box-sizing: border-box;
    border: ${props => (props.border && '1px solid ' + isset(colors[props.border.toLowerCase()], colors.white))};
    border-bottom: ${props => (props.type === 'Normal' && '1px solid #eeeeee')};
    background: ${props => props.background};
`;

const Banner = ({ background, message, children, type, color, buttonColor = '', buttonType, disableButton, onClick, closeButton, closeOnClick, buttonText, iconColor, icon, buttonIcon, border, ...props }) => {

    color = color.toLowerCase();
    var auxBackground = background.toLowerCase() + 'opacity';
    background = isset(colors[`${auxBackground}`], colors.white);

    var auxColor = isset(colors[`${color}`], colors.black);
    var buttonStyle = {};
    if (buttonType === 'Link') {
        auxColor = isset(colors[buttonColor.toLowerCase()], auxColor);
        if (color === 'black') {
            auxColor = colors.blue;
        }
        buttonStyle = { color: auxColor, textTransform: 'uppercase', fontWeight: '500', textDecoration: 'none' };
    }
    return (
        <MainBanner background={background} border={border} type={type} {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon && <Icon color={iconColor} name={icon} style={{ marginRight: '24px' }} />}
                <Text color={color}>{message}</Text>
                {children}
            </div>
            <div style={{ display: 'flex' }}>
                {buttonText && <Button disabled={disableButton} style={buttonStyle} type={buttonType} icon={buttonIcon} iconColor={buttonColor} iconSide='Left' onClick={onClick}>{buttonText}</Button>}
                {closeButton && <Button style={buttonStyle} type={buttonType} onClick={closeOnClick}>{i18n.t('Close')}</Button>}
            </div>
        </MainBanner>
    );
};


Banner.propTypes = {
    /**
     * Message for banner
     */
    message: PropTypes.string,
    /**
     * Message color
     */
    color: PropTypes.string,
    /**
     * Background color
     */
    background: PropTypes.string,
    /**
     * Banner border
     */
    type: PropTypes.oneOf(types),
    /**
     * Text from button
     */
    buttonText: PropTypes.string,
    /**
     * Type of button
     */
    buttonType: PropTypes.oneOf(buttonTypes),
    /**
     * Button click event
     */
    onClick: PropTypes.func,
    /**
     * show the close button
     */
    closeButton: PropTypes.bool,
    /**
     * Close Button click event
     */
    closeOnClick: PropTypes.func,
    /**
     * Banner border color
     */
    border: PropTypes.string,
    /**
     *  Banner children
     */
    children: PropTypes.any
};

Banner.defaultProps = {
    message: '',
    background: 'White',
    type: 'Normal',
    color: 'Blue',
    buttonType: 'Link'
};

export default Banner;
