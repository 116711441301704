import ActionUtils from '../../../base/ActionUtils';
import { isset, issetDot, readTime } from '../../../base/Utils';

/* User OS notification
Notification.requestPermission().then(function(result) {
    console.log(result); //denied, granted
});
var n = new Notification('[WARNING] - General', { body: "teste", icon: "https://arq.swonkie.work/php/image.php?this=924f1b9b4398931b5c19a8a0db1a881f.png"});
n.onclick = () => {
    alert("clicked");
};
*/

class Notification {
    static queue = [];
    static onScreen = []
    static onScreenLimit = 5

    static callbacks = []

    static add(id, area, content, date, photo, readed, type, actions) {
        this.queue.push({ id: id, area: area, content: content, date: date, photo: photo, readed: readed, type: type, actions: actions, time: readTime(content), sound: true });
        this.checkToSpawn();
    }

    static setCallback(callback) {
        this.callbacks.push(callback);
    }

    static callCallbacks() {
        this.callbacks.map((callback) => { return callback(this.onScreen); });
    }

    static checkToSpawn() {

        if (this.onScreen.length < this.onScreenLimit && this.queue.length > 0) {
            this.setDestroyTimeout(this.queue[0]);
            this.onScreen.push(this.queue[0]);

            var action = issetDot(this.queue[0], 'actions.0', {});
            if (isset(action.type, '') === 'function') {
                var { execute = null, data = {}, executeInstantly = false } = action;

                if (execute && executeInstantly && isset(ActionUtils[`${execute}`])) {
                    ActionUtils[`${execute}`](data);
                }
            }

            this.queue.splice(0, 1);
        }

        this.callCallbacks();
    }

    static setDestroyTimeout(data) {
        data.timeout = setTimeout((id) => this.destroy(id), readTime(data.content) + 1000, data.id);
    }

    static destroy(dataId) {
        for (var id in this.onScreen) {
            if (this.onScreen[`${id}`].id === dataId) {
                this.onScreen.splice(id, 1); break;
            }
        }

        this.checkToSpawn();
    }
}


export default Notification;
