import React, { Component } from 'react';
import SelectAux, { components } from 'react-select';
import Text from '../../../common/components/text/Text';
import './Select.scss';
import Icon from '../icon/Icon';
import PropTypes from 'prop-types';
import { isset, issetDot, t } from '../../../base/Utils';
import i18n from '../../../i18n';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import CreatableSelect from 'react-select/creatable';
import ProfilePicture from '../profilePicture/ProfilePicture';
import Checkbox from '../checkbox/Checkbox';

const withoutBorderStyle = {
    container: (base) => ({
        ...base,
        cursor: 'pointer',
    }),
    control: (base) => ({
        ...base,
        '&:hover': { borderColor: 'transparent' },
        '&:visited': { borderColor: 'transparent' },
        boxShadow: 'none',
        background: 'transparent',
        color: 'White',
        borderColor: 'transparent',
        borderRadius: '23px',
        fontSize: '14px',
    }),
    option: (styles, state) => ({
        ...styles,
        color: '#212121',
        backgroundColor: ((state.isSelected) && 'rgba(0, 0, 0, 0.08)') || ((state.isFocused) && 'rgba(0, 0, 0, 0.04)') || 'transparent',
        '&:hover': {
            color: '#212121',
            backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0.04)',
        }
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: 'White',
            fontSize: '22px',
            fontWeight: 'Bold',
            textTransform: 'uppercase',
        };
    },
    singleValue: (styles) => {
        return {
            ...styles,
            color: 'White',
            fontSize: '22px',
            fontWeight: 'Bold',
            textTransform: 'uppercase',
            position: 'relative',
            top: '0px',
            transform: 'translateY(0px)'
        };
    }
};

const customStyles = {
    container: (base) => ({
        ...base,
        cursor: 'pointer',
    }),
    control: (base, state) => ({
        ...base,
        '&:hover': { borderColor: '#0a5c9c' },
        '&:visited': { borderColor: '#0a5c9c' },
        boxShadow: 'none',
        borderColor: state.isFocused
            ? '#0a5c9c'
            : '#DEDEDE',
        borderRadius: '23px',
        fontSize: '14px',
        cursor: 'pointer',
        minHeight: 'auto !important'
    }),
    option: (styles, state) => ({
        ...styles,
        color: '#212121',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        opacity: issetDot(state.data, 'disabled', false) && '0.5 !important',
        pointerEvents: issetDot(state.data, 'disabled', false) && 'none !important',
        backgroundColor: ((state.isSelected || issetDot(state.data, 'checked', false)) && 'rgba(0, 0, 0, 0.08)') || ((state.isFocused) && 'rgba(0, 0, 0, 0.04)') || 'transparent',
        '&:hover': {
            color: '#212121',
            backgroundColor: (state.isSelected || issetDot(state.data, 'checked', false)) ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0.04)',
        }
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#9E9E9E',
            fontSize: '14px',
        };
    },
    valueContainer: (defaultStyles) => {
        return {
            ...defaultStyles,
            flexWrap: 'initial',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            paddingRight: '0px'
        };
    },
    singleValue: (defaultStyles) => {
        return {
            ...defaultStyles,
            position: 'relative',
            top: '0px',
            transform: 'translateY(0px)'
        };
    },
};

const DropdownIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon name={props.selectProps.menuIsOpen ? 'Caret Up' : 'Caret Down'} color={isset(props.selectProps.arrowColor, 'Black')} size={isset(props.selectProps.iconSize, 'Big')} />
            </components.DropdownIndicator>
        ));
};

const ClearIndicator = props => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon name='Remove' color="black" size="Large" />
            </components.DropdownIndicator>
        ));
};


const { Option } = components;

const CustomDiv = styled.div`
	display: flex;
	align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
`;

export const SelectCircle = styled.div`
	height: ${props => isset(props.circleSize, '') === 'Small' ? '16px' : '24px'};
    min-width: ${props => isset(props.circleSize, '') === 'Small' ? '16px' : '24px'};
	border-radius: 100%;
	overflow: hidden;
	margin-right: ${props => isset(props.text, '').length > 0 ? '8px' : '0px'};
`;

const SelectImage = styled.img`
	height: 24px;
    min-width: 24px;
	border-radius: 100%;
	overflow: hidden;
	margin-right: 8px;
    object-fit: contain;
`;

const Options = props => (
    <Option {...props}>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between', width: '100%', padding: '4px 0px' }}>
            <CustomDiv>
                {props.isMulti && <Checkbox disabled={props.data.isDisabled} stopPropagation={false} color='Blue' isChecked={props.data.isDisabled ? false : props.data.checked || props.isSelected} />}
                {issetDot(props, 'data.color') && <SelectCircle text={issetDot(props, 'data.label', '')} style={{ background: isset(Config.colors[issetDot(props, 'data.color', '').toLowerCase()], 'black') }} />}
                {issetDot(props, 'data.photo') ? <ProfilePicture style={{ marginRight: '8px' }} onError={(e) => e.target.src = require('../../../resources/images/no_profile.png')} type='Medium' background={props.data.photo} source={props.data.source} /> : typeof issetDot(props, 'data.icon', '') === 'string' && <Icon style={{ marginRight: '6px' }} name={props.data.icon} size='Big' />}
                <Text color="black" singleLine >{props.data.label}</Text>
            </CustomDiv>
            {props.data.rightText && <Text singleLine color="HeavyGrey">{props.data.rightText}</Text>}
            {Object.keys(isset(props.data.icon, {})).length > 0 && <Icon name={issetDot(props.data.icon, 'name', ' ')} color={issetDot(props.data.icon, 'color', '')} size='Big' />}
        </div>
    </Option>
);

const ValueContainer = ({ children, ...props }) => {
    const multiOptionsText = t(props.selectProps.multiOptionsText);
    const multiPlaceholderText = t(props.selectProps.multiPlaceholderText);
    const showPlaceholderText = props.selectProps.showPlaceholderText;
    const type = props.selectProps.type;
    const length = children[0] ? children[0].length : 0;
    var auxChildren = [...children];
    var auxData = [...auxChildren];

    if (auxChildren[0]) {
        if (type === 'Multiple') {
            auxChildren[0] = isset(isset(auxChildren[0], [])[0], null);
            auxData[0] = <span>{auxChildren[0] ? issetDot(auxChildren[0], 'props.data.label', '') : ''}</span>;
        }
        if (length >= 2 || showPlaceholderText) {
            auxData[0] = <span>{issetDot(auxChildren[1], 'props.value', '') ? '' : showPlaceholderText ? multiPlaceholderText : length + ' ' + multiOptionsText}</span>;
        }
    }
    return (
        <components.ValueContainer {...props}>
            <div style={{ display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'space-between', width: '100%' }}>
                <CustomDiv>
                    {(isset(length, 0) < 2 && auxChildren[0]) && (issetDot(auxChildren[0], 'props.data.color') && typeof issetDot(auxChildren[0], 'props.data.icon', null) !== 'string') && <SelectCircle circleSize={issetDot(props, 'selectProps.circleSize', 'Big')} text={issetDot(auxChildren[0], 'props.data.label', '')} style={{ background: isset(Config.colors[issetDot(auxChildren[0], 'props.data.color').toLowerCase()], 'black') }} />}
                    {(isset(length, 0) < 2 && auxChildren[0]) && (issetDot(auxChildren[0], 'props.data.photo') ? <SelectImage onError={(e) => e.target.src = require('../../../resources/images/no_profile.png')} type='Medium' src={issetDot(auxChildren[0], 'props.data.photo')} /> : auxChildren[0] && typeof issetDot(auxChildren[0], 'props.data.icon', null) === 'string' && <Icon style={{ marginRight: '6px' }} name={auxChildren[0].props.data.icon} size='Big' color={auxChildren[0].props.data.color} />)}
                    {auxData}
                    {(isset(length, 0) < 1 && type === 'Multiple') && <span style={{ color: Config.colors.grey }}>{i18n.t(issetDot(props, 'selectProps.placeholder', ''))}</span>}
                </CustomDiv>
                {(isset(length, 0) < 2 && auxChildren[0]) && typeof issetDot(auxChildren[0], 'props.data.icon', null) === 'object' && <Icon name={issetDot(auxChildren[0], 'props.data.icon.name', '')} color={issetDot(auxChildren[0], 'props.data.icon.color', '')} size='Big' />}
            </div>
        </components.ValueContainer>
    );
};

const types = ['Normal', 'Multiple', 'MultipleLabels'];

const Select = ({ placeholder, withoutBorder, type, menuPlacement = 'auto', options, components, onChange, value, defaultValue, isSearchable, multiOptionsText, styles, hideSelectedOptions, isClearable, ...props }) => {
    var isMulti = false;

    if (isset(value)) {
        value = value || defaultValue || null;
    }

    if (type === 'MultipleLabels' && (value && value.length > 2)) {
        type = 'Multiple';
    }

    switch (type) {
    case 'Multiple':
        hideSelectedOptions = false;
        isMulti = true;
        break;
    case 'MultipleLabels':
        isMulti = true;
        hideSelectedOptions = true;
        break;
    default:
        break;
    }

    if (withoutBorder) styles = { ...withoutBorderStyle };
    else
        styles = { ...customStyles, ...styles };

    return <SelectAux
        type={type}
        value={value}
        withoutBorder={withoutBorder}
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        defaultValue={defaultValue}
        classNamePrefix="Select"
        placeholder={placeholder}
        styles={{ ...styles }}
        components={components}
        options={options}
        onChange={onChange}
        noOptionsMessage={() => i18n.t('No option')}
        hideSelectedOptions={hideSelectedOptions}
        multiOptionsText={multiOptionsText}
        menuPlacement={menuPlacement}
        {...props}
    />;
};

Select.propTypes = {
    /**
     * Type of Select
     */
    type: PropTypes.oneOf(types),
    /**
     * Remove Border from select
     */
    withoutBorder: PropTypes.bool,
    /**
     * Placeholder of Select
     */
    placeholder: PropTypes.string,
    /**
    * Options for select
    */
    options: PropTypes.array,
    /**
     *Do an action when select an option
     */
    onChange: PropTypes.func,
    /**
     * the default value select option
     */
    defaultValue: PropTypes.object,
    /**
     * hide the option when is selected
     */
    hideSelectedOptions: PropTypes.bool,
    /**
     * clean the selected options
     */
    isClearable: PropTypes.bool,
    /**
     * search options on select
     */
    isSearchable: PropTypes.bool,
    /**
     * text for multi options selected
     */
    multiOptionsText: PropTypes.string
};

Select.defaultProps = {
    placeholder: '',
    type: 'Normal',
    options: [],
    components: { DropdownIndicator, ValueContainer, Option: Options, ClearIndicator },
    onChange: function () { },
    defaultValue: null,
    styles: customStyles,
    hideSelectedOptions: true,
    isClearable: false,
    isSearchable: false,
    multiOptionsText: i18n.t('Selected')
};

const createOption = (label) => ({
    label,
    value: isset(label, '').toLowerCase().replace(/\W/g, ''),
});


const customCreateStyles = {
    control: (base, state) => ({
        ...base,
        '&:hover': { borderColor: '#0a5c9c' },
        '&:visited': { borderColor: '#0a5c9c' },
        boxShadow: 'none',
        borderColor: state.isFocused
            ? '#0a5c9c'
            : state.selectProps.error ? '#d64947' : '#DEDEDE',
        borderRadius: '23px',
        fontSize: '14px',
    }),
    indicatorSeparator: () => ({ display: 'none' }),
    option: (styles, state) => ({
        ...styles,
        color: '#212121',
        backgroundColor: ((state.isSelected) && 'rgba(0, 0, 0, 0.08)') || ((state.isFocused) && 'rgba(0, 0, 0, 0.04)') || 'transparent',
        '&:hover': {
            color: '#212121',
            backgroundColor: state.isSelected ? 'rgba(0, 0, 0, 0.04)' : 'rgba(0, 0, 0, 0.04)',
        }
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#9E9E9E',
            fontSize: '14px',
        };
    },
    multiValue: (styles) => {
        return {
            ...styles,
            backgroundColor: '#E0E0E0',
            borderRadius: '40px',
            padding: '4px'
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: '#212121',
        fontSize: '14px'
    }),
    multiValueRemove: (styles) => ({
        ...styles,
        color: '#212121',
        cursor: 'pointer',
        ':hover': {
            backgroundColor: 'transparent',
            color: '#212121',
        },
    }),
};

export class CreateSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue: '',
            options: [...isset(props.options, []), ...isset(props.defaultValue, [])],
            value: isset(props.defaultValue, []),
        };
    }

    handleChange = (newValue) => {
        const { maxValue } = this.props;
        if (!newValue) {
            newValue = [];
        }
        if (!isset(maxValue) || newValue.length <= maxValue) {
            this.setState({ value: newValue });

            if (isset(this.props.onCallBack)) {
                this.props.onCallBack(newValue);
            }
        }
    };

    handleCreate = (inputValue) => {
        var { options, value } = this.state;
        const { isMulti, maxValue } = this.props;
        const newOption = createOption(inputValue);
        if (!value) {
            value = [];
        }
        if (isMulti === true) {
            if (!isset(maxValue) || value.length < maxValue) {
                value.push(newOption);
            }
        }
        else {
            value = newOption;
        }

        this.setState({ options: [...options, newOption], value });

        if (isset(this.props.onCallBack)) {
            this.props.onCallBack(value);
        }
    };

    render() {
        const { options, value } = this.state;

        var { placeholder = '', components = { DropdownIndicator }, styles = customCreateStyles, isMulti = false, isClearable = false, error = false, ...props } = this.props;

        return (
            <CreatableSelect
                {...props}
                placeholder={placeholder}
                components={components}
                styles={styles}
                isMulti={isMulti}
                isClearable={isClearable}
                error={error}
                onChange={this.handleChange}
                onCreateOption={this.handleCreate}
                options={options}
                value={value}
            />
        );
    }
}

CreateSelect.propTypes = {
    /**
     * Add placeholder to select
     */
    placeholder: PropTypes.string,
    /**
     * Custom the select components
     */
    components: PropTypes.object,
    /**
     * Custom the select design
     */
    styles: PropTypes.object,
    /**
     * Make the values multiple or not
     */
    isMulti: PropTypes.bool,
    /**
     *  Show the button to clean the values from select
     */
    isClearable: PropTypes.bool,
    /**
     * Show error if something is invalid
     */
    error: PropTypes.bool,
    /**
     * Options for select
     */
    options: PropTypes.array,
    /**
     * The default value from select
     */
    defaultValue: PropTypes.object,
    /**
     *  Add max values from select
     */
    maxValue: PropTypes.number
};

export default Select;
