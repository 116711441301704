import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Text from '../text/Text';
import styled from 'styled-components';
import Config from '../../../base/config/Config';
import { clone, formatNumberSpaces, isset, issetDot, t, withSkeleton } from '../../../base/Utils';
import Icon from '../icon/Icon';
import ProfilePicture from '../profilePicture/ProfilePicture';
import Popup from '../popup/Popup';
import Input, { InputWrapper, InputSide } from '../input/Input';
import Select, { SelectCircle } from '../select/Select';
import Button from '../button/Button';
import Dropdown, { DropdownItem, DropdownSeparator } from '../dropdown/Dropdown';
import Checkbox from '../checkbox/Checkbox';
import i18n from '../../../i18n';
import LabelsRequests from './LabelsRequest';
import TableConstructor from '../../classes/Table';
import Table from '../table/Table';

const colors = Config.colors;
const types = ['Primary', 'Secondary', 'Tertiary'];
export const sizes = {
    'Small': '10px',
    'Medium': '12px',
    'Big': '14px',
};

export const iconSizes = {
    'Small': 'Micro',
    'Medium': 'Tiny',
    'Big': 'Small',
};

const labelSize = {
    'Small': '2px 10px',
    'Medium': '4px 12px'
};

export const borderRadiusTypes = { 'None': '0px', 'Rounded': '40px', 'SemiRounded': '10px' };

const MainLabel = styled.div`
    padding: ${props => isset(labelSize[props.labelSize], labelSize.Medium)};
    display: ${props => props.maxWidth ? 'flex' : 'inline-flex'};
    width: ${props => props.fullWidth ? '100%' : 'auto'};
    border-radius: ${props => isset(borderRadiusTypes[props.borderRadiusTypes], borderRadiusTypes.Rounded)};
    background: ${props => props.type === 'Tertiary' ? colors.white : props.background};
    ${props => props.type === 'Tertiary' && 'border: solid 1px ' + props.color};
    justify-content: space-between;
    align-items: center;
    overflow: ${props => props.visibleOverflow ? 'visible' : 'hidden'};
    box-sizing: border-box;
`;

const MainChild = styled.span`
    color: ${props => props.color};
    font-size: ${props => isset(sizes[props.size], sizes.Big)};
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: ${props => props.fullWidth ? '100%' : 'auto'};
    ${props => props.singleLine && { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }};
`;

const CountArea = styled.div`
    padding: 2px;
    font-size: 12px;
    color: white;
    background: ${props => props.color};
    border-radius: 20px;
    display: flex;
    align-items: center;
`;


const Label = ({ background, labelSize, children, fullWidth, icon, image, type, size, color, closeIcon, onClick, profiles, maxWidth, singleLine, visibleOverflow, tooltip, borderRadiusTypes, ...props }) => {
    if (type === 'Secondary') {
        background = color + 'opacity';
    }

    if (typeof icon === 'string') {
        icon = <Icon name={icon} color={color} size={isset(props.size, 'Medium')} style={{ marginRight: '8px' }} />;
    }

    if (closeIcon) {
        closeIcon = <Icon name="Remove" color={color} size={isset(props.size, 'Medium')} onClick={onClick} style={{ marginLeft: '8px', cursor: 'pointer' }} />;
    }

    color = color.toLowerCase();
    background = background.toLowerCase();
    color = isset(colors[`${color}`], colors.white);
    background = isset(colors[`${background}`], colors.blue);

    var profileArea = [];
    if (profiles) {
        var data = profiles.data;
        for (var id in data) {
            if (profiles.count && id >= 5) {
                break;
            }
            profileArea.push(<ProfilePicture type="Normal" background={data[`${id}`].photo} style={{ margin: '0px 2px' }} />);
        }
        if (profiles.count) {
            profileArea.push(<CountArea color={color}>+{profiles.count}</CountArea>);
        }
    }

    var style = props.style;

    delete props.style;

    return (
        <span tooltip={tooltip} style={{ width: fullWidth ? '100%' : 'auto', ...style }} {...props}>
            <MainLabel labelSize={labelSize} borderRadiusTypes={borderRadiusTypes} background={background} visibleOverflow={visibleOverflow} fullWidth={fullWidth} color={color} type={type} maxWidth={maxWidth} >
                <MainChild fullWidth={fullWidth} singleLine={singleLine} color={color} size={size}>
                    {image && <ProfilePicture type="Normal" background={image} style={{ marginRight: '8px' }} />}
                    {icon}
                    {children}
                </MainChild>
                <div style={{ display: 'flex' }}>
                    {profileArea}
                </div>
                {closeIcon}
            </MainLabel>
        </span>
    );
};

Label.propTypes = {
    /**
     * children for label
     */
    children: PropTypes.any,
    /**
     * Icon for label
     */
    icon: PropTypes.string,
    /**
     * Image for label
     */
    image: PropTypes.string,
    /**
     * children color
     */
    color: PropTypes.any,
    /**
     * Background color
     */
    background: PropTypes.any,
    /**
     * label border
     */
    type: PropTypes.oneOf(types),
    /**
     *  label child size
     */
    size: PropTypes.oneOf(Object.keys(sizes)),
    /**
     * Remove button for label
     */
    closeIcon: PropTypes.bool,
    /**
     * Add multiple profiles inside of label
     */
    profiles: PropTypes.object,
    /**
     * If you want the label to occupy the full of the width of the parent element
     */
    maxWidth: PropTypes.bool,
    /**
     *  change Border radius types
     */
    borderRadiusTypes: PropTypes.oneOf(Object.keys(borderRadiusTypes))
};

Label.defaultProps = {
    image: null,
    background: 'blue',
    type: 'Primary',
    color: 'white',
    icon: null,
    closeIcon: false,
    opacity: false
};

Label.skeleton = {
    children: 'str:15'
};

export default Label;
export const LabelSkeleton = (props) => withSkeleton(props, Label);

const Colors = [
    { value: 'blue', color: 'blue' },
    { value: 'yellow', color: 'yellow' },
    { value: 'green', color: 'green' },
    { value: 'red', color: 'red' },
    { value: 'orange', color: 'orange' },
    { value: 'purple', color: 'purple' },
    { value: 'grey', color: 'grey' },
    { value: 'brown', color: 'brown' }
];

const PopupLabelsArea = styled.div`
    padding: 0px 24px;
    margin-top: 24px;
`;

const PopupLabelsTopArea = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 23px;
`;

const CreateEditLabelArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 48px 0px 24px 0px;
`;

const ActiveLabels = (isActive, label, activeLabels, callback) => {
    if (isActive) {
        var index = activeLabels.findIndex((activeLabel) => activeLabel.id === label.id);
        if (index < 0) {
            activeLabels.push(label);
        }
    }
    else {
        for (var id in activeLabels) {
            if (activeLabels[`${id}`].id === label.id) {
                activeLabels.splice(id, 1); break;
            }
        }
    }
    callback(activeLabels);
};

const ApplyLabels = (activeLabels, initialLabels, area, referenceId, labelsPopupExtraData, labels, callback) => {
    var deleteArray = [];
    var postArray = [];
    var putArray = [];
    for (let initialLabel of initialLabels) {
        var index = activeLabels.findIndex((label) => label.id === initialLabel.id);
        if (index < 0) {
            deleteArray.push(initialLabel);
        }
    }
    for (let activeLabel of activeLabels) {
        index = initialLabels.findIndex((label) => label.id === activeLabel.id);
        if (index < 0) {
            postArray.push(activeLabel);
        }
        else if (activeLabel.text !== initialLabels[`${index}`].text) {
            putArray.push(activeLabel);
        }
    }

    var tempActiveLabels = [];
    for (var activeLabel of activeLabels) {
        if (labels.find((label) => activeLabel.id === label.id)) tempActiveLabels.push(activeLabel);
    }

    if (deleteArray.length < 1 && postArray.length < 1 && putArray.length < 1) callback({}, true, tempActiveLabels);

    deleteArray.map((label, key) => {
        return LabelsRequests.deleteAreaLabel(area, referenceId, label, { referenceIds: [referenceId], id: label.id, ...issetDot(labelsPopupExtraData, 'params', {}) }, (responseData) => {
            callback(responseData.history, (postArray.length < 1 || putArray.length < 1) ? parseInt(key) + 1 === deleteArray.length : false, tempActiveLabels);
        });
    });

    postArray.map((label, key) => {
        return LabelsRequests.postAreaLabel(area, referenceId, label, { referenceIds: [referenceId], id: label.id, ...issetDot(labelsPopupExtraData, 'params', {}) }, (responseData) => {
            callback(responseData.history, putArray.length < 1 ? parseInt(key) + 1 === postArray.length : false, tempActiveLabels);
        });
    });

    putArray.map((label, key) => {
        return LabelsRequests.putAreaLabel(area, referenceId, label, (responseData) => {
            callback(responseData.history, parseInt(key) + 1 === putArray.length);
        });
    });
};

const ArrayEqual = (firstArray = [], secondArray = []) => {
    if (firstArray.length !== secondArray.length) return false;

    for (var id = 0; id < firstArray.length; ++id) {
        if (firstArray[`${id}`].text !== secondArray[`${id}`].text) return false;
    }
    return true;
};

const CircleArea = styled.div`
	height: 18px;
    width: 18px;
	border-radius: 100%;
    background-color: ${props => props.color};
    float: right;
`;

export class LabelsPopup extends Component {
    constructor(props) {
        super(props);
        this.state = { labelData: {}, labels: [], searchedLabels: [], searchValue: '', isAllActive: -1, deleteLabelPopup: false, isEdit: false, activeLabels: clone(isset(props.data, [])), inputValue: '', errorInputMessage: '', selectOption: Colors, labelId: '', loading: false };
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            LabelsRequests.getLabels((responseData) => {
                responseData['searchedLabels'] = responseData['labels'];
                responseData['loading'] = false;

                this.setState(responseData, () => {
                    this.checkIfIsAllActive();
                });
            });
        });
    }

    showDeletePopup(labelId) {
        this.setState({ deleteLabel: true, labelId: labelId });
    }

    handleDeleteLabel(labelId) {
        var labels = this.state.labels;
        LabelsRequests.deleteLabel(labels, labelId, (responseData) => {
            responseData['deleteLabelPopup'] = false;
            this.setState(responseData, () => {
                this.handleSearch();
            });
        });
    }

    renderDeleteLabel() {
        var labelId = this.state.labelId;
        return <Popup
            isOpen={this.state.deleteLabelPopup}
            width='560'
            color="red"
            icon="Warning Sign"
            title={i18n.t('Delete Label?')}
            data={[
                {
                    text: i18n.t('Delete'),
                    type: 'Warning',
                    align: 'right',
                    onClick: () => this.handleDeleteLabel(labelId)
                },
                {
                    text: i18n.t('Cancel'),
                    type: 'Tertiary',
                    align: 'right',
                    onClick: () => this.setState({ deleteLabelPopup: false })
                }
            ]}
        >
            <div style={{ padding: '8px 24px 0px 24px' }}>
                <Text color="Grey" >{i18n.t('If this Label is deleted it will be removed from any report or content where was added. Once deleted, the label cannot be recovered.')}</Text>
            </div>
        </Popup>;
    }

    handleCreateEdit() {
        var area = isset(this.props.area, '');
        var { labels, inputValue, selectOption, isEdit, labelId } = this.state;
        var params = { text: inputValue, color: selectOption.value };
        var responseState = { createEditLabelPopup: false };
        if (isEdit) {
            LabelsRequests.putLabel(params, labels, labelId, (responseData) => {
                responseState = { ...responseData, ...responseState };
                if (isset(responseState.errorInputMessage, '').length > 0) responseState.createEditLabelPopup = true;
                this.setState(responseState, () => {
                    this.handleSearch();
                });
            });
        }
        else {
            LabelsRequests.postLabel(params, area, labels, (responseData) => {
                responseState = { ...responseData, ...responseState };
                if (isset(responseState.errorInputMessage, '').length > 0) responseState.createEditLabelPopup = true;
                this.setState(responseState, () => {
                    this.handleSearch();
                });
            });
        }
    }

    handleLabelColorChange(e, text, labelId) {
        var { labels, activeLabels } = this.state;
        var params = { text, color: e.value };
        var responseState = { loading: false };
        this.setState({ loading: true }, () => {
            LabelsRequests.putLabel(params, labels, labelId, (responseData) => {
                for (var key in activeLabels) {
                    var label = activeLabels[`${key}`];
                    if (label.id === labelId) {
                        activeLabels.splice(key, 1, { ...label, ...params });
                    }
                }
                responseState = { ...responseData, ...responseState };
                this.setState(responseState, () => {
                    this.props.callbackLabelPopup(activeLabels, {}, false, true);
                });
            });
        });
    }

    handleAllLabels(isActive) {
        var labels = this.state.labels;
        labels.map((label) => {
            this.handleActiveLabels(isActive, label);
            return null;
        });
        this.setState({ isAllActive: isActive });
    }

    handleActiveLabels(isActive, label) {
        var activeLabels = this.state.activeLabels;
        var labels = this.state.labels;
        for (var tempLabel of labels) {
            if (tempLabel.id === label.id) {
                if (isset(tempLabel.areas, []).length < 1) {
                    isset(tempLabel.areas, []).push({ area: this.props.area, count: issetDot(this.props.labelsPopupExtraData, 'params.referenceIds', [this.props.referenceId]).length });
                }
                else {
                    for (let area of tempLabel.areas) {
                        if (area.area === this.props.area) {
                            if (isActive)
                                area.count += issetDot(this.props.labelsPopupExtraData, 'params.referenceIds', [this.props.referenceId]).length;
                            else
                                area.count -= issetDot(this.props.labelsPopupExtraData, 'params.referenceIds', [this.props.referenceId]).length;
                        }
                    }
                }
                break;
            }
        }
        ActiveLabels(isActive, label, activeLabels, (responseLabels) => {
            this.setState({ activeLabels: responseLabels, labels }, () => {
                this.checkIfIsAllActive();
            });
        });
    }


    renderCreateEditLabel() {
        var { inputValue, selectOption, isEdit, errorInputMessage } = this.state;

        return <Popup
            isOpen={this.state.createEditLabelPopup}
            title={isEdit ? i18n.t('Edit Label') : i18n.t('Create New Label')}
            width='560'
            removeOverflow={true}
            data={[
                {
                    text: isEdit ? i18n.t('Edit') : i18n.t('Create'),
                    type: 'Default',
                    align: 'right',
                    onClick: () => inputValue.length > 0 ? this.handleCreateEdit() : this.setState({ errorInputMessage: i18n.t('Fill in the field') })
                },
                {
                    text: i18n.t('Cancel'),
                    type: 'Tertiary',
                    align: 'right',
                    onClick: () => this.setState({ createEditLabelPopup: false })
                }
            ]}
        >
            <CreateEditLabelArea>
                <div style={{ width: '100%', marginRight: '16px' }}>
                    <Input errorMessage={errorInputMessage} onChange={(e) => this.setState({ inputValue: e.target.value })} title={i18n.t('Content Label Name')} placeholder={isEdit ? i18n.t('Introduce Label Name') : i18n.t('Introduce New Label Name')} value={inputValue} />
                </div>
                <div style={{ width: '100px' }}>
                    <Select onChange={(e) => this.setState({ selectOption: e })} options={Colors} defaultValue={selectOption} />
                </div>
            </CreateEditLabelArea>
        </Popup>;
    }

    handleSearch(e) {
        var searchValue = issetDot(e, 'target') ? e.target.value : this.state.searchValue;
        var labels = this.state.labels;
        labels = labels.filter(label => label.text.toLowerCase().includes(searchValue.toLowerCase()));

        this.setState({ searchedLabels: labels, searchValue });
    }

    checkIfIsAllActive() {
        var labels = this.state.labels;
        var activeLabels = this.state.activeLabels;
        var isAllActive = 0;
        if (activeLabels.length === 0 || labels.length < 1) {
            isAllActive = -1;
        }
        else if (activeLabels.length === labels.length) {
            isAllActive = 1;
        }

        this.setState({ isAllActive });
    }

    handleOpenEdit(label) {
        var color = {};
        for (let tempColor of Colors) {
            if (tempColor.value === label.color) {
                color = tempColor;
            }
        }
        this.setState({ createEditLabelPopup: true, isEdit: true, inputValue: label.text, selectOption: color, labelId: label.id });
    }
    handleApplyLabels() {
        this.setState({ loading: true }, () => {
            ApplyLabels(this.state.activeLabels, this.props.data, this.props.area, this.props.referenceId, this.props.labelsPopupExtraData, this.state.labels, (history, close, activeLabels) => {
                this.props.callbackLabelPopup(activeLabels, history, close);
                if (close) {
                    this.setState({ loading: false });
                }
            });
        });
    }

    render() {
        var { activeLabels, searchedLabels, searchValue, labels } = this.state;
        var labelsTable = TableConstructor.start();

        var row = labelsTable.row({ type: 'head' });
        row.cell(<Checkbox isChecked={this.state.isAllActive} onCallback={(isActive) => this.handleAllLabels(isActive)} />, 'component', { spacing: 'compact' });
        row.cell(i18n.t('Label name'), null, { spacing: 'compact' });
        row.cell(i18n.t('Color'), null, { align: 'right' });
        row.cell(i18n.t('Uses'), null, { align: 'right' });
        row.cell('');
        labelsTable.addRow(row);

        searchedLabels.map((label) => {
            var row = labelsTable.row();
            var usedCounts = 0;
            var isActive = false;
            for (let activeLabel of activeLabels) {
                if (activeLabel.id === label.id) {
                    isActive = true; break;
                }
            }
            for (let area of isset(label.areas, [])) {
                usedCounts += area.count;
            }
            row.cell(<Checkbox disabled={activeLabels.length >= 10 && !isActive} isChecked={isActive} onCallback={(responseIsActive) => this.handleActiveLabels(responseIsActive, label)} />, 'component', { spacing: 'compact' });
            row.cell(<div tooltip={label.text} tooltip-position='center' style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} ><Icon size='Medium' name="Tag" color={'Blue'} style={{ marginRight: '16px' }} />{label.text}</div>, 'component', { spacing: 'compact' });
            row.cell(<CircleArea color={isset(Config.colors[label.color], Config.colors['blue'])} />, 'component', { align: 'right' });
            row.cell(formatNumberSpaces(usedCounts), null, { align: 'right' });
            row.cell(<Dropdown style={{ width: 'auto', float: 'right' }} position='Right' width="130px" trigger={<Icon name="Vertical Ellipsis" color="Grey" />}>
                <DropdownItem onClick={() => this.handleOpenEdit(label)}><span style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ marginRight: '16px' }} name="Pencil" color='Grey' />{i18n.t('Edit')}</span></DropdownItem>
                <DropdownSeparator />
                <DropdownItem onClick={() => this.setState({ deleteLabelPopup: true, labelId: label.id })}><span style={{ display: 'flex', alignItems: 'center' }}><Icon style={{ marginRight: '16px' }} name="Trash" color='Red' />{i18n.t('Delete')}</span></DropdownItem>
            </Dropdown>
            , 'component');
            labelsTable.addRow(row);
            return '';
        });
        if (this.props.type === 'small') {
            return <>
                <Popup
                    title={i18n.t('Manage Tags')}
                    isOpen={true}
                    width='420'
                    data={[
                        {
                            text: i18n.t('Save'),
                            type: 'Default',
                            align: 'right',
                            onClick: this.props.labelsPopupExtraData.title ? () => this.setState({ multipleMentionsPopup: true }) : () => this.handleApplyLabels(),
                            disabled: this.state.loading
                        },
                        {
                            text: i18n.t('Cancel'),
                            type: 'Tertiary',
                            align: 'right',
                            onClick: () => this.props.callbackLabelPopup(this.props.data, {}, true),
                            disabled: this.state.loading
                        }
                    ]}
                >
                    <div style={{ margin: '15px 0px' }}>
                        <InputWrapper style={{ paddingLeft: '0px' }} errorMessage={this.state.errorInputMessage}>
                            <InputSide>
                                <Dropdown style={{ margin: '0px 8px' }} horizontal arrow trigger={<SelectCircle circleSize='Small' style={{ background: Config.colors[isset(this.state.inputColorValue, Colors[0].color).toLowerCase()] }} />}>
                                    {Colors.map((color, key) => {
                                        if (color.color === isset(this.state.inputColorValue, Colors[0].color))
                                            return;
                                        return <DropdownItem key={key} style={{ padding: '8px' }} onClick={() => this.setState({ inputColorValue: color.value })}>
                                            <SelectCircle circleSize='Small' style={{ background: Config.colors[color.color.toLowerCase()] }} />
                                        </DropdownItem>;
                                    })}
                                </Dropdown>
                            </InputSide>
                            <Input onEnter={() => this.state.inputValue.length >= 40 ? null : this.state.inputValue.length > 0 ? this.handleCreateEdit() : this.setState({ errorInputMessage: i18n.t('Fill in the field') })} placeholder={i18n.t('Tag Name')} value={this.state.inputValue} onChange={(e) => this.setState({ inputValue: e.target.value })} />
                            <InputSide>
                                <Button style={{ padding: '4px 12px', fontSize: '12px', height: 'auto' }} text={i18n.t('Add Tag')} type="Default" disabled={this.state.loading || this.state.inputValue.length >= 40} onClick={() => this.state.inputValue.length > 0 ? this.handleCreateEdit() : this.setState({ errorInputMessage: i18n.t('Fill in the field') })} />
                            </InputSide>
                        </InputWrapper>
                        <div style={{ marginTop: '5px', marginRight: '15px', display: 'flex', justifyContent: 'flex-end' }}>
                            <Text color="Grey">{this.state.inputValue.length + '/40'}</Text>
                        </div>
                    </div>
                    <div className='test' style={{ overflow: 'auto', maxHeight: '200px' }} data-disable={this.state.loading}>
                        {labels ? labels.map((label, key) => {
                            var exist = false;
                            var usedCount = 0;
                            var color = isset(label.color, 'Blue');
                            var text = isset(label.text, '');
                            var used = isset(label.areas, [{}]);

                            for (let activeLabel of activeLabels) {
                                var id = isset(activeLabel.id, '');
                                if (id === label.id) {
                                    exist = true;
                                }
                            }

                            for (let count of used) {
                                usedCount += count.count;
                            }

                            return (
                                <div key={key} style={{ margin: '2px 0px' }}>
                                    <Label labelSize='Small' visibleOverflow fullWidth type='Secondary' color={label.color}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', height: '24px', padding: '2px 0px' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Dropdown contentStyle={labels.length > 1 && key === labels.length - 1 ? { bottom: '0px' } : {}} style={{ marginRight: '8px' }} horizontal arrow trigger={<SelectCircle circleSize='Small' style={{ background: Config.colors[isset(label.color.toLowerCase(), Colors[0].color).toLowerCase()] }} />}>
                                                    {Colors.map((color, key) => {
                                                        if (color.color === isset(label.color.toLowerCase(), Colors[0].color))
                                                            return;
                                                        return <DropdownItem key={key} style={{ padding: '8px' }} onClick={() => this.handleLabelColorChange(color, text, label.id)}>
                                                            <SelectCircle circleSize='Small' style={{ background: Config.colors[color.color.toLowerCase()] }} />
                                                        </DropdownItem>;
                                                    })}
                                                </Dropdown>
                                                <Text singleLine color={color} >{text}</Text>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                                                <Text style={{ marginRight: '5px' }} color={color} >{formatNumberSpaces(usedCount)}</Text>
                                                <Icon size='Large' color='Grey' name={(exist ? 'Minus' : 'Add')} style={{ cursor: 'pointer' }} onClick={() => this.handleActiveLabels(!exist, label)} />
                                                <Icon size='Large' color="Grey" tooltip={i18n.t('Delete')} name="Trash" style={{ cursor: 'pointer' }} onClick={() => this.setState({ deleteLabelPopup: true, labelId: label.id })} />
                                            </div>
                                        </div>
                                    </Label>
                                </div>
                            );
                        }) : null}
                    </div>
                </Popup>
                <Popup
                    isOpen={this.state.multipleMentionsPopup}
                    title={isset(this.props.labelsPopupExtraData.title, '').replace('{name}', issetDot(this.state.labelData.label, 'text', ''))}
                    titleColor={this.props.labelsPopupExtraData.titleColor}
                    width='420'
                    data={[
                        {
                            text: i18n.t('Add Label'),
                            type: this.props.labelsPopupExtraData.buttonType,
                            align: 'right',
                            onClick: () => this.handleApplyLabels(),
                            disabled: this.state.loading
                        },
                        {
                            text: i18n.t('Cancel'),
                            type: 'Tertiary',
                            align: 'right',
                            onClick: () => this.setState({ multipleMentionsPopup: false }),
                            disabled: this.state.loading
                        }
                    ]}
                >
                    <Text style={{ marginBottom: '35px' }} color="heavygrey" >{isset(this.props.labelsPopupExtraData.message, '').replace('{name}', issetDot(this.state.labelData.label, 'text', ''))}</Text>
                </Popup>
            </>;
        }
        return (
            <>
                <Popup
                    side="Right"
                    width="630"
                    fullHeight
                    isOpen={true}
                    data={[
                        {
                            text: i18n.t('Save'),
                            type: 'Default',
                            align: 'right',
                            onClick: () => this.handleApplyLabels(),
                            disabled: this.state.loading
                        },
                        {
                            text: i18n.t('Cancel'),
                            type: 'Tertiary',
                            align: 'right',
                            onClick: () => this.props.callbackLabelPopup(this.props.data, {}, true),
                            disabled: this.state.loading
                        }
                    ]}
                    title={i18n.t('Manage Labels')}
                    helpPage='Labels'
                    description={i18n.t('Use labels to categorize, organize and analyze your content in any way that suits you')}
                >
                    <PopupLabelsArea data-disable={this.state.loading} >
                        <PopupLabelsTopArea>
                            <InputWrapper style={{ maxWidth: '360px' }} >
                                <Input placeholder={i18n.t('Search')} value={searchValue} onChange={(e) => this.handleSearch(e)} />
                                <InputSide><Icon style={{ marginRight: '5px' }} name='Search' size='Big' /></InputSide>
                            </InputWrapper>
                            <Button icon="Add" iconSide="Left" onClick={() => this.setState({ createEditLabelPopup: true, inputValue: '', selectOption: Colors[0] })} >{i18n.t('Create New Label')}</Button>
                        </PopupLabelsTopArea>
                        <Table data={labelsTable.get('object', 'local')} />
                    </PopupLabelsArea>
                </Popup>
                {this.renderCreateEditLabel()}
                {this.renderDeleteLabel()}
            </>
        );
    }
}

const DropdownButtons = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const SelectCreateTitleArea = styled.div`
    padding: 8px 16px 0px 16px;
    display: flex;
    align-items: center;
`;

const SelectCreateInputArea = styled.div`
    display: flex;
    padding: 24px 16px;
    align-items: center;
`;

const SelectLabelArea = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
    background-color: ${props => props.isActive && '#0A5C9C0A'};
`;
export class LabelsSelect extends Component {
    constructor(props) {
        super(props);
        this.state = { searchValue: '', labels: [], searchedLabels: [], tempActiveLabels: isset(clone(props.data), []), activeLabels: isset(clone(props.data), []), createLabel: false, inputValue: '', errorInputMessage: '', createSelectOption: Colors[0] };
    }

    componentDidMount() {
        LabelsRequests.getLabels((responseData) => {
            responseData['searchedLabels'] = responseData['labels'];
            this.setState(responseData);
        });
    }

    handleCreateLabel() {
        var params = { color: this.state.createSelectOption.value, text: this.state.inputValue };
        var area = isset(this.props.area, '');
        var labels = this.state.labels;

        LabelsRequests.postLabel(params, area, labels, (responseData) => {
            this.setState(responseData);
        });
    }

    handleSearch(e) {
        var labels = this.state.labels;
        labels = labels.filter(label => label.text.toLowerCase().includes(e.target.value.toLowerCase()));

        this.setState({ searchedLabels: labels, searchValue: e.target.value });
    }

    handleActiveLabels(isActive, label) {
        var tempActiveLabels = this.state.tempActiveLabels;
        ActiveLabels(isActive, label, tempActiveLabels, (responseLabels) => {
            this.setState({ tempActiveLabels: responseLabels });
        });
    }

    handleApplyLabels() {
        var tempActiveLabels = this.state.tempActiveLabels;
        var initialLabels = this.state.activeLabels;
        var { area, referenceId, labelsPopupExtraData } = this.props;
        ApplyLabels(tempActiveLabels, initialLabels, area, referenceId, labelsPopupExtraData, this.state.labels, () => {
            this.setState({ activeLabels: clone(tempActiveLabels), isOpen: false });
        });
    }

    render() {
        var { inputValue, errorInputMessage, labels, tempActiveLabels, activeLabels, searchedLabels, searchValue, createSelectOption, createLabel } = this.state;

        return <Dropdown onCallback={(isOpen) => this.setState({ isOpen: isOpen })} isOpen={this.state.isOpen} triggerOnClick={() => this.setState({ isOpen: !this.state.isOpen })} hasBorder style={{ width: '280px' }} width='280px' trigger={<div style={{ display: 'flex', alignItems: 'center' }} ><Icon style={{ marginRight: '8px' }} name='Tag' color='Grey' /> {activeLabels.length > 0 ? activeLabels.length > 1 ? activeLabels.length + ' ' + t('Selected') : <Label>{activeLabels[0].text}</Label> : <Text color='Grey' >{i18n.t('Add Labels')}</Text>}</div>} arrow>
            {createLabel ?
                <>
                    <SelectCreateTitleArea>
                        <Icon name='Chevron Left' style={{ marginRight: '16px', cursor: 'pointer' }} color='Black' onClick={() => this.setState({ createLabel: false })} />
                        <Text size='Big' >{i18n.t('Create New Label')}</Text>
                    </SelectCreateTitleArea>
                    <DropdownSeparator />
                    <SelectCreateInputArea>
                        <div style={{ maxWidth: '170px', marginRight: '8px' }}>
                            <Input errorMessage={errorInputMessage} onChange={(e) => this.setState({ inputValue: e.target.value })} placeholder={i18n.t('Introduce Label Name')} />
                        </div>
                        <Select onChange={(e) => this.setState({ createSelectOption: e })} options={Colors} defaultValue={createSelectOption} />
                    </SelectCreateInputArea>
                    <DropdownSeparator />
                    <div style={{ padding: '8px' }}>
                        <DropdownButtons>
                            <Button type='Tertiary' onClick={() => this.setState({ isOpen: false })} >{i18n.t('Cancel')}</Button>
                            <Button disabled={inputValue.trim().length < 1} onClick={() => this.handleCreateLabel()} >{i18n.t('Create')}</Button>
                        </DropdownButtons>
                    </div>
                </>
                :
                <>
                    <div style={{ padding: '13px 8px 0px 8px' }}>
                        <InputWrapper >
                            <Input placeholder={i18n.t('Search')} value={searchValue} onChange={(e) => this.handleSearch(e)} />
                            <InputSide><Icon style={{ marginRight: '5px' }} name='Search' size='Big' /></InputSide>
                        </InputWrapper>

                    </div>
                    <DropdownSeparator />
                    <div style={{ maxHeight: '260px', overflow: 'auto' }}>
                        {searchedLabels.length > 0 ?
                            searchedLabels.map((label, key) => {
                                var isActive = false;
                                for (let tempActiveLabel of tempActiveLabels) {
                                    if (tempActiveLabel.id === label.id) {
                                        isActive = true; break;
                                    }
                                }
                                return <SelectLabelArea isActive={isActive} key={key} >
                                    <div>
                                        <Icon name="Tag" color={isActive ? 'Blue' : 'Grey'} style={{ marginRight: '16px' }} />
                                        <Text >{label.text}</Text>
                                    </div>
                                    <Checkbox isChecked={isActive} onCallback={(isActive) => this.handleActiveLabels(isActive, label)} />
                                </SelectLabelArea>;
                            })
                            :
                            <div style={{ margin: '0px 16px' }}>
                                <Text >{i18n.t('No Labels available.')}</Text>
                            </div>
                        }
                    </div>
                    <DropdownSeparator />
                    <div style={{ padding: '8px' }}>
                        <DropdownButtons>
                            <Button type='Link' style={{ padding: '0px' }} icon='Add' iconSide='Left' onClick={() => this.setState({ createLabel: true })} >{i18n.t('Create New Label')}</Button>
                            <Button disabled={labels.length <= 0 || ArrayEqual(tempActiveLabels, activeLabels)} onClick={() => this.handleApplyLabels()} >{i18n.t('Apply')}</Button>
                        </DropdownButtons>
                    </div>
                </>}
        </Dropdown>;
    }
}
