import i18n from '../../../i18n';
import { sendMessage } from '../../containers/OldPages';

export default () => ([
    {
        target: '[data-id="produce-content-andie-see-content-message"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Hurray! This is your first content created with ANDIE!'),
        content: i18n.t('Now, click the "I want to see it" button to behold your freshly crafted content.'),
        hideBackButton: false,
        showSkipButton: true,
        disableBeacon: true,
        afterStep: () => {
            sendMessage({ type: 'closePopups' });
        }
    },
    {
        target: '.text_area_manager:not([style="display: none;"]) .html_textarea:first-of-type',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Welcome to your content!'),
        content: i18n.t('The distinctive rainbow effect border indicates that the content was exclusively created by ANDIE. However, feel free to edit, modify, or improve the content according to your preferences.'),
        showSkipButton: true,
        disableBeacon: true
    },
    {
        target: '.text_area_manager:not([style="display: none;"]) .ai-actions:not(:empty)',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Fine-tune and improve your content'),
        content: i18n.t('Want to improve your content? Here you can select other options for ANDIE to execute. Now that you understand how ANDIE operates, you can start creating exceptional content!'),
        hideBackButton: false,
        showSkipButton: false,
        disableBeacon: true
    }
]);
