import React, { Component } from 'react';
import Notification from './Notification';
import Notifications from '../notifications/Notifications';
import Session from '../../../base/Session';
import styled from 'styled-components';
import NotificationsRequest from '../notifications/NotificationRequest';
import Broker from '../../classes/Broker';


const NotificationsArea = styled.div`
	position: fixed;
	display: flex;
    flex-direction: column;
    z-index: 100;
    bottom: 1em;
    right: 1em;
    * {
        margin-top: 6px;
    }
`;

export default class NotificationArea extends Component {
    constructor(props) {
        super(props);

        this.state = { notifications: [], session: Session.getParams() };

        Notification.setCallback((data) => this.setState({ notifications: data }));
        Session.setCallback(() => this.setState({ session: Session.getParams() }));
    }

    closeNotification(id) {
        Notification.destroy(id);
    }

    playAudio() {
        var audio = new Audio('/alert.mp3');
        audio.currentTime = 0;
        audio.play();
    }

    isListening = false;

    startListening() {
        //TODO Update with new API when implemented
        var session = this.state.session;

        if (!this.isListening) {
            Broker.addListener('notification', (data) => {
                var { notification = null, user = null } = data;
                if (parseInt(user) === parseInt(session.id)/* || user === team*/) {
                    NotificationsRequest.getNotification(notification, (id, area, content, date, photo, readed, type, actions) => {
                        Notification.add(id, area, content, date, photo, readed, type, actions);
                    });
                }
            });
            this.isListening = true;
        }
    }

    render() {
        this.startListening();
        var notifications = this.state.notifications;

        for (let notification of notifications) {
            if (notification.sound) {
                if (window.userInteracted()) {
                    this.playAudio();
                }
                notification.sound = false;
            }
        }

        return <React.Fragment>
            <NotificationsArea>
                {
                    notifications.map((item, index) => {
                        return (
                            <Notifications key={index} type={item.type} priority={item.type} title={item.area} buttonArea={item.actions} image={item.photo} date={item.date} message={item.content} onClose={() => this.closeNotification(item.id)} />
                        );
                    })
                }
            </NotificationsArea>
        </React.Fragment>;
    }
}
