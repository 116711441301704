import { getCookie, isset } from '../../../base/Utils';
import i18n from '../../../i18n';
import TourController from '../TourController';

export default () => ([
    {
        target: '.social_profiles',
        waitForTarget: true,
        disableBeacon: true,
        getFromLegacy: true,
        title: i18n.t('Select one or more profiles.'),
        content: i18n.t('You can choose where to publish your posts every time you create them.'),
        showSkipButton: false,
        sleepBeforeStart: 500,
        hideNextButton: true,
        spotlightClicks: true
    },
    {
        target: '.textarea_inner',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Elevate your content'),
        content: i18n.t('Enhance your content with text, hashtags, and images for a captivating experience.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '.textarea_inner .social_options',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Make your content shine'),
        content: i18n.t('Our versatile tools allow you to craft Instagram stories, reels, TikTok videos, tag people, create albums, and much more.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '.social_preview_warning',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Preview Your Posts Before Posting'),
        content: i18n.t('Preview your posts to see how they will look on different social networks.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        nextButtonText: i18n.t('Finish'),
        afterStep: () => {
            var contentProducerNextStep = isset(getCookie('contentProducer'), null);
            if (contentProducerNextStep !== null) {
                if (contentProducerNextStep == 'ScheduleModal') {
                    TourController.load('Basic.ScheduleModal');
                }
                if (contentProducerNextStep == 'PublishModal') {
                    TourController.load('Basic.PublishModal');
                }
            }
        }
    }
]);
