import '../../../resources/css/icons.css';

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { capitalize, isset } from '../../../base/Utils';
import Config from '../../../base/config/Config';

export const colors = Config.colors;

export const sizes = {
    'Micro': '10px',
    'Tiny': '12px',
    'Small': '14px',
    'Medium': '18px',
    'Large': '20px',
    'Big': '24px',
    'Huge': '36px',
    'Giant': '60px',
};

export const matches = {
    'facebook': 'Facebook Circle',
    'instagram': 'Instagram Circle',
    'youtube': 'Youtube Circle',
    'twitter': 'Twitter Circle',
    'linkedin': 'Linkedin Circle',
    'reddit': 'Reddit Circle',
    'web': 'Web Circle',
    'wordpress': 'Wordpress Circle',
    'tumblr': 'Tumblr Circle',
    'pinterest': 'Pinterest Circle',
    'fblike': 'Reaction Likes',
    'love': 'Reaction Love',
    'wow': 'Reaction Wow',
    'haha': 'Reaction Haha',
    'sad': 'Reaction Sad',
    'angry': 'Reaction Angry',
    'anger': 'Reaction Angry',
    'comments': 'Comment',
    'video': 'Play Circle',
    'other': 'Ellipsis Circle',
    'retweet': 'Repost',
    'favorite': 'Heart',
    'save': 'Download',
    'upvote': 'Angle Up',
    'downvote': 'Angle Down',
    'score': 'Reddit Circle',
    'pin': 'Pinterest Circle',
    'repin': 'Repost',
    'view': 'Eye',
    'google_my_business': 'Gmb Circle',
    'tiktok': 'Tiktok Circle',
    'meta_business': 'Meta Business Circle'
};

const MainIcon = styled.i`
    display: inline-block;
    vertical-align: baseline;
    opacity: 1;
    margin: 0px;
    width: auto !important;
    height: auto !important;
    font-family: 'Icons';
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    speak-as: none;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    font-size: ${props => isset(sizes[props.size], 'Small')};
    ${props => props.color && 'color: ' + props.color};
`;

const Icon = ({ color, name, size, tooltip, ...props }) => {
    var auxName = name.toLowerCase();
    if (color) {
        color = color.toLowerCase();

        color = isset(colors[`${color}`], isset(colors[`${auxName}`], Config.colors.grey));
    }
    else {
        color = isset(colors[`${auxName}`], Config.colors.grey);
    }

    if (isset(matches[`${auxName}`])) name = matches[`${auxName}`];

    var classValue = 'Icon ' + capitalize(name);

    var html = (
        <span tooltip={tooltip} {...props}><MainIcon className={classValue} size={size} color={color}></MainIcon></span>
    );

    return html;
};

Icon.propTypes = {
    /**
     * Size of the icon
     */
    size: PropTypes.oneOf(Object.keys(sizes)),
    /**
     * Color of the icon [Check Config]
     */
    color: PropTypes.any, //TODO: limit to config colors
    /**
     * Icon class name if you want
     */
    name: PropTypes.string,
};

Icon.defaultProps = {
    size: 'Big',
    name: ''
};

export default Icon;
