import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const MainContainer = styled.div`
	position: relative;
	height: 100%;
	width: 100%;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: ${props => props.horizontal ? 'row' : 'column'};
`;

const MainContent = styled.div`
	position: relative;
	flex: 0 0 auto;
`;

const MainPriority = styled.div`
	position: relative;
	height: 100%;
	flex-grow: 1;
    ${props => !props.noOverflow && (props.noScroll ? { overflow: 'hidden' } : { overflow: 'auto', overflowX: 'hidden' })}
`;

const FullPage = forwardRef(({ children, horizontal, ...props }, ref) => {
    return <MainContainer ref={ref} horizontal={horizontal} {...props}>{children}</MainContainer>;
});

export const PageContent = ({ children, horizontal, ...props }) => {
    return <MainContent horizontal={horizontal} {...props}>{children}</MainContent>;
};

export const PagePriority = forwardRef(({ children, horizontal, noScroll, noOverflow, ...props }, ref) => {
    return <MainPriority ref={ref} noScroll={noScroll} noOverflow={noOverflow} horizontal={horizontal} {...props}>{children}</MainPriority>;
});

PagePriority.displayName = 'PagePriority';

FullPage.propTypes = {
    /**
     * Switch the container to vertical or horizontal
     */
    horizontal: PropTypes.bool,
    /**
     * Children elements of the component
     */
    children: PropTypes.any,
};

FullPage.defaultProps = {
    horizontal: false
};

FullPage.displayName = 'FullPage';
export default FullPage;

