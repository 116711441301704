
class Config {
    static environment = process.env.REACT_APP_ENVIRONMENT;
    static base = process.env.REACT_APP_BASE_PATH;
    static host = process.env.REACT_APP_HOST;
    static publicHost = process.env.REACT_APP_PUBLIC_HOST;
    static backendHost = process.env.REACT_APP_BACKEND_HOST;
    static oldHost = process.env.REACT_APP_OLD_HOST;
    static brokerHost = process.env.REACT_APP_BROKER_HOST;
    static kbHost = process.env.REACT_APP_KB_HOST;
    static fileHost = process.env.REACT_APP_FILES_HOST;
    static externalHost = process.env.REACT_APP_EXTERNAL_HOST;
    static blogHost = process.env.REACT_APP_BLOG_HOST;
    static cookieHost = process.env.REACT_APP_COOKIE_HOST;
    static fenixAppHost = process.env.REACT_APP_FENIX_APP_HOST;

    static captchaV2Key = process.env.REACT_APP_CAPTCHA_V2_KEY;
    static captchaV3Key = process.env.REACT_APP_CAPTCHA_V3_KEY;

    static mainLang = 'en';
    static langFiles = {
        en: require('../../resources/locales/en.json'),
        pt: require('../../resources/locales/pt.json'),
        es: require('../../resources/locales/es.json'),
        'pt-BR': require('../../resources/locales/pt-BR.json'),
        fr: require('../../resources/locales/fr.json')
    };

    static dateFormat = 'DD/MM/YYYY';
    static dateFormatHour = 'DD/MM/YYYY HH:mm';
    static dateFormatHourNoYear = 'DD/MM HH:mm';
    static dateFormatHourGMB = 'DD/MM YYYY HH:mm';

    static oldPasswordMinLength = 6;
    static passwordMinLength = 9;
    static passwordMaxLength = 100;

    static maxExportReports = 6;

    static contactEmail = 'sayhello@swonkie.com';
    static contactAddress = 'Rua do Progresso nº 140';
    static contactLocation = 'Vila Nova de Famalicão, Portugal';
    static academy = 'https://www.swonkieacademy.com/welcome'

    static colors = {
        positive: '#16D39A',
        neutral: '#9E9E9E',
        negative: '#EF5350',
        primaryPallet: ['#512DA8', '#F2711C', '#FBBD08', '#2EABE3', '#0A5C9C', '#8D2DAD', '#D64947', '#795548', '#00BCD4', '#78909C', '#16D39A'],
        instagram: '#C52C7C',
        facebook: '#1878f3',
        twitter: '#00ACED',
        linkedin: '#0077b5',
        pinterest: '#CD2129',
        tumblr: '#314358',
        youtube: '#FF0000',
        web: '#2EABE3',
        google_my_business: '#4989F5',
        meta_business: '#4D5461',
        tiktok: '#000000',
        webopacity: '#2EABE329',
        reddit: '#ff4500',
        darkblue: '#0F31F2',
        wordpress: '#444',
        darkGrey: '#424242',
        grey: '#9E9E9E',
        greyopacity: 'rgba(158, 158, 158, 0.3)',
        orange: '#F2711C',
        orangeopacity: '#F2711C29',
        yellow: '#FBBD08',
        yellowopacity: 'rgba(251, 189, 8, 0.3)',
        heavyBlue: '#0F31F2',
        blue: '#0A5C9C',
        blueopacity: '#0A5C9C0A',
        purpleopacity: '#512DA829',
        azureblue: '#0E8AE8',
        lightblue: '#30B3F0',
        white: '#FFFFFF',
        // light: "#FFF",
        green: '#16D39A',
        lightgreen: '#16E0A4',
        greenopacity: 'rgba(22, 211, 154, 0.3)',
        purple: '#512DA8',
        violet: '#8D2DAD',
        violetopacity: '#8D2DAD29',
        brown: '#795548',
        brownopacity: '#79554829',
        heavygrey: '#757575',
        mediumgrey: '#BDBDBD',
        lightgrey: '#EEE',
        red: '#EF5350',
        redopacity: 'rgba(239, 83, 80, 0.3)',
        black: '#212121',
        alto: '#DEDEDE',
        //Hover
        veniceblue: '#084D82',
        mountainmeadow: '#13BA88',
        valencia: '#D64947',
        valenciaopacity: '#D6494729',
        irisblue: '#00BCD4',
        irisblueopacity: '#00BCD429',
        bermudagrey: '#78909C',
        bermudagreyopacity: '#78909C29',
        transparentblue: '#0A5C9C29',
        bigscreengrey: 'hsla(0,0%,100%,.16)',
        blueswonkieopacity: '#0a5c9c29',
        bluecyanopacity: '#00bcd429',
        ruby: '#d60153',
        pink: '#ff1f70',
        rosePink: '#d71867',
        darkpink: '#AF216C',
        darkorange: '#A94F14',
        darkorangeopacity: '#FBD4BB'
    };

    static fakerIP = null;
}

export default Config;
