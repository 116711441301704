import React from 'react';
import { redirect, t } from '../../../base/Utils';
import i18n from '../../../i18n';

export default () => ([
    {
        disableBeacon: false,
        title: i18n.t('Take your social media to the next level'),
        content: i18n.t('Swonkie reports give you access to detailed metrics to help you optimize your social presence.'),
        placement: 'center',
        target: 'body',
        nextButtonText: i18n.t('Let’s see it'),
        style: { minWidth: '600px' },
    },
    {
        target: '[data-id="topbar-menu-analytics"]',
        title: i18n.t('Start Analysing'),
        content: i18n.t('Click here to access your reports'),
        showSkipButton: false,
        spotlightClicks: true,
        hideNextButton: true,
        afterStep: () => {
            setTimeout(() => {
                redirect('/analytics/dashboards');
            }, 200);
        }
    },
    {
        target: '[data-id="analytics-create-new-report"]:not([style$="display: none;"])',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Create your report'),
        content: t(<>You can create reports for any of your connected accounts. Click <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>Create New Report</span> to get started.</>),
        showSkipButton: true,
        spotlightClicks: true,
        sleepBeforeStart: 2500,
        hideNextButton: true
    },
    {
        target: '.sweet-alert',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Report Basic Information'),
        content: i18n.t('Here, you can choose the profile, date interval, add tags, title, and more to your report.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '#metrics_accordion',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Report metrics'),
        content: i18n.t('Choose the metrics you want to see in your report. You can edit this later if you want.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
        nextButtonText: i18n.t('Close')
    }
]);
