import React from 'react';
import PropTypes from 'prop-types';
import { capitalize, colorApplication, formatNumberSpaces, isNumeric, isset, withSkeleton } from '../../../base/Utils';
import Text from '../text/Text';
import styled, { keyframes, css } from 'styled-components';
import Config from '../../../base/config/Config';

const colors = Config.colors;

export const heights = {
    Tiny: '4px',
    Small: '9px',
    Normal: '17px',
    Big: '24px'
};

const ProgressBarArea = styled.div`
    display: flex;
    align-items: center;
    ${props => props.type === 'Tiny' && { alignItems: 'flex-end', flexDirection: 'column' }}
`;

const BackgroundBar = styled.div`
    background: ${props => props.noBackground ? 'Transparent' : Config.colors.lightgrey};
    width: 100%;
    height: ${props => isset(heights[props.type]) ? heights[props.type] : heights['Small']};
    border-radius: ${props => props.roundSquare ? '4px' : props.square ? '0px' : '20px'};
    overflow: hidden;
    position: relative;
    display: flex;
`;
const moving = keyframes`
    from {
        margin-left: -25%;
    }
    to {
        margin-left: 100%;
    }
`;

const Line = styled.div`
    position: relative;
    opacity: 1;
    background: #0e8ae8;
    height: 100%;
    border-radius: ${props => props.roundSquare ? '4px' : props.square ? '0px' : '20px'};
    ${props => props.animated ?
        css` width: 25%; animation: ${moving} 2s infinite;` : {
            borderTopRightRadius: '0px',
            borderBottomRightRadius: '0px'
        }}
`;

const ProgressBar = ({ color, width, type, total, square, noBackground, background, data, description, roundSquare, showText, valueType, ...props }) => {
    var dataset = data || [];
    var colorCounts = {};
    var progressBar = [];
    var totalPercentage = 0;
    var maxValue = 0;

    background = background.toLowerCase();
    background = isset(colors[`${background}`], 'alto');
    noBackground && (background = 'transparent');

    color = color.toLowerCase();
    color = isset(colors[`${color}`], colors.blue);

    type = capitalize(type);

    if (dataset.length && dataset !== []) {
        for (let key in dataset) {
            var progressData = dataset[`${key}`];
            if (isset(progressData.color)) {
                var finalColor = progressData.color;
                if (finalColor.includes('primaryPallet')) {
                    var aux = colorApplication(finalColor, colorCounts, 'title');
                    var totalWidth = '';
                    colorCounts = aux.counts;
                    finalColor = aux.color;
                }

                if (!isNumeric(progressData.value)) progressData.value = 0;

                if (isset(dataset[1])) {
                    totalPercentage = Math.round(((isset(dataset[0], '').value + isset(dataset[1], '').value) / total) * 100);
                } else {
                    totalPercentage = Math.round((progressData.value / total) * 100);
                }

                totalWidth = Math.round((progressData.value / total) * 100);

                if (!isNumeric(totalPercentage)) totalPercentage = 0;

                totalPercentage += '%';
                maxValue += progressData.value;
                finalColor = isset(Config.colors[isset(finalColor, '').toLowerCase()], Config.colors.purple);

                progressBar.push(<Line square={square} type={type} tooltip={progressData.value} style={{ borderRadius: key > 0 && '0px', background: finalColor, width: Number.isNaN(totalWidth) ? '0%' : totalWidth + '%' }} />);
            }
        }
        return (<ProgressBarArea type={type} {...props}>
            {showText && <span style={{ flex: '0 0 auto', marginRight: (type !== 'Tiny' && '16px'), width: (type !== 'Tiny' && '30px'), whiteSpace: 'nowrap' }} >{valueType === 'number' ? formatNumberSpaces(maxValue).toString() : (formatNumberSpaces(totalPercentage) + '%')}</span>}
            <BackgroundBar noBackground={noBackground} type={type} square={square} style={{ width: width, background: square ? '#f5f5f5' : background }}>
                {progressBar}
            </BackgroundBar>
        </ProgressBarArea >);
    } else {

        totalPercentage = Math.round((width / total) * 100);
        if (!isNumeric(totalPercentage)) totalPercentage = 0;
        totalPercentage += '%';

        return <ProgressBarArea type={type} {...props}>
            {(showText && type === 'Tiny') &&
                <div style={{ display: 'flex', flexFlow: 'column' }}>
                    <Text singleLine={true} color="black" style={{ marginRight: '10px', overflow: 'visible' }} >{formatNumberSpaces(width)}</Text>
                    {description && <Text singleLine={true} color="Grey">{description}</Text>}
                </div>
            }
            <BackgroundBar style={{ background: background }} roundSquare={roundSquare} {...props} tooltip={formatNumberSpaces(width) + ' ' + description} noBackground={noBackground} square={square} type={type} >
                {roundSquare && <Text style={{ left: '4px', top: '4px', position: 'absolute', zIndex: 1 }} color="White" >{description}</Text>}
                <Line roundSquare={roundSquare} animated={type === 'Animated'} type={type} style={type === 'Animated' ? { background: color } : { width: totalPercentage, background: color }} />
                {roundSquare && <Text style={{ right: '4px', top: '4px', position: 'absolute', zIndex: 1 }} color="White" >{formatNumberSpaces(width) + '/' + formatNumberSpaces(total)}</Text>}
            </BackgroundBar>
        </ProgressBarArea>;
    }
};

ProgressBar.propTypes = {
    /**
     * Type of the Progress Bar (Position only work with Animated Progress Bar)
     */
    type: PropTypes.oneOf(['Animated', 'Normal', 'Tiny']),
    /**
     * Color of the Progress Bar
     */
    color: PropTypes.string,
    /**
     * Background of the Progress Bar
     */
    background: PropTypes.string,
    /**
     * Width total of Progress Bar
     */
    width: PropTypes.string,
    /**
     * Percentage of the Progress Bar
     */
    total: PropTypes.number,
    /**
     * Border radius of Progress Bar
     */
    square: PropTypes.bool,
    /**
     * Border radius of Progress Bar
     */
    noBackground: PropTypes.bool,
    /**
     *  Remove text from progressBar
     */
    noText: PropTypes.bool,
};

ProgressBar.defaultProps = {
    type: 'Normal',
    square: false,
    noBackground: false,
    background: 'alto',
    color: 'blue',
};

ProgressBar.skeleton = {
    children: 'str:0:0'
};

export default ProgressBar;
export const ProgressBarSkeleton = (props) => withSkeleton(props, ProgressBar);

