import React from 'react';
import Moment from 'moment';
import i18n from '../i18n';
import Config from './config/Config';
import history from '../history';
import Request from '../common/classes/Request';
import moment from 'moment';
import Session from './Session';
import Broker from '../common/classes/Broker';

class Utils {
}

export const checkAvailability = (array, value, setDefault = false) => {
    if (array.indexOf(value) < 0) {
        if (setDefault)
            return array[0];
        else
            return '';
    } else
        return value;
};

export const checkUsage = (value) => {
    if (value !== null && value !== '')
        return value;
    else
        return '';
};

export const checkArray = (value) => {
    return Array.isArray(value);
};

export const jsonDecode = (data) => {
    return JSON.parse(data);
};

export const urlEncode = (value) => {
    return encodeURIComponent(value);
};

export const redirect = (url, target) => {
    url = isset(url, null);

    if (!url) {
        window.location.reload();
    } else if (url.substr(0, 1) === '/') {
        history.push(url);
    } else {
        target = target || '_self';
        window.open(url, target).focus(); // eslint-disable-line
    }
};

export const fullPath = () => {
    var location = window.location;
    return (location.pathname + location.search + location.hash).trim();
};

export const checkResponse = (data) => {
    return (data.data.status && (data.status === 200 || data.status === 401));
};

export const getAPIStatusLayout = (value) => {
    var data = { text: '', color: '' };

    switch (value) {
    case 'normal':
        data.text = 'Normal';
        data.color = 'Good';
        break;
    case 'unstable':
        data.text = 'Unstable';
        data.color = 'Medium';
        break;
    case 'down':
        data.text = 'Down';
        data.color = 'Bad';
        break;
    default:
    }

    return data;
};

export const size = (val) => {
    if (typeof val === 'object' && val !== null) {
        if (Array.isArray(val)) {
            return val.length;
        } else {
            return Object.keys(val).length;
        }
    }

    return 0;
};

export const clone = (_class, recursive) => {
    var obj = '';
    recursive = isset(recursive) ? recursive : true;


    if (typeof _class === 'object' && _class !== null) {
        if (Array.isArray(_class)) {
            obj = _class.slice(0);
        } else {
            obj = Object.assign({}, _class);
        }
    } else {
        obj = _class;
    }

    if (!recursive) {
        return obj;
    }

    for (var key in obj) {
        if (typeof obj[`${key}`] === 'object' && obj[`${key}`] !== null) {
            obj[`${key}`] = clone(obj[`${key}`]);
        }
    }

    return obj;
};

export const validateEmail = (value) => {
    var re = /^[a-zA-Z0-9.\-_+]+[@]+[a-zA-Z0-9.\-_]+[.]+[a-zA-Z]{2,12}$/;
    return re.test(String(value).toLowerCase());
};

export const validatePhoneNumber = (value) => {
    var re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{3,6}$/im;
    return re.test(String(value));
};

export const validateIP = (value) => {
    var re = /^(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})\.(25[0-5]|2[0-4]\d|[01]?\d{1,2})$/;
    return re.test(String(value).toLowerCase());
};

export const getBrowserLanguage = () => {
    var browserLanguage = window.navigator.language.toLowerCase();

    if (browserLanguage.includes('pt')) {
        if (!browserLanguage.includes('br'))
            browserLanguage = 'pt';
    }
    else {
        var langFiles = Object.keys(Config.langFiles);
        var fileId = langFiles.findIndex((langFile) => browserLanguage.includes(langFile));
        if (fileId >= 0)
            browserLanguage = langFiles[`${fileId}`];
        else
            browserLanguage = 'en';
    }

    return browserLanguage;
};

export const convertDateFormat = (date, format = Config.dateFormat) => {
    var newDate = new Date(date);
    var momentObj = moment(newDate);
    return date = momentObj.format(format);
};

export const convertDate = (value, withHour = false, utc = false) => {
    if (utc)
        return Moment.utc(value * 1000).format((withHour) ? Config.dateFormatHour : Config.dateFormat);
    return Moment(value * 1000).format((withHour) ? Config.dateFormatHour : Config.dateFormat);
};

export const getUserIp = (callback) => {
    callback = callback || function () { };
    Request.send({
        url: 'https://www.cloudflare.com/cdn-cgi/trace', method: 'GET', headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    }, (response) => {
        var ipRegex = /^ip=(?<ip>[\d.]*)$/gm;
        var matches = ipRegex.exec(response);
        callback((matches || [])[1] || '');
    }, false);
};

export const time = (time) => {
    if (time === null) {
        return '';
    }

    switch (typeof time) {
    case 'number':
        break;
    case 'string':
        time = new Date(time).getTime() / 1000;
        break;
    case 'object':
        if (time.constructor === Date) time = time.getTime() / 1000;
        break;
    default:
        time = new Date().getTime() / 1000;
    }

    var time_formats = [
        [60, 'seconds', 1], // 60
        [120, '1 minute ago', '1 minute from now'], // 60*2
        [3600, 'minutes', 60], // 60*60, 60
        [7200, '1 hour ago', '1 hour from now'], // 60*60*2
        [86400, 'hours', 3600], // 60*60*24, 60*60
        [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
        [604800, 'days', 86400], // 60*60*24*7, 60*60*24
        [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
        [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
        [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
        [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
        [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
        [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
        [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
        [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];

    const actualTime = new Date().getTime() / 1000;
    var seconds = (actualTime - time),
        token = 'ago',
        list_choice = 1;

    if (seconds === 0) {
        return 'Just now';
    }

    if (seconds < 0) {
        seconds = Math.abs(seconds);
        token = 'from now';
        list_choice = 2;
    }

    var i = 0,
        format;

    while ((format = time_formats[i++]))
        if (seconds < format[0]) {
            if (typeof format[2] === 'string')
                return format[`${list_choice}`];
            else
                return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
        }

    return time;
};

//Translation auxiliar function
export const t = (string, fields, components, recursive) => {
    recursive = isset(recursive) ? recursive : true;
    components = components || [];

    if (typeof string === 'object') {
        var children = clone(string.props.children, false);
        string = '';
        children.map((child, key) => {
            if (typeof child === 'string') {
                string += child;
            } else {
                var aux = 'NA';

                if (typeof child.props.children === 'string') {
                    aux = child.props.children;
                }

                string += '<' + key + '>' + aux + '</' + key + '>';
            }

            return '';
        });

        string = t(string, fields, components, false);
        var splitted = string.split(/<(?:|\/)\d*>/g);

        splitted = splitted.filter((value) => value !== '');
        var componentCount = 0;
        splitted.map((string, key) => {
            var child = children[`${key}`];
            if (isset(child)) {
                child = clone(child, false);
                if (typeof child === 'string') {
                    child = string;
                } else {
                    if (isset(components[`${componentCount}`]))
                        child = clone(components[`${componentCount}`], false);

                    child.props = clone(child.props, false);
                    child.props.children = string;

                    if (isNumeric(child.type)) {
                        child.type = 'font';
                    }

                    componentCount++;
                }
            }
            children[`${key}`] = child;

            return '';
        });

        return children;
    }

    if (typeof string === 'string') {
        if (recursive && string.split(/(<(?:|\/)[\dA-Za-z]*>)/g).length > 1) {
            return t(componentText(string), fields, components);
        }

        fields = fields || {};

        for (var key in translationKeys) {
            var translation = translationKeys[`${key}`];
            try {
                var matches = string.match(translation);
            } catch {
                matches = [];
            }
            if (matches && isset(matches.groups) && Object.keys(matches.groups).length > 0) {
                fields = Object.assign(matches.groups, fields);
                for (var id in fields) {
                    fields[`${id}`] = t(fields[`${id}`]);
                }
                string = key; break;
            }
        }
    }

    return i18n.t(string, fields);
};

var translationKeys = {};

export const loadTranslationsKeys = () => {
    var transEN = Config.langFiles.en;
    translationKeys = {};
    for (var key in transEN) {
        if (transEN[`${key}`].includes('{{') || transEN[`${key}`].includes('</')) {
            var string = transEN[`${key}`];

            string = '^' + escapeRegExp(string).split('{{').join('(?<').split('}}').join('>.*?)') + '$';

            translationKeys[`${key}`] = string;
        }
    }
};

export const escapeRegExp = (string) => {
    return string.replace(/[.*+?^${}()|[]\]/g, '$&');
};

export const ampmTo24 = (time) => {
    var hours = Number(time.match(/^(\d+)/)[1]);
    var AP = time.match(/\s(.*)$/);
    if (!AP) AP = time.slice(-2);
    else AP = AP[1];
    if (AP === 'PM' && hours < 12) hours = hours + 12;
    if (AP === 'AM' && hours === 12) hours = hours - 12;
    var Hours24 = hours.toString();
    if (hours < 10) Hours24 = '0' + Hours24;

    return Hours24 + 'h';
};

export const number = (num, digits, start = 1, limit) => {
    digits = digits || 0;

    var si = [
        { value: 1, symbol: '' },
        { value: 1E3, symbol: 'k' },
        { value: 1E6, symbol: 'M' },
        { value: 1E9, symbol: 'G' },
        { value: 1E12, symbol: 'T' },
        { value: 1E15, symbol: 'P' },
        { value: 1E18, symbol: 'E' }
    ];

    var rx = /\.0+$|(\.\d*[1-9])0+$/;
    var i;

    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[`${i}`].value && (!isset(limit) || limit >= si[`${i}`].value) && num >= start) {
            break;
        }
    }

    return (num / si[`${i}`].value).toFixed(digits).replace(rx, '$1') + si[`${i}`].symbol;
};

export const checkUrl = (url) => {
    if (url && typeof url === 'string' && url.substring(0, 4) !== 'http' && url.substring(0, 5) !== 'https') {
        const newUrl = 'https://' + url;
        return newUrl;
    }

    return url;
};

export const removeDuplicate = (arr) => {
    var seen = {};

    return arr.filter((item) => {
        var hashValue = hash(JSON.stringify(item));
        return Object.prototype.hasOwnProperty.call(seen, hashValue) ? false : (seen[`${hashValue}`] = true);
    });
};

export const isNumeric = (str) => {
    try {
        return str && !isNaN(str) && !isNaN(parseFloat(str)) && !isNaN(parseInt(str));
    } catch {
        return false;
    }
};

export const formatNumberSpaces = (value) => {
    if (typeof value === 'string') value = parseFloat(value);
    if (!isset(value) || !value) return 0;

    if (isNumeric(value) && value.toString().length > parseFloat(value).toFixed(2).toString().length)
        value = parseFloat(value).toFixed(2);

    value = value.toString();

    var st1 = '';
    var newNumber = '';
    var glue = '';
    var b = '';

    st1 = value.split('.');
    glue = '.';

    if (typeof st1[1] === 'undefined') {
        st1 = value.split(',');
        glue = ',';
    }

    if (typeof st1[1] === 'undefined') {
        st1 = value.split('%');
        glue = '%';
    }

    value = st1[0];
    if (typeof st1[1] === 'undefined') {
        glue = '';
    }


    if (value.length > 3) {
        b = 0;

        for (var a = (value.length - 1); a >= 0; a--) {
            newNumber = value[`${a}`] + newNumber;
            b++;
            if (b === 3) {
                newNumber = ' ' + newNumber;
                b = 0;
            }
        }
    } else {
        newNumber = value;
    }

    st1[0] = newNumber.trim();

    return st1.join(glue);
};

export const unformat_number = (value) => {
    return value.split(' ').join('');
};

export const checkNumber = (number) => {
    if (number < 0) {
        return false;
    } else {
        return true;
    }
};

export const capitalize = (string) => {
    string = string.toString();
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const removeSpecialChar = (string) => {
    return string = string.replace(/[^a-zA-Z ]/g, ' ');
};

export const unique = () => {
    return btoa((new Date().getUTCMilliseconds() * Math.random()) + Math.random());
};

export const setCookie = (name, value, daysToLive, domain) => {
    var cookie = name + '=' + encodeURIComponent(value);

    if (typeof daysToLive === 'number') {
        cookie += '; Path=/; max-age=' + (daysToLive * 24 * 60 * 60);
    }
    if (typeof domain === 'string') {
        cookie += '; domain=' + domain;
        if (typeof daysToLive !== 'number') {
            cookie += '; Path=/';
        }
    }

    document.cookie = cookie;
};

export const getCookie = (name) => {
    var cookieArr = document.cookie.split(';');

    for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[`${i}`].split('=');

        if (name === cookiePair[0].trim()) {
            return decodeURIComponent(cookiePair[1].trim());
        }
    }

    return null;
};

// Available types: localStorage, sessionStorage
export const storageAvailable = (type) => {
    var storage;
    try {
        storage = window[`${type}`];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return e instanceof DOMException && (
            e.code === 22 ||
            e.code === 1014 ||
            e.name === 'QuotaExceededError' ||
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            (storage && storage.length !== 0);
    }
};

export const localStorageItem = (action, key, value, expiration) => {
    var savedItems = JSON.parse(localStorage.getItem('savedItems') || '{}');

    action = action || null;
    key = key || 'key';
    value = value || '';
    expiration = expiration || null;

    var result = null;

    for (var siKey in savedItems) {
        var item = savedItems[`${siKey}`];
        if (item.expiration
            && item.expiration <= (new Date().getTime() + 100)
            && localStorage.getItem(siKey)) {
            localStorage.removeItem(siKey);
            delete savedItems[`${siKey}`];
        }
    }

    switch (action) {
    case 'getItem':
        result = localStorage.getItem(key);
        break;
    case 'removeItem':
        if (isset(savedItems[`${key}`])) delete savedItems[`${key}`];

        result = localStorage.removeItem(key);
        break;
    default:
        result = localStorage.setItem(key, value);

        if (expiration) {
            savedItems[`${key}`] = {
                expiration: new Date().getTime() + (expiration * 1000)
            };
        }
    }

    localStorage.setItem('savedItems', JSON.stringify(savedItems));

    return result;
};

export const colorApplication = (colorKey, counts, area) => {
    var aux = colorKey.split(':');
    var palleteKey = null;

    if (aux.length > 1) {
        colorKey = aux[0];
        palleteKey = aux[1];
    }

    if (colorKey !== 'primaryPallet') return { color: colorKey.toLowerCase(), counts: counts };

    const colors = Config.colors;
    var color = colors[`${colorKey}`] || colors[Object.keys(colors)[0]];

    if (Array.isArray(color)) {
        if (palleteKey === null) {
            if (!isset(counts[`${area}`]))
                counts[`${area}`] = {};

            if (!isset(counts[`${area}`][`${colorKey}`]))
                counts[`${area}`][`${colorKey}`] = 0;

            palleteKey = counts[`${area}`][`${colorKey}`];
            counts[`${area}`][`${colorKey}`]++;
        }

        color = color[`${palleteKey}`];
        for (var id in colors) {
            if (colors[`${id}`] === color) {
                color = id;
                break;
            }
        }
    }

    return { color: color, counts: counts };
};

export const readTime = (content) => {

    content = content || '';

    content = content.trim();

    var time = parseInt(content.length) * 50;

    if (time > 0) {
        time += 1000;
    }

    return time;
};

export const hexToRgb = (hexStr) => {
    var col = {};
    col.r = parseInt(hexStr.substr(1, 2), 16);
    col.g = parseInt(hexStr.substr(3, 2), 16);
    col.b = parseInt(hexStr.substr(5, 2), 16);
    return col;
};

var sourceLoginData = {};

export const openSourceLogin = (source, action, channel, callback) => {
    source = source || null;
    action = action || 'new_account';
    channel = channel || null;
    callback = callback || function () { };

    var width = 1000;
    var height = 600;
    var top = (window.screen.height / 2) - (height / 2);
    var left = (window.screen.width / 2) - (width / 2);
    var settings = 'menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes,width=' + width + ',height=' + height + ',top=' + top + ',left=' + left;

    if (source) {
        sourceLoginData = {
            popup: window.open(Config.oldHost + 'OAuth/' + source + '.php?' + action + (channel ? '&channelId=' + channel : ''), 'Swonkie Social Network Login', settings), // eslint-disable-line
            //popup: window.open(Config.host + 'social-networks', '_blank'), // eslint-disable-line
            callback: callback,
            interval: setInterval(() => {
                if (sourceLoginData.popup && sourceLoginData.popup.closed) {
                    sourceLoginData.timeout = setTimeout(() => {
                        sourceLoginData.callback();
                        sourceLoginData = {};
                    }, 1000);
                    clearInterval(sourceLoginData.interval);
                }
            }, 1000)
        };
        sourceLoginData.popup.focus();
        listenSourceLogin();
    }
};

var isListening = false;
const listenSourceLogin = () => {
    if (!isListening) {
        Broker.addListener('get-something', (data) => {
            if (isset(data.user, null) == Session.getParams().id) {
                var jsonData = JSON.parse(data.data);

                if (isset(jsonData.status, null) === 1) {
                    sourceLoginData.callback(true);
                    clearInterval(sourceLoginData.interval);
                    clearTimeout(sourceLoginData.timeout);
                    sourceLoginData = {};
                }
            }
        });
        isListening = true;
    }
};

export const getMaxDate = (date) => {
    var maxDate = date.split('-');
    if (maxDate[2]) {
        maxDate[2] = parseInt(maxDate[2]) - 1;
        maxDate[1] = parseInt(maxDate[1]) - 1;
        if (maxDate[2] < 1) {
            if (maxDate[1] < 1) {
                maxDate[0] = parseInt(maxDate[0]) - 1;
                maxDate[1] = 11;
                maxDate[2] = 31;
            }
            else
                maxDate[1] = parseInt(maxDate[1]) - 1;
        }
        maxDate = moment(new Date(maxDate[0], maxDate[2] < 1 ? maxDate[1] + 1 : maxDate[1], maxDate[2])).format('YYYY-MM-DD');
    }
    return maxDate;
};

export const isset = (data, def) => {
    if (typeof def !== 'undefined') {
        return isset(data) ? data : def;
    }
    return !(typeof data === 'undefined');
};

export const issetDot = (data, string, def) => {
    data = isset(data, {});
    def = isset(def, '');
    var splitted = isset(string, '').split('.');

    for (var key in splitted) {
        var item = splitted[`${key}`];

        if (isset(data[`${item}`])) {
            data = data[`${item}`];
        } else {
            data = def; break;
        }
    }

    return data;
};

export const issetBulk = (data, strings, defs) => {
    data = isset(data, {});
    strings = isset(strings, []);
    defs = isset(defs, []);

    for (var key in strings) {
        var string = strings[`${key}`];
        data[`${string}`] = issetDot(data, string, isset(defs[`${key}`], ''));
    }

    return data;
};

export const hash = (value) => {
    var hash = 0, i, chr;

    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0;
    }

    return btoa(hash);
};

export const getLanguage = (simple) => {
    simple = isset(simple, false);
    var language = isset(i18n.language, 'en');

    if (language.includes('-')) {
        language = language.split('-');
        language[1] = language[1].toUpperCase();
        language = language.join('-');
    }

    if (!isset(Config.langFiles[`${language}`])) {
        language = Config.mainLang;
    }

    if (simple) {
        language = language.split('-')[0];
    }

    return language.toLowerCase();
};

export const availableHelpPages = [
    'social-networks',
    'analytics',
    'produce-content',
    'influencers',
    'listening',
    'listening-restriction',
    'roadmap',
    'listening-advanced-query',
    'twitter-changes'
];

export const getKBUrl = (page, article) => {
    var lang = getLanguage();

    if (lang === 'en') lang = 'en-us';


    if (availableHelpPages.indexOf(page) === -1) {
        page = 'getting-started';
    }

    return Config.kbHost + lang + (article ? '/article/' : '/category/') + page;

};

export const componentText = (string, tagName, component) => {
    // eslint-disable-next-line
    var splitted = string.split(/(<(?:|\/)[^\/>].*?>)/gm);
    // eslint-disable-next-line
    tagName = tagName || '[^\/>].*?';
    component = component || <></>;

    if (splitted.length > 1) {
        var object = [];
        var inTag = null;

        splitted.map((string) => {
            // eslint-disable-next-line
            var tag = string.match('^<(|\/)(' + tagName + ')>$');
            var key = object.length - 1;

            var obj = object[`${key}`];
            if (inTag) {
                obj.props.children = string;
            } else if (tag) {
                if (tag[1] === '') {
                    var tagSplitted = tag[2].split(' ');
                    var tagType = tagSplitted[0];
                    var props = {};
                    tagSplitted.shift();
                    if (tagSplitted.length > 0) {
                        for (var id in tagSplitted) {
                            var tagObject = tagSplitted[`${id}`].split('=');
                            if (!tagSplitted[`${id}`].includes('=')) {
                                tagSplitted[id - 1] += (' ' + tagSplitted[`${id}`]);
                                tagSplitted.splice(id, 1);
                                tagObject = tagSplitted[id - 1].split('=');
                            }
                            var splittedTagSplitted = tagObject;
                            // eslint-disable-next-line
                            props[`${splittedTagSplitted[0]}`] = splittedTagSplitted[1].replace(/[\"]/g, '');
                        }
                    }

                    object.push(clone(component, false));
                    key++;
                    obj = object[`${key}`];
                    obj.props = clone({ ...obj.props, ...props }, false);
                    obj.type = tagType;
                } else {
                    tag = null;
                }
            } else {
                object.push(string);
            }

            inTag = tag;
            return null;
        });

        component = clone(<></>, false);
        component.props = clone(component.props, false);
        component.props.children = object;
    }

    var componentText = string;

    if (isset(component.props.children)) {
        componentText = component;
    }


    return componentText;
};

export const highlightKeyword = (text, keyword) => {
    if (text) {
        if (typeof keyword === 'object') {
            keyword = keyword.join('|');
            if (keyword.indexOf(')') >= 0)
                keyword = keyword.replace(/\)/g, '\\)');

            const pattern = new RegExp(`(${keyword})`, 'ig'); // eslint-disable-line

            text = text.replace(pattern, match => `<highlight>${match}</highlight>`);
        }

        return componentText(text, 'highlight');
    }
};

export const isMediaValid = (url, type, callback) => {
    if (!url) return false;

    type = type || 'photo';
    callback = callback || function () { };

    if (type === 'video') {
        var media = document.createElement('video');

        var tries = 0;
        var videoCheck = setInterval(() => {
            if (media.duration && !isNaN(media.duration)) {
                callback(true);
            } else {
                if (videoCheck && tries > 10) {
                    clearInterval(videoCheck);
                }

                tries++;
            }
        }, 1000);

        media.onerror = () => {
            if (videoCheck) clearInterval(videoCheck);

            callback(false);
        };
    } else {
        media = new Image();
        media.onload = () => callback(true);
        media.onerror = () => callback(false);
    }

    media.onload = () => callback(true);
    media.onerror = () => {
        if (videoCheck) clearInterval(videoCheck);

        callback(false);
    };

    media.src = url;
};

export const isObjectEmpty = (object) => {
    for (var key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key))
            return false;
    }
    return true;
};

export const numberReachedLimit = (number) => {
    if (number >= 999) {
        return '+999';
    } else if (number < 999) {
        return number;
    }
};

/*
* Usage: export default (props) => withSkeleton(props, YourComponent);
*/
export const withSkeleton = (props, Component) => {
    var auxProps = clone(props, false);

    var skeletonProps = {};

    for (var id in Component.skeleton) {
        var prop = Component.skeleton[`${id}`];
        if (prop.substr(0, 4) === 'str:') {
            var splitted = prop.split(':');
            if (splitted.length === 3) {
                prop = '▮'.repeat(random(splitted[1], splitted[2]));
            }
            else {
                prop = '▮'.repeat(Math.round(splitted[1] * 1.5));
            }
        }
        else if (prop === 'invisible') {
            prop = ' ⠀⠀⠀⠀⠀⠀⠀⠀⠀ ';
        }
        else if (prop === '') {
            prop = '▮'.repeat(random(8, 13));
        }
        skeletonProps[`${id}`] = prop;
    }
    auxProps = Object.assign(auxProps, clone(isset(Component.defaultProps, {})));
    auxProps = Object.assign(auxProps, skeletonProps);
    auxProps['data-skeleton'] = true;


    return <Component {...auxProps} />;
};

export const random = (minimum, maximum) => {
    return Math.floor(Math.random() * (maximum - minimum + 1)) + minimum;
};

export const normalize = (string) => {
    if (typeof string === 'string') {
        return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
    return string;
};

export const getColumn = (matrix, col) => {
    var column = [];

    for (var mat of matrix) {
        column.push(mat[`${col}`]);
    }

    return column;
};

export const convertDateUnix = (date, format = Config.dateFormat) => {
    return date = moment.unix(date).format(format);
};

var timeouts = {};

export const timeout = (id = null, callback = () => { }, time = 1000, data = {}, repeat = false, maxRepeats = -1) => {
    if (id) {
        if (typeof timeouts[`${id}`] === 'undefined' || !timeouts[`${id}`].obj) {
            timeouts[`${id}`] = {
                count: 0,
                maxCount: maxRepeats,
                obj: (repeat ?
                    setInterval((id, data) => {
                        if (timeouts[`${id}`].count >= timeouts[`${id}`].maxRepeats && timeouts[`${id}`].maxRepeats >= 0) {
                            timeouts[`${id}`].clear();
                        }
                        callback(data, timeouts[`${id}`]);
                        timeouts[`${id}`].count++;
                    }, time, id, data) :
                    setTimeout((id, data) => {
                        callback(data, timeouts[`${id}`]); timeouts[`${id}`].clear();
                    }, time, id, data)
                ),
                clear: (repeat ?
                    function () {
                        clearInterval(this.obj);
                        this.obj = null;
                    } :
                    function () {
                        clearTimeout(this.obj);
                        this.obj = null;
                    }
                )
            };
        }

        return timeouts[`${id}`];
    }

    return false;
};

export default Utils;
