import React from 'react';
import { t } from '../../../base/Utils';
import i18n from '../../../i18n';

export default () => ([
    {
        target: '[data-id="produce-content-publish-button"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Magic is one button away'),
        content: t(<>When your content is ready and just the way you like it, click <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>Publish</span> to send it to your profiles</>),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideNextButton: true,
        spotlightClicks: true
    },
    {
        target: '[data-id="produce-content-post-options-box"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('How to publish'),
        content: i18n.t('Here, you can see the options available to you for publishing to your profiles. Some options depend on the social networks you have selected.'),
        showSkipButton: true,
        sleepBeforeStart: 500,
        hideBackButton: false,
    },
    {
        target: '[onclick="confirm_swal();"]',
        waitForTarget: true,
        getFromLegacy: true,
        title: i18n.t('Ready to publish'),
        content: t(<>When your content is ready and you&apos;ve chosen how to publish, the last step is to click <span style={{ color: '#ff1f70', fontWeight: 'bold' }}>Confirm</span>. Your post will then be automatically published.</>),
        sleepBeforeStart: 500,
        hideBackButton: false,
        nextButtonText: i18n.t('Finish'),
    }
]);
